import { formatByte } from './../utils/commonUtil';

// TO Do translations should add to translation.json
export const filterTypeValues = [
	{ label: 'ANALYTICS_FILTER_TYPE_CUSTOM', value: 'Custom' },
	{ label: 'ANALYTICS_FILTER_TYPE_30_DAYS', value: 'Last 30 Days' },
	{ label: 'ANALYTICS_FILTER_TYPE_LAST_WEEK', value: 'Last Week' },
	{ label: 'ANALYTICS_FILTER_TYPE_TODAY', value: 'Today' },
];

export const topUrlsColumns = [
	{ id: 'url', label: 'ANALYTICS_URL', minWidth: 300, sort: true, link: 'url' },
	{ id: 'hits', label: 'ANALYTICS_HITS', minWidth: 150, sort: true },
	{
		id: 'size',
		label: 'ANALYTICS_DISTRIBUTED_VOLUME',
		minWidth: 120,
		align: 'right',
		format: (value) => formatByte(value),
		sort: true,
	},
];

export const osColumns = [
	{ id: 'name', label: 'Operating System', minWidth: 300, sort: true },
	{
		id: 'y',
		label: 'ANALYTICS_VOLUME',
		minWidth: 120,
		align: 'right',
		format: (value) => formatByte(value),
		sort: true,
	},
];

export const locationColumns = [
	{ id: 'name', label: 'ANALYTICS_CITY', minWidth: 300, sort: true },
	{
		id: 'hits',
		label: 'ANALYTICS_HITS',
		minWidth: 120,
		align: 'right',
		sort: true,
	},
	{
		id: 'value',
		label: 'ANALYTICS_VOLUME',
		minWidth: 120,
		align: 'right',
		format: (value) => formatByte(value),
		sort: true,
	},
];

export const socialColumns = [
	{ id: 'Title', label: 'COMMON_TITLE', minWidth: 300, sort: true },
	{
		id: 'Views',
		label: 'ANALYTICS_VIEWS',
		minWidth: 120,
		align: 'right',
		sort: true,
	},
	{
		id: 'WatchTimeMinutes',
		label: 'ANALYTICS_TOTAL_WATCH_TIME',
		minWidth: 120,
		align: 'right',
		sort: true,
	},
	{
		id: 'Shares',
		label: 'ANALYTICS_SHARES',
		minWidth: 120,
		align: 'right',
		sort: true,
	},
	{
		id: 'Likes',
		label: 'ANALYTICS_LIKES',
		minWidth: 120,
		align: 'right',
		sort: true,
	},
	{
		id: 'Comments',
		label: 'ANALYTICS_COMMENTS',
		minWidth: 120,
		align: 'right',
		sort: true,
	},
];
