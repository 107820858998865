import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

export const SUPPORTED_LANGUAGE = {
	en: {
		code: 'en',
		name: 'COMMON_LANGUAGE_ENGLISH',
	},
	sv: {
		code: 'sv',
		name: 'COMMON_LANGUAGE_SWEDISH',
	},
	da: {
		code: 'da',
		name: 'COMMON_LANGUAGE_DENMARK',
	},
	no: {
		code: 'no',
		name: 'COMMON_LANGUAGE_NORWEGIAN',
	},
	fi: {
		code: 'fi',
		name: 'COMMON_LANGUAGE_FINLAND',
	},
};

const LanguageSelector = () => {
	const { t, i18n } = useTranslation();
	const [menuAnchorEl, setAnchorEl] = useState();

	const handleLanguageChange = (lang: string) => {
		setAnchorEl(undefined);
		if (lang === i18n.language) {
			return;
		}
		Cookies.set('selected_language', lang);
		window.location.reload();
	};

	return (
		<>
			<Button
				className="language-selector"
				onClick={(e: any) => {
					setAnchorEl(e.currentTarget);
				}}
				startIcon={<LanguageIcon />}
				endIcon={Boolean(menuAnchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			>
				{SUPPORTED_LANGUAGE[(i18n.resolvedLanguage as keyof typeof SUPPORTED_LANGUAGE) ?? 'en'].code}
			</Button>
			<Menu
				id="languageList"
				anchorEl={menuAnchorEl}
				onClose={() => {
					setAnchorEl(undefined);
				}}
				open={Boolean(menuAnchorEl)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				{Object.values(SUPPORTED_LANGUAGE).map(({ code, name }) => {
					return (
						<MenuItem
							className="language-menu-item"
							key={code}
							onClick={() => {
								handleLanguageChange(code);
							}}
							selected={code === i18n.resolvedLanguage}
						>
							{t(name)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default LanguageSelector;
