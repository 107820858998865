import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './FileTableListMenu.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class FileTableListMenu extends Component {
	state = {
		menuAnchor: this.props.menuAnchor,
	};

	handleMenuClose = (e) => {
		this.props.handleMenuClose(e);
	};

	render() {
		const { t, handleMenuClose, menuAnchor } = this.props;

		return (
			<>
				<Menu
					id="simple-menu"
					anchorEl={menuAnchor}
					keepMounted
					open={Boolean(menuAnchor)}
					onClose={handleMenuClose}
				>
					<MenuItem id="download" onClick={this.handleMenuClose}>
						{t('COMMON_DOWNLOAD')}
					</MenuItem>
					<MenuItem id="exchange" onClick={this.handleMenuClose}>
						{t('COMMON_EXCHANGE')}
					</MenuItem>
					<MenuItem id="delete" onClick={this.handleMenuClose}>
						{t('COMMON_DELETE')}
					</MenuItem>
				</Menu>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FileTableListMenu);
