import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { stringify, parse } from 'subtitle';
import smoothscroll from 'smoothscroll-polyfill';
import 'date-input-polyfill';
import LoadingOverlay from 'react-loading-overlay';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import moment from 'moment';
import charming from 'charming';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import LinearProgress from '@material-ui/core/LinearProgress';

import TimeInput from '../../../reusable/TimeInput/TimeInput';
import { mediaNames, messageTypes, UPLOAD_FAILED } from '../../../constants/mediaConstants';
import { generateId } from '../../../services/stringHelperService';
import { showNotification } from '../../../actions/notificationActions';
import { renditionTypes } from '../../../constants/mediaConstants';
import LivePlayer from '../../../components/LivePlayer';
import { languageSelector } from '../../../constants/subtitleLanguageSelector';
import SpeechToTextControlBar from './SpeechToTextSections/SpeechToTextControlBar';
import SpeechToTextHeader from './SpeechToTextSections/SpeechToTextHeader';
import {
	setPlayerPosition,
	startTranscription,
	saveTranscription,
	loadTranscription,
	resetSpeechToTextReducer,
	toggleShowPreview,
	setClickedPosition,
	playButtonPlay,
	playButtonPause,
	saveSrt,
	setRawTranscriptionText,
	addTranslationDetailsToDatabase,
	setAppMode,
	setIsMountedSubtitleUpdated,
	setSubtitleIsUpdating,
} from '../../../actions/speechToTextActions';
import {
	startSubtitleUploadProcess,
	removeSubtitle,
	getMediaQueryDetails,
	sendMediaScreenChanged,
} from '../../../actions/publishActions';
import {
	googleTranslate,
	googleTranslateAPIKey,
	googleTranslateRestrictedAccounts,
} from '../../../utils/googleTranslate';
import deleteIcon from '../../../assets/images/x.png';
import downloadIcon from '../../../assets/images/dl.png';
import { getDetails } from './../../../services/mediaDisplayService';
import { defaultVideoWidth, defaultVideoHeight } from './../../../services/inspectScreenHelperService';
import { SpeechToTextDeleteSubtitleDialog } from './SpeechToTextSections/SpeechToTextDeleteSubtitleDialog';

import './SpeechToTextScreen.scss';

class SpeechToTextScreen extends PureComponent {
	initialState = {
		mobileView: false,
		mediaDetails: this.props.mediaDetails,
		routeAfterSave: '',
		selectedResourceId: '',
		selectedMenu: this.defaultMenuKey,
		isSaveInProgress: false,
		subtitleData: [],
		subtitleArrays: [],
		liveMediaChannelId: '',
		lastRoute: '',
		videoWidth: defaultVideoWidth,
		videoHeight: defaultVideoHeight,
		playerConfigurationId: 'default',
		selectedLanguage: 'en',
		transcriptionData: '',
		focusedLanguage: '',
		transcriptedWordArray: [],
		singleSubsArrayData: [],
		currentIndexOfSelectedSub: '',
		subEditTextAreaTemp: '',
		subEditStartTemp: '',
		subEditEndTemp: '',
		subEditDurationTemp: '',
		changeOfSub: false,
		srtMaxWords: 10,
		srtTimeout: 300,
		subtitleLineLength: 10,
		generatedSubString: '',
		positionMs: 0,
		isItLoading: false,
		wordEditModeEnabled: false,
		wordToEdit: '',
		wordToEditTemp: '',
		saveWordToEditTemp: false,
		wordToEditIdValue: '',
		subToEdit: '',
		subToEditTemp: '',
		saveSubToEditTemp: false,
		subToEditIdValue: '',
		translatedSubtitleArray: [],
		translatedSubtitleArrayTemp: [],
		translationLanguageCodes: [],
		redoArray: [],
		clickedOnWord: false,
		translationLanguage: '',
		languageToTranslate: '',
		checkIfTranslated: false,
		translationMappingFinished: false,
		currentCursorBorderIndexOnWord: '',
		currentCursorBorderIndexOnSub: '',
		onEditWordMode: false,
		onEditSubMode: false,
		keyboardEventsDisabled: true,
		checkIfNewSubtitle: false,
		checkIfDeletedSub: false,
		mountedSubtitleArray: [],
		reloadSubtitleArray: false,
		currentSubIndexToBeReplaced: '',
		replaceSubEntry: false,
		currentSubIndexToDeleted: '',
		deleteSubEntry: false,
		currentSubIndexToAdded: '',
		addSubEntry: false,
		subExists: true,
		isSubDataLoaded: true,
		translatedSubToUploadLanguage: '',
		translatedSubToUpload: [],
		showDeleteSelectedSubtitleDialogStatus: false,
		currentAssetIdSub: '',
		currentResourceIdSub: '',
		isSubtitleEntryUpdated: false,
		endPointNotAllowed: false,
		startPointNotAllowed: false,
		currentDisplayedSub: '',
		isDurationOver300: false,
		isDurationOver300Previous: false,
		isDurationOver300Next: false,
		subtitleActiveUpdate: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			mobileView: false,
			mediaDetails: this.props.mediaDetails,
			routeAfterSave: '',
			selectedResourceId: '',
			selectedMenu: this.defaultMenuKey,
			isSaveInProgress: false,
			subtitleData: [],
			subtitleArrays: [],
			liveMediaChannelId: '',
			lastRoute: '',
			videoWidth: defaultVideoWidth,
			videoHeight: defaultVideoHeight,
			playerConfigurationId: 'default',
			selectedLanguage: 'en',
			transcriptionData: '',
			focusedLanguage: '',
			transcriptedWordArray: [],
			singleSubsArrayData: [],
			currentIndexOfSelectedSub: '',
			subEditTextAreaTemp: '',
			subEditStartTemp: '',
			subEditEndTemp: '',
			subEditDurationTemp: '',
			changeOfSub: false,
			srtMaxWords: 10,
			srtTimeout: 300,
			subtitleLineLength: 10,
			generatedSubString: '',
			positionMs: 0,
			isItLoading: false,
			wordEditModeEnabled: false,
			wordToEdit: '',
			wordToEditTemp: '',
			reloadSubtitleArray: false,
			saveWordToEditTemp: false,
			wordToEditIdValue: '',
			subToEdit: '',
			subToEditTemp: '',
			saveSubToEditTemp: false,
			subToEditIdValue: '',
			translatedSubtitleArray: [],
			translatedSubtitleArrayTemp: [],
			translationLanguageCodes: [],
			redoArray: [],
			clickedOnWord: false,
			translationLanguage: '',
			languageToTranslate: '',
			checkIfTranslated: false,
			translationMappingFinished: false,
			currentCursorBorderIndexOnWord: '',
			currentCursorBorderIndexOnSub: '',
			onEditWordMode: false,
			onEditSubMode: false,
			keyboardEventsDisabled: true,
			checkIfNewSubtitle: false,
			checkIfDeletedSub: false,
			mountedSubtitleArray: [],
			currentSubIndexToBeReplaced: '',
			replaceSubEntry: false,
			currentSubIndexToDeleted: '',
			deleteSubEntry: false,
			currentSubIndexToAdded: '',
			addSubEntry: false,
			subExists: true,
			isSubDataLoaded: true,
			translatedSubToUploadLanguage: '',
			translatedSubToUpload: [],
			showDeleteSelectedSubtitleDialogStatus: false,
			currentAssetIdSub: '',
			currentResourceIdSub: '',
			isSubtitleEntryUpdated: false,
			endPointNotAllowed: false,
			startPointNotAllowed: false,
			currentDisplayedSub: '',
			isDurationOver300: false,
			isDurationOver300Previous: false,
			isDurationOver300Next: false,
			subtitleActiveUpdate: false,
			loading: false,
		};
		this.mediaDetailContainer = 'mediaDetailContainer-' + generateId();
		this.defaultMenuKey = this.props.menuKeys.details;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.playerConfigId !== this.props.playerConfigId) {
			this.setState({ playerConfigurationId: nextProps.playerConfigId });
		}

		if (nextProps.clickedPosition !== this.props.clickedPosition && this.player) {
			this.player.position((nextProps.clickedPosition / 100) * this.props.mediaDetails.duration);
		}

		if (nextProps.location !== this.props.location) {
			this.setState({ ...this.initialState });
		}
	}

	UNSAFE_componentWillMount = () => {
		this.getData();
		this.props.resetSpeechToTextReducer();
		this.loadTranscriptionData();
		this._initPlayer();
		smoothscroll.polyfill();
		window.addEventListener('resize', () => this.updatePlayerAspectRatio());
	};

	componentWillUnmount() {
		window.onunload = function () {};
		window.removeEventListener('resize', () => this.updatePlayerAspectRatio());
		window.removeEventListener('keydown', () => {});
		clearInterval(this.interval);

		this.props.toggleShowPreview(false);
		this.props.setAppMode('edit');
	}

	componentDidMount = () => {
		if (this.state.subtitleData.length > 0) {
			this.props.setAppMode('subEdit');
		}

		if (this.props.onRef) {
			this.props.onRef(this);
		}

		if (this.state.subtitleData.length === 0) {
			this.interval = setInterval(() => {
				if (this.state.isItLoading) {
					this.props
						.loadTranscription(this.props.defaultAccountId, this.props.mediaDetails.id)
						.then((data) => {
							if (data && data.entries && data.status && data.status === 8) {
								this.setState({ focusedLanguage: data.language.substring(0, 2) }, () =>
									this.setState({
										translationLanguage: this.state.focusedLanguage,
									})
								);

								this.setState({ transcriptionData: data }, () => {
									if (this.state.transcriptionData.status === 8) {
										this.setState({ isItLoading: false });
									}

									this.setState({
										transcriptedWordArray: this.getTranscriptedWordArray(
											this.state.transcriptionData.entries
										),
									});
									this.setState({
										singleSubsArrayData: this.singleSubsArray(this.state.transcriptionData.entries),
									});
									this.setState({
										generatedSubString: stringify(
											this.convertArrayToSubData(
												this.singleSubsArray(this.state.transcriptionData.entries)
											)
										),
									});

									if (data && data.entries) {
										this.props.setRawTranscriptionText(
											this.generateRawTranscriptionText(this.state.transcriptionData.entries)
										);
									}
								});
							}
						});
				}
			}, 4000);
		}

		if (
			!googleTranslateRestrictedAccounts ||
			!googleTranslateRestrictedAccounts.includes(this.props.defaultAccountId)
		) {
			const url =
				'https://translation.googleapis.com/language/translate/v2/languages?key=' +
				googleTranslateAPIKey +
				'&target=en';
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					if (!data || !data.data) {
						return;
					}

					this.setState({ translationLanguageCodes: data.data.languages });
				});
		}

		window.onkeydown = function (e) {
			if (e.keyCode == 32 && e.target == document.body) {
				e.preventDefault();
				return false;
			}
		};

		window.addEventListener('resize', this.updatePlayerAspectRatio());
	};

	updatePlayerAspectRatio = () => {
		let width = window.innerWidth;

		let screenSize;

		if (width > 1900) {
			screenSize = 34.1; // setting 48 because player is occupying 50% width
		} else {
			screenSize = 32.5; // setting 48 because player is occupying 50% width
		}

		let widthToSet = (screenSize / 100) * width;
		let heightToSet = (widthToSet * 9) / 16;
		let $mediaTags = document.getElementById('speechToTextPlayer-od');

		if ($mediaTags) {
			$mediaTags.style.width = widthToSet + 'px';
			$mediaTags.style.height = heightToSet + 'px';
		}
	};

	createElementFromHTML = (htmlString) => {
		var div = document.createElement('div');
		div.innerHTML = htmlString.trim();

		// Change this to div.childNodes to support multiple top-level nodes
		return div.firstChild;
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.subtitleIsUpdating !== prevProps.subtitleIsUpdating) {
			this.setState({ subtitleActiveUpdate: this.props.subtitleIsUpdating });
		}

		if (this.props.showPlayer !== prevProps.showPlayer) {
			this.updatePlayerAspectRatio();
		}

		if (prevState.subtitleData.length !== this.state.subtitleData.length) {
			if (this.state.checkIfDeletedSub) {
				this.setState({ checkIfDeletedSub: false });
			} else {
				this.setState({ checkIfNewSubtitle: true });
			}
			if (this.state.subtitleData.length > 0) {
				setTimeout(() => {
					this.setState({
						mountedSubtitleArray: this.generateSubtitleView(this.state.focusedLanguage),
					});
				}, 1000);
			} else {
				this.setState({ mountedSubtitleArray: [] });
			}
		}

		if (
			prevState.positionMs != this.state.positionMs &&
			Math.abs(prevState.positionMs - this.state.positionMs) > 1
		) {
			let words = document.getElementsByClassName('transcribedWord');

			words = Array.from(words);
			let found = words.find(
				(el) =>
					el.getAttribute('start') <= this.state.positionMs && el.getAttribute('end') > this.state.positionMs
			);

			let highlightWords = document.getElementsByClassName('highlight');
			if (highlightWords.length > 0) {
				document.getElementById(highlightWords[0].id).classList.remove('highlight');
			}

			if (found) {
				document.getElementById(found.id).className = 'transcribedWord highlight';

				if (!this.visibilityCheckTranscribed(document.getElementById(found.id))) {
					//document.getElementById(words[i].id).scrollIntoView(false)
					document.getElementById('transcriptEditor').scrollBy({
						top: 25,
						left: 0,
						behaviour: 'smooth',
					});
				}
			}
		}

		if (prevProps.positionPlayer != this.props.positionPlayer && this.player) {
			if (this.props.positionPlayer === 0) {
				try {
					this.player.position(0);
				} catch (error) {
					console.error(error);
				}
				this.setState({ positionMs: 0 });
			}
		}

		if (prevState.positionMs != this.state.positionMs) {
			if (this.state.mountedSubtitleArray != undefined) {
				let trimItem = this.getTrimItem();
				let found = trimItem.exists
					? this.state.mountedSubtitleArray.find(
							(el) =>
								el.start <= this.state.positionMs + trimItem.start &&
								el.end > this.state.positionMs + trimItem.start
					  )
					: this.state.mountedSubtitleArray.find(
							(el) => el.start <= this.state.positionMs && el.end > this.state.positionMs
					  );

				if (found != undefined) {
					this.setState({ currentDisplayedSub: found.text });
				} else {
					this.setState({ currentDisplayedSub: '' });
				}
			}
		}

		if (prevState.positionMs != this.state.positionMs) {
			let subs = document.getElementsByClassName('srtSub');

			subs = Array.from(subs);
			let trimItem = this.getTrimItem();
			if (trimItem.exists) {
				subs.map((el) => {
					if (
						el.getAttribute('start') <= this.state.positionMs + trimItem.start &&
						el.getAttribute('end') > this.state.positionMs + trimItem.start
					) {
						if (!document.getElementById(el.id + '.').classList.contains('highlightedTranslationButtons')) {
							//document.getElementById(el.id).className = "srtSub highlight"
							document.getElementById(el.id).classList.add('highlight');
							document.getElementById(el.id).scrollIntoView({
								behavior: 'auto',
								block: 'center',
								inline: 'nearest',
							});
						}
					} else {
						document.getElementById(el.id).classList.remove('highlight');
					}
				});
			} else {
				subs.map((el) => {
					if (
						el.getAttribute('start') <= this.state.positionMs &&
						el.getAttribute('end') > this.state.positionMs
					) {
						if (!document.getElementById(el.id + '.').classList.contains('highlightedTranslationButtons')) {
							//document.getElementById(el.id).className = "srtSub highlight"
							document.getElementById(el.id).classList.add('highlight');
							document.getElementById(el.id).scrollIntoView({
								behavior: 'auto',
								block: 'center',
								inline: 'nearest',
							});
						}
					} else {
						document.getElementById(el.id).classList.remove('highlight');
					}
				});
			}
		}

		if (prevState.wordToEditIdValue != this.state.wordToEditIdValue) {
			if (this.state.wordToEditIdValue != '') {
				document.getElementById(this.state.wordToEditIdValue).classList.add('editHighlight');

				if (document.getElementById(this.state.wordToEditIdValue).getAttribute('value').includes('¤')) {
					document.getElementById(this.state.wordToEditIdValue).classList.add('customBreakline');
				}
			}
		}

		if (prevState.onEditWordMode != this.state.onEditWordMode) {
			this.setState({ redoArray: [] });
		}

		if (prevState.saveWordToEditTemp != this.state.saveWordToEditTemp) {
			const word = document.getElementsByClassName('highlight');
			if (this.state.saveWordToEditTemp === true) {
				this.setState({
					wordToEditTemp: document.getElementById(word[0].id).getAttribute('value'),
				});
			} else {
				this.setState({ wordToEditTemp: '' });
			}
		}

		if (prevState.saveSubToEditTemp != this.state.saveSubToEditTemp) {
			const sub = document.getElementsByClassName('highlightedTranslation');
			if (this.state.saveSubToEditTemp === true) {
				this.setState({
					subToEditTemp: document.getElementById(sub[0].id).innerHTML,
				});
			} else {
				this.setState({ subToEditTemp: '' });
			}
		}

		if (prevState.subToEditIdValue != this.state.subToEditIdValue) {
			if (this.state.subToEditIdValue != '') {
				document.getElementById(this.state.subToEditIdValue).classList.add('editHighlightTranslation');
			}
		}

		if (prevState.translationMappingFinished != this.state.translationMappingFinished) {
			if (this.state.translationMappingFinished === true) {
				this.setState({ translatedSubtitleArray: this.state.translatedSubtitleArrayTemp }, () => {
					this.setState({ translationMappingFinished: false });
				});
			}
		}

		if (this.props.appMode !== prevProps.appMode) {
			if (this.props.appMode === 'edit') {
				if (this.state.transcriptionData.language !== undefined) {
					this.setState(
						{
							focusedLanguage: this.state.transcriptionData.language.substring(0, 2),
						},
						() => this.setState({ translationLanguage: this.state.focusedLanguage })
					);
				} else {
					this.setState({ focusedLanguage: '' });
				}
			}
		}

		if (this.state.translationLanguage !== prevState.translationLanguage) {
			this.setState({
				focusedLanguage: this.state.translationLanguage?.substring(0, 2),
			});
		}

		if (this.state.focusedLanguage !== prevState.focusedLanguage) {
			if (this.state.subExists === true) {
				this.setState({ currentIndexOfSelectedSub: '' });
				this.setState({
					mountedSubtitleArray: this.generateSubtitleView(this.state.focusedLanguage),
				});
			}
		}

		if (this.state.reloadSubtitleArray !== prevState.reloadSubtitleArray) {
			if (this.state.reloadSubtitleArray === true) {
				this.setState(
					{
						mountedSubtitleArray: this.generateSubtitleView(this.state.focusedLanguage),
					},
					() => this.setState({ reloadSubtitleArray: false })
				);
			}
		}

		if (this.state.replaceSubEntry !== prevState.replaceSubEntry) {
			if (this.state.replaceSubEntry === true) {
				this.setState(
					{
						mountedSubtitleArray: this.generateMountedSubtitleArray(this.state.currentSubIndexToBeReplaced),
					},
					() => this.setState({ replaceSubEntry: false })
				);
			}
		}

		if (this.state.deleteSubEntry !== prevState.deleteSubEntry) {
			if (this.state.deleteSubEntry === true) {
				this.setState(
					{
						mountedSubtitleArray: this.generateMountedSubtitleArrayDelete(
							this.state.currentSubIndexToBeDeleted
						),
					},
					() => this.setState({ deleteSubEntry: false })
				);
			}
		}

		if (this.state.addSubEntry !== prevState.addSubEntry) {
			if (this.state.addSubEntry === true) {
				this.setState(
					{
						mountedSubtitleArray: this.generateMountedSubtitleArrayAdd(this.state.currentSubIndexToBeAdded),
					},
					() => this.setState({ addSubEntry: false })
				);
			}
		}

		if (this.state.subExists !== prevState.subExists) {
			if (this.state.subExists === false) {
				this.uploadSrt(this.state.translatedSubToUploadLanguage, stringify(this.state.translatedSubToUpload));
				this.props
					.addTranslationDetails(
						this.props.defaultAccountId,
						this.props.mediaDetails.id,
						this.state.translatedSubToUploadLanguage,
						this.getTranslatedCount(this.state.translatedSubToUpload)
					)
					.then(() =>
						this.setState({
							focusedLanguage: this.state.translatedSubToUploadLanguage,
						})
					);
				this.setState({ subExists: true });
			}
		}
	}

	generateMountedSubtitleArray = (index) => {
		let currentObj = {
			start: this.state.subEditStartTemp,
			end: this.state.subEditEndTemp,
			text: this.state.subEditTextAreaTemp,
		};

		let subArray = this.state.mountedSubtitleArray;

		subArray[index] = currentObj;

		if (this.state.mountedSubtitleArray[index - 1] !== undefined) {
			if (this.state.subEditStartTemp < this.state.mountedSubtitleArray[index - 1].end) {
				let prevObj = {
					start: this.state.mountedSubtitleArray[index - 1].start,
					end: this.state.subEditStartTemp,
					text: this.state.mountedSubtitleArray[index - 1].text,
				};
				subArray[index - 1] = prevObj;
			}
		}

		if (this.state.mountedSubtitleArray[+index + 1] !== undefined) {
			if (this.state.subEditEndTemp > this.state.mountedSubtitleArray[+index + 1].start) {
				let nextObj = {
					start: this.state.subEditEndTemp,
					end: this.state.mountedSubtitleArray[+index + 1].end,
					text: this.state.mountedSubtitleArray[+index + 1].text,
				};
				subArray[+index + 1] = nextObj;
			}
		}

		return subArray;
	};

	generateMountedSubtitleArrayDelete = (index) => {
		let subArray = this.state.mountedSubtitleArray;

		if (index > -1) {
			subArray.splice(index, 1);
		}

		return subArray;
	};

	generateMountedSubtitleArrayAdd = (index) => {
		let subArray = this.state.mountedSubtitleArray;
		let obj = {};

		if (index === subArray.length - 1) {
			subArray[index].end = +subArray[index].end - 150;

			obj = {
				start: subArray[index].end,
				end: +subArray[index].end + 300,
				text: 'New text',
			};
		} else {
			subArray[index].end = +subArray[index].end - 150;
			subArray[index + 1].start = +subArray[index + 1].start + 150;

			obj = {
				start: subArray[index].end,
				end: +subArray[index].end + 300,
				text: 'New text',
			};
		}

		subArray.splice(index + 1, 0, obj);

		return subArray;
	};

	visibilityCheck = (el) => {
		var rect = el.getBoundingClientRect();
		var spaceBelow = window.innerHeight - rect.bottom;
		if (spaceBelow < 150) {
			return false;
		} else {
			return true;
		}
	};
	visibilityCheckTranscribed = (el) => {
		var rectHightlighted = el.getBoundingClientRect();
		var rectControlbar = document.getElementById('playerCtrl').getBoundingClientRect();
		var spaceBelow = rectControlbar.top - rectHightlighted.bottom;
		if (spaceBelow < 100) {
			return false;
		} else {
			return true;
		}
	};
	visibilityCheckSubPreview = (el) => {
		var rect = el.getBoundingClientRect(),
			vWidth = window.innerWidth || document.documentElement.clientWidth,
			vHeight = window.innerHeight || document.documentElement.clientHeight,
			efp = function (x, y) {
				return document.elementFromPoint(x, y);
			};

		// Return false if it's not in the viewport
		if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
			return false;
		}

		// Return true if any of its four corners are visible
		return (
			el.contains(efp(rect.left, rect.top)) ||
			el.contains(efp(rect.right, rect.top)) ||
			el.contains(efp(rect.right, rect.bottom)) ||
			el.contains(efp(rect.left, rect.bottom))
		);
	};

	loadTranscriptionData = () => {
		this.props.loadTranscription(this.props.defaultAccountId, this.props.mediaDetails.id).then((data) => {
			if (data && data.entries) {
				this.setState({
					singleSubsArrayData: this.singleSubsArray(data.entries),
				});
				this.setState({
					generatedSubString: stringify(this.convertArrayToSubData(this.singleSubsArray(data.entries))),
				});
				this.setState({
					transcriptedWordArray: this.getTranscriptedWordArray(data.entries),
				});
				if (this.state.transcriptionData.status === 8) {
					this.setState({ isItLoading: false });
					this.generateRawTranscriptionText(data.entries);
				}
			}
			if (this.state.subtitleData.length > 0) {
				this.setState({ focusedLanguage: this.state.subtitleData[0]?.language }, () => {
					this.setState({
						translationLanguage: this.state.focusedLanguage,
					});
					this.setState({
						mountedSubtitleArray: this.generateSubtitleView(this.state.focusedLanguage),
					});
				});
			} else {
				this.setState({ transcriptionData: data }, () => {
					if (this.state.transcriptionData !== undefined) {
						if (
							this.state.subtitleData.some(
								(e) => e.language === this.state.transcriptionData.language.substring(0, 2)
							)
						) {
							this.setState(
								{
									focusedLanguage: this.state.transcriptionData.language.substring(0, 2),
								},
								() =>
									this.setState({
										translationLanguage: this.state.focusedLanguage,
									})
							);
						}
					}

					if (data && data.entries) {
						this.props.setRawTranscriptionText(
							this.generateRawTranscriptionText(this.state.transcriptionData.entries)
						);
					}
				});
			}
		});
	};

	generateRawTranscriptionText = (data) => {
		let result = '';
		let tempResult = '';

		for (var i = 0; i < data.length; i++) {
			let word = data[i].content;

			tempResult = tempResult + word + ' ';

			result = result + tempResult;

			tempResult = '';
		}
		result = this.insertLines(result);
		return result;
	};

	insertLines = (a) => {
		var a_split = a.split(' ');
		var res = '';
		for (var i = 0; i < a_split.length; i++) {
			res += a_split[i] + ' ';
			if ((i + 1) % 15 === 0) {
				res += '\n';
			}
		}
		return res;
	};

	getSortedLanguageData = (list, property) => {
		const sortedArray = list.sort((a, b) => (a[property] <= b[property] ? 1 : -1));
		const result = sortedArray.filter((item) => item.mimeType === 'text/srt');
		return result;
	};

	getData = () => {
		const { mediaDetails } = this.state;

		let subtitles = [];
		mediaDetails &&
			mediaDetails.asset &&
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.map((resource) => {
				if (resource && resource.renditions) {
					resource.renditions.map((rendition) => {
						let link = rendition.links ? rendition.links[0].href : '';
						let arr = link ? link.split('/') : rendition.path ? rendition.path.split('/') : [];
						let lastName = arr.length > 0 ? arr[arr.length - 1] : '';
						let defaultName = '...' + (link ? link.slice(-17) : '');
						let name = lastName || defaultName;
						let genericObj = {
							resourceId: resource.id,
							renditionId: rendition.id,
							name: name,
							link: link,
						};
						let customObj = {
							...genericObj,
							...rendition,
						};
						switch (rendition.type) {
							case renditionTypes.SUBTITLE:
								subtitles.push(customObj);
								break;
							default:
								break;
						}
					});
				}
			});

		subtitles = [...this.getSortedLanguageData(subtitles, 'created')];
		this.setState({ subtitleData: subtitles }, () => {
			let subArray = [];

			(this.state.subtitleData ?? []).map((item) => {
				let subText;
				let subLang;
				let obj;

				fetch(item.link)
					.then((r) => r.text())
					.then((text) => {
						subText = parse(text);

						subLang = item.language;

						obj = { language: subLang, subtitle: subText };

						subArray.push(obj);
					});

				this.setState({ subtitleArrays: subArray });
				setTimeout(() => {
					this.setState({
						mountedSubtitleArray: this.generateSubtitleView(this.state.subtitleData[0]?.language),
					});
				}, 2000);
			});
		});
	};

	getTranscriptedWordArray = (data) => {
		const resultArray = [];

		if (data) {
			data.map((item) => {
				var word = item;
				if (word.content !== '') {
					resultArray.push(word);
				}
			});
		}

		return resultArray;
	};

	checkForExistedGeneratedSub = (data) => {
		const found = data.some((sub) => sub.language === this.state.focusedLanguage);
		if (found && this.props.appMode === 'edit') {
			return false;
		} else if (!found && this.props.appMode !== 'edit') {
			return true;
		} else {
			return false;
		}
	};

	handleClickOnSubList = (lang) => {
		let subs = document.getElementsByClassName('highlightedTranslation');
		if (subs.length > 0) {
			subs = Array.from(subs);
			subs.map((el) => {
				document.getElementById(el.id).classList.remove('highlightedTranslation');
			});
		}

		let subButtons = document.getElementsByClassName('highlightedTranslationButtons');

		if (subButtons.length > 0) {
			subButtons = Array.from(subButtons);
			subButtons.map((el) => {
				document.getElementById(el.id).classList.remove('highlightedTranslationButtons');
			});
		}

		this.setState({ currentDisplayedSub: '' });

		this.setState({ translationLanguage: lang });
		if (this.state.mountedSubtitleArray === undefined) {
			this.setState({ reloadSubtitleArray: true });
		}
	};

	showDeleteSelectedSubtitleDialog = (curentAssetIdSub, resourceId) => {
		this.setState({
			showDeleteSelectedSubtitleDialogStatus: true,
			currentAssetIdSub: curentAssetIdSub,
			currentResourceIdSub: resourceId,
		});
	};

	handleHideDeleteSelectedSubtitleDialog = (_) =>
		this.setState({
			showDeleteSelectedSubtitleDialogStatus: false,
			currentAssetIdSub: '',
			currentResourceIdSub: '',
		});

	getSubtitleTable = (subtitleData) => {
		const { mediaDetails, appMode, defaultAccountId, t } = this.props;

		let assetId = mediaDetails.asset ? mediaDetails.asset.id : '';

		const showTranslateModule =
			appMode === 'subEdit' &&
			(!googleTranslateRestrictedAccounts || !googleTranslateRestrictedAccounts.includes(defaultAccountId));

		return (
			<>
				{this.state.transcriptionData === undefined && this.state.isItLoading === false ? (
					''
				) : (
					<div>
						{subtitleData.length > 0 ? (
							<div>
								<div className="sttSubtitleList">
									<h3 className="headerAttachedSubtitles">Attached Subtitles</h3>
									<ul className="sttSublistUL">
										{subtitleData.map((sub, idx) => {
											return (
												<li
													key={idx}
													className={
														sub.language === this.state.focusedLanguage
															? 'subtitleListItem subtitleListItemSelected'
															: 'subtitleListItem'
													}
												>
													<div className="sttSubListLeftSide">
														<a href={sub.link} download style={{ marginRight: '0px' }}>
															<img className="downloadIcon" src={downloadIcon} />
														</a>
														<p
															className="sttSubListText"
															onClick={() => this.handleClickOnSubList(sub.language)}
														>
															{sub.name} - {sub.language}
														</p>
													</div>

													<img
														className="deleteIcon"
														src={deleteIcon}
														onClick={() =>
															this.showDeleteSelectedSubtitleDialog(
																assetId,
																sub.resourceId
															)
														}
													/>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						) : (
							''
						)}
						{showTranslateModule ? (
							<>
								<div className="sttlanguageSelectorSidebar">
									<span>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_TRANSLATE_TO')}</span>
									<select
										onChange={(evt) => this.setState({ languageToTranslate: evt.target.value })}
									>
										{this.state.translationLanguageCodes.map((lang, idx) => {
											if (lang.language === 'sv') {
												return (
													<option key={idx} value={lang.language} selected>
														{lang.name}
													</option>
												);
											} else {
												return (
													<option key={idx} value={lang.language}>
														{lang.name}
													</option>
												);
											}
										})}
									</select>
									<button onClick={() => this.generateTranslation(this.state.languageToTranslate)}>
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_GENERATE_SUBTITLES')}
									</button>
								</div>
							</>
						) : (
							''
						)}
					</div>
				)}
			</>
		);
	};

	deleteSubtitle = (accountId, assetId, resourceId) => {
		var tempArray = [...this.state.subtitleData];
		this.handleHideDeleteSelectedSubtitleDialog();
		this.props.removeSubtitle(accountId, assetId, resourceId).then((data) => {
			if (data && data.message) {
				this.props.showNotification(
					this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_DELETE_FAILED') + ' ' + data.message
				);
				return;
			}

			this.props.showNotification(this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_DELETED'));

			this.refreshSubtitleData(this.getRemainingSubtitleData(tempArray, resourceId));
		});
	};

	refreshSubtitleData = (data) => {
		this.setState({ checkIfDeletedSub: true }, () => {
			this.setState({ subtitleData: data }, () => {
				if (this.state.subtitleData.length === 0) {
					if (!this.state.subtitleActiveUpdate) {
						this.props.setAppMode('edit');
					}
				}
			});
		});
	};

	getRemainingSubtitleData = (tempArray, resourceId) => {
		var removeSub = tempArray
			.map(function (item) {
				return item.resourceId;
			})
			.indexOf(resourceId);

		tempArray.splice(removeSub, 1);

		return tempArray;
	};

	renderLanguageSelector = () => {
		const { t } = this.props;

		return (
			<div className="sttlanguageSelector">
				<p className=" sstlanguageSelectorText">
					{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_WHAT_LANGUAGE_IS_YOUR_CLIP')}
				</p>
				<select onChange={(evt) => this.setState({ selectedLanguage: evt.target.value })}>
					{languageSelector.map((lang, index) => {
						if (lang.value === 'en') {
							return (
								<option key={index} style={{ paddingeft: '5px' }} value={lang.value} selected>
									{' ' + lang.name}
								</option>
							);
						} else {
							return (
								<option key={index} style={{ paddingLeft: '5px' }} value={lang.value}>
									{' ' + lang.name}
								</option>
							);
						}
					})}
				</select>
				<button onClick={() => this.beginTranscription()}>
					{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_GEN_TRANSCRIPTION')}
				</button>
			</div>
		);
	};

	beginTranscription = () => {
		this.setState({ isItLoading: true });
		this.props.startTranscription(
			this.props.defaultAccountId,
			this.props.mediaDetails.id,
			this.state.selectedLanguage,
			this.props.mediaDetails.duration
		);
	};

	_initPlayer = () => {
		this.addPlayerEventHandlers();
	};

	addPlayerEventHandlers = () => {
		let eventInterval = setInterval(() => {
			this.player = window.GoBrain.widgets()['speechToTextPlayer-od'];
			if (this.player) {
				this.player.on('position', this.handlePlayerPositionEvent);
				clearInterval(eventInterval);
			}
		}, 300);
	};

	handlePlayerPositionEvent = (position) => {
		this.setState({ positionMs: Math.ceil(position * 1000) });
		let trimItem = this.getTrimItem();
		let trimDurationSeconds = (trimItem.end - trimItem.start) / 1000;
		let positionResult = trimItem.exists
			? (position / trimDurationSeconds) * 100
			: (position / this.props.mediaDetails.duration) * 100;
		this.props.setPlayerPosition(positionResult);
	};

	setTimeOnPlayer = () => {
		if (!this.player) {
			return;
		}
		this.player.position(this.props.positionPlayer);
	};

	renderParagraphs = (data) => {
		return (
			<p sytle={{ marginBottom: '10px' }}>
				{data.map((word) => {
					return this.renderWords(word);
				})}{' '}
			</p>
		);
	};

	singleSubsArray = (data) => {
		let resultArray = [];
		let currentSubWords = new Object();
		let currentAlternative = [];
		var breakAfterNext = false;
		if (data) {
			/*  data.map(item => { */
			for (var i = 0; i < data.length; i++) {
				/* for (var i = 0; i < item.alternatives[0].words.length; i++) {
                    var word = item.alternatives[0].words[i];
                    var start = word.s;

                    if (Object.keys(currentSubWords).length >= (this.state.srtMaxWords - 1)) {
                        //console.log('more than 5 break after ', word.w)
                        breakAfterNext = true;
                    }

                    if (i > 0 && (lastEnd - start) > 300) {
                        //console.log('pause break after ', word.w);
                        breakAfterNext = true;
                    }

                    lastEnd = word.e;
                    if (word.w.endsWith('.') || word.w.endsWith('!') || word.w.endsWith('?') || word.w.endsWith('¤')) {
                        //console.log('sign break after ', word.w);
                        breakAfterNext = true;
                    }

                    if (i === (item.alternatives[0].words.length - 1)) {
                        breakAfterNext = true;
                    }

                    if (breakAfterNext) {
                        currentAlternative.push(word);
                        //currentSubWords = { currentSubWords, ...currentAlternative}
                        if (currentAlternative.length > this.state.subtitleLineLength) {
                            for (let i = 0, j = currentAlternative.length; i < j; i += this.state.subtitleLineLength) {
                                let tempArray = currentAlternative.slice(i, i + this.state.subtitleLineLength);
                                resultArray.push(tempArray)
                            }
                            currentAlternative = [];
                            breakAfterNext = false;
                            continue;
                        } else {
                            resultArray.push(currentAlternative);
                            currentAlternative = [];
                            breakAfterNext = false;
                            continue;
                        }
                    }
                    currentAlternative.push(word)

                } */

				var word = data[i];

				if (Object.keys(currentSubWords).length >= this.state.srtMaxWords - 1) {
					//console.log('more than 5 break after ', word.w)
					breakAfterNext = true;
				}

				/* if ((lastEnd - start) > 300) {
                    //console.log('pause break after ', word.w);
                    breakAfterNext = true;
                } */

				if (
					word.content.endsWith('.') ||
					word.content.endsWith('!') ||
					word.content.endsWith('?') ||
					word.content.endsWith('¤')
				) {
					//console.log('sign break after ', word.w);
					breakAfterNext = true;
				}

				if (breakAfterNext) {
					currentAlternative.push(word);
					//currentSubWords = { currentSubWords, ...currentAlternative}
					if (currentAlternative.length > this.state.subtitleLineLength) {
						for (let i = 0, j = currentAlternative.length; i < j; i += this.state.subtitleLineLength) {
							let tempArray = currentAlternative.slice(i, i + this.state.subtitleLineLength);
							resultArray.push(tempArray);
						}
						currentAlternative = [];
						breakAfterNext = false;
						continue;
					} else {
						resultArray.push(currentAlternative);
						currentAlternative = [];
						breakAfterNext = false;
						continue;
					}
				}
				currentAlternative.push(word);
			}
		}

		return resultArray;
	};

	convertArrayToSubData = (data) => {
		let resultArray = [];

		for (var i = 0; i < data.length; i++) {
			let minStart = 99999999999999;
			let maxEnd = 0;
			let subText = '';
			let newLineCounter = 0;

			/*  for (var j=0; j<data[i].length; i++){
             } */
			data[i].map((word) => {
				if (word.startTime < minStart) {
					minStart = word.startTime;
				}

				if (word.endTime > maxEnd) {
					maxEnd = word.endTime;
				}

				subText += word.content + ' ';
				newLineCounter = newLineCounter + 1;

				if (newLineCounter === 5) {
					subText = subText + '\n';
					newLineCounter = 0;
				}
			});
			//console.log(data[i])

			/*  resultArray.push({
                 start: minStart,
                 end: maxEnd,
                 text: subText.split(/((?:[A-Za-zÀ-ȕ0-9]+ ){5})/g).filter(Boolean).join("\n")
             }) */

			resultArray.push({
				start: minStart,
				end: maxEnd,
				text: subText,
			});
		}

		return resultArray;
	};

	checkIfInTimePosition = (data) => {
		if (data.startTime < this.state.positionMs && data.endTime > this.state.positionMs) {
			return true;
		} else {
			return false;
		}
	};

	fixApostropheNewLine = (string) => {
		if (string.includes(`'`)) {
			let splitedString = string.split(`'`);
			splitedString[1] = splitedString[1].slice(2);

			let resultString = splitedString.join('');

			return resultString;
		} else {
			return string;
		}
	};

	renderWords = (data) => {
		const inputWidth = (data.content.length + 1) * 8 + 'px';

		if (this.state.onEditWordMode) {
			if (data.content.includes('¤')) {
				return (
					<>
						{/* <span customeBreakLine={true} id={data.s + '-' + data.e} start={data.s}  onClick={event => this.handleClickOnWord(event)} end={data.e} className='transcribedWord' value={data.w}  style={{ width: inputWidth }}>{data.w.replace(/¤/, "")}</span> */}
						<span
							customBreakLine={true}
							id={data.startTime + '-' + data.endTime}
							start={data.startTime}
							onClick={(event) => this.handleClickOnWord(event)}
							end={data.endTime}
							className="transcribedWord"
							value={data.content}
							style={{ width: inputWidth }}
						>
							{data.content}
						</span>
						<span> </span>
					</>
				);
			} else {
				return (
					<>
						<span
							customBreakLine={false}
							id={data.startTime + '-' + data.endTime}
							start={data.startTime}
							onClick={(event) => this.handleClickOnWord(event)}
							end={data.endTime}
							className="transcribedWord"
							value={data.content}
							style={{ width: inputWidth }}
						>
							{data.content}
						</span>
						<span> </span>
					</>
				);
			}
		} else {
			if (data.content.includes('¤')) {
				return (
					<>
						<span
							customBreakLine={true}
							id={data.startTime + '-' + data.endTime}
							start={data.startTime}
							onClick={(event) => this.handleClickOnWord(event)}
							end={data.endTime}
							className="transcribedWord"
							value={data.content}
							style={{ width: inputWidth }}
						>
							{data.content}
						</span>
						<span> </span>
					</>
				);
			} else {
				return (
					<>
						<span
							customBreakLine={false}
							id={data.startTime + '-' + data.endTime}
							start={data.startTime}
							onClick={(event) => this.handleClickOnWord(event)}
							end={data.endTime}
							className="transcribedWord"
							value={data.content}
							style={{ width: inputWidth }}
						>
							{data.content}
						</span>
						<span> </span>
					</>
				);
			}
		}
	};

	handleClickOnWord = (event) => {
		let words = document.getElementsByClassName('highlight');

		const targetWord = event.currentTarget.className;
		if (targetWord.indexOf('editHighlight') !== -1) {
		} else {
			if (words.length > 0) {
				this.simulateEscapeKey(event.currentTarget.getAttribute('value'));
				words = Array.from(words);
				words.map((el) => {
					document.getElementById(el.id).classList.remove('highlight');
				});
			}

			/* var evt = new KeyboardEvent('keydown', {'keyCode':27, 'which':27,'key': 'Escape', 'code': 'Escape','isTrusted': true });
            this.handleKeydown('esc',evt) */

			event.currentTarget.classList.add('highlight');
			/* let position = document.getElementById(event.currentTarget.id).getAttribute('start')
            this.setState({positionMs: position}) */

			this.setState({ clickedOnWord: true });
			if (this.player) {
				this.props.setPlayerPosition(
					document.getElementById(event.currentTarget.id).getAttribute('start') / 1000
				);
				this.player.position(document.getElementById(event.currentTarget.id).getAttribute('start') / 1000);
			}

			event.stopPropagation();
		}
	};

	simulateEscapeKey = (clickedWord) => {
		const words = document.getElementsByClassName('highlight');
		const wordsEdit = document.getElementsByClassName('editHighlight');

		if (words[0] != undefined && wordsEdit.length > 0) {
			this.setState({ wordToEdit: clickedWord }, () => {
				document.getElementById(wordsEdit[0].id).innerHTML = this.state.wordToEditTemp;
				this.setState({ saveWordToEditTemp: false }, () => {
					document.getElementById(this.state.wordToEditIdValue).classList.remove('editHighlight');
					this.setState({ wordToEdit: '' });
					this.setState({ wordToEditIdValue: '' });
					this.setState({ onEditWordMode: false });
					document.getElementById(this.state.wordToEditIdValue).classList.remove('customBreakline');
				});
			});
		}
	};

	renderTranscriptionText = (data) => {
		const { t } = this.props;
		if (data) {
			if (data.entries.length <= 0) {
				this.setState({ isItLoading: true });
				return this.renderLoadingState();
			} else {
				let tResults = data.entries;
				//this.setState({ singleSubsArrayData: this.singleSubsArray(tResults)})

				return (
					<>
						<div className="transcriptionAreaWrapper">
							<h5 className="sttEditTextHeader">
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_CLICK_WORDS_TO_EDIT')}
							</h5>
							<div
								id="transcriptEditor"
								className={this.props.showPreview ? 'subtitlePreviewAvtive' : 'subtitlePreviewDeAvtive'}
							>
								{this.renderParagraphs(tResults)}
							</div>
							<button
								className="saveSrtBtnMain"
								onClick={() =>
									this.uploadSrtTranscription(
										this.state.transcriptionData.language,
										this.state.generatedSubString
									)
								}
							>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_CREATE_SUBTITLE_FROM_TRANSCRIPTION')}
							</button>
						</div>

						{this.props.showPreview
							? this.renderSubtitleEditModePreview(this.state.singleSubsArrayData)
							: ''}
					</>
				);
			}
		}
	};

	renderSubtitleEditModePreview = (subData) => {
		return (
			<div id="subtitlePreviewArea" className={this.props.appMode === 'subEdit' ? 'subEditModeWidth' : ''}>
				<div className="sttSubPreview">
					{subData.map((sub) => {
						return this.renderSingleSub(sub);
					})}
				</div>
			</div>
		);
	};

	renderSubtitlePreview = (subData) => {
		return (
			<div id="subtitlePreviewArea" className={this.props.appMode === 'subEdit' ? 'subEditModeWidth' : ''}>
				{subData.map((sub) => {
					return this.renderSingleSub(sub);
				})}
			</div>
		);
	};

	renderTranslatedSubtitlePreview = (subData) => {
		return (
			<div id="translatedPreviewArea" className={this.props.appMode === 'subEdit' ? 'subEditModeWidth' : ''}>
				{subData.map((sub, index) => {
					return this.renderSingleTranslatedSub(sub, index);
				})}
			</div>
		);
	};

	renderSingleSub = (data) => {
		let start = data[0].startTime;
		let end = data[data.length - 1].endTime;

		let rArr = [data.slice(0, 5)];
		if (data.length > 5) {
			rArr.push(data.slice(5));
		} else {
			rArr.push();
		}

		return (
			<p id={start + '-' + end} start={start} end={end} className="srtSub">
				<span className="srtSubTiming">{this.formatSrtTime(start).slice(0, -1)}</span>
				<div className="textSection">
					{rArr.map((line, index) => {
						return this.renderSingleSubLine(line, index);
					})}
				</div>
			</p>
		);
	};

	renderSingleTranslatedSub = (data, index) => {
		const { t } = this.props;
		let start = data.start;
		let end = data.end;
		let text = data.text;

		let currentIndex = index;

		let prevSelectedIndex = this.state.currentIndexOfSelectedSub - 1;

		let nextSelectedIndex = +this.state.currentIndexOfSelectedSub + 1;

		let trimItem = this.getTrimItem();

		if (trimItem.exists) {
			return (
				<p
					id={'traslated' + start + '-' + end}
					start={start}
					end={end}
					className={
						(trimItem.start > start && trimItem.start > end && trimItem.end > end) ||
						(trimItem.end < start && trimItem.end < end && trimItem.start < end)
							? 'srtSub srtSubUnsuable '
							: 'srtSub'
					}
				>
					<div className="translatedSubContentWrapper">
						<div>
							{this.state.subEditEndTemp > start &&
							this.state.subEditEndTemp <= end - 300 &&
							nextSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlapping">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic">
										{' '}
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_EDIT_START_TIME')}
									</span>
								</div>
							) : this.state.subEditStartTemp < end &&
							  this.state.subEditStartTemp >= +start + 300 &&
							  prevSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlapping">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic">
										{' '}
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_EDIT_END_TIME')}
									</span>
								</div>
							) : this.state.startPointNotAllowed &&
							  this.state.subEditStartTemp < +start + 300 &&
							  prevSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlappingNotAllowed">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic"></span>
								</div>
							) : this.state.endPointNotAllowed &&
							  this.state.subEditEndTemp > end - 300 &&
							  nextSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlappingNotAllowed">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic"></span>
								</div>
							) : (
								<span className="srtSubTiming">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
								</span>
							)}
						</div>

						{(trimItem.start > start && trimItem.start > end && trimItem.end > end) ||
						(trimItem.end < start && trimItem.end < end && trimItem.start < end) ? (
							<span
								style={{ maxWidth: '300px' }}
								id={'traslated' + start + '-' + end + '-'}
								start={start}
								end={end}
								cIndex={currentIndex}
								className="textSectionTranslated textSectionTranslatedUnsuable"
							>
								{text}{' '}
							</span>
						) : (
							<span
								style={{ maxWidth: '300px' }}
								id={'traslated' + start + '-' + end + '-'}
								start={start}
								end={end}
								cIndex={currentIndex}
								className="textSectionTranslated"
								onClick={(evt) => this.handleClickOnTranslatedSub(evt)}
							>
								{text}{' '}
							</span>
						)}
					</div>
					<div id={'traslated' + start + '-' + end + '.'} className="translatedSubButtonWrapper">
						<button
							className="deleteSubEntryButton"
							onClick={() => this.handleDeleteSubEntry(currentIndex)}
						></button>
						<button
							className="addSubEntryButton"
							onClick={() => this.handleAddSubEntry(currentIndex)}
						></button>
					</div>
				</p>
			);
		} else {
			return (
				<p id={'traslated' + start + '-' + end} start={start} end={end} className="srtSub">
					<div className="translatedSubContentWrapper">
						<div>
							{this.state.subEditEndTemp > start &&
							this.state.subEditEndTemp <= end - 300 &&
							nextSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlapping">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic">
										{' '}
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_EDIT_START_TIME')}
									</span>
								</div>
							) : this.state.subEditStartTemp < end &&
							  this.state.subEditStartTemp >= +start + 300 &&
							  prevSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlapping">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic">
										{' '}
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_EDIT_END_TIME')}
									</span>
								</div>
							) : this.state.startPointNotAllowed &&
							  this.state.subEditStartTemp < +start + 300 &&
							  prevSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlappingNotAllowed">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic"></span>
								</div>
							) : this.state.endPointNotAllowed &&
							  this.state.subEditEndTemp > end - 300 &&
							  nextSelectedIndex == currentIndex ? (
								<div className="srtSubTiming srtSubTimingOverlappingNotAllowed">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
									<span className="srtSubTimingItalic"></span>
								</div>
							) : (
								<span className="srtSubTiming">
									{this.formatSrtTime(start) + ' - ' + this.formatSrtTime(end)}
								</span>
							)}
						</div>
						<span
							style={{ maxWidth: '300px' }}
							id={'traslated' + start + '-' + end + '-'}
							start={start}
							end={end}
							cIndex={currentIndex}
							className="textSectionTranslated"
							onClick={(evt) => this.handleClickOnTranslatedSub(evt)}
						>
							{text}{' '}
						</span>
					</div>
					<div id={'traslated' + start + '-' + end + '.'} className="translatedSubButtonWrapper">
						<button
							className="deleteSubEntryButton"
							onClick={() => this.handleDeleteSubEntry(currentIndex)}
						></button>
						<button
							className="addSubEntryButton"
							onClick={() => this.handleAddSubEntry(currentIndex)}
						></button>
					</div>
				</p>
			);
		}
	};

	handleClickOnTranslatedSub = (event) => {
		this.setState({ isSubtitleEntryUpdated: false });
		this.setState({ startPointNotAllowed: false });
		this.setState({ endPointNotAllowed: false });
		let subs = document.getElementsByClassName('highlightedTranslationGray');
		if (subs.length > 0) {
			subs = Array.from(subs);
			subs.map((el) => {
				document.getElementById(el.id).classList.remove('highlightedTranslationGray');
			});
		}

		let subButtons = document.getElementsByClassName('highlightedTranslationButtons');

		if (subButtons.length > 0) {
			subButtons = Array.from(subButtons);
			subButtons.map((el) => {
				document.getElementById(el.id).classList.remove('highlightedTranslationButtons');
			});
		}

		let elementId = event.currentTarget.id;

		elementId = elementId.substring(0, elementId.length - 1);

		document.getElementById(elementId + '.').classList.add('highlightedTranslationButtons');

		event.currentTarget.classList.add('highlightedTranslationGray');
		let trimItem = this.getTrimItem();
		if (this.player) {
			this.props.setPlayerPosition(
				trimItem.exists
					? (document.getElementById(event.currentTarget.id).getAttribute('start') - trimItem.start) / 1000
					: document.getElementById(event.currentTarget.id).getAttribute('start') / 1000
			);
			this.player.position(
				trimItem.exists
					? (document.getElementById(event.currentTarget.id).getAttribute('start') - trimItem.start) / 1000
					: document.getElementById(event.currentTarget.id).getAttribute('start') / 1000
			);
		}

		this.setState({
			currentIndexOfSelectedSub: event.currentTarget.getAttribute('cIndex'),
		});
		this.setState({ changeOfSub: true });
	};

	renderLoadingState = () => {
		const { t } = this.props;
		let transcriptionTime = this.props.mediaDetails.duration * 0.75;

		let minutes = Math.floor(transcriptionTime / 60);

		return (
			<div id="loadingState">
				<p className="loadingText">{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_LOADING_TRANSCRIPTION')}</p>
				<LinearProgress />
				<span className="loadingTextMinor">
					{`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_ESTIMATE_MESSAGE')} ${
						minutes < 1
							? `${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_ESTIMATE_LESS_THAN_MINUTES')}.`
							: minutes + ` ${t('COMMON_MINUTES')}.`
					}  `}
					{/* <br>{`Please click the restart button if the aforementioned time has exceeded`}</br> */}
				</span>
				<span className="loadingTextMinor">{`${t(
					'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_RESTART_MESSAGE'
				)}.`}</span>
				<button className="restartButton" onClick={() => this.restartTranscription()}>
					{t('COMMON_RESTART')}
				</button>
			</div>
		);
	};

	restartTranscription = () => {
		this.props.startTranscription(
			this.props.defaultAccountId,
			this.props.mediaDetails.id,
			this.state.selectedLanguage,
			this.props.mediaDetails.duration
		);
	};

	renderSingleSubLine = (data, index) => {
		return (
			<span className={'srtSubText line' + (index + 1)}>
				{data.map((word, idx) => {
					return (
						<span key={idx} id="subWord">
							{word.w.replace(/¤/, '')}{' '}
						</span>
					); // hide line break char
				})}
			</span>
		);
	};

	formatSrtTime = (millis) => {
		var thours = '00',
			tminutes = '00',
			tseconds = '00',
			tms = '00';
		var hours, minutes, seconds;
		thours += hours = Math.floor(millis / 1000 / 3600);
		tminutes += minutes = Math.floor(millis / 1000 / 60) - hours * 60;
		tseconds += seconds = Math.floor(millis / 1000) - hours * 3600 - minutes * 60;
		tms += Math.floor(millis) - hours * 3600000 - minutes * 60000 - seconds * 1000;
		return (
			thours.substr(thours.length - 2) +
			':' +
			tminutes.substr(tminutes.length - 2) +
			':' +
			tseconds.substr(tseconds.length - 2) +
			':' +
			tms.substr(tms.length - 3)
		);
	};

	updateSubToEdit = (id) => {
		var count = document.getElementById(id).childElementCount;
		var sub = document.getElementById(id);
		let result = '';
		for (var i = 0; i < count; i++) {
			//var x = document.getElementsByClassName(`${i + 1}-${id}`)
			result = result + sub.children[i].innerHTML;
			this.setState({ subToEdit: result });
		}
		if (count > 0) {
			document.getElementById(id).innerHTML = this.state.subToEdit;
		} else {
			document.getElementById(id).innerHTML = '';
		}
	};

	updateWordToEdit = (id) => {
		var count = document.getElementById(id).childElementCount;
		var word = document.getElementById(id);
		let result = '';
		for (var i = 0; i < count; i++) {
			//var x = document.getElementsByClassName(`${i + 1}-${id}`)
			result = result + word.children[i].innerHTML;
			this.setState({ wordToEdit: result }, () => {
				//document.getElementById(id).innerHTML = this.state.wordToEdit
			});
		}
		if (count > 0) {
			document.getElementById(id).innerHTML = this.state.wordToEdit;
		} else {
			document.getElementById(id).innerHTML = '';
		}
	};

	addNewCharToWordToEditUndo = (char, position, id) => {
		let result = '';

		var x = document.getElementsByClassName('cursorBorder');
		if (x.length > 0) {
			var currentCursorBorderIndex = position - 1;
		} else {
			var currentCursorBorderIndex = 0;
			var z = document.getElementsByClassName(`1-${id}`);

			if (z.length > 0) {
				z[0].classList.remove('cursorBorderLeft');
			}
		}
		var count = document.getElementById(id).childElementCount;
		var word = document.getElementById(id);

		let tempArray = [];

		if (count > 0) {
			for (var i = 0; i < count; i++) {
				tempArray.push(word.children[i].innerHTML);
			}
			this.setState({
				currentCursorBorderIndexOnWord: currentCursorBorderIndex,
			});

			tempArray.splice(currentCursorBorderIndex, 0, char);
		} else {
			this.setState({
				currentCursorBorderIndexOnWord: currentCursorBorderIndex,
			});
			tempArray.push(char);
		}

		result = tempArray.join('');
		return result;
	};

	addNewCharToWordToEdit = (char, id) => {
		let result = '';

		var x = document.getElementsByClassName('cursorBorder');
		if (x.length > 0) {
			var currentCursorBorderIndex = x[0].className.split(/^([^-]+)/)[1];
		} else {
			var currentCursorBorderIndex = 0;
			var z = document.getElementsByClassName(`1-${id}`);

			if (z.length > 0) {
				z[0].classList.remove('cursorBorderLeft');
			}
		}
		var count = document.getElementById(id).childElementCount;
		var word = document.getElementById(id);

		let tempArray = [];

		if (count > 0) {
			for (var i = 0; i < count; i++) {
				//var x = document.getElementsByClassName(`${i + 1}-${id}`)
				tempArray.push(word.children[i].innerHTML);
			}
			this.setState({
				currentCursorBorderIndexOnWord: currentCursorBorderIndex,
			});

			tempArray.splice(currentCursorBorderIndex, 0, char);
		} else {
			this.setState({
				currentCursorBorderIndexOnWord: currentCursorBorderIndex,
			});
			tempArray.push(char);
		}

		result = tempArray.join('');
		return result;
	};

	addNewCharToSubToEdit = (char, id) => {
		let result = '';

		var x = document.getElementsByClassName('cursorBorderTranslated');
		if (x.length > 0) {
			var currentCursorBorderIndex = x[0].className.split(/^([^-]+)/)[1];
		} else {
			var currentCursorBorderIndex = 0;
			var z = document.getElementsByClassName(`1-${id}`);

			if (z.length > 0) {
				z[0].classList.remove('cursorBorderTranslatedLeft');
			}
		}
		var count = document.getElementById(id).childElementCount;
		var sub = document.getElementById(id);

		let tempArray = [];

		if (count > 0) {
			for (var i = 0; i < count; i++) {
				//var x = document.getElementsByClassName(`${i + 1}-${id}`)
				tempArray.push(sub.children[i].innerHTML);
			}
			this.setState({
				currentCursorBorderIndexOnSub: currentCursorBorderIndex,
			});

			tempArray.splice(currentCursorBorderIndex, 0, char);
		} else {
			this.setState({
				currentCursorBorderIndexOnSub: currentCursorBorderIndex,
			});
			tempArray.push(char);
		}

		result = tempArray.join('');
		return result;
	};

	handleClickOnChar = (event) => {
		var targetCharClass = event.currentTarget.className;

		var z = document.getElementsByClassName(targetCharClass);

		var x = document.getElementsByClassName('cursorBorder');

		if (x[0] != undefined) {
			x[0].classList.remove('cursorBorder');
		}

		if (z[0] != undefined) {
			z[0].classList.add('cursorBorder');
		}
	};

	handleClickOnCharTranslated = (event) => {
		var targetCharClass = event.currentTarget.className;

		var z = document.getElementsByClassName(targetCharClass);

		var x = document.getElementsByClassName('cursorBorderTranslated');

		if (x[0] != undefined) {
			x[0].classList.remove('cursorBorderTranslated');
		}

		if (z[0] != undefined) {
			z[0].classList.add('cursorBorderTranslated');
		}
	};

	handleKeydown = (key, e) => {
		const words = document.getElementsByClassName('highlight');
		const wordsEdit = document.getElementsByClassName('editHighlight');
		const subs = document.getElementsByClassName('highlightedTranslation');
		const subsEdit = document.getElementsByClassName('editHighlightTranslation');
		e.preventDefault();
		/*  */
		if (words.length > 0 && this.props.appMode === 'edit') {
			const alphanumericArray = [
				'Ð',
				'ð',
				'Á',
				'á',
				'Ô',
				'ô',
				'Ò',
				'ò',
				'ó',
				'Ó',
				'Ê',
				'ê',
				'É',
				'è',
				'É',
				'é',
				'Æ',
				'æ',
				'Ø',
				'ø',
				'ß',
				' ',
				'A',
				'a',
				'B',
				'b',
				'C',
				'c',
				'D',
				'd',
				'E',
				'e',
				'F',
				'f',
				'G',
				'g',
				'H',
				'h',
				'I',
				'i',
				'J',
				'j',
				'K',
				'k',
				'L',
				'l',
				'M',
				'm',
				'N',
				'n',
				'O',
				'o',
				'P',
				'p',
				'Q',
				'q',
				'R',
				'r',
				'S',
				's',
				'T',
				't',
				'U',
				'u',
				'V',
				'v',
				'W',
				'w',
				'X',
				'x',
				'Y',
				'y',
				'Z',
				'z',
				'ö',
				'Ö',
				'å',
				'Å',
				'ä',
				'Ä',
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'?',
				'.',
				',',
				'!',
				'@',
				'+',
				'-',
				'=',
				'_',
				'*',
				'&',
				'(',
				')',
				'/',
				'#',
				'$',
				'%',
				'^',
				'`',
				'"',
				':',
				';',
				'€',
				'½',
			];
			if (!this.props.playPlayer) {
				if (
					(alphanumericArray.includes(e.key) || e.code === 'Backslash') &&
					e.ctrlKey === false &&
					e.metaKey === false
				) {
					if (e.code === 'Space' && this.state.wordToEdit === '') {
					} else {
						if (this.state.onEditWordMode === true) {
							this.setState({ saveWordToEditTemp: true });
							this.setState({ wordToEditIdValue: words[0].id });
							this.setState({ wordToEdit: this.addNewCharToWordToEdit(e.key, words[0].id) }, () => {
								document.getElementById(words[0].id).innerHTML = this.state.wordToEdit;
							});

							charming(document.getElementById(words[0].id), {
								setClassName: function (index) {
									return `${index}-${words[0].id}`;
								},
							});
							if (this.state.wordToEdit.length > 1) {
								for (var i = 1; i < this.state.wordToEdit.length + 1; i++) {
									var z = document.getElementsByClassName(`${i}-${words[0].id}`);
									z[0].onclick = (ev) => this.handleClickOnChar(ev);
								}
							}

							var x = document.getElementsByClassName(
								`${parseInt(this.state.currentCursorBorderIndexOnWord) + 1}-${words[0].id}`
							);

							if (x[0] !== undefined) {
								x[0].classList.add('cursorBorder');
							}
						} else {
							this.setState({ saveWordToEditTemp: true });
							this.setState({ wordToEditIdValue: words[0].id });
							this.setState({ wordToEdit: e.key }, () => {
								document.getElementById(words[0].id).innerHTML = this.state.wordToEdit;
								this.setState({ onEditWordMode: true });
							});

							charming(document.getElementById(words[0].id), {
								setClassName: function (index) {
									return `${index}-${words[0].id}`;
								},
							});
							if (this.state.wordToEdit.length > 1) {
								for (var i = 1; i < this.state.wordToEdit.length + 1; i++) {
									var z = document.getElementsByClassName(`${i}-${words[0].id}`);
									z[0].onclick = (ev) => this.handleClickOnChar(ev);
								}
							}

							var x = document.getElementsByClassName(`${this.state.wordToEdit.length}-${words[0].id}`);

							if (x[0] !== undefined) {
								x[0].classList.add('cursorBorder');
							}
						}
					}
				}

				if (e.key === 'Enter' && (e.ctrlKey === true || e.metaKey === true)) {
					if (this.state.onEditWordMode === false) {
						const startTime = document.getElementById(words[0].id).getAttribute('start');

						this.setState(
							{
								transcriptionData: this.replaceWordInTranscriptionDataAddLineBreak(startTime),
							},
							() => {
								this.setState({
									transcriptedWordArray: this.getTranscriptedWordArray(
										this.state.transcriptionData.entries
									),
								});
								this.saveEditedTranscription();
							}
						);
					} else {
					}
				}

				if (e.key === 'Backspace' && (e.ctrlKey === true || e.metaKey === true)) {
					if (this.state.onEditWordMode === false) {
						const startTime = document.getElementById(words[0].id).getAttribute('start');

						this.setState(
							{
								transcriptionData: this.replaceWordInTranscriptionDataRemoveLineBreak(startTime),
							},
							() => {
								this.setState({
									transcriptedWordArray: this.getTranscriptedWordArray(
										this.state.transcriptionData.entries
									),
								});
								this.saveEditedTranscription();
							}
						);
					}
				}

				if ((e.key === 'z' || e.key === 'Z') && (e.ctrlKey === true || e.metaKey === true)) {
					if (this.state.onEditWordMode) {
						if (this.state.redoArray.length > 0) {
							let charToAdd = this.state.redoArray[this.state.redoArray.length - 1].char;
							let position = this.state.redoArray[this.state.redoArray.length - 1].position;
							let undoArray = this.state.redoArray;

							undoArray.pop();

							this.setState({ saveWordToEditTemp: true });
							this.setState({ wordToEditIdValue: words[0].id });
							this.setState(
								{
									wordToEdit: this.addNewCharToWordToEditUndo(charToAdd, position, words[0].id),
								},
								() => {
									document.getElementById(words[0].id).innerHTML = this.state.wordToEdit;
								}
							);

							charming(document.getElementById(words[0].id), {
								setClassName: function (index) {
									return `${index}-${words[0].id}`;
								},
							});
							if (this.state.wordToEdit.length > 1) {
								for (var i = 1; i < this.state.wordToEdit.length + 1; i++) {
									var z = document.getElementsByClassName(`${i}-${words[0].id}`);
									z[0].onclick = (ev) => this.handleClickOnChar(ev);
								}
							}

							var x = document.getElementsByClassName(
								`${parseInt(this.state.currentCursorBorderIndexOnWord) + 1}-${words[0].id}`
							);

							if (x[0] !== undefined) {
								x[0].classList.add('cursorBorder');
							}

							this.setState({ redoArray: undoArray });
						}
					}
				}

				if (e.key === 'Backspace' && e.ctrlKey === false && e.metaKey === false) {
					if (words[0] != undefined) {
						var count = document.getElementById(words[0].id).childElementCount;
						var x = document.getElementsByClassName('cursorBorder');
						if (count > 0) {
							if (x.length > 0) {
								let lastDeletedChar = x[0].innerText;
								var lastDeletedCharPosition = x[0].className.split(/^([^-]+)/)[1];
								let element = {
									char: lastDeletedChar,
									position: lastDeletedCharPosition,
								};

								let joined = this.state.redoArray.concat(element);

								this.setState({ redoArray: joined });
								var nextCursorBorderIndex = x[0].className.split(/^([^-]+)/)[1] - 1;
								x[0].remove();

								this.updateWordToEdit(words[0].id);

								charming(document.getElementById(words[0].id), {
									setClassName: function (index) {
										return `${index}-${words[0].id}`;
									},
								});
								if (count > 1) {
									for (var i = 1; i < this.state.wordToEdit.length + 1; i++) {
										var z = document.getElementsByClassName(`${i}-${words[0].id}`);
										z[0].onclick = (ev) => this.handleClickOnChar(ev);
									}
								}

								var y = document.getElementsByClassName(`${nextCursorBorderIndex}-${words[0].id}`);
								if (y.length > 0) {
									y[0].classList.add('cursorBorder');
								}
							}
						}
					}
				}

				if (e.key === 'Escape') {
					if (words[0] != undefined && wordsEdit.length > 0) {
						this.setState({ wordToEdit: this.state.wordToEditTemp }, () => {
							document.getElementById(words[0].id).innerHTML = this.state.wordToEdit;
							this.setState({ saveWordToEditTemp: false }, () => {
								document.getElementById(this.state.wordToEditIdValue).classList.remove('editHighlight');
								this.setState({ wordToEdit: '' });
								this.setState({ wordToEditIdValue: '' });
								this.setState({ onEditWordMode: false });
								document
									.getElementById(this.state.wordToEditIdValue)
									.classList.remove('customBreakline');
							});
						});
					}
				}

				if (e.key === 'Enter' && e.ctrlKey === false && e.metaKey === false) {
					if (wordsEdit.length > 0) {
						//if(this.state.wordToEditIdValue != '')

						document.getElementById(this.state.wordToEditIdValue).classList.remove('editHighlight');
						const startTime = document.getElementById(this.state.wordToEditIdValue).getAttribute('start');
						const endTime = document.getElementById(this.state.wordToEditIdValue).getAttribute('end');

						//this.replaceWordInTranscriptionData(startTime)
						//this.setState({ wordToEditIdValue: ''})
						this.setState(
							{
								transcriptionData: this.replaceWordInTranscriptionData(
									startTime,
									endTime,
									this.hasClass(
										document.getElementById(this.state.wordToEditIdValue),
										'customBreakline'
									)
								),
							},
							() => {
								this.setState({ saveWordToEditTemp: false });
								this.setState({ wordToEditIdValue: '' });
								this.setState({ wordToEdit: '' });
								this.setState({ onEditWordMode: false });
								this.setState({ wordToEditTemp: '' });
								this.setState({
									transcriptedWordArray: this.getTranscriptedWordArray(
										this.state.transcriptionData.entries
									),
								});
								this.saveEditedTranscription();
								document
									.getElementById(this.state.wordToEditIdValue)
									.classList.remove('customBreakline');
							}
						);
					} else {
						this.setState({ saveWordToEditTemp: true });
						this.setState({ wordToEditIdValue: words[0].id });
						this.setState({ wordToEdit: this.state.wordToEditTemp }, () => {
							document.getElementById(words[0].id).value = this.state.wordToEdit;
							this.setState({ onEditWordMode: true });
						});

						charming(document.getElementById(words[0].id), {
							setClassName: function (index) {
								return `${index}-${words[0].id}`;
							},
						});

						if (this.state.wordToEdit.length > 1) {
							for (var i = 1; i < this.state.wordToEdit.length + 1; i++) {
								var z = document.getElementsByClassName(`${i}-${words[0].id}`);
								if (z[0].innerText === '¤') {
									z[0].setAttribute('style', 'display:none');
								}
								z[0].onclick = (ev) => this.handleClickOnChar(ev);
							}
						}

						var x = document.getElementsByClassName(`${this.state.wordToEdit.length}-${words[0].id}`);

						if (x[0] !== undefined) {
							x[0].classList.add('cursorBorder');
						}
					}
				}
			}
		}

		if (e.code === 'Space') {
			if (wordsEdit.length > 0 || subsEdit.length > 0) {
			} else if ((this.player && wordsEdit.length <= 0) || (this.player && subsEdit.length)) {
				if (this.props.appMode === 'edit') {
					this.togglePlay();
				} else if (this.props.appMode === 'subEdit') {
					//this.togglePlay()
				}
			}
		}

		if (key === 'right') {
			if (this.props.appMode === 'edit') {
				if (wordsEdit.length > 0) {
					var count = document.getElementById(words[0].id).childElementCount;
					var x = document.getElementsByClassName('cursorBorder');

					if (count > 0) {
						if (x.length > 0) {
							var cursorBorderIndex = x[0].className.split(/^([^-]+)/)[1];
							x[0].classList.remove('cursorBorder');

							let nextIndex = parseInt(cursorBorderIndex) + 1;
							var y = document.getElementsByClassName(`${nextIndex}-${words[0].id}`);
							if (y.length > 0) {
								y[0].classList.add('cursorBorder');
							}
						} else {
							var z = document.getElementsByClassName(`1-${words[0].id}`);
							z[0].classList.remove('cursorBorderLeft');
							z[0].classList.add('cursorBorder');
						}
					}
				} else if (this.player && wordsEdit.length <= 0) {
					let nextWord = this.state.transcriptedWordArray.find((item) => {
						return item.startTime > this.state.positionMs;
					});

					if (nextWord === undefined || nextWord.startTime === undefined) {
						this.player.position(this.state.positionMs);

						this.setState({ wordToEdit: '' });
						this.setState({ wordToEditIdValue: '' });
					} else {
						//this.props.setPlayerPosition(nextWord.s / 1000)
						this.player.position(nextWord.startTime / 1000);
						this.setState({ positionMs: nextWord.startTime });
						this.setState({ wordToEdit: '' });
						this.setState({ wordToEditIdValue: '' });
					}
				}
			}
		}

		if (key === 'left') {
			if (this.props.appMode === 'edit') {
				if (wordsEdit.length > 0) {
					var count = document.getElementById(words[0].id).childElementCount;
					var x = document.getElementsByClassName('cursorBorder');

					if (count > 0) {
						if (x.length > 0) {
							var cursorBorderIndex = x[0].className.split(/^([^-]+)/)[1];
							x[0].classList.remove('cursorBorder');

							var y = document.getElementsByClassName(`${cursorBorderIndex - 1}-${words[0].id}`);

							if (y.length > 0) {
								y[0].classList.add('cursorBorder');
							} else {
								var z = document.getElementsByClassName(`1-${words[0].id}`);
								z[0].classList.add('cursorBorderLeft');
							}
						}
					}
				} else if (this.player && wordsEdit.length <= 0) {
					let reverseArray = this.reverseArr(this.state.transcriptedWordArray);

					let nextWord = reverseArray.find((item) => {
						return item.startTime < this.state.positionMs - 1.1;
					});

					if (nextWord === undefined || nextWord.startTime === undefined) {
						this.props.setPlayerPosition(0);
						this.player.position(0);
						this.setState({ positionMs: 0 });
						this.setState({ wordToEdit: '' });
						this.setState({ wordToEditIdValue: '' });
					} else {
						//this.props.setPlayerPosition(nextWord.s / 1000)
						this.player.position(nextWord.startTime / 1000);
						this.setState({ positionMs: nextWord.startTime });
						this.setState({ wordToEdit: '' });
						this.setState({ wordToEditIdValue: '' });
					}
				}
			} else {
				if (subsEdit.length > 0) {
					var count = document.getElementById(subs[0].id).childElementCount;
					var x = document.getElementsByClassName('cursorBorderTranslated');

					if (count > 0) {
						if (x.length > 0) {
							var cursorBorderIndex = x[0].className.split(/^([^-]+)/)[1];
							x[0].classList.remove('cursorBorderTranslated');

							var y = document.getElementsByClassName(`${cursorBorderIndex - 1}-${subs[0].id}`);

							if (y.length > 0) {
								y[0].classList.add('cursorBorderTranslated');
							} else {
								var z = document.getElementsByClassName(`1-${subs[0].id}`);
								z[0].classList.add('cursorBorderTranslatedLeft');
							}
						}
					}
				}
			}
		}
	};

	hasClass = (element, className) => {
		return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
	};

	togglePlay = () => {
		if (this.props.playPlayer === true) {
			this.props.playButtonPause();
		} else {
			this.props.playButtonPlay();
		}
	};

	saveEditedTranscription = () => {
		this.setState({
			singleSubsArrayData: this.singleSubsArray(this.state.transcriptionData.entries),
		});
		this.props
			.saveTranscription(this.props.defaultAccountId, this.props.mediaDetails.id, this.state.transcriptionData)
			.then(() => {
				this.getData();
				this.props.loadTranscription(this.props.defaultAccountId, this.props.mediaDetails.id).then((data) => {
					if (data && data.entries) {
						this.setState({
							generatedSubString: stringify(
								this.convertArrayToSubData(this.singleSubsArray(data.entries))
							),
						});
					}
					return data;
				});
			});
		this.props.setRawTranscriptionText(this.generateRawTranscriptionText(this.state.transcriptionData.entries));
	};

	replaceSubInTranslatedArrayData = (startTime) => {
		const resultData = this.state.translatedSubtitleArray;
		const subs = document.getElementsByClassName('highlightedTranslation');
		let replacementText = '';

		for (var i = 0; i < this.state.subToEdit.length; i++) {
			var x = document.getElementsByClassName(`${i + 1}-${subs[0].id}`);

			let el;

			if (x[0] !== undefined) {
				el = x[0].innerHTML;
			} else {
				el = '';
			}

			replacementText = replacementText + el;
		}

		document.getElementById(subs[0].id).innerHTML = replacementText;

		for (var i = 0; i < resultData.length; i++) {
			if (resultData[i].start == startTime) {
				resultData[i].text = replacementText;
			}
		}

		return resultData;
	};

	replaceWordInTranscriptionData = (startTime, endTime, customBreakLine) => {
		let resultData = this.state.transcriptionData;
		const words = document.getElementsByClassName('highlight');
		let replacementText = '';

		for (var i = 0; i < this.state.wordToEdit.length; i++) {
			var x = document.getElementsByClassName(`${i + 1}-${words[0].id}`);

			let el;

			if (x[0] !== undefined) {
				el = x[0].innerHTML;
			} else {
				el = '';
			}
			replacementText = replacementText + el;
		}

		if (customBreakLine) {
			if (replacementText.includes('¤')) {
			} else {
				replacementText = replacementText + '¤';
			}
		}

		document.getElementById(words[0].id).innerHTML = replacementText.split(' ')[0];

		for (var i = 0; i < resultData.entries.length; i++) {
			if (resultData.entries[i].startTime == startTime) {
				if (replacementText === '') {
					resultData.entries.splice(i, 1);
					break;
				}

				if (this.has_any_spaces(replacementText)) {
					let wordArray = replacementText.split(' ');
					let toBeAdded = this.getArrayOfWords(startTime, endTime, wordArray);
					resultData.entries.splice(i, 1);

					resultData.entries.splice(...[i, 0].concat(toBeAdded));

					break;
				} else {
					resultData.entries[i].content = replacementText;
				}
			}
		}

		return resultData;
	};

	has_any_spaces = (str) => {
		const regex = /^\S+$/g;
		if (regex.test(str)) {
			return false;
		}
		return true;
	};

	getTrimItem = () => {
		const { interactItems } = this.props;

		let trimItem = _.find(interactItems, (item) => {
			return item.itemType === 'video';
		});

		if (trimItem) {
			let tempObj = {};
			Object.assign(tempObj, trimItem);

			let trimStart = new Date(tempObj.start);
			let trimEnd = new Date(tempObj.end);
			let zeroTime = new Date('Thu Jan 01 1970 00:00:0 GMT+0100 (Central European Standard Time)');

			let resultObj = {
				start: trimStart.getTime() - zeroTime.getTime(),
				end: trimEnd.getTime() - zeroTime.getTime(),
				exists: true,
			};
			return resultObj;
		} else {
			let resultObj = {
				start: 0,
				end: 0,
				exists: false,
			};
			return resultObj;
		}
	};

	getArrayOfWords = (start, end, wordArray) => {
		let result = [];

		let timeDiff = end - start;

		let timeInterval = timeDiff / wordArray.length;

		let sVal = parseInt(start);

		for (var i = 0; i < wordArray.length; i++) {
			let obj = {};
			if (i === wordArray.length - 1) {
				obj.endTime = parseInt(end);
			} else {
				obj.endTime = parseInt(sVal) + parseInt(timeInterval);
			}
			obj.startTime = parseInt(sVal);
			obj.content = wordArray[i];

			result.push(obj);

			sVal = parseInt(sVal) + parseInt(timeInterval);
		}

		return result;
	};

	replaceWordInTranscriptionDataAddLineBreak = (startTime) => {
		const resultData = this.state.transcriptionData;

		/* resultData.result.results.map(data => {
            console.log(data)
               return data.alternatives.map(words => {
                   return words
            })


        }) */
		const words = document.getElementsByClassName('highlight');
		let replacement = document.getElementById(words[0].id).innerText;
		let replacementText = replacement;

		for (var i = 0; i < resultData.entries.length; i++) {
			//console.log(resultData.result.results[i].alternatives[0].words)

			if (resultData.entries[i].startTime == startTime) {
				resultData.entries[i].content = replacementText + '¤';
				document.getElementById(words[0].id).innerHTML = replacementText + '¤';
			}
		}
		return resultData;
	};

	replaceWordInTranscriptionDataRemoveLineBreak = (startTime) => {
		const resultData = this.state.transcriptionData;

		const words = document.getElementsByClassName('highlight');
		let replacement = document.getElementById(words[0].id).innerText;

		let replacementText = replacement;

		//replacementText.toString()

		for (var i = 0; i < resultData.entries.length; i++) {
			//console.log(resultData.result.results[i].alternatives[0].words)

			if (resultData.entries[i].startTime === startTime) {
				resultData.entries[i].content = replacementText.replace(/¤/, '');
			}
		}
		return resultData;
	};

	reverseArr = (input) => {
		var ret = new Array();
		for (var i = input.length - 1; i >= 0; i--) {
			ret.push(input[i]);
		}
		return ret;
	};

	updateMediaDetails = (media) => {
		const { mediaName } = this.props;
		const customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
		this.setState({ mediaDetails: customMediaInfo }, () => {
			this.getData();
		});
	};

	subExists = (lang) => {
		return this.state.subtitleData.some((item) => {
			return item.language === lang;
		});
	};

	getSrtFilename = (lang, date) => {
		let fileName =
			lang +
			'_' +
			date.getFullYear().toString().substr(-2) +
			('0' + (date.getMonth() + 1)).slice(-2) +
			('0' + date.getDate()).slice(-2) +
			('0' + date.getHours()).slice(-2) +
			('0' + date.getMinutes()).slice(-2) +
			('0' + date.getSeconds()).slice(-2) +
			'.srt';
		return fileName;
	};

	fixSubtitleStringTimings = (subStringInput) => {
		let subArray = parse(subStringInput);
		for (let i = 0; i < subArray.length; i++) {
			if (subArray[i + 1] !== undefined) {
				if (subArray[i].end > subArray[i + 1].start) {
					subArray[i].end = subArray[i + 1].start;
				}
			}
		}
		let subStringOuput = stringify(subArray);

		return subStringOuput;
	};

	uploadSrtTranscription = (selectedLanguage, subtitleStringTemp) => {
		const { t } = this.props;

		let assetId = this.props.mediaDetails.asset ? this.props.mediaDetails.asset.id : '';
		let mediaID = this.props.mediaDetails.id ? this.props.mediaDetails.id : '';
		let lang = selectedLanguage.substring(0, 2);
		let subtitleString = this.fixSubtitleStringTimings(subtitleStringTemp);
		let d = new Date();
		let subBlob = new Blob([subtitleString.toString().replace(/¤/, '')], {
			type: 'application/octet-stream',
		});

		//let subBlob = this.dataURItoBlob(subtitleString)

		let fileName = this.getSrtFilename(lang, d);

		let file = this.blobToFile(subBlob, fileName);

		if (this.subExists(lang)) {
			const subtitleItem = this.state.subtitleData.find((subItem) => subItem.language === lang);

			var tempArray = [...this.state.subtitleData];

			this.props.removeSubtitle(this.props.defaultAccountId, assetId, subtitleItem.resourceId).then((data) => {
				if (data && data.message) {
					this.props.showNotification(
						this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_DELETE_FAILED') + ' ' + data.message
					);
					return;
				}

				this.props.showNotification(this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPDATING'));

				this.refreshSubtitleData(this.getRemainingSubtitleData(tempArray, subtitleItem.resourceId));

				this.props
					.startSubtitleUploadProcess(this.props.defaultAccountId, file, lang, assetId, mediaID)
					.then((data) => {
						if ((data && data.message) || !data || data === UPLOAD_FAILED) {
							return;
						}
						this.props.showMessage(
							this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPDATED'),
							messageTypes.success
						);
					});

				var timer = setInterval(() => {
					if (this.state.checkIfNewSubtitle) {
						clearInterval(timer);
						this.setState({ checkIfNewSubtitle: false });
						return;
					}
					this.props
						.getMediaQueryDetails(this.props.defaultAccountId, mediaID, this.props.mediaName)
						.then((media) => {
							if (!media) {
								this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
								return;
							}
							if (typeof media == 'string') {
								this.props.showNotification(media);
								return;
							}

							this.updateMediaDetails(media);
						});
				}, 2000);
			});
		} else {
			this.props
				.startSubtitleUploadProcess(this.props.defaultAccountId, file, lang, assetId, mediaID)
				.then((data) => {
					if ((data && data.message) || !data || data === UPLOAD_FAILED) {
						return;
					}

					this.props.showMessage(
						this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPLOADED'),
						messageTypes.success
					);
					this.props.setAppMode('subEdit');
					this.setState({ focusedLanguage: lang });
				});

			var timer = setInterval(() => {
				if (this.state.checkIfNewSubtitle) {
					clearInterval(timer);
					this.setState({ checkIfNewSubtitle: false });
					return;
				}
				this.props
					.getMediaQueryDetails(this.props.defaultAccountId, mediaID, this.props.mediaName)
					.then((media) => {
						if (!media) {
							this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
							return;
						}
						if (typeof media == 'string') {
							this.props.showNotification(media);
							return;
						}

						this.updateMediaDetails(media);
					});
			}, 2000);
		}
	};

	uploadSrt = (selectedLanguage, subtitleString) => {
		const { t } = this.props;
		let assetId = this.props.mediaDetails.asset ? this.props.mediaDetails.asset.id : '';
		let mediaID = this.props.mediaDetails.id ? this.props.mediaDetails.id : '';
		let lang = selectedLanguage.substring(0, 2);
		let d = new Date();
		let subBlob = new Blob([subtitleString.toString().replace(/¤/, '')], {
			type: 'application/octet-stream',
		});
		let fileName = this.getSrtFilename(lang, d);
		let file = this.blobToFile(subBlob, fileName);

		this.props.setSubtitleIsUpdating(true);

		if (this.subExists(lang)) {
			const subtitleItem = this.state.subtitleData.find((subItem) => subItem.language === lang);
			var tempArray = [...this.state.subtitleData];

			this.props.removeSubtitle(this.props.defaultAccountId, assetId, subtitleItem.resourceId).then((data) => {
				if (data && data.message) {
					this.props.showNotification(
						this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_DELETE_FAILED') + ' ' + data.message
					);
					return;
				}

				this.props.showNotification(this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPDATING'));

				this.refreshSubtitleData(this.getRemainingSubtitleData(tempArray, subtitleItem.resourceId));

				this.props
					.startSubtitleUploadProcess(this.props.defaultAccountId, file, lang, assetId, mediaID)
					.then((data) => {
						if ((data && data.message) || !data || data === UPLOAD_FAILED) {
							return;
						}

						this.props.showMessage(
							this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPDATED'),
							messageTypes.success
						);
					});

				var timer = setInterval(() => {
					if (this.state.checkIfNewSubtitle) {
						clearInterval(timer);
						this.setState({ checkIfNewSubtitle: false });
						this.props.setSubtitleIsUpdating(false);
						return;
					}
					this.props
						.getMediaQueryDetails(this.props.defaultAccountId, mediaID, this.props.mediaName)
						.then((media) => {
							if (!media) {
								this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
								return;
							}
							if (typeof media == 'string') {
								this.props.showNotification(media);
								return;
							}

							this.updateMediaDetails(media);
						});
				}, 2000);
			});
		} else {
			this.props
				.startSubtitleUploadProcess(this.props.defaultAccountId, file, lang, assetId, mediaID)
				.then((data) => {
					if ((data && data.message) || !data || data === UPLOAD_FAILED) {
						return;
					}
					this.props.showMessage(
						this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPLOADED'),
						messageTypes.success
					);
				});

			var timer = setInterval(() => {
				if (this.state.checkIfNewSubtitle) {
					clearInterval(timer);
					this.setState({ checkIfNewSubtitle: false });
					this.props.setSubtitleIsUpdating(false);
					return;
				}
				this.props
					.getMediaQueryDetails(this.props.defaultAccountId, mediaID, this.props.mediaName)
					.then((media) => {
						if (!media) {
							this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
							return;
						}
						if (typeof media == 'string') {
							this.props.showNotification(media);
							return;
						}

						this.updateMediaDetails(media);
					});
			}, 2000);
		}
	};

	uploadSrtTranslated = (selectedLanguage, subtitleString) => {
		const { t } = this.props;

		let assetId = this.props.mediaDetails.asset ? this.props.mediaDetails.asset.id : '';
		let mediaID = this.props.mediaDetails.id ? this.props.mediaDetails.id : '';
		let lang = selectedLanguage.substring(0, 2);
		let d = new Date();

		let fileName = this.getSrtFilename(lang, d);

		if (this.subExists(lang)) {
			const subtitleItem = this.state.subtitleData.find((subItem) => subItem.language === lang);

			var tempArray = [...this.state.subtitleData];

			this.props.removeSubtitle(this.props.defaultAccountId, assetId, subtitleItem.resourceId).then((data) => {
				if (data && data.message) {
					this.props.showNotification(
						this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_DELETE_FAILED') + ' ' + data.message
					);
					return;
				}

				this.props.showNotification(this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPDATING'));
				this.refreshSubtitleData(this.getRemainingSubtitleData(tempArray, subtitleItem.resourceId));
				this.props
					.saveSrt(
						this.props.defaultAccountId,
						mediaID,
						subtitleString.toString().replace(/¤/, ''),
						lang,
						fileName
					)
					.then((data) => {
						if (data && data.message) {
							this.props.showNotification(
								this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPLOAD_FAILED') +
									' ' +
									data.message
							);
							return;
						}
						this.props.showNotification(
							this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPDATED')
						);
					});

				var timer = setInterval(() => {
					if (this.state.checkIfNewSubtitle) {
						clearInterval(timer);
						this.setState({ checkIfNewSubtitle: false });
						return;
					}
					this.props
						.getMediaQueryDetails(this.props.defaultAccountId, mediaID, this.props.mediaName)
						.then((media) => {
							if (!media) {
								this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
								return;
							}
							if (typeof media == 'string') {
								this.props.showNotification(media);
								return;
							}

							this.updateMediaDetails(media);
						});
				}, 2000);
			});
		} else {
			this.props
				.saveSrt(
					this.props.defaultAccountId,
					mediaID,
					subtitleString.toString().replace(/¤/, ''),
					lang,
					fileName
				)
				.then((data) => {
					if (data && data.message) {
						this.props.showNotification(
							this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPLOAD_FAILED') +
								' ' +
								data.message
						);
						return;
					}
					this.props.showNotification(this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_UPLOADED'));
				});

			var timer = setInterval(() => {
				if (this.state.checkIfNewSubtitle) {
					clearInterval(timer);
					this.setState({ checkIfNewSubtitle: false });
					return;
				}
				this.props
					.getMediaQueryDetails(this.props.defaultAccountId, mediaID, this.props.mediaName)
					.then((media) => {
						if (!media) {
							this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
							return;
						}
						if (typeof media == 'string') {
							this.props.showNotification(media);
							return;
						}

						this.updateMediaDetails(media);
					});
			}, 2000);
		}
	};

	blobToFile = (blobData, fileName) => {
		const fd = new FormData();
		fd.set('a', blobData, fileName);
		return fd.get('a');
	};

	renderMainArea = () => {
		const { t } = this.props;
		switch (this.props.appMode) {
			case 'edit':
				return (
					<div className="sstTranscriber">
						<div className="sttLanguageSelectorWrapper">
							{(this.state.transcriptionData === undefined || this.state.transcriptionData === '') &&
							this.state.isItLoading === false
								? this.renderLanguageSelector()
								: this.state.isItLoading
								? this.renderLoadingState()
								: this.renderTranscriptionText(this.state.transcriptionData)}
						</div>
					</div>
				);
			case 'subEdit':
				return (
					<div className="sttSubEdit">
						{this.state.subtitleActiveUpdate ? (
							<>
								<div className="sttSubEditPlaceholder">
									{`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SUBTITLE_BEING_SAVED_MESSAGE')}.`}
								</div>
							</>
						) : (
							<>
								{this.state.mountedSubtitleArray === undefined ||
								this.state.mountedSubtitleArray.length === 0 ? (
									<div className="sttSubEditPlaceholder">
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_LOADING_SUBTITLE_DATA')}
									</div>
								) : (
									<>
										<div className="translatedPreview">
											{this.renderTranslatedSubtitlePreview(this.state.mountedSubtitleArray)}
										</div>

										{this.renderSubEditForm(
											this.state.mountedSubtitleArray,
											this.state.currentIndexOfSelectedSub
										)}
									</>
								)}
							</>
						)}
					</div>
				);
			default:
				return 'foo';
		}
	};

	renderSubEditForm = (subTable, currentIndex) => {
		let sub;

		if (currentIndex !== '' && currentIndex !== undefined && this.state.changeOfSub) {
			sub = subTable[currentIndex];

			this.setState({ subEditTextAreaTemp: sub.text });
			this.setState({ subEditStartTemp: sub.start }, () =>
				this.setState({ subEditEndTemp: sub.end }, () =>
					this.setState({
						subEditDurationTemp: this.state.subEditEndTemp - this.state.subEditStartTemp,
					})
				)
			);
			this.setState({ changeOfSub: false });
		}

		return this.renderSubEditFormFields(currentIndex);
	};

	getFormattedTimeWithMilliseconds = (dateInput, format) => moment(dateInput).format(format);

	renderSubEditFormFields = (currentIndex) => {
		const { t } = this.props;
		let startTimeValue = this.formatSrtTime(this.state.subEditStartTemp);
		let endTimeValue = this.formatSrtTime(this.state.subEditEndTemp);

		return (
			<div className="subEditForm">
				{currentIndex !== '' && currentIndex !== undefined ? (
					<div className="subEditFormWrapper">
						<textarea
							className="subEditTextArea"
							value={this.state.subEditTextAreaTemp}
							onChange={(e) => this.handleChangeTextarea(e)}
						></textarea>
						<label className="subEditMarginForm subEditLabel">
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_IN_OUT_POINTS')}
						</label>
						{this.state.startPointNotAllowed &&
						!this.state.endPointNotAllowed &&
						!this.state.isDurationOver300 &&
						!this.state.isDurationOver300Previous ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										className="notAllowedInputBorderColor"
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText">
									{' '}
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_START_TIME_EXCEED_ENTRY_MESSAGE')}
								</div>
							</>
						) : this.state.startPointNotAllowed &&
						  !this.state.endPointNotAllowed &&
						  this.state.isDurationOver300 &&
						  !this.state.isDurationOver300Previous ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										className="notAllowedInputBorderColor"
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText"> </div>
							</>
						) : this.state.startPointNotAllowed &&
						  !this.state.endPointNotAllowed &&
						  !this.state.isDurationOver300 &&
						  this.state.isDurationOver300Previous ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										className="notAllowedInputBorderColor"
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText">
									{' '}
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_PREVIOUS_ENTRY_DURATION_LONGER')}
								</div>
							</>
						) : !this.state.startPointNotAllowed &&
						  this.state.endPointNotAllowed &&
						  !this.state.isDurationOver300 &&
						  !this.state.isDurationOver300Next ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										className="notAllowedInputBorderColor"
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText">
									{' '}
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_END_TIME_EXCEED_ENTRY_MESSAGE')}
								</div>
							</>
						) : !this.state.startPointNotAllowed &&
						  this.state.endPointNotAllowed &&
						  this.state.isDurationOver300 &&
						  !this.state.isDurationOver300Next ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										className="notAllowedInputBorderColor"
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText"> </div>
							</>
						) : !this.state.startPointNotAllowed &&
						  this.state.endPointNotAllowed &&
						  !this.state.isDurationOver300 &&
						  this.state.isDurationOver300Next ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										className="notAllowedInputBorderColor"
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText">
									{' '}
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_NEXT_ENTRY_DURATION_LONGER')}
								</div>
							</>
						) : this.state.startPointNotAllowed && this.state.endPointNotAllowed ? (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										className="notAllowedInputBorderColor"
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										className="notAllowedInputBorderColor"
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText">
									{' '}
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_END_START_TIME_EXCEED_ENTRY_MESSAGE')}
								</div>
							</>
						) : (
							<>
								<div className="subEditTimestampInputWrapper">
									<TimeInput
										value={startTimeValue}
										onChange={(e) => this.handleChangeTimeInputStart(e, currentIndex)}
									/>
									<div className="subEditInputDash"> - </div>
									<TimeInput
										value={endTimeValue}
										onChange={(e) => this.handleChangeTimeInputEnd(e, currentIndex)}
									/>
								</div>
								<div className="notAllowedInputText"></div>
							</>
						)}
						{this.state.isDurationOver300 ? (
							<>
								<div className="subEditDurationWrapper subEditDurationNotAllowed">
									<label className="subEditMarginForm subEditLabel">{t('COMMON_DURATION')}:</label>
									<div className="subEditDurationText subEditDurationTextNotAllowed">
										{this.formatSrtTime(this.state.subEditEndTemp - this.state.subEditStartTemp)}{' '}
									</div>
								</div>
								<div className="notAllowedInputText">
									{' '}
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_ENTRY_DURATION_LONGER')}
								</div>
							</>
						) : (
							<div className="subEditDurationWrapper">
								<label className="subEditMarginForm subEditLabel">{t('COMMON_DURATION')}:</label>
								<div className="subEditDurationText">
									{this.formatSrtTime(this.state.subEditEndTemp - this.state.subEditStartTemp)}{' '}
								</div>
							</div>
						)}
						{this.state.isSubtitleEntryUpdated &&
						!this.state.startPointNotAllowed &&
						!this.state.endPointNotAllowed ? (
							<button
								className="subEditMarginForm subEditUpdateButton"
								onClick={() => this.handleUpdateCurrentSub(currentIndex)}
							>
								{t('COMMON_UPDATE')}
							</button>
						) : (
							<button className="subEditMarginForm subEditUpdateButtonDeactive">
								{t('COMMON_UPDATE')}
							</button>
						)}
					</div>
				) : (
					<div className="subEditFormPlaceholder">
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SELECT_SUBTITLE_TO_EDIT')}
					</div>
				)}
			</div>
		);
	};

	handleUpdateCurrentSub = (currentIndex) => {
		const { t } = this.props;
		/* let obj = { 'start': this.state.subEditStartTemp, 'end': this.state.subEditEndTemp, 'text': this.state.subEditTextAreaTemp}

        let subArray = this.state.mountedSubtitleArray

        subArray[currentIndex] = obj

     this.setState( {mountedSubtitleArray: subArray}) */
		if (
			this.state.mountedSubtitleArray[currentIndex - 1] !== undefined &&
			this.state.mountedSubtitleArray[+currentIndex + 1] !== undefined
		) {
			if (this.state.subEditEndTemp - this.state.subEditStartTemp < 300) {
				this.props.showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SMALL_SUB_DURATION'));
				return;
			}
			if (this.state.startPointNotAllowed) {
				this.props.showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_IN_POINT_OVERLAPPING'));
				return;
			}
			if (this.state.endPointNotAllowed) {
				this.props.showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_OUTPOINT_OVERLAPPING'));
				return;
			}

			this.setState({ currentSubIndexToBeReplaced: currentIndex }, () =>
				this.setState({ replaceSubEntry: true }, () => {
					this.props.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_UPDATED_SUBTITLE_ENTRY'),
						messageTypes.info
					);
					this.props.setIsMountedSubtitleUpdated(true);
					/* this.props.sendMediaScreenChanged(); */
					this.setState({
						isSubtitleEntryUpdated: false,
						currentDisplayedSub: this.state.subEditTextAreaTemp,
					});
				})
			);
		} else {
			if (this.state.subEditEndTemp - this.state.subEditStartTemp < 300) {
				this.props.showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_SMALL_SUB_DURATION'));
			} else {
				this.setState({ currentSubIndexToBeReplaced: currentIndex }, () =>
					this.setState({ replaceSubEntry: true }, () => {
						this.props.showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_UPDATED_SUBTITLE_ENTRY'),
							messageTypes.info
						);
						this.props.setIsMountedSubtitleUpdated(true);
						/*  this.props.sendMediaScreenChanged(); */
						this.setState({
							isSubtitleEntryUpdated: false,
							currentDisplayedSub: this.state.subEditTextAreaTemp,
						});
					})
				);
			}
		}
	};

	handleDeleteSubEntry = (index) => {
		this.setState({ currentSubIndexToBeDeleted: index }, () => this.setState({ deleteSubEntry: true }));
	};

	handleAddSubEntry = (index) => {
		this.setState({ currentSubIndexToBeAdded: index }, () => this.setState({ addSubEntry: true }));
	};

	handleChangeTextarea = (e) => {
		this.setState({ subEditTextAreaTemp: e.target.value });
		this.setState({ isSubtitleEntryUpdated: true });
	};

	handleChangeTimeInputStart = (e, currentIndex) => {
		let timeString = e;

		this.setState({ subEditStartTemp: this.converTimestampsToMs(timeString) });
		this.setState({ isSubtitleEntryUpdated: true });

		if (currentIndex == 0) {
			if (this.state.mountedSubtitleArray[currentIndex] !== undefined) {
				if (
					!(
						this.converTimestampsToMs(timeString) >= 0 &&
						this.converTimestampsToMs(timeString) < this.state.mountedSubtitleArray[currentIndex].end - 300
					)
				) {
					this.setState({ startPointNotAllowed: true });

					if (
						this.state.subEditEndTemp - this.converTimestampsToMs(timeString) <= 300 &&
						this.state.subEditEndTemp - this.converTimestampsToMs(timeString) >= 0
					) {
						this.setState({ isDurationOver300: true });
					} else {
						this.setState({ isDurationOver300: false });
					}
				} else {
					this.setState({ startPointNotAllowed: false });
					this.setState({ isDurationOver300: false });
					this.setState({ isDurationOver300Previous: false });
					this.setState({ isDurationOver300Next: false });
				}
			}
		} else {
			if (
				this.state.mountedSubtitleArray[currentIndex] !== undefined &&
				this.state.mountedSubtitleArray[currentIndex - 1] !== undefined
			) {
				if (
					!(
						this.converTimestampsToMs(timeString) >=
							this.state.mountedSubtitleArray[currentIndex - 1].start + 300 &&
						this.converTimestampsToMs(timeString) < this.state.mountedSubtitleArray[currentIndex].end - 300
					)
				) {
					this.setState({ startPointNotAllowed: true });

					if (
						this.state.subEditEndTemp - this.converTimestampsToMs(timeString) <= 300 &&
						this.state.subEditEndTemp - this.converTimestampsToMs(timeString) >= 0
					) {
						this.setState({ isDurationOver300: true });
					} else {
						this.setState({ isDurationOver300: false });
					}

					if (
						this.converTimestampsToMs(timeString) <=
							this.state.mountedSubtitleArray[currentIndex - 1].start + 300 &&
						this.converTimestampsToMs(timeString) >= this.state.mountedSubtitleArray[currentIndex - 1].start
					) {
						this.setState({ isDurationOver300Previous: true });
					} else {
						this.setState({ isDurationOver300Previous: false });
					}
				} else {
					this.setState({ startPointNotAllowed: false });
					this.setState({ isDurationOver300: false });
					this.setState({ isDurationOver300Previous: false });
					this.setState({ isDurationOver300Next: false });
				}
			}
		}
	};

	handleChangeTimeInputEnd = (e, currentIndex) => {
		let timeString = e;

		this.setState({ subEditEndTemp: this.converTimestampsToMs(timeString) });
		this.setState({ isSubtitleEntryUpdated: true });

		if (this.state.mountedSubtitleArray.length > 0) {
			if (currentIndex == this.state.mountedSubtitleArray.length - 1) {
				if (this.state.mountedSubtitleArray[currentIndex] !== undefined) {
					if (
						!(
							this.converTimestampsToMs(timeString) >
							this.state.mountedSubtitleArray[currentIndex].start + 300
						)
					) {
						this.setState({ endPointNotAllowed: true });

						if (
							this.converTimestampsToMs(timeString) - this.state.subEditStartTemp <= 300 &&
							this.converTimestampsToMs(timeString) - this.state.subEditStartTemp >= 0
						) {
							this.setState({ isDurationOver300: true });
						} else {
							this.setState({ isDurationOver300: false });
						}
					} else {
						this.setState({ isDurationOver300: false });
						this.setState({ endPointNotAllowed: false });
						this.setState({ isDurationOver300Previous: false });
						this.setState({ isDurationOver300Next: false });
					}
				}
			} else {
				if (
					this.state.mountedSubtitleArray[currentIndex] !== undefined &&
					this.state.mountedSubtitleArray[+currentIndex + +1] !== undefined
				) {
					if (
						!(
							this.converTimestampsToMs(timeString) <
								+this.state.mountedSubtitleArray[+currentIndex + +1].end - +300 &&
							this.converTimestampsToMs(timeString) >
								this.state.mountedSubtitleArray[currentIndex].start + 300
						)
					) {
						this.setState({ endPointNotAllowed: true });
						if (
							this.converTimestampsToMs(timeString) - this.state.subEditStartTemp <= 300 &&
							this.converTimestampsToMs(timeString) - this.state.subEditStartTemp >= 0
						) {
							this.setState({ isDurationOver300: true });
						} else {
							this.setState({ isDurationOver300: false });
						}

						if (
							this.converTimestampsToMs(timeString) >=
								+this.state.mountedSubtitleArray[+currentIndex + +1].end - +300 &&
							this.converTimestampsToMs(timeString) <=
								this.state.mountedSubtitleArray[+currentIndex + +1].end
						) {
							this.setState({ isDurationOver300Next: true });
						} else {
							this.setState({ isDurationOver300Next: false });
						}
					} else {
						this.setState({ isDurationOver300: false });
						this.setState({ endPointNotAllowed: false });
						this.setState({ isDurationOver300Previous: false });
						this.setState({ isDurationOver300Next: false });
					}
				}
			}
		}
	};

	handleChangeTimeInputDuration = (e) => {
		let timeString = e.target.value;

		this.setState({
			subEditDurationTemp: this.converTimestampsToMs(timeString),
		});
	};

	converTimestampsToMs = (time) => {
		let timeArray = time.split(/[ .:]+/);

		let timeHours = timeArray[0] * 3600000;
		let timeMinutes = timeArray[1] * 60000;
		let timeSeconds = timeArray[2] * 1000;
		let timeMs = timeArray[3];

		let timeResult = +timeHours + +timeMinutes + +timeSeconds + +timeMs;

		return timeResult;
	};

	asyncFunction = (item, cb) => {
		setTimeout(() => {
			cb();
		}, 100);
	};

	generateTranslation = (lang) => {
		const { t, defaultAccountId } = this.props;

		if (googleTranslateRestrictedAccounts && googleTranslateRestrictedAccounts.includes(defaultAccountId)) {
			return;
		}

		let languageValue;
		const tempArray = [...(this.state.mountedSubtitleArray ?? [])];

		let resultArray = tempArray;

		if (lang === '') {
			languageValue = 'sv';
		} else {
			languageValue = lang;
		}

		if (this.state.subtitleData.some((el) => el.language === languageValue)) {
			this.setState({ subExists: true });
			this.props.showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_TRANSLATION_EXISTS'));
		} else {
			this.setState({ currentDisplayedSub: '', loading: true }, () => {
				const debounceTime = 50;
				const newSubs = [];
				resultArray.forEach((sub, i) => {
					setTimeout(() => {
						googleTranslate.translate(
							sub.text.replace(/¤/, ''),
							languageValue,
							function (err, translation) {
								if (!translation) {
									return;
								}
								let str = translation.translatedText;
								let temp = '';
								str = str.split(' ');
								for (let i = 0; i < str.length; i++) {
									if ((i + 1) % 6 == 0) {
										temp += '\n';
									}
									temp += ' ' + str[i];
								}
								newSubs.push({ ...sub, text: temp });
							}
						);
					}, i * debounceTime);
				});
				setTimeout(() => {
					this.setState({
						translatedSubToUpload: newSubs.sort(({ start: startA }, { start: startB }) => startA - startB),
						translatedSubToUploadLanguage: languageValue,
						subExists: false,
						loading: false,
					});
				}, resultArray.length * debounceTime + 1000);
			});
		}
	};

	generateSubtitleView = (lang) => {
		let languageValue;

		let resultArray = [];
		if (this.state.subtitleArrays.length > 0) {
			resultArray = [...this.state.subtitleArrays.filter((sub) => sub.language === lang)];
		}

		if (lang === '') {
			languageValue = 'sv';
		} else {
			languageValue = lang;
		}
		this.setState({
			translationLanguage: languageValue,
			checkIfTranslated: true,
		});

		try {
			return resultArray[0].subtitle;
		} catch (e) {
			console.error(e);
		}
	};

	setTranslation = (resultArray, subExists, languageValue) => {
		this.setState({ translatedSubtitleArray: resultArray }, () => {
			if (subExists === false) {
				this.props.addTranslationDetails(
					this.props.defaultAccountId,
					this.props.mediaDetails.id,
					languageValue,
					this.getTranslatedCount(this.state.translatedSubtitleArray)
				);
			}
		});
	};

	getTranslatedCount = (array) => {
		let count = 0;

		array.map((el) => {
			count = count + el.text.length;
		});

		return count;
	};

	resetTranscriptionData = () => {
		const { t, showNotification } = this.props;
		this.setState({ transcriptionData: undefined, loading: false });
		showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_HEADER_DELETE_TRANSCRIPTION'));
	};

	render() {
		const { t } = this.props;
		const showPlayer = this.props.showPlayer || window.location.href.includes('selectedMenu=speechToText');
		let trimObj = this.getTrimItem();

		return (
			<>
				<div id="speechToTextScreen" ref={(ref) => (this.myDiv = ref)}>
					<div className="sttLeftSide">
						{this.state.transcriptionData === undefined ? (
							''
						) : (
							<div className="sttHeader">
								<SpeechToTextHeader
									t={t}
									selectedLanguage={this.state.selectedLanguage}
									generatedSubString={this.state.generatedSubString}
									mediaDetails={this.props.mediaDetails}
									uploadSrt={this.uploadSrt}
									generateTranslation={this.generateTranslation}
									focusedLanguage={this.state.focusedLanguage}
									mountedSubtitleArray={this.state.mountedSubtitleArray}
									checkIfTranslated={this.state.checkIfTranslated}
									resetTranscriptionDataInLocalState={this.resetTranscriptionData}
									triggerLoading={() => {
										this.setState({ loading: true });
									}}
									isEnableDeleteOption={this.state.transcriptionData?.status === 8}
								/>
							</div>
						)}
						{this.renderMainArea()}
						{this.state.transcriptionData === undefined ? (
							''
						) : (
							<SpeechToTextControlBar
								msPosition={this.state.positionMs}
								videoDuration={this.props.mediaDetails.duration * 1000}
								trimItem={trimObj}
							/>
						)}
					</div>
					<div className="sttRightSide">
						<div id="sttPlayer" className="sttVideoPlayer">
							{showPlayer && (
								<LivePlayer
									configUrl={
										'https://video.qbrick.com/play2/api/v1/accounts/Accpy7KNHj1IUilFL5BMuNL0Q/configurations/speech-2-text'
									}
									isLive={false}
									widgetPrefix="speechToTextPlayer"
									entityId={this.props.mediaDetails.id}
									entityType={this.props.mediaNames.medias}
									container={this.mediaDetailContainer}
									defaultAccountId={this.props.defaultAccountId}
									width={this.state.videoWidth}
									height={this.state.videoHeight}
									pause={this.state.selectedMenu != this.props.menuKeys.details}
									key={this.state.playerConfigurationId}
									play={this.props.playPlayer}
									pausePlayer={this.props.pausePlayer}
									position={this.props.positionPlayer}
									hideControls={true}
									autoPlay={false}
									keepPreviousStateAfterSeeking={this.props.playPlayer ? false : true}
								/>
							)}
							<div className="sttCurrentDisplayedSub">{this.state.currentDisplayedSub}</div>
						</div>
						{this.getSubtitleTable(this.state.subtitleData)}
					</div>
				</div>
				<KeyboardEventHandler
					handleKeys={['all', 'ctrl+enter', 'ctr+backspace']}
					isDisabled={!this.props.speechToTextIsOn}
					onKeyEvent={(key, e) => this.handleKeydown(key, e)}
				/>

				<SpeechToTextDeleteSubtitleDialog
					t={t}
					state={this.state}
					defaultAccountId={this.props.defaultAccountId}
					deleteSubtitle={this.deleteSubtitle}
					handleHideDeleteSelectedSubtitleDialog={this.handleHideDeleteSelectedSubtitleDialog}
				/>

				<LoadingOverlay active={this.state.loading} spinner={<ScaleLoader color={'#ffffff'} />} />
			</>
		);
	}
}

const mapStateToProps = ({ session, speechToText, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	playPlayer: speechToText.playPlayer,
	pausePlayer: speechToText.pausePlayer,
	positionPlayer: speechToText.positionPlayer,
	clickedPosition: speechToText.clickedPosition,
	transcriptionData: speechToText.transcriptionData,
	showPreview: speechToText.showPreview,
	appMode: speechToText.appMode,
	interactItems: interactTimelineReducer.items,
	subtitleIsUpdating: speechToText.subtitleIsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	setPlayerPosition: (position) => dispatch(setPlayerPosition(position)),
	playButtonPlay: (_) => dispatch(playButtonPlay()),
	playButtonPause: (_) => dispatch(playButtonPause()),
	setClickedPosition: (clickedPosition) => dispatch(setClickedPosition(clickedPosition)),
	startTranscription: (accouuntId, mediaId, language, duration) =>
		dispatch(startTranscription(accouuntId, mediaId, language, duration)),
	saveTranscription: (accouuntId, mediaId, body) => dispatch(saveTranscription(accouuntId, mediaId, body)),
	loadTranscription: (accouuntId, mediaId) => dispatch(loadTranscription(accouuntId, mediaId)),
	toggleShowPreview: (showPreview) => dispatch(toggleShowPreview(showPreview)),
	resetSpeechToTextReducer: (_) => dispatch(resetSpeechToTextReducer()),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	startSubtitleUploadProcess: (accId, file, language, assetId, mediaId) =>
		dispatch(startSubtitleUploadProcess(accId, file, language, assetId, mediaId)),
	saveSrt: (accountId, mediaId, body, language, fileName) =>
		dispatch(saveSrt(accountId, mediaId, body, language, fileName)),
	removeSubtitle: (accountId, assetId, resourceId) => dispatch(removeSubtitle(accountId, assetId, resourceId)),
	setRawTranscriptionText: (text) => dispatch(setRawTranscriptionText(text)),
	addTranslationDetails: (accountId, mediaId, language, charCount) =>
		dispatch(addTranslationDetailsToDatabase(accountId, mediaId, language, charCount)),
	setAppMode: (mode) => dispatch(setAppMode(mode)),
	setIsMountedSubtitleUpdated: (mountedSubUpdate) => dispatch(setIsMountedSubtitleUpdated(mountedSubUpdate)),
	sendMediaScreenChanged: (_) => dispatch(sendMediaScreenChanged()),
	setSubtitleIsUpdating: (value) => dispatch(setSubtitleIsUpdating(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SpeechToTextScreen);
