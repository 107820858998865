import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import { IconButton, InputAdornment } from '@material-ui/core';

import { showNotification } from '../../../../actions/notificationActions';
import TagsControl from '../../../../reusable/TagsControl/TagsControl';
import { retrieveTags, saveInspectScreenDetails, getMediaQueryDetails } from '../../../../actions/publishActions';
import {
	mediaTypes,
	customMetadataTypes,
	messageTypes,
	mediaNames,
	mediaPropertyNames,
} from '../../../../constants/mediaConstants';
import { isRequestFail } from './../../../../services/inspectScreenHelperService';
import DeleteMedia from './DeleteMedia/DeleteMedia';
import Button from '@material-ui/core/Button';
import { updateMediaProperties } from './../../../../actions/inspectScreenActions';
import { getDetails } from '../../../../services/mediaDisplayService';
import './MediaDetails.scss';
import ConfirmationDialog from '../../../../reusable/ConfirmationDialog';

const styles = () => ({
	colorSwitchBase: {
		color: '#AFAFAF',
		'&$colorChecked': {
			color: '#126CFC',
			'& + $colorBar': {
				backgroundColor: '#126CFC',
			},
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#126CFC',
		},
	},
	colorBar: {},
	colorChecked: {},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {},
	menu: {
		width: 200,
	},
});

class MediaDetails extends Component {
	state = {
		isNameEdited: false,
		accountTags: [],
		customMetadataObject: '',
		custom: this.props.mediaDetails.custom || {},
		inputTags: this.props.mediaDetails.tags || [],
		inputNameValue: this.props.mediaDetails.title,
		inputDescriptionValue: this.props.mediaDetails.description,
		mediaDetailsModified: false,
		latestChanges: {
			title: this.props.mediaDetails.title,
			description: this.props.mediaDetails.description,
			tags: this.props.mediaDetails.tags || [],
			custom: this.props.mediaDetails.custom || {},
		},
		lastRoute: this.props.lastRoute,
		customErrorState: false,
	};

	constructor(props) {
		super(props);
		this.tagsComponent = null;
	}

	UNSAFE_componentWillMount() {
		const { customMetadata } = this.props;
		this.fetchTags();
		this.setCustomMetaDataObject(customMetadata);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.customMetadata && nextProps.customMetadata != this.props.customMetadata) {
			this.setCustomMetaDataObject(nextProps.customMetadata);
		}
		if (nextProps.lastRoute != this.props.lastRoute) {
			this.setState({
				lastRoute: nextProps.lastRout,
			});
		}
	}
	componentDidMount() {
		if (this.props.onMediaDetailsSectionLoaded) {
			this.props.onMediaDetailsSectionLoaded(this);
		}
	}

	componentDidUpdate() {
		if (this.props.mediaDetailsRef) {
			this.props.mediaDetailsRef.current = { stateData: this.state, tags: this.tagsComponent.state.tags || [] };
		}
	}

	fetchTags = (_) => this.props.retrieveTags().then((data) => this.setState({ accountTags: data }));

	handleSaveForMediaDetails = () => {
		const { t, mediaDetails, showMessage } = this.props;
		if (mediaDetails && mediaDetails.asset) {
			if (!this.checkForErrorCases()) {
				this.handleSaveForGeneralDetails();
			}
		} else {
			showMessage(t('COMMON_FAILED_TO_SAVE_NO_ASSET_ID'), messageTypes.error);
		}
	};

	handleCancelForMediaDetails = () => {
		const { latestChanges } = this.state;
		this.setState({
			customErrorState: false,
		});
		this.onRevertChanges(latestChanges);
	};

	checkForErrorCases = (_) => {
		const { t, showMessage } = this.props;
		const { customMetadataObject, custom } = this.state;
		if (customMetadataObject && customMetadataObject.required && customMetadataObject.required.length > 0) {
			for (var property in customMetadataObject.properties) {
				if (
					customMetadataObject.required &&
					customMetadataObject.required.indexOf &&
					customMetadataObject.required.indexOf(property) !== -1 &&
					!custom[property]
				) {
					showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_COMMON_FILL_THE_REQUIRED_FIELDS'),
						messageTypes.error
					);
					this.setState({
						customErrorState: true,
					});
					return true;
				}
			}
		}
		this.setState({
			customErrorState: false,
		});
		return false;
	};

	handleSaveForGeneralDetails = (_) => {
		const { defaultAccountId, t, mediaName, mediaDetails, showMessage, mediaType } = this.props;
		const { latestChanges } = this.state;
		//important to mention both name and description in body else the other detail gets blank
		if (mediaType === mediaTypes.trashedMedias) {
			showMessage(t('COMMON_RESTORE_TO_CHANGE'), messageTypes.info);
			return;
		}

		let body = '';
		showMessage(t('COMMON_WAIT_TO_SAVE_CHANGE'), messageTypes.info);
		this.getBodyOfMediaForDetails((body1) => {
			body = body1;
			this.props.saveInspectScreenDetails(defaultAccountId, mediaDetails.id, mediaName, body).then((data) => {
				if (isRequestFail(this.props, data)) {
					showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_MEDIA_DETAILS'),
						messageTypes.error
					);
					return;
				}
				showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_RESTRICTION_SAVED_DETAILS_SUCCESSFULLY'),
					messageTypes.info
				);
				let newChanges = {
					...latestChanges,
				};
				if (body.metadata.hasOwnProperty('title')) {
					newChanges['title'] = body.metadata.title;
					this.props.updateMediaProperties({
						id: mediaDetails.id,
						mediaPropertyKey: mediaPropertyNames.TITLE,
						mediaPropertyValue: body.metadata.title,
					});
				}
				if (body.metadata.hasOwnProperty('description')) {
					newChanges['description'] = body.metadata.description;
				}
				if (body.hasOwnProperty('tags')) {
					newChanges['tags'] = body.tags;
				}
				if (body.hasOwnProperty('custom')) {
					newChanges['custom'] = body.custom;
				}
				this.setState({
					latestChanges: newChanges,
					mediaDetailsModified: false,
				});
			});
		});
	};

	getBodyOfMediaForDetails = (callback) => {
		const { mediaDetails, defaultAccountId, t, mediaName } = this.props;
		const { inputNameValue, inputDescriptionValue, custom } = this.state;
		let body = {
			id: mediaDetails.id,
			asset: {
				id: mediaDetails.asset.id,
			},
			metadata: {
				title: inputNameValue || '',
				description: inputDescriptionValue || '',
			},
			tags: this.tagsComponent.state.tags || [],
			custom: custom || {},
		};
		let newCustom = {
			...custom,
		};
		let newCustomObj = {};
		for (var key in newCustom) {
			// to be relooked if this is really needed else assign state.custom directly to body.custom
			if (newCustom[key]) {
				newCustomObj[key] = newCustom[key];
			}
		}
		body.custom = newCustomObj;
		if (mediaName == mediaNames.medias) {
			this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias).then((media) => {
				if (isRequestFail(this, media)) {
					this.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
						messageTypes.error
					);
					return;
				}
				let mediaDetails = getDetails(media, mediaName === mediaNames.playlists);
				if (mediaDetails && mediaDetails.custom && mediaDetails.custom.livemanager) {
					if (mediaDetails.custom.livemanager.start) {
						body.custom['livemanager'] = {
							start: mediaDetails.custom.livemanager.start,
						};
					}
					if (body.custom.livemanager && mediaDetails.custom.livemanager.stop) {
						body.custom.livemanager.stop = mediaDetails.custom.livemanager.stop;
					}
				}
				callback(body);
			});
		} else {
			callback(body);
		}
	};

	onRevertChanges = (changes) => {
		this.setState({
			inputNameValue: changes.title || '',
			mediaDetailsModified: false,
		});
		if (this.props.updateScreenTitle) {
			this.props.updateScreenTitle(changes.title);
		}
		this.setState({
			inputDescriptionValue: changes.description || '',
			custom: changes.custom || {},
		});
		this.updateTagsComponentState(changes.tags);
	};

	updateTagsComponentState = (tags) => this.tagsComponent.updatStateFromParent(tags);

	setCustomMetaDataObject = (customMetadata) => {
		const { mediaDetails } = this.props;
		let customMetadataObject = '';
		if (mediaDetails && customMetadata) {
			if (mediaDetails.type == mediaTypes.playlists) {
				customMetadataObject = customMetadata.properties.playlist;
			} else if (mediaDetails.type == mediaTypes.catalog) {
				customMetadataObject = customMetadata.properties.catalog;
			} else {
				customMetadataObject = customMetadata.properties.media;
			}
			this.setState({ customMetadataObject: customMetadataObject });
		}
	};

	onEditName = (_) => {
		const { isNameEdited } = this.state;
		if (!isNameEdited) {
			this.setState({ isNameEdited: true });
		}
	};

	stopNameEdit = (_) => this.state.isNameEdited && this.setState({ isNameEdited: false });

	onDetailsChange = () => {
		if (this.props.mediaType === mediaTypes.trashedMedias) {
			return;
		}

		this.setState({
			mediaDetailsModified: true,
		});
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange();
		}
	};

	onNameInputValueChange = (e) => {
		let title = e.target.value;
		this.setState({ inputNameValue: title });
		this.onDetailsChange();
		if (this.props.updateScreenTitle) {
			this.props.updateScreenTitle(title);
		}
	};

	onDescriptionInputValueChange = (e) => {
		this.setState({ inputDescriptionValue: e.target.value });
		this.onDetailsChange();
	};

	getNameSection = (_) => {
		const { t } = this.props;
		const { inputNameValue } = this.state;
		return (
			<>
				<div className="editNameSection">
					<label>{t('COMMON_TITLE')}</label>
					{<textarea rows="1" onChange={this.onNameInputValueChange} value={inputNameValue} />}
				</div>
			</>
		);
	};

	getDescriptionSection = (_) => {
		const { t } = this.props;
		const { inputDescriptionValue } = this.state;
		return (
			<>
				<div className="descriptionSection">
					<label>{t('COMMON_DESCRIPTION')}</label>
					{
						<textarea
							rows="4"
							value={inputDescriptionValue}
							onChange={this.onDescriptionInputValueChange}
							placeholder={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_ENTER_DESCRIPTION')}
						/>
					}
				</div>
			</>
		);
	};

	onTagsLoaded = (tagsComponent) => (this.tagsComponent = tagsComponent);

	getTagsArea = (_) => {
		const { t } = this.props;
		const { inputTags, accountTags } = this.state;
		return (
			<>
				<div className="tagsSection">
					<label>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_TEXT_TAGS')}</label>
					<TagsControl
						t={t}
						displayTags={inputTags}
						suggestions={accountTags}
						onTagsLoaded={this.onTagsLoaded}
						onDetailsChange={this.onDetailsChange}
					/>
				</div>
			</>
		);
	};

	getCalendarClockIcon = (_) => {
		return (
			<svg
				style={{
					width: '25px',
					height: '25px',
					fill: '#878cac',
				}}
				className="dateTimeIcon"
				viewBox="0 0 60.24 57.93"
			>
				<defs />
				<title>Calendar_clock</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="M48.5,36.7c-1.2,1.17-2.37,2.37-3.55,3.55l-.53.53-.54-.54c-1.15-1.15-2.29-2.32-3.46-3.45a2,2,0,0,0-2.8-.11,1.87,1.87,0,0,0-.09,2.64c1.84,1.9,3.71,3.78,5.62,5.61a1.78,1.78,0,0,0,2.62-.09c1.82-1.8,3.64-3.61,5.44-5.44a1.85,1.85,0,0,0,0-2.67A1.88,1.88,0,0,0,48.5,36.7Z" />
						<path d="M9.69,11.56a1.91,1.91,0,0,0,1.9-1.91V1.91a1.91,1.91,0,1,0-3.81,0V9.65A1.91,1.91,0,0,0,9.69,11.56Z" />
						<path d="M40.42,11.56a1.92,1.92,0,0,0,1.91-1.91V1.91a1.91,1.91,0,1,0-3.82,0V9.65A1.92,1.92,0,0,0,40.42,11.56Z" />
						<rect x="21.38" y="20.36" width="7.08" height="7.22" />
						<rect x="8.66" y="20.36" width="7.08" height="7.22" />
						<rect x="8.66" y="31.63" width="7.08" height="7.22" />
						<path d="M24,42.1H3.62a.36.36,0,0,1-.36-.36V17.28a.36.36,0,0,1,.36-.36H46.49a.36.36,0,0,1,.36.36v4.87a17.75,17.75,0,0,1,3.26.75V10.61a3.21,3.21,0,0,0-3.21-3.2H44.33V9.65a3.91,3.91,0,1,1-7.82,0V7.41H13.59V9.65a3.91,3.91,0,1,1-7.81,0V7.41H3.2A3.2,3.2,0,0,0,0,10.61V42.73a3.21,3.21,0,0,0,3.2,3.21H24.46A17.38,17.38,0,0,1,24,42.1Z" />
						<path d="M44.41,26.27A15.83,15.83,0,1,0,60.24,42.1,15.85,15.85,0,0,0,44.41,26.27Zm0,27.66A11.83,11.83,0,1,1,56.24,42.1,11.84,11.84,0,0,1,44.41,53.93Z" />
						<path d="M21.38,38.86h3.06a20.1,20.1,0,0,1,2.66-7.23H21.38Z" />
						<path d="M41.21,22.13V20.36H34.13v4.32A19.89,19.89,0,0,1,41.21,22.13Z" />
					</g>
				</g>
			</svg>
		);
	};

	getCalendarIcon = (_) => {
		return (
			<svg
				style={{
					width: '21px',
					height: '25px',
					fill: '#878cac',
				}}
				viewBox="0 0 50.11 50.09"
			>
				<title>Calendar_new</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="M41.88,30.67h-7.6V23h7.6Z" />
						<path d="M8.23,30.66V23.05h7.63v7.61Z" />
						<path d="M29.34,30.66H21.75V23.05h7.59Z" />
						<path d="M8.23,42.23V34.6h7.61v7.63Z" />
						<path d="M21.73,34.59h7.62v7.64H21.73Z" />
						<path d="M34.25,34.59h7.62v7.64H34.25Z" />
						<rect x="7.75" width="3.81" height="11.56" rx="1.91" />
						<rect x="38.48" width="3.81" height="11.56" rx="1.91" />
						<path d="M45.49,7.69h-1.2c0,.82.06,1.58,0,2.32a3.79,3.79,0,0,1-5.21,3.2,3.7,3.7,0,0,1-2.43-3.42c0-.67,0-1.35,0-2.06H13.48c0,.71,0,1.39,0,2.06A3.7,3.7,0,0,1,11,13.21,3.8,3.8,0,0,1,5.82,10c-.06-.74,0-1.5,0-2.32H4.62C1.7,7.7,0,9.4,0,12.31V45.42c0,.21,0,.43,0,.64a4.18,4.18,0,0,0,4.16,4H45.93a4.18,4.18,0,0,0,4.17-4c0-.21,0-.43,0-.64V12.31C50.11,9.4,48.41,7.7,45.49,7.69Zm.77,37.59c0,1,0,1-1,1H4.85c-1,0-1,0-1-1q0-12.62,0-25.23c0-.66.17-.8.81-.8q10.2,0,20.41,0t20.42,0c.63,0,.81.14.81.8Q46.25,32.67,46.26,45.28Z" />
					</g>
				</g>
			</svg>
		);
	};

	handleCustomChange = (e, property, propertyKey) => {
		let newCustomObject = {
			...this.state.custom,
		};
		switch (property.type) {
			case customMetadataTypes.BOOLEAN:
				if (e.target) {
					newCustomObject[propertyKey] = String(e.target.checked);
					this.onDetailsChange();
				}
				break;
			case customMetadataTypes.MULTISELECT:
				if (e.target.selectedIndex) {
					newCustomObject[propertyKey] = String(e.target[e.target.selectedIndex].value);
				} else if (e.target.selectedIndex === 0) {
					delete newCustomObject[propertyKey];
				}
				this.onDetailsChange();
				break;
			case customMetadataTypes.NUMBER:
				if (e.target && !isNaN(e.target.value)) {
					newCustomObject[propertyKey] = e.target.value.toString();
					this.onDetailsChange();
				}
				break;
			case customMetadataTypes.STRING:
				if (e.target) {
					newCustomObject[propertyKey] = e.target.value.toString();
					this.onDetailsChange();
				}
				break;
			case customMetadataTypes.DATETIME:
				newCustomObject[propertyKey] = e;
				this.onDetailsChange();
				break;
			case customMetadataTypes.DATE:
				newCustomObject[propertyKey] = e !== null ? e.toISOString().split('T')[0] : e;
				this.onDetailsChange();
				break;
			default:
				break;
		}
		this.setState({ custom: newCustomObject });
	};

	getCustomPropertiesComponent = (property, propertyKey, requiredSymbol) => {
		const { t, classes } = this.props;
		const { custom, customErrorState } = this.state;
		if (!property.type) {
			return null;
		}
		switch (property.type) {
			case customMetadataTypes.MULTISELECT:
				return (
					<>
						<div
							className="customPropertyComponent sortingDropDownWrapper fullWidth"
							attr={customErrorState && requiredSymbol && !custom[propertyKey] ? 'customerror' : ''}
						>
							<select
								onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
								className="sortingDropDown fulWidth"
							>
								<option value="">{`-${t('COMMON_SELECT')}-`}</option>
								{property &&
									property.enum &&
									property.enum.map &&
									property.enum.map((value, index) => {
										const key = Object.keys(property.enum[index])[0];
										const text = property.enum[index][Object.keys(property.enum[index])[0]];
										return (
											<option
												key={key}
												attr={key}
												value={key}
												selected={custom && custom[propertyKey] === key}
											>
												{text}
											</option>
										);
									})}
							</select>
						</div>
					</>
				);
			case customMetadataTypes.BOOLEAN:
				return (
					<>
						<div className="customPropertyComponent">
							{' '}
							{/*attr={customErrorState && requiredSymbol && !custom.hasOwnProperty(propertyKey) ? 'customerror' : ''}   {boolean was not handled*/}
							<Switch
								className="swicthButton"
								onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
								checked={
									custom && custom.hasOwnProperty(propertyKey) && custom[propertyKey]
										? JSON.parse(custom[propertyKey])
										: false
								} //need to handle
								classes={{
									switchBase: classes.colorSwitchBase,
									checked: classes.colorChecked,
									/* colorPrimary: classes.colorBar, */
								}}
							/>
						</div>
					</>
				);
			case customMetadataTypes.STRING:
				return (
					<>
						<div
							className="customPropertyComponent"
							attr={customErrorState && requiredSymbol && !custom[propertyKey] ? 'customerror' : ''}
						>
							{
								<textarea
									rows="2"
									value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : ''}
									onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
									placeholder={t('COMMON_ENTER_TEXT')}
								/>
							}
							{/*}
                        <TextField
                            onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
                            value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : ''}
                            id=""
                            label={t('ENTER_TEXT')}
                            multiline
                            rows="2"
                            fullWidth
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />*/}
						</div>
					</>
				);
			case customMetadataTypes.NUMBER:
				return (
					<>
						<div
							className="customPropertyComponent"
							attr={customErrorState && requiredSymbol && !custom[propertyKey] ? 'customerror' : ''}
						>
							{
								<input
									type="number"
									onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
									value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : ''}
									placeholder={t('COMMON_ADD_NUMBERS')}
								/>
							}
							{/*
                        <TextField
                            onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
                            value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : ''}
                            id="outlined-number"
                            label={t('ENTER_NUMBER')}
                            type="number"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                         */}
						</div>
					</>
				);
			case customMetadataTypes.DATETIME:
				return (
					<>
						<div
							className="customPropertyComponent"
							attr={customErrorState && requiredSymbol && !custom[propertyKey] ? 'customerror' : ''}
						>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									className="datetime"
									onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
									value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : null}
									format="yyyy-MM-dd"
									ampm={false}
									margin="normal"
									clearable
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton>{this.getCalendarIcon()}</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<TimePicker
									className="datetime"
									onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
									value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : null}
									format="HH:mm:ss"
									ampm={false}
									margin="normal"
									clearable
									seconds
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton>{this.getCalendarClockIcon()}</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</>
				);
			case customMetadataTypes.DATE:
				return (
					<>
						<div
							className="customPropertyComponent"
							attr={customErrorState && requiredSymbol && !custom[propertyKey] ? 'customerror' : ''}
						>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									className="datetime"
									onChange={(e) => this.handleCustomChange(e, property, propertyKey)}
									value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : null}
									format="yyyy-MM-dd"
									margin="normal"
									clearable
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton>{this.getCalendarIcon()}</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</>
				);
		}
	};

	renderCustomMetadataProperties = (propertyObj, propertyKey) => {
		let requiredSymbol = '';
		const { customMetadataObject } = this.state;
		if (
			customMetadataObject.required &&
			customMetadataObject.required.indexOf &&
			customMetadataObject.required.indexOf(propertyKey) != -1
		) {
			requiredSymbol = ' * ';
		}
		return (
			<>
				<label className="customPropertyTitle"> {propertyObj.title + requiredSymbol} </label>
				{this.getCustomPropertiesComponent(propertyObj, propertyKey, requiredSymbol)}
			</>
		);
	};

	renderCustomMetadata = (_) => {
		const { customMetadataObject } = this.state;
		if (
			customMetadataObject &&
			customMetadataObject.properties &&
			customMetadataObject.properties &&
			Object.keys(customMetadataObject.properties).map
		) {
			return (
				<>
					<div className="customMetadataList">
						{Object.keys(customMetadataObject.properties).map((propertyKey) => (
							<>
								{this.renderCustomMetadataProperties(
									customMetadataObject.properties[propertyKey],
									propertyKey
								)}
							</>
						))}
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	renderDeleteMedia = () => {
		const { t, mediaDetails, mediaName, showMessage, history, getDeleteMediaDialogRef } = this.props;
		const { lastRoute } = this.state;
		return (
			<DeleteMedia
				mediaDetails={mediaDetails}
				mediaName={mediaName}
				t={t}
				showMessage={showMessage}
				lastRoute={lastRoute}
				history={history}
				getDeleteMediaDialogRef={getDeleteMediaDialogRef}
			/>
		);
	};

	render() {
		const { t, handleSave, handleCancel, openReplaceContentDialog, handleMediaReplacermentUserChoose, mediaType } =
			this.props;
		const { mediaDetailsModified } = this.state;

		return (
			<>
				<div id="detailsSection">
					{this.getNameSection()}
					{this.getDescriptionSection()}

					{this.getTagsArea()}
					<hr />
					<br />
					{this.renderCustomMetadata()}

					<div className="bottomActionButtons">
						<Button
							disabled={mediaType === mediaTypes.trashedMedias || !mediaDetailsModified}
							variant="contained"
							className="defaultActionBtn neutralBtn"
							onClick={handleCancel}
						>
							{t('COMMON_CANCEL')}
						</Button>
						<Button
							disabled={mediaType === mediaTypes.trashedMedias || !mediaDetailsModified}
							variant="contained"
							className="defaultActionBtn"
							onClick={handleSave}
						>
							{t('COMMON_SAVE')}
						</Button>
					</div>
					<hr />
					<div className="bottomActionButtons warningActions">
						{this.renderDeleteMedia()}
						{openReplaceContentDialog && (
							<ConfirmationDialog
								t={t}
								title={t('COMMON_REPLACE_VIDEO')}
								message={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_REPLACE_VIDEO_CONTENT_WARNING')}
								callback={handleMediaReplacermentUserChoose}
								cancelButtonText={t('COMMON_CANCEL')}
								okButtonText={t('COMMON_YES')}
							/>
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ publish, session, liveManager }) => ({
	loading: publish.loading,
	customMetadata: session.customMetadata ? JSON.parse(session.customMetadata) : '',
	liveEventStartTime: liveManager.liveEventStartTime,
	liveEventStopTime: liveManager.liveEventStopTime,
	accountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	retrieveTags: (accountId) => dispatch(retrieveTags(accountId)),
	showNotification: (message) => dispatch(showNotification(message)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	updateMediaProperties: (updatedMedia) => dispatch(updateMediaProperties(updatedMedia)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles)(MediaDetails));
