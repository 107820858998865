import React from 'react';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../../../services/stringHelperService';
import CardData from '../../../../utils/CardData';
import CreatedWidgetList from './createdWidgetList/CreatedWidgetList';
import { WIDGETS_TYPE_LIST, WIDGET_TYPES } from '../../../../../../constants/scenarioConstant';
import { useTranslation } from 'react-i18next';

interface WidgetsTabProps {
	baseClassName: string;
	editingScene: { id: string };
	duplicateWidget: (_widget: object, _templateToCopy?: object) => {};
	handleDragStart: (_event: {}) => {};
}

export interface AllWidgetTemplatesProps {
	id: string;
	name: string;
	widgetTemplates: any[];
}

const WidgetsTab = ({ baseClassName, editingScene, handleDragStart, duplicateWidget }: WidgetsTabProps) => {
	const { t } = useTranslation();
	const scenes = useSelector((state) => (state as any).scenarioReducer.scenes);
	const { id: editingSceneId } = editingScene ?? {};
	const allWidgetTemplates = scenes.reduce((allTemplates: any[], currentScene: AllWidgetTemplatesProps) => {
		const { id, name, widgetTemplates } = currentScene;
		if (Array.isArray(widgetTemplates)) {
			const validWidgets = widgetTemplates.filter(
				(widget: { type: string }) => widget.type !== WIDGET_TYPES.WIDGET_TYPE_VIDEO
			);

			if (validWidgets.length > 0 && id !== editingSceneId) {
				return [
					...allTemplates,
					{
						id: id,
						widgetTemplates: validWidgets,
						name,
					},
				];
			}
		}
		return allTemplates;
	}, []);

	return (
		<div className={`${baseClassName}__tab-content`}>
			<CardData title={t('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_WIDGET_LIBRARY')} noPadding>
				<div className={`${baseClassName}__tab-content ${baseClassName}__tab-widgets`}>
					{WIDGETS_TYPE_LIST.map((widget) => (
						<div
							key={widget.type}
							className={`${baseClassName}__widget ${baseClassName}__widget-dnd`}
							data-type={widget.type}
							onDragStart={handleDragStart}
							draggable
						>
							{widget.icon}
							<span className="widget-name">{capitalizeFirstLetter(widget.type)}</span>
						</div>
					))}
				</div>
			</CardData>
			{allWidgetTemplates.length > 0 && (
				<CardData title={t('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CREATED_WIDGETS')} noPadding>
					<CreatedWidgetList duplicateWidget={duplicateWidget} allWidgetTemplates={allWidgetTemplates} />
				</CardData>
			)}
		</div>
	);
};

export default WidgetsTab;
