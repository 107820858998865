import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';

import { customScreenSizes } from '../../constants/mediaConstants';
import './LibraryPagination.scss';
import { useTranslation } from 'react-i18next';

const useStyles1 = makeStyles(() => ({
	root: {
		flexShrink: 0,
	},
}));

interface TablePaginationActionsProps {
	disabled?: boolean;
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (_event: React.MouseEvent<HTMLButtonElement>, _newPage: number) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;
	const totalPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, totalPages);
	};

	const handlePageNumberButtonClick = (event: React.ChangeEvent<unknown>, value: number) => {
		onPageChange(event as any, value - 1);
	};

	return (
		<div className={`${classes.root} paginationAction`}>
			<IconButton
				className="actionButton"
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				className="actionButton"
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			{totalPages !== 0 && (
				<Pagination
					//page count not item count
					count={totalPages + 1}
					// mui pagination page start at 1
					page={page + 1}
					onChange={handlePageNumberButtonClick}
					hideNextButton
					hidePrevButton
				/>
			)}
			<IconButton
				className="actionButton"
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				className="actionButton"
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
};

type LibraryPaginationProps = {
	count?: number;
	page?: number;
	rowsPerPage?: number;
	paginationRef?: (_: HTMLElement | null) => void;
	onPageChange: (_event: React.MouseEvent<HTMLButtonElement> | null, _newPage: number) => void;
	onRowsPerPageChange: (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const LibraryPagination = ({
	count,
	page,
	rowsPerPage,
	paginationRef,
	onPageChange,
	onRowsPerPageChange,
}: LibraryPaginationProps) => {
	const { t } = useTranslation();
	return (
		<div className="paginationContentWrapper" id="paginationSection" ref={paginationRef}>
			<TablePagination
				component="div"
				count={count ?? 0}
				page={page ?? 1}
				rowsPerPage={rowsPerPage ?? 50}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				size="medium"
				labelRowsPerPage={
					window.innerWidth >= customScreenSizes.mobile
						? t('MEDIA_LIBRARY_PAGINATION_MEDIA_PER_PAGE_FULL')
						: t('MEDIA_LIBRARY_PAGINATION_MEDIA_PER_PAGE_SHORT')
				}
				rowsPerPageOptions={[50, 100, 200]}
				ActionsComponent={TablePaginationActions}
				labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count === 10000 ? '10K+' : count}`}
			/>
		</div>
	);
};

export default LibraryPagination;
