import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { showNotification } from '../../../actions/notificationActions';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { IconsList } from '../../../reusable/IconsStore/IconsList';

import './LiveBroadcasterSettings.scss';
import _ from 'underscore';
import { broadcasterSettingsId } from './../../../constants/liveManagerConstants';
import LiveManagerSettingsList from './../LiveManagerSettingsList';
import QRCode from 'qrcode.react';
import { postOTP, deleteOTP } from '../../../actions/otpActions';
import { randomNumberString } from './../../../services/stringHelperService';

class LiveBroadcasterSettings extends PureComponent {
	constructor(props) {
		super(props);
		const { t } = this.props;
		this.state = {
			deepLink: '',
			code1: '',
			code2: '',
			code3: '',
			code4: '',
			code5: '',
			code6: '',
		};
		this.settingsOptionList = [
			{
				id: broadcasterSettingsId.GENERATE_QR,
				title: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QR_CODE_LABEL'),
				iconName: IconsList.BROADCASTER_QR_CODE,
			},
			{
				id: broadcasterSettingsId.ABOUT,
				title: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_BROADCASTER_ABOUT_LABEL'),
				iconName: IconsList.BROADCASTER_ABOUT,
			},
		];
		//qbrickbroadcaster://streamApp.com/sso?accountId=AccIlcLiv6RZUxSIqrcdNqRzQ&mediaId=eb838488-8a76-49ec-ad51-e89fa4e16ed3&otp=jcf93jg784h
	}

	generateQRCode = () => {
		let str = randomNumberString();
		this.setState(
			{
				code1: str.charAt(0),
				code2: str.charAt(1),
				code3: str.charAt(2),
				code4: str.charAt(3),
				code5: str.charAt(4),
				code6: str.charAt(5),
			},
			() => {
				this.generateOTP();
			}
		);
	};

	generateOTP = () => {
		const { code1, code2, code3, code4, code5, code6 } = this.state;
		const { postOTP, showNotification, defaultAccountId, mediaId, t } = this.props;
		let completeCode = code1 + code2 + code3 + code4 + code5 + code6;
		if (!code1 || !code2 || !code3 || !code4 || !code5 || !code6 || completeCode.indexOf(' ') > 0) {
			showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CODE_NOT_VALID'), completeCode);
			return;
		}

		postOTP(defaultAccountId, mediaId, completeCode).then((res) => {
			if (res && res.url) {
				//this.resetCode()
				this.setDeepLink(res.url);
				showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CODE_GENERATED'));
			} else {
				showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CODE_GENERATE_FAILED'));
			}
		});
	};

	resetCode = () => {
		this.setState({ code1: '', code2: '', code3: '', code4: '', code5: '', code6: '' });
	};

	setDeepLink = (url) => {
		//let otp = url && url.split('/').pop()
		//let deepLink = `${otpDeepLinkEndpoint}?accountId=${defaultAccountId}&mediaId=${mediaId}&otp=${otp}`
		this.setState({ deepLink: url });
	};

	renderQRCode = () => {
		const { t } = this.props;
		const { deepLink, code1, code2, code3, code4, code5, code6 } = this.state;

		return (
			<>
				<div className="qrCodeWrapper">
					<div className="generateSection">
						<Button
							id="generateButton"
							className="generateButton centerButton fullWidthButton"
							onClick={this.generateQRCode}
						>
							{t('GENERATE')}
						</Button>
						{
							<QRCode
								value={deepLink}
								bgColor={'#FFFFFF'}
								includeMargin={true}
								size={200}
								style={{ opacity: deepLink ? '' : '0.1' }}
							></QRCode>
						}
						<label className="expiryLabel">{t('QR_CODE_EXPIRY_LABEL')}</label>
					</div>
					<label className="OTPLabel">{t('OTP_CODE_LABEL')}</label>
					<div className="codeButtonsSection">
						<>
							<TextField
								id="code1"
								value={code1}
								inputProps={{ maxLength: 1 }}
								className="codeField"
								disabled
							/>
							<TextField
								id="code2"
								value={code2}
								inputProps={{ maxLength: 1 }}
								className="codeField"
								disabled
							/>
							<TextField
								id="code3"
								value={code3}
								inputProps={{ maxLength: 1 }}
								className="codeField"
								disabled
							/>
							<TextField
								id="code4"
								value={code4}
								inputProps={{ maxLength: 1 }}
								className="codeField"
								disabled
							/>
							<TextField
								id="code5"
								value={code5}
								inputProps={{ maxLength: 1 }}
								className="codeField"
								disabled
							/>
							<TextField
								id="code6"
								value={code6}
								inputProps={{ maxLength: 1 }}
								className="codeField"
								disabled
							/>
						</>
					</div>
				</div>
			</>
		);
	};
	renderAboutSection = () => {
		return <>{}</>;
	};

	renderList = (item) => {
		switch (item.id) {
			case broadcasterSettingsId.GENERATE_QR:
				return this.renderQRCode();
			case broadcasterSettingsId.ABOUT:
				return this.renderAboutSection();
			default:
				return null;
		}
	};

	render() {
		return (
			<>
				<div className="LiveManagerStreamSettingsWrapper">
					{this.settingsOptionList.map((item) => (
						<>
							<LiveManagerSettingsList title={item.title} iconName={item.iconName}>
								<div className="generalSubField">{this.renderList(item)}</div>
							</LiveManagerSettingsList>
						</>
					))}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, notification }) => ({
	defaultAccountId: session.defaultAccountId,
	message: notification.message,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	postOTP: (accountId, mediaId, code) => dispatch(postOTP(accountId, mediaId, code)),
	deleteOTP: (otp) => dispatch(deleteOTP(otp)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveBroadcasterSettings);
