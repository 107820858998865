import React, { Component } from 'react';

import './TagsControl.scss';

const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;

class TagsControl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			showSuggestion: false,
			matchingSuggestions: [],
			tags: this.props.displayTags || [],
			suggestions: this.props.suggestions || [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.props.getTagsHandler?.(() => this.state.tags);
	}

	UNSAFE_componentWillMount() {
		if (this.props.onTagsLoaded) {
			this.props.onTagsLoaded(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.suggestions !== this.props.suggestions) {
			this.setState({ suggestions: nextProps.suggestions });
		}
		if (nextProps.displayTags !== this.props.displayTags) {
			this.state = {
				tags: nextProps.displayTags,
				value: '',
			};
		}
	}

	updatStateFromParent = (tags) => this.setState({ tags: tags });

	handleChange = (e) => {
		let value = e.target.value;
		this.setState({ value: value });
		if (!value) {
			this.hideSuggestion();
		} else {
			this.fillMatchingSuggestions(value);
			this.showSuggestion();
		}
	};

	handleKeyUp = (e) => {
		const key = e.keyCode;
		if (key === ENTER_KEY || key === COMMA_KEY) {
			this.addTag();
		}
	};

	handleKeyDown = (e) => {
		const key = e.keyCode;
		if (key === BACKSPACE_KEY) {
			//this.editPrevTag()
		} else {
			/*let dropdwn = document.getElementById('suggestionList');
            if (dropdwn) {
                var items = dropdwn.getElementsByTagName("li");
                items[0].classList.remove("highlight");
                for (var i = 0; i < items.length; ++i) {
                    var aKey = items[i].dataset.key;
                    if (String.fromCharCode(e.keyCode) === aKey) {
                        for (var j = 0; j < items.length; ++j) {
                            items[j].classList.remove("highlight");
                        }
                        items[i].classList.add("highlight");
                    }
                }
            }*/
			// to focus on key down
		}
	};

	addTag = (_) => {
		const { tags, value } = this.state;
		let tag = value.trim();
		tag = tag.replace(/,/g, '');
		if (!tag) {
			return;
		}
		this.setState({ tags: [...tags, tag], value: '' });
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange(tag);
		}
		this.hideSuggestion();
	};

	editPrevTag = (_) => {
		const { tags } = this.state;
		const tag = tags.pop();
		this.setState({ tags, value: tag });
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange(tag);
		}
	};

	removeSelectedTag = (tag) => {
		const { tags } = this.state;
		const index = tags.indexOf(tag);
		if (index > -1) {
			tags.splice(index, 1);
		}
		this.setState({ tags: tags });
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange(tag);
		}
	};

	showSuggestion = (_) => this.setState({ showSuggestion: true });

	hideSuggestion = (_) => this.setState({ showSuggestion: false });

	setTag = (name) => {
		this.setState({ value: name }, () => {
			this.addTag();
		});
		this.hideSuggestion();
	};

	fillMatchingSuggestions = (value) => {
		let matchingText = [];
		const { suggestions, showSuggestion } = this.state;
		if (suggestions && suggestions.length > 0 && showSuggestion) {
			suggestions.map((item) => {
				if (item.name && value && item.name.toLocaleLowerCase().match(value.toLocaleLowerCase())) {
					matchingText.push(item);
				}
			});
			this.setState({ matchingSuggestions: matchingText });
		}
	};

	renderSuggestionList = (_) => {
		const { matchingSuggestions, showSuggestion } = this.state;
		if (matchingSuggestions && matchingSuggestions.length > 0 && showSuggestion) {
			return (
				<ul className="suggestionList" id="suggestionList">
					{matchingSuggestions.map((item, index) => (
						<li key={index} onClick={() => this.setTag(item.name)}>
							{' '}
							{item.name + '(' + item.count + ')'}
						</li>
					))}
				</ul>
			);
		}
	};

	renderTagList = (_) => {
		const { tags } = this.state;
		return tags.map((tag, i) => (
			<li key={tag + i} className="tag">
				<svg viewBox="0 0 19.5 19.5" onClick={() => this.removeSelectedTag(tag)}>
					<defs>
						<style>{'.prefix__cls-1{fill:#707070}'}</style>
					</defs>
					<title>{'X'}</title>
					<g id="prefix__Layer_2" data-name="Layer 2">
						<g id="prefix__Layer_1-2" data-name="Layer 1">
							<path
								className="prefix__cls-1"
								d="M.45.45a1.56 1.56 0 012.19 0l16.41 16.41a1.55 1.55 0 01-2.19 2.19L.45 2.64a1.56 1.56 0 010-2.19z"
							/>
							<path
								className="prefix__cls-1"
								d="M19.05.45a1.56 1.56 0 010 2.19L2.64 19.05a1.55 1.55 0 01-2.19-2.19L16.86.45a1.56 1.56 0 012.19 0z"
							/>
						</g>
					</g>
				</svg>
				{tag}
			</li>
		));
	};

	render() {
		const { t } = this.props;
		const { value, tags } = this.state;
		return (
			<div className="form" id="TagsSection">
				{
					<input
						id="tagInput"
						ref="tag"
						type="text"
						value={value}
						placeholder={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_ADD_TAG')}
						onKeyUp={this.handleKeyUp}
						onChange={this.handleChange}
						onKeyDown={this.handleKeyDown}
					/>
				}

				<label className="tagInputInfo">{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PRESS_ENTER')}</label>
				{this.renderSuggestionList()}
				{tags && tags.length > 0 && <ul className="tagsListSection">{this.renderTagList()}</ul>}
			</div>
		);
	}
}

export default TagsControl;
