import React, { useEffect, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getThumbnailFromRenditionsBasedOnWidth } from '../../../../services/mediaDisplayService';
import { mediaTypes } from '../../../../constants/mediaConstants';
import { convertSecondsToTimeStamp, dateToTimeElapsed } from '../../../../services/timeStampService';
import { isMediaProcessing } from '../../../../utils/commonUtil';
import no_thumbnail from '../../../../assets/svg/NoThumbnail.svg';

import '../TabContent.scss';

interface MediaItemProps {
	id: string;
	translator: any;
	media: object;
	selected: boolean;
	currentMediaType: string;
	onMediaSelectionChange: (_id: object) => void;
}
const MediaItem = ({ id, translator, media, selected, currentMediaType, onMediaSelectionChange }: MediaItemProps) => {
	const accountRestrictions = useSelector((state) => (state as any).publish.accountRestrictions);
	const { title, created, duration, type, thumbnail, previewImages, artist, appliedRestrictions } = media as any;
	const { name: artistName, url: artistPage } = artist ?? {};

	let refinedThumbnail = thumbnail;
	if (refinedThumbnail instanceof Array) {
		refinedThumbnail = getThumbnailFromRenditionsBasedOnWidth(refinedThumbnail, 220);
	}

	const [currentThumbnail, setCurrentThumbnail] = useState(refinedThumbnail);
	const [isHover, setHoverStatus] = useState(false);

	let displayType = type;

	switch (type) {
		case mediaTypes.image:
			displayType = translator('COMMON_MEDIA_TYPE_IMAGE');
			break;
		case mediaTypes.liveaudio:
			displayType = translator('COMMON_MEDIA_LIVE_AUDIO');
			break;
		case mediaTypes.livevideo:
			displayType = translator('COMMON_MEDIA_LIVE_VIDEO');
			break;
		case mediaTypes.video:
			displayType = translator('COMMON_MEDIA_TYPE_VIDEO');
			break;
		default:
			displayType = translator('SCENARIO_MEDIA_LIBRARY_ITEM_TYPE');
	}
	const isDisabledMedia = type === '' || type !== currentMediaType;

	useEffect(() => {
		if (!previewImages || !isHover) {
			return;
		}
		let currentImgPos = 0;
		const previewCounter = setInterval(() => {
			if (currentImgPos < previewImages.length) {
				setCurrentThumbnail(previewImages[currentImgPos].picture);
				currentImgPos++;
			} else {
				currentImgPos = 0;
			}
		}, 100);

		return () => {
			clearInterval(previewCounter);
		};
	}, [isHover]);

	return (
		<Box
			key={id}
			className="media-item"
			onClick={() => {
				!isDisabledMedia && onMediaSelectionChange(media);
			}}
			onMouseEnter={() => {
				setHoverStatus(true);
			}}
			onMouseLeave={() => {
				setHoverStatus(false);
			}}
		>
			<Box className="item-image">
				<Box className={`overlay ${selected ? 'selected' : ''} ${isDisabledMedia ? 'disabled' : ''}`}></Box>
				{isMediaProcessing(accountRestrictions, appliedRestrictions) ? (
					<div className="processing">{translator('COMMON_PROCESSING_VIDEO')}</div>
				) : (
					<img
						src={currentThumbnail}
						loading="lazy"
						className={
							!thumbnail ||
							thumbnail === no_thumbnail ||
							thumbnail.toString().toLowerCase().includes('nothumbnail')
								? `no-thumbnail`
								: undefined
						}
					/>
				)}
				{duration && <Box className="item-duration">{convertSecondsToTimeStamp(duration)}</Box>}
				{artistName && artistPage && (
					<Box className="artist-information">
						<Link
							href={artistPage}
							target="_blank"
							color="inherit"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							{artistName.length >= 20 ? `${artistName.substring(0, 17)}...` : artistName}
						</Link>
					</Box>
				)}
			</Box>
			<Box className="item-info">
				<Box className="type-and-elapsed-time">
					<Box>{displayType}</Box>
					{created && <Box>{dateToTimeElapsed(created, translator)}</Box>}
				</Box>
				{title && (
					<Box className="item-title">{title.length >= 35 ? `${title.substring(0, 32)}...` : title}</Box>
				)}
			</Box>
		</Box>
	);
};

export default MediaItem;
