import { Divider, FormControlLabel } from '@material-ui/core';
import React from 'react';
import TimeInput from '../../../../../../../../../reusable/TimeInput/TimeInput';
import { useTranslation } from 'react-i18next';

interface DurationProps {
	disabled: boolean;
	widgetStartTime: string;
	widgetEndTime: string;
	handleTimeAttributeChange: (_value: string, _attr: string) => {};
	handleSubmitTimeAttribute: (_value: string, _attr: string) => {};
}

const Duration = ({
	disabled,
	widgetStartTime,
	widgetEndTime,
	handleTimeAttributeChange,
	handleSubmitTimeAttribute,
}: DurationProps) => {
	const { t } = useTranslation();
	return (
		<>
			<Divider className="divider-extra-space" />

			<FormControlLabel
				className={'scenario-chart__control'}
				control={
					<div className="scene-widgets__sort-input-control">
						<TimeInput
							max={24}
							value={widgetStartTime}
							onChange={(e: string) => handleTimeAttributeChange(e, 'start')}
							onSubmit={(e: string) => handleSubmitTimeAttribute(e, 'start')}
							disabled={disabled}
						/>
					</div>
				}
				label={t('COMMON_START')}
				labelPlacement="start"
			/>

			<FormControlLabel
				className={'scenario-chart__control'}
				control={
					<div className="scene-widgets__sort-input-control">
						<TimeInput
							max={24}
							value={widgetEndTime}
							onChange={(e: string) => handleTimeAttributeChange(e, 'end')}
							onSubmit={(e: string) => handleSubmitTimeAttribute(e, 'end')}
							disabled={disabled}
						/>
					</div>
				}
				label={t('COMMON_END')}
				labelPlacement="start"
			/>
		</>
	);
};

export default Duration;
