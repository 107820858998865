import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import AddBox from '@material-ui/icons/AddBox';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankOutlined from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CreateNewFolderOutlined from '@material-ui/icons/CreateNewFolderOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FilterListOutlined from '@material-ui/icons/FilterListOutlined';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PlaylistAddOutlined from '@material-ui/icons/PlaylistAddOutlined';
import SortOutlined from '@material-ui/icons/SortOutlined';
import ViewListOutlined from '@material-ui/icons/ViewListOutlined';
import ViewModuleOutlined from '@material-ui/icons/ViewModuleOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import {
	customScreenType,
	mediaActionKeys,
	mediaFiltersQueries,
	mediaTypes,
	mediaViews,
	sortingOptionsDropDownItems,
	sortingOptionsDropDownSearchItems,
} from '../../../constants/mediaConstants';
import clear_icon from '../../../assets/images/clear.svg';
import clear_hover_icon from '../../../assets/images/clear-hover.svg';
import search_filter from '../../../assets/images/search-filter.svg';
import search_filter_active from '../../../assets/images/search-filter-active.svg';
import { joinClassNames } from '../../../services/elementHelperService';
import { SearchContext } from '../../../context/SearchContext';

import { ViewType } from './utils';

import './ToolBar.scss';
import { useHistory } from 'react-router';
import { screenRoutes } from '../../../constants/routesPath';
import { Delete, DeleteForever, Restore } from '@material-ui/icons';
import ColorHub, { ColorTypes } from '../../../reusable/ColorHub/ColorHub';

export interface ToolBarProps {
	view?: ViewType;
	screenType: string;
	sortType?: string;
	isMobileView?: boolean;
	isSelectAll?: boolean;
	selectedMedias?: any[];
	mediaFilters?: any;
	mediaType?: string;
	advancedSearchAreaRef?: any;
	hideListingViewMenuItemsMenu?: (_: string) => void;
	onSelectAllMediaItemsPerPage?: () => void;
	showUploadDropDownMenu?: (_e: React.MouseEvent<HTMLElement>) => void;
	onToolbarActionClick?: (_action: string) => void;
	handleSortTypeChange?: (_sortType: any) => void;
	onMediaFilterChange?: (_type: any) => void;
	toolbarRef?: (_: HTMLElement | null) => void;
	onSubmitSearch?: (_keyWord: string) => void;
	onViewChange?: (_view: ViewType) => void;
}

export const ToolBar: React.FC<ToolBarProps> = ({
	view = 'grid',
	screenType,
	isMobileView,
	sortType,
	isSelectAll,
	advancedSearchAreaRef,
	selectedMedias,
	mediaFilters,
	onViewChange,
	onSelectAllMediaItemsPerPage,
	onToolbarActionClick,
	showUploadDropDownMenu,
	handleSortTypeChange,
	onMediaFilterChange,
	toolbarRef,
	onSubmitSearch,
}) => {
	const { t } = useTranslation();
	const { location } = useHistory();

	const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(
		advancedSearchAreaRef?.current?.isAdvancedSearchOpen()
	);
	const [sortingMenuAnchor, setSortingMenuAnchor] = useState<HTMLElement | null>(null);
	const [mediaFilterAnchor, setMediaFilterAnchor] = useState<HTMLElement | null>(null);
	const [mobileMediaActionAnchor, setMobileMediaActionAnchor] = useState<HTMLElement | null>(null);
	const isAtRecycleBin = location.pathname.match(screenRoutes.PUBLISH_RECYCLE_BIN);

	const { searchInputValue, setSearchInputValue } = useContext(SearchContext);

	const { disabledAddToCatalog, disabledAddToPlaylist, useDeleteBtn } = useMemo(() => {
		return {
			disabledAddToCatalog: selectedMedias?.some((item) => ['playlists', 'scenario'].includes(item.type)),
			disabledAddToPlaylist: selectedMedias?.some((item) =>
				['playlists', 'scenario', 'catalog'].includes(item.type)
			),
			useDeleteBtn: selectedMedias?.some((item) => ['playlists', 'scenario', 'catalog'].includes(item.type)),
		};
	}, [selectedMedias]);

	const handleSortingTypeChange = useCallback(
		(option: any) => {
			setSortingMenuAnchor(null);
			handleSortTypeChange?.(option);
		},
		[handleSortTypeChange]
	);

	const showSortingMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setSortingMenuAnchor(e.currentTarget);
	}, []);

	const showMediaFilterMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setMediaFilterAnchor(e.currentTarget);
	}, []);

	const onSearchInputValueChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setSearchInputValue(e.target.value);
		},
		[setSearchInputValue]
	);

	const onSearchInputKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key !== 'Enter') {
				return;
			}
			onSubmitSearch?.(searchInputValue);
			if (!searchInputValue) {
				setIsAdvancedSearchOpen(false);
			}
		},
		[onSubmitSearch, searchInputValue]
	);

	const toggleAdvancedSearch = useCallback(() => {
		advancedSearchAreaRef.current.toggleAdvancedSearch();
		setIsAdvancedSearchOpen((prev: boolean) => !prev);
	}, [advancedSearchAreaRef]);

	const onClearBtnClick = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			e.preventDefault();
			onSubmitSearch?.('');
			setIsAdvancedSearchOpen(false);
			setTimeout(() => {
				document.getElementById('searchInputBox')?.focus();
			});
		},
		[onSubmitSearch]
	);

	return (
		<>
			<div className="filter-section" ref={toolbarRef}>
				<div className="filter-options">
					<Paper variant="outlined" className="filter-options_item">
						{screenType !== customScreenType.large && screenType !== customScreenType.medium ? (
							<IconButton title={sortType} onClick={showSortingMenu}>
								<SortOutlined />
							</IconButton>
						) : (
							<Button startIcon={<SortOutlined />} onClick={showSortingMenu}>
								{t(
									sortingOptionsDropDownSearchItems.find((i) => i.key === sortType)
										?.translateKey as string
								) ?? sortType}
							</Button>
						)}
					</Paper>

					<Paper variant="outlined" className="filter-options_item">
						{screenType !== customScreenType.large && screenType !== customScreenType.medium ? (
							<IconButton onClick={onSelectAllMediaItemsPerPage}>
								{isSelectAll ? <CheckBox color="primary" /> : <CheckBoxOutlineBlankOutlined />}
							</IconButton>
						) : (
							<Button
								startIcon={
									isSelectAll ? <CheckBox color="primary" /> : <CheckBoxOutlineBlankOutlined />
								}
								onClick={onSelectAllMediaItemsPerPage}
							>
								{t('COMMON_SELECT')}
							</Button>
						)}
					</Paper>

					{!isMobileView && (
						<Paper variant="outlined" className="filter-options_item">
							<ButtonGroup size="small" variant="text">
								<Button
									title={`${t('COMMON_VIEW_AS')} ${t('MEDIA_LIBRARY_SEARCH_AREA_VIEW_AS_GRID')}`}
									onClick={() => onViewChange?.(mediaViews.grid as ViewType)}
									style={view === mediaViews.grid ? { background: '#E6E9EC' } : undefined}
								>
									<ViewModuleOutlined />
								</Button>
								<Button
									title={`${t('COMMON_VIEW_AS')} ${t('MEDIA_LIBRARY_SEARCH_AREA_VIEW_AS_LIST')}`}
									onClick={() => onViewChange?.(mediaViews.list as ViewType)}
									style={view === mediaViews.list ? { background: '#E6E9EC' } : undefined}
								>
									<ViewListOutlined />
								</Button>
							</ButtonGroup>
						</Paper>
					)}

					<Paper variant="outlined" className="filter-options_item">
						{isMobileView ? (
							<IconButton title={t('COMMON_FILTER')} onClick={showMediaFilterMenu}>
								<FilterListOutlined />
							</IconButton>
						) : (
							<Button startIcon={<FilterListOutlined />} onClick={showMediaFilterMenu}>
								{mediaFilters?.[mediaTypes.video] && mediaFilters?.[mediaTypes.livevideo]
									? t('COMMON_FILTER')
									: mediaFilters?.[mediaTypes.livevideo]
									? mediaFiltersQueries?.[mediaTypes.livevideo].displayName
									: mediaFilters?.[mediaTypes.video]
									? mediaFiltersQueries?.[mediaTypes.video].displayName
									: t('COMMON_FILTER')}
							</Button>
						)}
					</Paper>

					{!isMobileView && (
						<Paper className="filter-options_item" elevation={0}>
							<div id="searchInputContainer">
								<div className="searchInputWrapper">
									<div
										id="searchInput"
										className={joinClassNames([
											!searchInputValue ? 'collapsed' : 'expanded',
											searchInputValue ? 'hasvalue' : '',
											'searchInput',
										])}
									>
										<input
											id="searchInputBox"
											autoComplete="off"
											className={
												!searchInputValue ? 'collapsedSearchInput' : 'expandedSearchInput'
											}
											onKeyDown={onSearchInputKeyDown}
											onChange={onSearchInputValueChange}
											value={searchInputValue}
											title={t('MEDIA_LIBRARY_SEARCH_AREA_SEARCH_CURRENT_VIEW_TITLE')}
										/>
										<img
											className="clear_search_img"
											src={clear_icon}
											onMouseOver={(e) => (e.currentTarget.src = clear_hover_icon)}
											onMouseOut={(e) => (e.currentTarget.src = clear_icon)}
											onMouseDown={onClearBtnClick}
											title={t('MEDIA_LIBRARY_SEARCH_AREA_CLEAR_SEARCH_LABEL')}
											style={searchInputValue ? { display: 'block' } : undefined}
										/>
									</div>
								</div>
								<button
									className="search-filter-btn"
									onClick={toggleAdvancedSearch}
									title={t('MEDIA_LIBRARY_SEARCH_AREA_TOGGLE_ADVANCED_SEARCH_LABEL')}
								>
									<img src={isAdvancedSearchOpen ? search_filter_active : search_filter} />
								</button>
							</div>
						</Paper>
					)}

					{!isMobileView && selectedMedias && selectedMedias.length > 0 && (
						<Paper elevation={0} className="filter-options_item">
							{isAtRecycleBin ? (
								<>
									<ButtonGroup size="small" variant="outlined">
										<Button
											title={t('MEDIA_LIBRARY_SEARCH_AREA_DELETE_SELECTION_TITLE')}
											onClick={() => onToolbarActionClick?.(mediaActionKeys.deleteMedia)}
											startIcon={
												<ColorHub component={<Delete />} color={'error' as ColorTypes} />
											}
										>
											{t('COMMON_DELETE')}
										</Button>
										<Button
											onClick={() => onToolbarActionClick?.(mediaActionKeys.restore)}
											style={{ whiteSpace: 'nowrap' }}
											startIcon={
												<ColorHub component={<Restore />} color={'info' as ColorTypes} />
											}
										>
											{t('COMMON_RESTORE')}
										</Button>
									</ButtonGroup>
								</>
							) : (
								<>
									<ButtonGroup size="small" variant="outlined">
										<Button
											disabled={disabledAddToCatalog}
											title={
												disabledAddToCatalog
													? t('MEDIA_LIBRARY_SEARCH_AREA_DISABLE_ADD_TO_WARNING')
													: t('COMMON_ADD_TO_CATALOG')
											}
											onClick={() => onToolbarActionClick?.(mediaActionKeys.addToCatalog)}
										>
											<CreateNewFolderOutlined
												color={disabledAddToCatalog ? undefined : 'primary'}
											/>
										</Button>
										<Button
											disabled={disabledAddToPlaylist}
											title={
												disabledAddToPlaylist
													? t('MEDIA_LIBRARY_SEARCH_AREA_DISABLE_ADD_TO_WARNING')
													: t('COMMON_ADD_TO_PLAYLIST')
											}
											onClick={() => onToolbarActionClick?.(mediaActionKeys.addToPlaylist)}
										>
											<PlaylistAddOutlined
												color={disabledAddToPlaylist ? undefined : 'primary'}
											/>
										</Button>
										{useDeleteBtn && (
											<Button
												title={t('MEDIA_LIBRARY_SEARCH_AREA_DELETE_SELECTION_TITLE')}
												onClick={() => onToolbarActionClick?.(mediaActionKeys.deleteMedia)}
											>
												<DeleteForever color="error" />
											</Button>
										)}
										{!useDeleteBtn && (
											<Button
												title={t('MEDIA_LIBRARY_SEARCH_AREA_MOVE_TO_BIN')}
												onClick={() => onToolbarActionClick?.(mediaActionKeys.moveToBin)}
											>
												<DeleteOutline color="error" />
											</Button>
										)}
									</ButtonGroup>
								</>
							)}
						</Paper>
					)}

					{isMobileView && selectedMedias && selectedMedias.length > 0 && (
						<Paper elevation={0} className="filter-options_item">
							<IconButton
								title={t('MEDIA_LIBRARY_SEARCH_AREA_USE_ACTIONS')}
								onClick={(e) => setMobileMediaActionAnchor(e.currentTarget)}
							>
								<MoreHoriz />
							</IconButton>
						</Paper>
					)}
				</div>

				<div className="uploadsSection">
					{/* // TODO: To be implemented when emptyBin API end-point is done */}
					{/* {isAtRecycleBin ? (
						<Button
							variant="contained"
							color="primary"
							startIcon={<DeleteIcon />}
							style={{ whiteSpace: 'nowrap' }}
							onClick={() => onToolbarActionClick?.(mediaActionKeys.emptyBin)}
						>
							{t('LABEL_EMPTY_BIN')}
						</Button>
					) :  */}

					{!isAtRecycleBin && (
						<Button
							variant="contained"
							color="primary"
							onClick={showUploadDropDownMenu}
							startIcon={<AddBox />}
						>
							{t('COMMON_NEW')}
						</Button>
					)}
				</div>
			</div>

			<Menu
				getContentAnchorEl={null}
				anchorEl={sortingMenuAnchor}
				onClose={() => setSortingMenuAnchor(null)}
				open={Boolean(sortingMenuAnchor)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				{(searchInputValue ? sortingOptionsDropDownSearchItems : sortingOptionsDropDownItems).map(
					(eachOption: any, i: number) => (
						<MenuItem
							className="listMenuItem"
							selected={sortType === eachOption.value}
							key={i}
							value={eachOption.key}
							onMouseDown={(e) => {
								e.stopPropagation();
								handleSortingTypeChange(eachOption);
							}}
						>
							{t(eachOption.translateKey)}
						</MenuItem>
					)
				)}
			</Menu>
			<Menu
				getContentAnchorEl={null}
				anchorEl={mediaFilterAnchor}
				onClose={() => setMediaFilterAnchor(null)}
				open={Boolean(mediaFilterAnchor)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				{Object.entries(mediaFiltersQueries).map(([filter, { displayName }], i: number) => (
					<MenuItem key={i} onClick={() => onMediaFilterChange?.(filter)}>
						<ListItemIcon style={{ minWidth: '30px' }}>
							{mediaFilters?.[filter] ? <CheckBox color="primary" /> : <CheckBoxOutlineBlankOutlined />}
						</ListItemIcon>
						<ListItemText primary={displayName} />
					</MenuItem>
				))}
			</Menu>

			<Menu
				getContentAnchorEl={null}
				anchorEl={mobileMediaActionAnchor}
				open={Boolean(mobileMediaActionAnchor)}
				onClose={() => setMobileMediaActionAnchor(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				{!isAtRecycleBin && (
					<MenuItem
						key="4"
						className="listMenuItem"
						onClick={() => {
							setMobileMediaActionAnchor(null);
							onToolbarActionClick?.(mediaActionKeys.addToCatalog);
						}}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<CreateNewFolderOutlined color="primary" />
						</ListItemIcon>
						<ListItemText primary={t('COMMON_ADD_TO_CATALOG')} />
					</MenuItem>
				)}
				{!isAtRecycleBin && (
					<MenuItem
						key="5"
						className="listMenuItem"
						onClick={() => {
							setMobileMediaActionAnchor(null);
							onToolbarActionClick?.(mediaActionKeys.addToPlaylist);
						}}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<PlaylistAddOutlined color="primary" />
						</ListItemIcon>
						<ListItemText primary={t('COMMON_ADD_TO_PLAYLIST')} />
					</MenuItem>
				)}
				{isAtRecycleBin && (
					<MenuItem
						key="6"
						className="listMenuItem"
						onClick={() => {
							setMobileMediaActionAnchor(null);
							onToolbarActionClick?.(mediaActionKeys.restore);
						}}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<RotateLeftIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={t('COMMON_RESTORE')} />
					</MenuItem>
				)}

				{useDeleteBtn && (
					<MenuItem
						key="3"
						className="listMenuItem"
						onClick={() => {
							setMobileMediaActionAnchor(null);
							onToolbarActionClick?.(mediaActionKeys.deleteMedia);
						}}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<DeleteForever color="error" />
						</ListItemIcon>
						<ListItemText primary={t('COMMON_DELETE_MEDIAS')} />
					</MenuItem>
				)}

				{!useDeleteBtn && (
					<MenuItem
						key="3"
						className="listMenuItem"
						onClick={() => {
							setMobileMediaActionAnchor(null);
							onToolbarActionClick?.(mediaActionKeys.moveToBin);
						}}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<DeleteOutline color="error" />
						</ListItemIcon>
						<ListItemText primary={t('MEDIA_LIBRARY_SEARCH_AREA_MOVE_TO_BIN')} />
					</MenuItem>
				)}
			</Menu>
		</>
	);
};
