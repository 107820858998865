import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import { Box } from '@material-ui/core';
import { screenRoutes } from './../../../constants/routesPath';
import { secondsToTime } from './../../../utils/commonUtil';
import { useTranslation } from 'react-i18next';

const columns = [
	{ id: 'thumb', label: 'COMMON_THUMBNAIL', minWidth: 100 },
	{ id: 'title', label: 'COMMON_TITLE', minWidth: 150, sort: true },
	{
		id: 'totalView',
		label: 'ANALYTICS_TOTAL_VIEW',
		minWidth: 80,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
		sort: true,
	},
	{
		id: 'uniqueView',
		label: 'ANALYTICS_UNIQUE_VIEW',
		minWidth: 80,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
		sort: true,
	},
	{
		id: 'completes',
		label: 'ANALYTICS_COMPLETES',
		minWidth: 80,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
		sort: true,
	},
	{
		id: 'timeWatched',
		label: 'ANALYTICS_TIME_WATCHED',
		align: 'right',
		minWidth: 80,
		format: (value) => secondsToTime(value),
		sort: true,
	},
	{
		id: 'engagement',
		label: 'ANALYTICS_ENGAGEMENT',
		align: 'right',
		minWidth: 80,
		format: (value) => value + '%',
		sort: true,
	},
	{ id: 'popularity', label: 'Popularity', minWidth: 100, rating: true, sort: true },
	{
		id: 'bitrate',
		label: 'ANALYTICS_BITRATE_AVERAGE',
		minWidth: 80,
		align: 'right',
		format: (value) => (value / Math.pow(1024, 2)).toFixed(2) + ' Mbps',
		sort: true,
	},
];

const RankingVideoPerformance = ({ data }) => {
	let history = useHistory();
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [orderBy, setOrderBy] = React.useState();
	const [order, setOrder] = React.useState();
	const [filterText, setFilterText] = useState('');

	let updatedData =
		filterText != ''
			? data.filter(function (obj) {
					return (
						obj &&
						Object.keys(obj).some(function (key) {
							return (
								obj[key] &&
								obj[key].toString().toLowerCase().includes(filterText.toString().toLowerCase())
							);
						})
					);
			  })
			: data;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	const openMediaPage = (mediaId) => {
		if (mediaId) {
			const path = screenRoutes.ANALYTICS + `/${mediaId}`;
			history.push(path);
		}
	};

	return (
		<Paper>
			<Box p={2} display="flex" justifyContent="flex-start">
				<TextField
					id="topVideos"
					label={t('COMMON_SEARCH')}
					variant="outlined"
					type="text"
					value={filterText}
					onChange={(e) => setFilterText(e.target.value)}
				/>
			</Box>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sortDirection={orderBy === column.id ? order : false}
								>
									{column.sort ? (
										<TableSortLabel
											active={orderBy === column.id}
											direction={orderBy === column.id ? order : 'asc'}
											onClick={() => handleRequestSort(column.id)}
										>
											{t(column.label)}
											{orderBy === column.id ? (
												<span className="visuallyHidden">
													{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									) : (
										t(column.label)
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{updatedData.length > 0 &&
							stableSort(updatedData, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									return (
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={row.key}
											onClick={() => openMediaPage(row.key)}
										>
											{columns.map((column, i) => {
												const value = row[column.id];
												return (
													<TableCell key={`${row.key}-${i}`} align={column.align}>
														{column.format && typeof value === 'number' ? (
															column.format(value)
														) : column.rating ? (
															<Rating
																name="read-only"
																value={value}
																readOnly
																precision={0.5}
															/>
														) : column.id === 'thumb' ? (
															<img className="tableThumb" alt="thumb" src={value} />
														) : (
															value
														)}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={updatedData.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t('MEDIA_LIBRARY_PAGINATION_MEDIA_PER_PAGE_FULL')}
			/>
		</Paper>
	);
};

export default RankingVideoPerformance;
