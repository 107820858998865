import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { showNotification } from '../../actions/notificationActions';
import { messageTypes } from '../../constants/mediaConstants';
import { getAccountPresets, deleteElemenentFromAssetLibrary } from '../../actions/interactElementsActions';
import { dialogSizes } from './../../constants/dialogSizesConstant';
import add_preset from '../../assets/images/add_preset.svg';
import delete_icon from '../../assets/images/delete_icon.svg';
import { getItemStyle } from '../../services/interactScreenHelperService';
import { getFormattedDate } from '../../services/timeStampService';
import { showMessage } from '../../actions/globalActions';
import { interactAssetLibraryEndpointV1 } from '../../utils/config';

import './AssetLibrary.scss';

class AssetLibrary extends React.Component {
	state = {
		showLoadingMsg: false,
		assetList: [],
	};

	handleClose = () => {
		this.props.onClose();
	};

	componentDidMount() {
		this.getAccountAssets();
	}

	getAccountAssets = () => {
		const { defaultAccountId, t } = this.props;
		this.setState({
			showLoadingMsg: `${t('COMMON_LOADING')}...`,
		});
		// this.props.getAccountPresets(defaultAccountId).then(data => {
		//using fetch as requests are getting cancelled when there is a slow response from server
		fetch(`${interactAssetLibraryEndpointV1}interact/accounts/${defaultAccountId}/presets`)
			.then((res) => {
				if (!res.ok) {
					this.setState({
						showLoadingMsg: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_ELEMENTS_FOUND'),
						assetList: [],
					});
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data && data.map) {
					this.setState({
						showLoadingMsg: '',
						assetList: data,
					});
				} else {
					this.setState({
						showLoadingMsg: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_ELEMENTS_FOUND'),
						assetList: [],
					});
				}
			});
	};

	onAdd = (item) => {
		this.props.addPresets(item);
		this.handleClose();
	};

	onRemove = (item) => {
		const { defaultAccountId, t } = this.props;
		this.props.showMessage(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_DELETING_ASSET'), messageTypes.info);
		this.props.deleteElemenentFromAssetLibrary(defaultAccountId, item.id).then((res) => {
			if (res === true) {
				this.props.showMessage(t('COMMON_DELETED_SUCCESSFULLY'), messageTypes.info);
			} else {
				this.props.showMessage(t('COMMON_DELETION_FAILED'), messageTypes.error);
			}
			setTimeout(() => {
				this.getAccountAssets();
			}, 2000);
		});
	};

	/*getPresetStyle = (item) => {
        let element = _.find(item.settings, (eachItem) => { return (eachItem.color !== null && eachItem.color !== undefined && eachItem.color !== '') })
        if (element && element.color) {
            return { "background-color": element.color }
        }
        element = _.find(item.settings, (eachItem) => { return (eachItem.imageUrl !== null && eachItem.imageUrl !== undefined && eachItem.imageUrl !== '') })
        if (element && element.imageUrl) {
            return { "background-image": "url(" + element.imageUrl + ")" }
        }
        return { "background-color": 'transparent' }
    }*/

	renderAssetData = () => {
		const { showLoadingMsg } = this.state;
		const { t } = this.props;
		let { assetList } = this.state;
		assetList = assetList.sort(function (a, b) {
			return new Date(b.created) - new Date(a.created);
		});
		return (
			<>
				{showLoadingMsg && <label className="loadingMessage"> {showLoadingMsg}</label>}
				{assetList.map((item) => (
					<>
						<div className="assetItemContainer">
							<div className="assetItem">
								<div className="presetElements" style={getItemStyle(item)}>
									{/*<label> {item.name} </label>*/}
								</div>
								<div className="add" onClick={() => this.onAdd(item)}>
									<img src={add_preset} />
									{t('COMMON_ADD')}
								</div>
								<div className="remove" onClick={() => this.onRemove(item)}>
									<img src={delete_icon} />
								</div>
							</div>
							{item.created ? (
								<> {`${item.name} (created ${getFormattedDate(item.created)})`} </>
							) : (
								<> {item.name} </>
							)}
						</div>
					</>
				))}
			</>
		);
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<Dialog
					id="AssetLibrary"
					onClose={this.handleClose}
					aria-labelledby="simple-dialog-title"
					fullWidth={true}
					maxWidth={dialogSizes.SMALL}
					open={true}
				>
					<DialogTitle className="dialogTitle dialogTitle--darkMode" disableTypography>
						<Typography variant="h6">
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ASSET_LIBRARY')}
						</Typography>
						<IconButton onClick={this.handleClose}>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent className="dialogContent">
						<DialogContentText>
							<div className="assetWrapper">{this.renderAssetData()}</div>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = ({ session, liveManager, notification, inspectScreenReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	message: notification.message,
	liveEventStatus: liveManager.liveEventStatus,
	mediaOriginalDetails: inspectScreenReducer.mediaOriginalDetails,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	getAccountPresets: (accId) => dispatch(getAccountPresets(accId)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	deleteElemenentFromAssetLibrary: (accId, assetId) => dispatch(deleteElemenentFromAssetLibrary(accId, assetId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AssetLibrary);
