import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import MediaLibrary from './tabsContent/MediaLibrary';
import StockLibrary from './tabsContent/StockLibrary';
import UploadFile from './tabsContent/UploadFile';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import LayersIcon from '@material-ui/icons/Layers';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

import { mediaTypes } from '../../constants/mediaConstants';
import useUploadingVideoByUrl from '../../hooks/screens/library/useUploadingVideoByUrl';
import { sendDownloadRequestToUnsplash } from '../../services/thirdPartyService';

import useFetchData from './hooks/useFetchData';

import './InteractMediaLibraryModal.scss';

const UPLOAD_NEW_MEDIA = 'UPLOAD_NEW_MEDIA';
const MEDIA_LIBRARY = 'MEDIA_LIBRARY';
const STOCK_LIBRARY = 'STOCK_LIBRARY';

export const TAB_ID = {
	UPLOAD_NEW_MEDIA,
	MEDIA_LIBRARY,
	STOCK_LIBRARY,
};

interface InteractMediaLibraryModalProps {
	mediaType: string;
	handleReturnedMedia: (_media: object) => {};
}

const InteractMediaLibraryModal = forwardRef((props: InteractMediaLibraryModalProps, ref): any => {
	const { t: translator } = useTranslation();
	const { mediaType, handleReturnedMedia } = props;

	const [isOpen, setVisibleStatus] = useState(false);
	const [currentTab, setCurrentTab] = useState(TAB_ID.MEDIA_LIBRARY);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [isAllowToSubmit, setAllowToSubmit] = useState(false);

	const { uploadVideoByUrl } = useUploadingVideoByUrl();

	const {
		encodingProfiles,
		qbrickEncoderFlows,
		medias,
		totalCount,
		stockMedias,
		page,
		rowsPerPage,
		selectedCatalog,
		selectedStockProvider,
		isUploading,
		isFetchingData,
		setPaginationInfo,
		setSelectedStockProvider,
		setSelectedCatalog,
		setIsUploading,
	} = useFetchData(mediaType, searchKeyword, currentTab, isOpen);

	const uploadFileRef = useRef<any>();
	const mediaLibraryRef = useRef<any>();
	const stockLibraryRef = useRef<any>();

	useImperativeHandle(ref, () => {
		return {
			showMediaLibrary: (isOpen: boolean, selectTab?: string) => {
				setVisibleStatus(isOpen);
				selectTab && setCurrentTab(selectTab);
			},
		};
	});

	useEffect(() => {
		setAllowToSubmit(false);
	}, [currentTab, searchKeyword]);

	const closeMediaLibrary = useCallback(() => {
		setVisibleStatus(false);
		setCurrentTab(TAB_ID.MEDIA_LIBRARY);
	}, []);

	const selectATab = useCallback((_: {}, newTab: string) => {
		setCurrentTab(newTab);
	}, []);

	const handleActionButtonClick = () => {
		switch (currentTab) {
			case UPLOAD_NEW_MEDIA: {
				uploadFileRef.current.startUploadProcess().then((uploadedFiles: any) => {
					uploadedFiles && handleReturnedMedia(uploadedFiles);
				});
				break;
			}

			case MEDIA_LIBRARY: {
				handleReturnedMedia(mediaLibraryRef.current.submitSelectedMedia());
				break;
			}

			case STOCK_LIBRARY: {
				const [selectedStockMedia] = stockLibraryRef.current.submitSelectedMedia();
				const { type, download, duration, fileType, trackingUrl } = selectedStockMedia;
				const mediaTitle = `Stock media - ${new Date().toDateString()}`;
				const mediaUrl = download;
				if (type === mediaTypes.video) {
					uploadVideoByUrl({
						mediaTitle,
						mediaDescription: '',
						mediaUrl,
					}).then((media: any) => {
						handleReturnedMedia([{ ...media, duration, type: 'uploadNew' }]);
					});
					break;
				}

				if (type === mediaTypes.image) {
					handleReturnedMedia([
						{
							download,
							type: `image/${fileType}`,
						},
					]);
					trackingUrl && sendDownloadRequestToUnsplash(trackingUrl);
				}
				break;
			}
		}
		setIsUploading(false);
		setVisibleStatus(false);
	};

	return (
		<Dialog className="media-library" open={isOpen} fullWidth={true} maxWidth={'md'}>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{translator('COMMON_CHOOSE_MEDIA')}</Typography>
				<IconButton onClick={closeMediaLibrary}>
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Box className="dialog-content">
					<TabContext value={currentTab}>
						<Box className="tabs-header">
							<TabList onChange={selectATab} indicatorColor="primary" textColor="primary">
								<Tab
									style={mediaType !== mediaTypes.image ? { display: 'none' } : undefined}
									hidden={mediaType !== mediaTypes.image}
									label={
										<Box className="tab-title">
											<Box className="icon">
												<AddIcon />
											</Box>
											<Typography variant="body1" color="textPrimary">
												{translator('COMMON_UPLOAD_NEW')}
											</Typography>
										</Box>
									}
									value={TAB_ID.UPLOAD_NEW_MEDIA}
								/>

								<Tab
									label={
										<Box className="tab-title">
											<Box className="icon">
												<VideoLibraryIcon />
											</Box>
											<Typography variant="body1" color="textPrimary">
												{translator('HEADER_MENU_LABEL_MEDIA_LIBRARY')}
											</Typography>
											<Box paddingLeft="8px" className="progress-icon">
												{isFetchingData && currentTab === TAB_ID.MEDIA_LIBRARY ? (
													<CircularProgress
														variant="indeterminate"
														color="inherit"
														size={12}
													/>
												) : (
													<Box width={12} height={12}></Box>
												)}
											</Box>
										</Box>
									}
									value={TAB_ID.MEDIA_LIBRARY}
								/>
								<Tab
									label={
										<Box className="tab-title">
											<Box className="icon">
												<LayersIcon />
											</Box>
											<Typography variant="body1" color="textPrimary">
												{translator('SCENARIO_MEDIA_LIBRARY_TAB_TITLE_STOCK_LIBRARY')}
											</Typography>
											<Box paddingLeft="8px" className="progress-icon">
												{isFetchingData && currentTab === TAB_ID.STOCK_LIBRARY ? (
													<CircularProgress
														variant="indeterminate"
														color="inherit"
														size={12}
													/>
												) : (
													<Box width={12} height={12}></Box>
												)}
											</Box>
										</Box>
									}
									value={TAB_ID.STOCK_LIBRARY}
								/>
							</TabList>
							<Box>
								<Input
									id="search-box"
									placeholder="Search"
									disabled={currentTab === TAB_ID.UPLOAD_NEW_MEDIA}
									value={searchKeyword}
									onChange={(event) => {
										setSearchKeyword(event.currentTarget.value);
									}}
									startAdornment={
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="end">
											{searchKeyword !== '' && (
												<ClearIcon
													className="clear-search-icon"
													fontSize="small"
													color="disabled"
													onClick={() => {
														setSearchKeyword('');
													}}
												/>
											)}
										</InputAdornment>
									}
								/>
							</Box>
						</Box>
						<Box className="tabs-content">
							<Box className="loading-overlay"></Box>
							<TabPanel value={TAB_ID.UPLOAD_NEW_MEDIA}>
								<UploadFile
									ref={uploadFileRef}
									encodingProfiles={encodingProfiles}
									qbrickEncoderFlows={qbrickEncoderFlows}
									mediaType={mediaType}
									isUploading={isUploading}
									selectedCatalog={selectedCatalog}
									setSelectedCatalog={setSelectedCatalog}
									setAllowToSubmit={setAllowToSubmit}
									setCurrentTab={setCurrentTab}
									setIsUploading={setIsUploading}
								/>
							</TabPanel>
							<TabPanel value={TAB_ID.MEDIA_LIBRARY}>
								<MediaLibrary
									mediaType={mediaType}
									ref={mediaLibraryRef}
									medias={medias}
									totalCount={totalCount}
									page={page}
									rowsPerPage={rowsPerPage}
									selectedCatalog={selectedCatalog}
									onPageChange={(_, number) => {
										setPaginationInfo({
											page: number,
											rowsPerPage,
										});
									}}
									onRowPerPageChange={(event) => {
										setPaginationInfo({ page, rowsPerPage: parseInt(event.target.value, 10) });
									}}
									setSelectedCatalog={setSelectedCatalog}
									setAllowToSubmit={setAllowToSubmit}
								/>
							</TabPanel>
							<TabPanel value={TAB_ID.STOCK_LIBRARY}>
								<StockLibrary
									provider={selectedStockProvider}
									ref={stockLibraryRef}
									mediaType={mediaType}
									medias={stockMedias}
									totalCount={totalCount}
									page={page}
									rowsPerPage={rowsPerPage}
									onPageChange={(_, number) => {
										setPaginationInfo({
											page: number,
											rowsPerPage,
										});
									}}
									onRowPerPageChange={(event) => {
										setPaginationInfo({ page, rowsPerPage: parseInt(event.target.value, 10) });
									}}
									onProviderChange={(provider: string) => {
										setSelectedStockProvider(provider);
									}}
									setAllowToSubmit={setAllowToSubmit}
								/>
							</TabPanel>
						</Box>
					</TabContext>
				</Box>
			</DialogContent>
			<DialogActions className={`dialog-actions`}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleActionButtonClick}
					disabled={!isAllowToSubmit}
				>
					{isUploading ? (
						<span>
							{<CircularProgress variant="indeterminate" color="inherit" size="1rem" />}{' '}
							{translator('COMMON_UPLOADING')}...
						</span>
					) : (
						translator('COMMON_CHOOSE_MEDIA')
					)}
				</Button>
				<div className={`dialog-notion`}>
					{currentTab === TAB_ID.STOCK_LIBRARY && (
						<Typography variant="body1" className="notion-provider">
							{`${mediaType.charAt(0).toUpperCase()}${mediaType.slice(1)}s ${translator(
								'SCENARIO_MEDIA_LIBRARY_ACTIONS_NOTION_PROVIDED_BY'
							)} ${selectedStockProvider}`}
						</Typography>
					)}
				</div>
			</DialogActions>
		</Dialog>
	);
});

export default InteractMediaLibraryModal;
