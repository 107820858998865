export const screenRoutes = {
	LOGIN: '/login',
	SETTINGS: '/settings',
	DASHBOARD: '/dashboard',
	ANALYTICS: '/analytics/videoperformance',
	CREATE: '/create',
	LIVE: '/live',
	PUBLISH_MEDIA_LIBRARY: '/medialibrary',
	PUBLISH: '/medialibrary', //same as redirecting to media library
	PUBLISH_PLAYLISTS: '/medialibrary/playlists',
	PUBLISH_SCENARIOS: '/medialibrary/interact',
	PUBLISH_CATALOGS: '/medialibrary/catalogs',
	PUBLISH_ALL_MEDIA: '/medialibrary/allmedia',
	PUBLISH_LIVE_MEDIA: '/medialibrary/livemedia',
	PUBLISH_SMART_FILTER: '/medialibrary/smartfilters',
	PUBLISH_UNSORTED_MEDIA: '/medialibrary/unsortmedia',
	PUBLISH_EDITED_MEDIA: '/medialibrary/editedmedia',
	PUBLISH_RECYCLE_BIN: '/medialibrary/recyclebin',
	APPS: '/apps',
	APPS_EDITOR: '/apps/editor',
	SCENE: '/scene',
	ANALYTICS_VIDEO_PERFORMANCE: '/analytics/videoperformance',
	ANALYTICS_SOCIAL: '/analytics/social',
	ANALYTICS_REAL_TIME: '/analytics/cdn/realtime',
	ANALYTICS_GENERAL_PERFORMANCE: '/analytics/cdn/general-performance',
	ANALYTICS_URL_PERFORMANCE: '/analytics/cdn/url-performance',
	ANALYTICS_STORAGE: '/analytics/cdn/storage',
	ANALYTICS_OPERATING_SYSTEMS: '/analytics/cdn/operatingsystems',
	ANALYTICS_DEVICE_TYPE: '/analytics/cdn/devicetypes',
	ANALYTICS_BROWSERS: '/analytics/cdn/browsers',
	ANALYTICS_BROWSERS_TYPE: '/analytics/cdn/browser-type',
	ANALYTICS_PROTOCOL: '/analytics/cdn/protocol',
	ANALYTICS_COUNTRIES: '/analytics/cdn/countries',
	ANALYTICS_CITIES: '/analytics/cdn/cities',
	ANALYTICS_REGIONS: '/analytics/cdn/regions',
};

export const routePathSplitter = '#';
