import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';

import { generateId } from '../../services/stringHelperService';
import { getLastIndexCatalogId } from '../../services/locationPathServices';
import { createNewCatalog, refreshCatalogs } from '../../actions/publishActions';
import { showMessage } from '../../actions/globalActions';
import { messageTypes } from '../../constants/mediaConstants';
import CatalogLimitedAccess from './CatalogLimitedAccess';

interface CreateNewCatalogDialogProps {
	open?: boolean;
	onClose?: () => void;
	onCreateNewCatalog: (_catalogId: string) => void;
	parentCatalogId?: string;
}

const CreateNewCatalogDialog: React.FC<CreateNewCatalogDialogProps> = ({
	open,
	onClose,
	onCreateNewCatalog,
	parentCatalogId,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const [catalogName, setCatalogName] = useState('');
	const limitedAccessRef = useRef<any>(null);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);

	const handleCatalogNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCatalogName(e.target.value);
	};

	const handleCreateCatalogSuccess = (catalogId: string) => {
		setTimeout(() => {
			// delay 1s for backend have time to update
			dispatch(showMessage(t('MODAL_CREATE_NEW_CATALOG_NEW_CATALOG_CREATED'), messageTypes.success));
			onCreateNewCatalog?.(catalogId);
			limitedAccessRef.current?.resetAndCloseModal();
			setIsSubmit(false);
			setCatalogName('');
			dispatch(refreshCatalogs());
		}, 1000);
	};

	const handleCreateNewCatalog = () => {
		setIsSubmit(true);
		let parentId = parentCatalogId === 'allCatalog' ? '' : getLastIndexCatalogId() || '';
		let assetId = generateId();
		let catalogId = generateId();
		const restrictionUsersAfterUpdate = limitedAccessRef.current?.getRestrictionUsers();

		if (catalogName) {
			dispatch(
				createNewCatalog(
					defaultAccountId,
					assetId,
					catalogId,
					catalogName,
					parentId,
					restrictionUsersAfterUpdate
				)
			).then((data: any) => {
				if (data && data.length) {
					handleCreateCatalogSuccess(catalogId);
					return;
				} else {
					limitedAccessRef.current?.resetAndCloseModal();
					dispatch(showMessage(t('MODAL_CREATE_NEW_CATALOG_FAILED_NOTIFICATION'), messageTypes.error));
					setIsSubmit(false);
					setCatalogName('');
				}
			});
		}
	};

	return (
		<Dialog
			onClose={() => {
				limitedAccessRef.current?.resetAndCloseModal();
			}}
			aria-labelledby="simple-dialog-title"
			open={!!open}
			fullWidth
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('MODAL_CREATE_NEW_CATALOG_TITLE')}</Typography>
				<IconButton
					onClick={() => {
						limitedAccessRef.current?.resetAndCloseModal();
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<div className="dialogContentWrapper">
				<label> {t('MODAL_CREATE_NEW_CATALOG_TITLE_OF_CATALOG_LABEL')}</label>
				<div className="fullWidthControl">
					<input
						value={catalogName}
						type="text"
						placeholder={t('MODAL_CREATE_NEW_CATALOG_TITLE_OF_CATALOG_PLACEHOLDER')}
						onChange={handleCatalogNameChange}
					/>
				</div>
			</div>
			<CatalogLimitedAccess open={open} ref={limitedAccessRef} onClose={onClose} />

			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
						<Button
							variant="contained"
							className="defaultActionBtn"
							onClick={handleCreateNewCatalog}
							disabled={!catalogName || isSubmit}
						>
							{isSubmit ? (
								<>
									<span>{t('MODAL_CREATE_NEW_CATALOG_BUTTON_LABEL')}</span>{' '}
									<CircularProgress size={10} color="inherit" />{' '}
								</>
							) : (
								<span>{t('MODAL_CREATE_NEW_CATALOG_BUTTON_LABEL')}</span>
							)}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default CreateNewCatalogDialog;
