import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lock, MoreHoriz } from '@material-ui/icons';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';

import { ReactComponent as MobileMenuIcon } from '../../assets/images/main_menu_icon_mobile.svg';
import { setPermissionInformationDialog } from '../../actions/publishActions';
import { joinClassNames } from '../../services/elementHelperService';
import { customScreenType } from '../../constants/mediaConstants';
import { DashboardUtils } from '../../screens/DashboardScreen/DashboardUtils';

import { KEYS } from './constants';
import { useDispatch } from 'react-redux';

interface HeaderMainMenuProps {
	items: any[];
	// eslint-disable-next-line no-unused-vars
	onItemClick: (item: any) => void;
	selectedItemKey?: string;
	screenType?: string;
}

const HeaderMainMenu: React.FC<HeaderMainMenuProps> = ({ items, onItemClick, selectedItemKey, screenType }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [menuAnchor, setMenuAnchor] = useState<any | null>(null);

	const { showMenuItems, condensedMenuItem } = useMemo(() => {
		let condensedMenuItem = [];
		let showMenuItems = items;
		if (screenType === customScreenType.tablet) {
			showMenuItems = [...items].filter((_, i) => i <= 2);
			condensedMenuItem = [...items].filter((_, i) => i > 2);
		}

		return {
			showMenuItems,
			condensedMenuItem,
		};
	}, [items, screenType]);

	const onOpenMenu = useCallback(
		(event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
			if (menuAnchor) {
				setMenuAnchor(null);
				return;
			}

			setMenuAnchor(event.target);
		},
		[menuAnchor]
	);

	const onMenuItemClick = useCallback(
		(item: any) => {
			if (item.hasPermission === false) {
				const feature = item.key === KEYS.edit ? DashboardUtils.RecentMediaTypes.edits : item.key;
				dispatch(setPermissionInformationDialog(feature));
				return;
			}
			setMenuAnchor(null);
			onItemClick(item);
		},
		[dispatch, onItemClick]
	);

	if (screenType === customScreenType.small || screenType === customScreenType.mobile) {
		return (
			<div className="navigation">
				<IconButton onClick={onOpenMenu}>
					<MobileMenuIcon />
				</IconButton>
				<Menu
					className="main-menu-mobile"
					anchorEl={menuAnchor}
					open={Boolean(menuAnchor)}
					onClose={() => setMenuAnchor(null)}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				>
					<div key={'title'} className={'main-menu-mobile__title'}>
						{t('COMMON_MENU')}
					</div>
					{items.map((item) => (
						<MenuItem
							key={item.key}
							onClick={() => onMenuItemClick(item)}
							className={joinClassNames(
								'main-menu-mobile__item',
								selectedItemKey === item.key ? 'main-menu-mobile__item--selected' : '',
								item.hasPermission === false ? 'main-menu-mobile__item--locked' : ''
							)}
						>
							{item.hasPermission === false && (
								<div className={`main-menu-mobile__item-locked-layer`}>
									<Lock />
								</div>
							)}
							<ListItemIcon style={item.key === KEYS.interact ? { opacity: '0.54' } : undefined}>
								{item.icon}
							</ListItemIcon>
							<ListItemText primary={t(item.text) ? t(item.text) : item.text} />
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}

	return (
		<div className="navigation">
			{showMenuItems.map((item, index) => {
				return (
					<>
						<div
							className={joinClassNames(
								'navigation-items',
								selectedItemKey === item.key ? 'navigation-items--selected' : '',
								item.hasPermission === false ? 'navigation-items--locked' : ''
							)}
							key={item.key}
							onClick={() => onMenuItemClick(item)}
						>
							{item.hasPermission === false && (
								<div className={`navigation-items__locked-layer`}>
									<Lock />
								</div>
							)}
							{item.icon}
							<div className="navigation-items__label">{t(item.text) ? t(item.text) : item.text}</div>
						</div>
						{item.key === 'analytics' && index < showMenuItems.length - 1 && (
							<Divider orientation="vertical" style={{ height: '60%' }} />
						)}
					</>
				);
			})}
			{condensedMenuItem.length > 0 && (
				<>
					<div className={joinClassNames('navigation-items')} key={'more'} onClick={onOpenMenu}>
						<MoreHoriz />
					</div>
					<Menu
						className="main-menu-mobile"
						anchorEl={menuAnchor}
						open={Boolean(menuAnchor)}
						onClose={() => setMenuAnchor(null)}
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					>
						{condensedMenuItem.map((item) => (
							<MenuItem
								key={item.key}
								onClick={() => onMenuItemClick(item)}
								className={joinClassNames(
									'main-menu-mobile__item',
									selectedItemKey === item.key ? 'main-menu-mobile__item--selected' : '',
									item.hasPermission === false ? 'main-menu-mobile__item--locked' : ''
								)}
							>
								{item.hasPermission === false && (
									<div className={`main-menu-mobile__item-locked-layer`}>
										<Lock />
									</div>
								)}
								<ListItemIcon style={item.key === KEYS.interact ? { opacity: '0.54' } : undefined}>
									{item.icon}
								</ListItemIcon>
								<ListItemText primary={t(item.text) ? t(item.text) : item.text} />
							</MenuItem>
						))}
					</Menu>
				</>
			)}
		</div>
	);
};

export default HeaderMainMenu;
