import React from 'react';
import { Button } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface GoBackHeaderProps {
	onGoBackClick: () => void;
}
const GoBackHeader: React.FC<GoBackHeaderProps> = ({ onGoBackClick }) => {
	const { t } = useTranslation();
	return (
		<header className="header-container header-container__go-back">
			<Button
				color="primary"
				startIcon={<ChevronLeft />}
				onClick={onGoBackClick}
				className="header-container__back-btn"
			>
				{t('COMMON_BACK')}
			</Button>
		</header>
	);
};

export default GoBackHeader;
