import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEncoderUploading, uploadMediaToEncoder } from '../../../actions/publishActions';
import { FLOWS, messageTypes } from '../../../constants/mediaConstants';
import { showAlert, showMessage } from '../../../actions/globalActions';
import { useTranslation } from 'react-i18next';
import { AdobeExpressUtils } from '../../../screens/PublishScreen/AdobeExpress/utils';
import { generateUUID } from '../../../utils/commonUtil';

interface UploadingMediaByUrlProps {
	mediaTitle: string;
	mediaDescription: string;
	mediaUrl: string;
	projectId?: string;
}

const useUploadingVideoByUrl = () => {
	const dispatch = useDispatch() as any;
	const { t: translator } = useTranslation();
	const accountId = useSelector((state) => (state as any).session.defaultAccountId);

	const uploadVideoByUrl = useCallback(
		({ mediaTitle, mediaDescription, mediaUrl, projectId }: UploadingMediaByUrlProps) => {
			return new Promise((resolve) => {
				const flowId = FLOWS.qbrickStandard;
				const callbackUrl = '';
				const mediaId = projectId ? AdobeExpressUtils.getMediaIdFromProjectId(projectId) : generateUUID();
				dispatch(
					loadEncoderUploading({
						accountId,
						flowId,
						callbackUrl,
						mediaId,
						mediaTitle,
						mediaDescription,
					})
				).then((data: any) => {
					if (!data || typeof data === 'string' || !data.id) {
						return;
					}

					// Send to encoder
					dispatch(uploadMediaToEncoder({ accountId, mediaUrl, uploadId: data.id })).then((data: any) => {
						if (data) {
							dispatch(
								showMessage(
									`${translator('MEDIA_LIBRARY_NOTIFICATION_REFRESH_TO_REVIEW_CONTENT')}`,
									messageTypes.info
								)
							);
						} else {
							dispatch(showAlert(`${translator('COMMON_ERROR')}`, messageTypes.error));
						}
					});
					resolve({ id: mediaId });
				});
			});
		},
		[dispatch, accountId, translator]
	);

	return {
		uploadVideoByUrl,
	};
};

export default useUploadingVideoByUrl;
