import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { Popover } from '@material-ui/core';

import { hexToRGBA, rGBAToHex } from './helper';

class ColorPicker extends React.Component {
	state = {
		displayColorPicker: false,
		color: hexToRGBA(this.props.color),
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false });
	};

	handleChange = (color) => {
		this.setState({ color: color.rgb });
	};

	handleChangeComplete = (color) => {
		this.setState({ color: color.rgb });
		if (this.props.onChangeComplete) {
			const { r, g, b, a } = color.rgb;
			this.props.onChangeComplete({ hex: `#${rGBAToHex(r, g, b, a)}` });
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.color !== this.props.color) {
			this.setState({
				color: this.props.color,
			});
		}
	}

	render() {
		const styles = reactCSS({
			default: {
				color: {
					width: this.props.width ? this.props.width : '36px',
					height: this.props.height ? this.props.height : '14px',
					borderRadius: this.props.borderRadius ? this.props.borderRadius : '2px',
					background: this.props.color,
				},
				swatch: {
					padding: this.props.padding ? this.props.padding : '5px',
					background: '#fff',
					borderRadius: this.props.borderRadius ? this.props.borderRadius : '1px',
					boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
					display: 'inline-block',
					cursor: 'pointer',
				},
			},
		});

		return (
			<div className="colorPickerSelection">
				<div style={styles.swatch} onClick={this.handleClick}>
					<div style={styles.color} className="colorPickerContainer" ref={(ref) => (this.pickerRef = ref)} />
				</div>
				<Popover
					style={{
						marginTop: '5px',
						marginLeft: '-3px',
					}}
					anchorEl={this.pickerRef}
					open={this.state.displayColorPicker}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<SketchPicker
						color={this.state.color}
						disableAlpha={this.props.disableAlpha}
						onChange={this.handleChange}
						onChangeComplete={this.handleChangeComplete}
					/>
				</Popover>
			</div>
		);
	}
}

export default ColorPicker;
