import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
	Box,
	Button,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Close, InfoOutlined } from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { listAllUsers } from '../../actions/profileActions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	compareCatalogAccess,
	findParentsCatalog,
	getCatalogHash,
	isAccessObjInsideOtherAccess,
	standarlizeRestrictionUser,
} from '../MediaLibrary/utils';

const EVERYONE = {
	type: 'EVERYONE',
	label: 'CATALOG_TREE_LIMIT_ACCESS_CATALOG_EVERYONE',
};

const SELECTED_USERS = {
	type: 'SELECTED_USERS',
	label: 'CATALOG_TREE_LIMIT_ACCESS_CATALOG_SELECTED_USERS',
};

export const LIMITED_ACCESS_TYPE = {
	EVERYONE,
	SELECTED_USERS,
};

const FULL = {
	type: 'FULL',
	label: 'CATALOG_TREE_LIMITED_ACCESS_LEVEL_FULL_LABEL',
	description: 'CATALOG_TREE_LIMITED_ACCESS_LEVEL_FULL_DESCRIPTION',
};

const LIMITED = {
	type: 'LIMITED',
	label: 'CATALOG_TREE_LIMITED_ACCESS_LEVEL_LIMITED_LABEL',
	description: 'CATALOG_TREE_LIMITED_ACCESS_LEVEL_LIMITED_DESCRIPTION',
};

export const ACCESS_LEVEL = {
	FULL,
	LIMITED,
};

const REMOVE_ALL_USERS = { id: 'REMOVE_ALL_USERS', label: 'CATALOG_TREE_LIMIT_ACCESS_CATALOG_REMOVE_ALL_USER' };
const RESTORE_TO_INHERITED_RULE = {
	id: 'RESTORE_TO_INHERITED_RULE',
	label: 'CATALOG_TREE_LIMIT_ACCESS_CATALOG_RESTORE_TO_INHERITED_RULE',
};

const CATALOG_ACCESS_MENU = [REMOVE_ALL_USERS, RESTORE_TO_INHERITED_RULE];

interface CatalogLimitedAccessProps {
	open?: boolean;
	preDefineCatalog?: any;
	onClose?: () => void;
	setInfoMessage?: (_message: string) => void;
}

const CatalogLimitedAccess = forwardRef(
	({ open, preDefineCatalog, onClose, setInfoMessage }: CatalogLimitedAccessProps, ref: any) => {
		const { t } = useTranslation();
		const dispatch = useDispatch() as any;
		const { defaultAccountId, username, userId } = useSelector((state) => (state as any).session);
		const { catalogs } = useSelector((state) => (state as any).publish);

		const [limitedAccessType, setLimitedAccessType] = useState<string>(EVERYONE.type);
		const [isCatalogInheritedAccess, setCatalogInheritedAccess] = useState<boolean>(false);
		const [userEmailSearchText, setUserEmailSearchText] = useState<string>('');
		const [availableUsers, setAvailableUsers] = useState<any>({});
		const [restrictionUsers, setRestrictionUsers] = useState<any>({});
		const [parentRestrictionUsers, setParentRestrictionUsers] = useState<any>({});
		const [parentIdRestrictionUsers, setParentIdRestrictionUsers] = useState<string>();
		const [accessLevelMenuAnchorEl, setAccessLevelMenuAnchorEl] = useState<any>(null);
		const [removeAccessMenuAnchorEl, setRemoveAccessMenuAnchorEl] = useState<any>(null);
		const [isUserEditedRuleSet, setUserEditedRuleSet] = useState<boolean>(false);
		const [isUserUsedRestore, setUserUsedRestore] = useState<boolean>(false);

		const loading = open && Object.keys(availableUsers).length === 0;
		const isShowOverrideMessage = !isCatalogInheritedAccess && preDefineCatalog?.parentId && !isUserUsedRestore;

		let isDisableEveryOneOption = false;
		if (preDefineCatalog) {
			//case edit catalog
			isDisableEveryOneOption = preDefineCatalog.parentId
				? Object.keys(parentRestrictionUsers).length !== 0
				: false;
		}

		const handleUpdateUserAccessLevel = (accessLevel: string, userId: string) => {
			setUserEditedRuleSet(true);
			setRestrictionUsers({ ...restrictionUsers, [userId]: { id: userId, accessLevel } });
		};

		const handleRemoveRestrictedUser = (id: string) => {
			delete restrictionUsers[id];
			setRestrictionUsers({ ...restrictionUsers });
			setUserEditedRuleSet(true);
		};

		const handleMenuAccessLevelClick = (type: any) => {
			switch (type) {
				case REMOVE_ALL_USERS.id: {
					setRestrictionUsers({
						[userId]: { id: userId, email: username, accessLevel: FULL.type },
					});
					setUserEditedRuleSet(true);
					break;
				}
				case RESTORE_TO_INHERITED_RULE.id: {
					const { id } = preDefineCatalog;

					const parentsCatalog = findParentsCatalog(catalogs, id).filter((catalogId) => catalogId !== id);
					const catalogHash = getCatalogHash(catalogs);

					const catalogWidthValidAccessId = parentsCatalog.find((catalogId) => {
						const catalog = catalogHash[catalogId];

						return catalog && Object.keys(catalog.access).length !== 0;
					});

					const { access } = catalogWidthValidAccessId
						? catalogHash[catalogWidthValidAccessId]
						: { access: {} };

					const presetRestrictionUsers = standarlizeRestrictionUser(access);

					setLimitedAccessType(SELECTED_USERS.type);
					setRestrictionUsers(presetRestrictionUsers);
					setUserEditedRuleSet(false);
					setUserUsedRestore(true);
					break;
				}
			}
			setRemoveAccessMenuAnchorEl(null);
		};

		const resetAndCloseModal = () => {
			setLimitedAccessType(EVERYONE.type);
			setUserEmailSearchText('');
			setAvailableUsers({});
			setRestrictionUsers({});
			setAccessLevelMenuAnchorEl(null);
			setUserEditedRuleSet(false);
			setUserUsedRestore(false);
			onClose?.();
		};

		useImperativeHandle(ref, () => {
			return {
				resetAndCloseModal,
				getRestrictionUsers: () => {
					return limitedAccessType === EVERYONE.type ? {} : restrictionUsers;
				},
				getParentRestrictionUsersInfo: () => {
					return { id: parentIdRestrictionUsers, access: parentRestrictionUsers };
				},

				isCatalogInheritedAccess: () => {
					if (preDefineCatalog.parentId && Object.keys(parentRestrictionUsers).length !== 0) {
						return isCatalogInheritedAccess;
					}
					return false;
				},
			};
		});

		useEffect(() => {
			//init data for auto complete
			if (limitedAccessType === EVERYONE.type || Object.keys(availableUsers).length !== 0) {
				return;
			}

			//editing user should be available in case of LIMITED_ACCESS_TYPE
			setRestrictionUsers({
				...restrictionUsers,
				[userId]: { id: userId, email: username, accessLevel: FULL.type },
			});

			dispatch(listAllUsers(defaultAccountId)).then((data: any) => {
				if (!data) {
					return;
				}
				const availableUsersMap = data.reduce((all: any, { login, userId }: any) => {
					if (!login || !userId) {
						return all;
					}

					return { ...all, [userId]: { id: userId, email: login } };
				}, {});

				setAvailableUsers(availableUsersMap);
			});
		}, [limitedAccessType, availableUsers, defaultAccountId]);

		useEffect(() => {
			if (!preDefineCatalog) {
				return;
			}

			//initial data
			const { id, access, parentId } = preDefineCatalog;
			const isRootCatalog = !parentId;

			const parentCatalogIds = findParentsCatalog(catalogs, id);
			const catalogHashMap = getCatalogHash(catalogs);

			const parentCatalogWidthValidAccessId = parentCatalogIds.find((catalogId) => {
				const catalog = catalogHashMap[catalogId];

				return id !== catalogId && catalog && Object.keys(catalog.access).length !== 0;
			});

			const { access: parentAccess } = parentCatalogWidthValidAccessId
				? catalogHashMap[parentCatalogWidthValidAccessId]
				: { access: {} };

			const parentRestrictionUsers = standarlizeRestrictionUser(parentAccess);

			let catalogRestrictionUsers = standarlizeRestrictionUser(access);

			const isCurrentCatalogHaveNoRule = Object.keys(access).length === 0;

			if (isCurrentCatalogHaveNoRule) {
				catalogRestrictionUsers = parentRestrictionUsers;
			}

			const isAccessEmpty = Object.keys(access).length === 0;
			const accessLimitKeyLength = Object.keys(
				isRootCatalog ? access : isAccessEmpty ? parentAccess : access
			).length;

			setLimitedAccessType(accessLimitKeyLength === 0 ? EVERYONE.type : SELECTED_USERS.type);
			setRestrictionUsers(catalogRestrictionUsers);
			setParentRestrictionUsers(parentRestrictionUsers);
			setParentIdRestrictionUsers(parentCatalogWidthValidAccessId);
		}, [preDefineCatalog, catalogs]);

		useEffect(() => {
			// checking if catalog is inherited or not
			if (Object.keys(restrictionUsers).length === 0) {
				return;
			}

			const isBothCatalogEqual = compareCatalogAccess(restrictionUsers, parentRestrictionUsers);

			if (isBothCatalogEqual) {
				setCatalogInheritedAccess(isBothCatalogEqual);
				setUserUsedRestore(true);
			} else {
				const isParentRestrictionUsersChildOfRestrictionUsers = isAccessObjInsideOtherAccess(
					parentRestrictionUsers,
					restrictionUsers
				);

				if (isParentRestrictionUsersChildOfRestrictionUsers) {
					setCatalogInheritedAccess(true);
				} else {
					setCatalogInheritedAccess(false);
				}
			}
		}, [restrictionUsers, parentRestrictionUsers]);

		useEffect(() => {
			// check to show the correct info message

			if (!isUserEditedRuleSet) {
				setInfoMessage?.('');
				return;
			}

			const isBothCatalogHasDifferentAccess = !compareCatalogAccess(restrictionUsers, parentRestrictionUsers);
			const isParentCatalogAccessChildOfCurrentCatalog = isAccessObjInsideOtherAccess(
				parentRestrictionUsers,
				restrictionUsers
			);
			const isParentCatalogHasRuleSet = Object.keys(parentRestrictionUsers).length !== 0;
			const isNotARootCatalog = preDefineCatalog?.parentId;

			if (!isBothCatalogHasDifferentAccess) {
				setInfoMessage?.('');
				return;
			}

			if (isParentCatalogAccessChildOfCurrentCatalog && isParentCatalogHasRuleSet && isNotARootCatalog) {
				// case inherited and update users
				const { id } = preDefineCatalog;
				const catalogIdNeedToUpdates = findParentsCatalog(catalogs, id).slice(1);
				const catalogHash = getCatalogHash(catalogs);
				const userIds = Object.keys(restrictionUsers);
				const catalogsNames = catalogIdNeedToUpdates
					.map((id: any) => {
						const {
							access,
							metadata: { title },
						} = catalogHash[id];

						const isNeedToShowCatalog =
							Object.keys(access).length > 0 &&
							userIds.some((id) => {
								return !access[id] && userId !== id;
							});

						return isNeedToShowCatalog ? title || id : null;
					})
					.filter(Boolean);

				setInfoMessage?.(
					t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_UPDATE_RULES', {
						catalogNames:
							catalogsNames.length > 5
								? `${catalogsNames.slice(0, 5).toString()} ,...`
								: catalogsNames.toString(),
					})
				);
			} else if (!isParentCatalogAccessChildOfCurrentCatalog && isUserUsedRestore) {
				// case override and update users
				setInfoMessage?.(t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_REMOVE_USERS'));
			}
		}, [
			t,
			catalogs,
			preDefineCatalog,
			restrictionUsers,
			parentRestrictionUsers,
			preDefineCatalog?.parentId,
			setInfoMessage,
			isUserEditedRuleSet,
			isUserUsedRestore,
		]);

		return (
			<div className="dialogContentWrapper">
				<label> {t('CATALOG_TREE_LIMIT_ACCESS_CATALOG')}</label>
				<div className="fullWidthControl">
					<RadioGroup
						row
						value={limitedAccessType}
						onChange={(event: any) => {
							setLimitedAccessType(event.currentTarget.value);
						}}
					>
						{Object.values(LIMITED_ACCESS_TYPE).map(({ type, label }) => {
							return (
								<FormControlLabel
									key={type}
									value={type}
									control={
										<Radio
											color="primary"
											disabled={
												type === LIMITED_ACCESS_TYPE.EVERYONE.type
													? isDisableEveryOneOption
													: false
											}
										/>
									}
									label={t(label)}
								/>
							);
						})}
					</RadioGroup>
				</div>
				{limitedAccessType === SELECTED_USERS.type && (
					<>
						<Autocomplete
							value={''}
							disableClearable
							onChange={(_event, newValue: any) => {
								if (!newValue) {
									return;
								}
								const { id } = newValue;
								setUserEmailSearchText('');
								setUserEditedRuleSet(true);
								if (id === 'allUsers') {
									const allUsersToAdd = Object.values(availableUsers).filter(({ id }: any) => {
										return !restrictionUsers[id];
									});
									const usersHash = allUsersToAdd.reduce((all: any, { id }: any) => {
										return { ...all, [id]: { id, accessLevel: LIMITED.type } };
									}, {});

									setRestrictionUsers({ ...restrictionUsers, ...(usersHash as any) });
								} else {
									setRestrictionUsers({
										...restrictionUsers,
										[id]: { id, accessLevel: LIMITED.type },
									});
								}
							}}
							options={
								Object.keys(availableUsers).length !== Object.keys(restrictionUsers).length
									? [
											{ id: 'allUsers' },
											...Object.values(availableUsers).filter(({ id }: any) => {
												return !restrictionUsers[id];
											}),
									  ]
									: Object.values(availableUsers).filter(({ id }: any) => {
											return !restrictionUsers[id];
									  })
							}
							getOptionLabel={(option: any) => {
								if (option === '') {
									return '';
								}
								const { id, email } = option;
								return id === 'allUsers'
									? t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_SELECT_ALL_USERS')
									: email;
							}}
							getOptionSelected={(option, value) => option === value}
							loading={loading}
							inputValue={userEmailSearchText}
							onInputChange={(_event, newInputValue) => {
								setUserEmailSearchText(newInputValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									className="user-email-search-box"
									type="text"
									placeholder={t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_EMAIL_SEARCH_PLACE_HOLDER')}
									InputProps={{
										...params.InputProps,
										disableUnderline: true,
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon htmlColor="#aab0b4" />
											</InputAdornment>
										),
									}}
								/>
							)}
						/>
						<div className="fullWidthControl">
							<TableContainer style={{ maxHeight: 230, marginTop: 10 }}>
								<Table aria-label="restriction user table" stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell width="70%">
												{t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_USER')}
											</TableCell>
											<TableCell width="20%">
												{t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_ACCESS_LEVEL')}
											</TableCell>
											<TableCell align="right" width="10%">
												<IconButton
													size="small"
													onClick={(e) => {
														setRemoveAccessMenuAnchorEl(e.currentTarget);
													}}
												>
													<MoreHorizIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{Object.values(restrictionUsers)
											.sort(({ id: idA }: any, { id: idB }: any) => {
												if (availableUsers[idA] >= availableUsers[idB]) {
													return 1;
												}
												return -1;
											})
											.map(({ id, email, accessLevel }: any) => (
												<TableRow key={id} hover>
													<TableCell width="70%" component="th" scope="row">
														<Typography>{email || availableUsers[id]?.email}</Typography>
													</TableCell>
													<TableCell width="20%">
														{
															<Button
																endIcon={<ExpandMoreIcon />}
																size="small"
																data-userid={id}
																fullWidth
																style={{ justifyContent: 'space-between' }}
																disabled={userId === id}
																onClick={(event: any) => {
																	setAccessLevelMenuAnchorEl(event.currentTarget);
																}}
															>
																<Typography>
																	{t(
																		ACCESS_LEVEL[
																			accessLevel as keyof typeof ACCESS_LEVEL
																		].label
																	)}
																</Typography>
															</Button>
														}
													</TableCell>
													<TableCell align="right" width="10%">
														<IconButton
															size="small"
															disabled={userId === id}
															onClick={() => {
																handleRemoveRestrictedUser(id);
															}}
														>
															<Close />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>

							{isShowOverrideMessage && (
								<Box display="flex" alignItems="center" paddingTop="12px">
									<InfoOutlined />
									<Typography style={{ paddingLeft: 10 }} variant="caption">
										{t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_OVERRIDE_RULES')}
									</Typography>
								</Box>
							)}
						</div>
						<Menu
							id="access-level-menu"
							anchorEl={accessLevelMenuAnchorEl}
							keepMounted
							open={Boolean(accessLevelMenuAnchorEl)}
							onClose={() => {
								setAccessLevelMenuAnchorEl(null);
							}}
						>
							{Object.values(ACCESS_LEVEL).map(({ type, label, description }: any) => {
								return (
									<MenuItem
										key={type}
										onClick={() => {
											const userId = accessLevelMenuAnchorEl.dataset.userid;
											setAccessLevelMenuAnchorEl(null);
											handleUpdateUserAccessLevel(type, userId);
										}}
										style={{ flexDirection: 'column', alignItems: 'start' }}
									>
										<Typography variant="body1">{t(label)}</Typography>
										<Typography variant="body2" color="textSecondary">
											{t(description)}
										</Typography>
									</MenuItem>
								);
							})}
						</Menu>
						<Menu
							id="table-option-menu"
							anchorEl={removeAccessMenuAnchorEl}
							keepMounted
							open={Boolean(removeAccessMenuAnchorEl)}
							onClose={() => {
								setRemoveAccessMenuAnchorEl(null);
							}}
						>
							{CATALOG_ACCESS_MENU.map(({ id, label }: any) => {
								let isDisabled = false;

								if (id === REMOVE_ALL_USERS.id) {
									isDisabled =
										Object.keys(restrictionUsers).length === 1 && !!restrictionUsers[userId];
								}

								if (id === RESTORE_TO_INHERITED_RULE.id) {
									isDisabled = isCatalogInheritedAccess || !preDefineCatalog?.parentId;
								}

								return (
									<MenuItem
										key={id}
										disabled={isDisabled}
										onClick={() => {
											handleMenuAccessLevelClick(id);
										}}
									>
										{t(label)}
									</MenuItem>
								);
							})}
						</Menu>
					</>
				)}
			</div>
		);
	}
);

export default CatalogLimitedAccess;
