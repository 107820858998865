import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { stringify } from 'subtitle';
import { showNotification } from '../../../../actions/notificationActions';
import {
	toggleShowPreview,
	setAppMode,
	setIsMountedSubtitleUpdated,
	deleteTranscription,
} from '../../../../actions/speechToTextActions';
import { startSubtitleUploadProcess } from '../../../../actions/publishActions';
import downArrow from '../../../../assets/images/down_arrow_black.svg';

import './SpeechToTextHeader.scss';

class SpeechToTextHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			optionsOpen: false,
			previewValue: '',
			translationLanguage: 'sv',
		};
	}

	toggleSubtitlePreview = () => {
		if (this.props.showPreview === false) {
			this.props.toggleShowPreview(true);
		} else {
			this.props.toggleShowPreview(false);
		}

		if (this.state.previewValue === '') {
			this.setState({ previewValue: 'checked' });
		} else {
			this.setState({ previewValue: '' });
		}
	};

	setModeView = (event) => {
		if (event.currentTarget.id === 'editMode') {
			this.props.setAppMode('edit');
		} else if (event.currentTarget.id === 'subEditMode') {
			this.props.setAppMode('subEdit');
		}
	};

	openOptionMenu = () => {
		this.setState({ optionsOpen: true });
	};

	closeOptionMenu = () => {
		this.setState({ optionsOpen: false });
	};

	renderHeader = (t) => {
		switch (this.props.appMode) {
			case 'edit':
				return <h3>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_HEADER_EDIT_TITLE')}</h3>;
			case 'subEdit':
				return <h3>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_HEADER_EDIT_SUBTITLE_TITLE')}</h3>;
			default:
				return 'foo';
		}
	};

	downloadTxt = (text, fileType, fileName) => {
		var blob = new Blob([text.replace(/¤/, '')], { type: fileType });

		var a = document.createElement('a');
		a.download = fileName;
		a.href = URL.createObjectURL(blob);
		a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		setTimeout(function () {
			URL.revokeObjectURL(a.href);
		}, 1500);
	};

	deleteCurrentTranscription = () => {
		const {
			defaultAccountId,
			mediaDetails,
			triggerLoading,
			deleteTranscription,
			resetTranscriptionDataInLocalState,
		} = this.props;
		triggerLoading();
		deleteTranscription(defaultAccountId, mediaDetails.id, resetTranscriptionDataInLocalState);
	};

	render() {
		const { t, isEnableDeleteOption } = this.props;

		return (
			<div id="sttHeader">
				<div id="sttHeaderLeftSide">
					<div className="sttHeaderTitle">{this.renderHeader(t)}</div>
				</div>
				<div id="switchViewLayer">
					{this.props.appMode === 'edit' ? (
						<div>
							<button id="optionsButton" className="optionsBtn" onClick={() => this.openOptionMenu()}>
								{t('COMMON_OPTIONS')}
								<img className="downArrow" src={downArrow} />
							</button>
						</div>
					) : (
						<div>
							{this.props.isMountedSubtitleUpdated ? (
								<button
									id="saveSrtBtnHeader"
									onClick={() => {
										this.props.uploadSrt(
											this.props.focusedLanguage,
											stringify(this.props.mountedSubtitleArray)
										);
										this.props.setIsMountedSubtitleUpdated(false);
									}}
								>
									{t('COMMON_SAVE_CHANGES')}
								</button>
							) : (
								<button id="saveSrtBtnHeaderDeactive">{t('COMMON_SAVE_CHANGES')}</button>
							)}

							<button id="optionsButton" className="optionsBtn" onClick={() => this.openOptionMenu()}>
								{t('COMMON_OPTIONS')}
								<img className="downArrow" src={downArrow} />
							</button>
						</div>
					)}
				</div>
				<Menu
					id="simple-menu"
					anchorEl={document.getElementById('optionsButton')}
					keepMounted
					disableAutoFocusItem={true}
					open={this.state.optionsOpen}
					onClose={this.closeOptionMenu}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					PaperProps={{
						style: {
							marginTop: '8px',
							border: '2px solid rgb(118,118,118)',
							borderRadius: '0px',
							padding: 0,
						},
					}}
				>
					<MenuItem
						onClick={() => this.downloadTxt(this.props.rawTranscribedText, 'text/txt', 'transcription.txt')}
					>
						{t('COMMON_DOWNLOAD')} raw .txt
					</MenuItem>
					<MenuItem onClick={() => this.deleteCurrentTranscription()} disabled={!isEnableDeleteOption}>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_HEADER_DELETE_TRANSCRIPTION')}
					</MenuItem>
				</Menu>
			</div>
		);
	}
}

const mapStateToProps = ({ session, speechToText }) => ({
	showPreview: speechToText.showPreview,
	appMode: speechToText.appMode,
	defaultAccountId: session.defaultAccountId,
	rawTranscribedText: speechToText.rawTranscribedText,
	isMountedSubtitleUpdated: speechToText.isMountedSubtitleUpdated,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	toggleShowPreview: (showPreview) => dispatch(toggleShowPreview(showPreview)),
	setAppMode: (mode) => dispatch(setAppMode(mode)),
	startSubtitleUploadProcess: (accId, file, language, assetId, mediaId) =>
		dispatch(startSubtitleUploadProcess(accId, file, language, assetId, mediaId)),
	setIsMountedSubtitleUpdated: (mountedSubUpdate) => dispatch(setIsMountedSubtitleUpdated(mountedSubUpdate)),
	deleteTranscription: (accountId, mediaId, callback) => {
		dispatch(deleteTranscription(accountId, mediaId, callback));
	},
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SpeechToTextHeader);
