import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dialogSizes } from '../../../constants/dialogSizesConstant';
import { odSelectionList } from '../../../constants/liveManagerConstants';
import { isTimelineItemInteract } from '../../../services/interactScreenHelperService';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import { getDetails } from '../../../services/mediaDisplayService';
import { mediaNames } from '../../../constants/mediaConstants';

import './StopLiveEventConfirmationDialog.scss';

class StopLiveEventConfirmationDialog extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			open: true,
			selectedId: '',
		};
	}

	UNSAFE_componentWillMount = () => {
		var items = this.getTimeLineItems();
		if (items.length > 0) {
			this.setState({
				selectedId: items[0].id,
			});
		}

		const { defaultAccountId, mediaId } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			let mediaDetails = getDetails(media, false);
			const SaveAsOnDemandFromData =
				mediaDetails.custom &&
				mediaDetails.custom.livemanager &&
				mediaDetails.custom.livemanager.SaveAsOnDemand;
			this.setState({
				SaveAsOnDemand:
					SaveAsOnDemandFromData !== null && SaveAsOnDemandFromData !== undefined
						? SaveAsOnDemandFromData
						: true,
			});
		});
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleSelection = (e) => {
		if (e && e.target && e.target.id) {
			let id = e.target.id;
			this.setState({
				selectedId: id,
			});
		}
	};

	getTimeLineItems = () => {
		const { timelineItems, mediaId, t } = this.props;
		var list = [
			{ id: odSelectionList.POSTCOVER, text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_KEEP_POST_COVER') },
		];
		if (this.state.SaveAsOnDemand) {
			list.push({
				id: odSelectionList.FULLSTREAM,
				text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FULL_STREAM'),
			});
		}
		timelineItems.map((item) => {
			if (item.id != mediaId && !item.editable && !isTimelineItemInteract(item)) {
				//this will always be live
				let obj = {
					id: item.id,
					text: item.content,
				};
				list.push(obj);
			}
		});
		return list;
	};

	render() {
		const { t } = this.props;
		const { selectedId } = this.state;
		return (
			<div className="ConfirmationDialogWrapper">
				<Dialog
					id="StopLiveEventConfirmationDialog"
					fullWidth={true}
					maxWidth={dialogSizes.SMALL}
					open={this.state.open}
					className="confirmationPopup"
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">{t(this.props.title)}</Typography>
						<IconButton
							onClick={() => {
								this.handleClose();
								this.props.callback({ ok: false });
							}}
						>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<div className="dialogContentWrapper">
								{this.getTimeLineItems().length > 0 && (
									<>
										<label className="snapShotReplaceInfo">
											{' '}
											{t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_REPLACE_LIVE_WITH_MEDIAS'
											)}{' '}
										</label>
										<ul className="odSnapShotsList">
											{this.getTimeLineItems().map((item) => (
												<li
													className={
														'odSnapShot ' + (selectedId === item.id ? 'selected' : '')
													}
													key={item.id}
													id={item.id}
													onClick={this.handleSelection}
												>
													{' '}
													{item.text}{' '}
												</li>
											))}
										</ul>
									</>
								)}
							</div>
						</DialogContentText>
						<DialogContentText>
							<div className="dialogContentWrapper">
								<label className="dialogMessage">{t(this.props.message)}</label>
							</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
								<Button
									className="defaultActionBtn neutralBtn"
									onClick={() => {
										this.handleClose();
										this.props.callback({ ok: false });
									}}
								>
									{t(this.props.cancelButtonText)}
								</Button>

								<Button
									disabled={!selectedId}
									className="defaultActionBtn"
									onClick={() => {
										this.handleClose();
										this.props.callback({ ok: true, selectedSnapShotForOd: selectedId });
									}}
								>
									{t(this.props.okButtonText)}
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

StopLiveEventConfirmationDialog.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	descriptionAsHtml: PropTypes.string,
	open: PropTypes.bool.isRequired,
	okButtonText: PropTypes.string.isRequired,
	cancelButtonText: PropTypes.string.isRequired,
	callback: PropTypes.func,
};

StopLiveEventConfirmationDialog.defaultProps = {
	open: true,
	title: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STOP_LIVE_EVENT_MODAL',
	message: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STOP_LIVE_EVENT_MODAL_CONFIRM_MESSAGE',
	okButtonText: 'COMMON_YES',
	cancelButtonText: 'COMMON_BACK',
};

const mapStateToProps = ({ session, liveManager }) => ({
	defaultAccountId: session.defaultAccountId,
	timelineItems: liveManager.items,
});

const mapDispatchToProps = (dispatch) => ({
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StopLiveEventConfirmationDialog);
