import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './assets/locale/en/translation.json';
import translationSV from './assets/locale/sv/translation.json';
import translationFR from './assets/locale/fr/translation.json';
import translationDE from './assets/locale/de/translation.json';
import translationNO from './assets/locale/no/translation.json';
import translationDA from './assets/locale/da/translation.json';
import translationFI from './assets/locale/fi/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	sv: {
		translation: translationSV,
	},
	fr: {
		translation: translationFR,
	},
	de: {
		translation: translationDE,
	},
	da: {
		translation: translationDA,
	},
	no: {
		translation: translationNO,
	},
	fi: {
		translation: translationFI,
	},
};

i18n.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'en',
		fallbackLng: 'en',
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
