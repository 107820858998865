import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dialogSizes } from './../../constants/dialogSizesConstant';
import IconsStore from './../IconsStore/IconsStore';
import { IconsList } from './../IconsStore/IconsList';

class ConfirmationDialog extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			open: true,
		};
	}

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { t, okButtonText } = this.props;
		return (
			this.state.open && (
				<div className="ConfirmationDialogWrapper">
					<Dialog
						id="ConfirmationDialog"
						fullWidth={true}
						maxWidth={dialogSizes.SMALL}
						open={this.state.open}
						className="confirmationPopup"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{this.props.title}</Typography>
							<IconButton
								onClick={() => {
									this.handleClose();
									this.props.callback({ ok: false });
								}}
							>
								<Close />
							</IconButton>
						</DialogTitle>

						<label className="confirmationTitle">{this.props.message}</label>
						{this.props.mediaTitle && (
							<DialogContent>
								<DialogContentText className="dialogContentWrapper">
									<label>{this.props.mediaTitle}</label>
								</DialogContentText>
							</DialogContent>
						)}

						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
									<Button
										className="defaultActionBtn neutralBtn"
										onClick={() => {
											this.handleClose();
											this.props.callback({ ok: false });
										}}
									>
										{this.props.cancelButtonText}
									</Button>
									{t && t('COMMON_DELETE') === okButtonText ? (
										<Button
											className="defaultActionBtn deleteButtonWithIcon"
											onClick={() => {
												this.handleClose();
												this.props.callback({ ok: true });
											}}
										>
											<IconsStore iconName={IconsList.TRASHCAN} />
											{this.props.okButtonText}
										</Button>
									) : (
										<Button
											className="defaultActionBtn"
											onClick={() => {
												this.handleClose();
												this.props.callback({ ok: true });
											}}
										>
											{this.props.okButtonText}
										</Button>
									)}
								</div>
							</div>
						</DialogActions>
					</Dialog>
				</div>
			)
		);
	}
}

ConfirmationDialog.propTypes = {
	mediaTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	descriptionAsHtml: PropTypes.string,
	open: PropTypes.bool.isRequired,
	okButtonText: PropTypes.string.isRequired,
	cancelButtonText: PropTypes.string.isRequired,
	callback: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
	open: true,
	title: 'Dialog',
	message: 'Are you sure to perform this action?',
	okButtonText: 'Yes',
	cancelButtonText: 'Go back',
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);
