import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from './../../../../../reusable/ConfirmationDialog/ConfirmationDialog';
import { mediaNames, messageTypes } from '../../../../../constants/mediaConstants';
import { isRequestFail } from './../../../../../services/inspectScreenHelperService';
import { deleteSelectedMediaItem, loadPlaylistRelativeMedias } from '../../../../../actions/publishActions';
import './DeleteMedia.scss';
import { sendMediaDeleted } from './../../../../../actions/inspectScreenActions';
import { showMessage } from '../../../../../actions/globalActions';

class DeleteMedia extends Component {
	state = {
		showConfirmationDialog: false,
		confirmationDialogTitle: this.props.t('COMMON_DELETE'),
		confirmationDialogMessage: this.props.t('COMMON_DELETE_CONFIRMATION_SINGLE'),
		buttonName: this.props.t('COMMON_DELETE'),
		lastRoute: this.props.lastRoute,
		cancelButtonText: this.props.t('COMMON_BACK'),
	};

	UNSAFE_componentWillMount() {
		const { t, mediaName } = this.props;
		let buttonName = t('COMMON_DELETE');
		if (mediaName === mediaNames.medias) {
			buttonName = t('COMMON_DELETE_MEDIA');
		} else if (mediaName === mediaNames.catalogs) {
			buttonName = t('COMMON_DELETE_CATALOG');
		} else if (mediaName === mediaNames.playlists) {
			buttonName = t('COMMON_DELETE_PLAYLISTS');
		}
		this.setState({
			buttonName: buttonName,
			confirmationDialogTitle: buttonName + '?',
		});
	}

	componentDidMount() {
		const { getDeleteMediaDialogRef } = this.props;
		getDeleteMediaDialogRef && getDeleteMediaDialogRef(this);
	}

	componentWillUnmount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.lastRoute != this.props.lastRoute) {
			this.setState({
				lastRoute: nextProps.lastRoute,
			});
		}
	}

	handleDeleteMedia = () => {
		const { t, mediaDetails, mediaName, showMessageInternal: showMessage } = this.props;

		if (!(mediaDetails && mediaDetails.id)) {
			showMessage(t('COMMON_MEDIA_NOT_FOUND'), messageTypes.error);
			return;
		} else if (mediaName === mediaNames.catalogs) {
			if (mediaDetails.includedChildren > 0 && !mediaDetails.hasChildren) {
				showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_CATALOG_WITH_MEDIAS_CANNOT_DELETED'),
					messageTypes.error
				);
				return;
			} else if (mediaDetails.includedChildren == 0 && mediaDetails.hasChildren) {
				showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_CATALOG_WITH_SUBCATALOG_CANNOT_DELETED'),
					messageTypes.error
				);
				return;
			} else if (mediaDetails.includedChildren > 0 && mediaDetails.hasChildren) {
				showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_CATALOG_WITH_MEDIAS_SUBCATALOG_CANNOT_DELETED'),
					messageTypes.error
				);
				return;
			}
		}
		this.setState(() => ({
			showConfirmationDialog: true,
		}));
	};

	handleDialogCallback = (e) => {
		this.setState(() => ({ showConfirmationDialog: false }));
		if (e.ok) {
			this.deleteMedia();
		}
	};

	performDelete = (assetId) => {
		const { defaultAccountId, t, mediaName, mediaDetails, showMessageInternal: showMessage } = this.props;
		this.props.deleteMediaItem(mediaDetails.id, defaultAccountId, mediaName, assetId).then((data) => {
			if (isRequestFail(this.props, data)) {
				showMessage(t('COMMON_DELETION_FAILED'), messageTypes.error);
				return;
			}
			showMessage(t('COMMON_DELETED_SUCCESSFULLY'), messageTypes.info);
			this.props.sendMediaDeleted(mediaDetails.id);
			setTimeout(() => {
				if (this.props.history) {
					this.props.history.push('/medialibrary/allmedia');
				}
			}, 2000);
		});
	};

	deleteMedia = () => {
		const { defaultAccountId, t, mediaName, mediaDetails, showMessageInternal: showMessage } = this.props;
		let assetId = '';
		if (mediaName === mediaNames.catalogs || mediaName === mediaNames.playlists) {
			//not trying to delete catalog and playlist asset for now
			assetId = '';
		} else {
			assetId = mediaDetails.asset ? mediaDetails.asset.id : '';
		}
		if (mediaName === mediaNames.playlists) {
			this.props.loadPlaylistRelativeMedias(defaultAccountId, mediaDetails.id).then((data) => {
				if (data && data.medias && data.medias.length > 0) {
					showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYLIST_WITH_MEDIA_NO_DELETE'),
						messageTypes.error
					);
					return;
				} else {
					this.performDelete(assetId);
				}
			});
		} else {
			this.performDelete(assetId);
		}
	};

	getDeleteIcon = () => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.73 8.52">
				<title>Delete</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<g id="H7CZZr.tif">
							<path d="M5.71,8.52H1l-.37-6L0,2.51V1.79a1.81,1.81,0,0,1,.23,0H6.5a1.6,1.6,0,0,1,.23,0v.72l-.65,0ZM3,2.55l.11,5.18H3.6l.11-5.18Zm-1.59,0,.32,5.17h.47l-.1-5.17ZM4.51,7.73H5L5.3,2.56H4.62Z" />
							<path d="M4,0V.57H6.13v.76H.61V.57H2.74l0-.57Z" />
						</g>
					</g>
				</g>
			</svg>
		);
	};

	render() {
		const { t } = this.props;
		const { showConfirmationDialog, confirmationDialogTitle, confirmationDialogMessage, cancelButtonText } =
			this.state;
		return (
			<>
				{showConfirmationDialog && (
					<ConfirmationDialog
						t={t}
						openDialog={showConfirmationDialog}
						title={confirmationDialogTitle}
						message={confirmationDialogMessage}
						callback={this.handleDialogCallback}
						cancelButtonText={cancelButtonText}
						okButtonText={t('COMMON_DELETE')}
					/>
				)}
			</>
		);
	}
}
const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	deleteMediaItem: (id, accountId, mediaName, assetId) =>
		dispatch(deleteSelectedMediaItem(id, accountId, mediaName, assetId)),
	sendMediaDeleted: (mediaId) => dispatch(sendMediaDeleted(mediaId)).showNotification,
	loadPlaylistRelativeMedias: (accountId, playlistId) => dispatch(loadPlaylistRelativeMedias(accountId, playlistId)),
	showMessageInternal: (mess, type) => dispatch(showMessage(mess, type)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DeleteMedia);
