import React from 'react';

import { Button, ButtonGroup } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import ReplayIcon from '@material-ui/icons/Replay';
import ResumeIcon from '@material-ui/icons/RadioButtonChecked';

import { ReactComponent as OnScreenIcon } from '../../../assets/images/screen_on_screen.svg';
import { ReactComponent as OnScreenExitIcon } from '../../../assets/images/screen_on_screen_exit.svg';

import './ActionsButton.scss';
import { RECORDING_STATUS } from '../../../hooks/screens/library/useScreenRecorder';
import { convertSecondsToHHMMSS } from '../../../utils/commonUtil';
import { useTranslation } from 'react-i18next';

interface ActionsButtonProps {
	recordingStatus: string;
	isModalShow: boolean;
	recordDuration: number;
	handleStopRecording: () => void;
	handlePauseRecording: () => void;
	handleResumeRecording: () => void;
	handleRestartRecording: () => void;
	handleCloseModal: () => void;
	handleOpenModal: () => void;
}

const ActionsButton = ({
	recordingStatus,
	isModalShow,
	recordDuration,
	handleStopRecording,
	handlePauseRecording,
	handleResumeRecording,
	handleRestartRecording,
	handleCloseModal,
	handleOpenModal,
}: ActionsButtonProps) => {
	const { t } = useTranslation();
	return (
		<>
			<ButtonGroup
				className="recording-cta-btn-group"
				disableElevation
				variant="outlined"
				color="primary"
				size="large"
				fullWidth
			>
				<Button
					className="pause-button"
					onClick={() => {
						if (recordingStatus === RECORDING_STATUS.RECORDING) {
							handlePauseRecording();
							return;
						}

						if (recordingStatus === RECORDING_STATUS.PAUSED) {
							handleResumeRecording();
							return;
						}
					}}
					color="primary"
				>
					{recordingStatus === RECORDING_STATUS.RECORDING ? (
						<PauseIcon />
					) : (
						<ResumeIcon className="resume-icon" />
					)}
				</Button>
				<Button onClick={handleRestartRecording} color="primary">
					<ReplayIcon />
				</Button>
				{/* Using a fake button for showing duration. Utilize button group styling */}
				<Button
					className="duration"
					onClick={() => {}}
					color="primary"
					disableRipple
					disableFocusRipple
					tabIndex={-1}
				>
					{convertSecondsToHHMMSS(recordDuration)}
				</Button>
				<Button
					onClick={() => {
						if (isModalShow) {
							handleCloseModal();
						} else {
							handleOpenModal();
						}
					}}
					color="primary"
				>
					<div className="ons-creen-icon-container">
						{isModalShow ? <OnScreenIcon /> : <OnScreenExitIcon />}
					</div>
				</Button>
			</ButtonGroup>
			<Button className="stop-button" onClick={handleStopRecording} variant="contained" color="primary">
				{t('COMMON_STOP')}
			</Button>
		</>
	);
};

export default ActionsButton;
