import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './StreamInfo.scss';

class StreamInfo extends Component {
	render() {
		const { t, stream } = this.props;
		return (
			<>
				<div id="streamInfo">
					<table>
						<tbody>
							<tr>
								<td>{`${t('COMMON_MEDIA_TYPE_VIDEO')} ${t('COMMON_BITRATE')}:`}</td>
								<td> {stream.bitRate} </td>
								<td> {`${t('COMMON_MEDIA_TYPE_AUDIO')} ${t('COMMON_BITRATE')}:`}</td>
								<td> {stream.audioBitRate} </td>
							</tr>
							<tr>
								<td> {`${t('COMMON_MEDIA_TYPE_VIDEO')} ${t('COMMON_CODEC')}:`}</td>
								<td> {stream.codec} </td>
								<td> {t('COMMON_SAMPLE_RATE') + ':'}</td>
								<td> {stream.sampleRate} </td>
							</tr>
							<tr>
								<td> {`${t('COMMON_MEDIA_TYPE_VIDEO')} ${t('COMMON_WIDTH')}:`}</td>
								<td> {stream.width} </td>
								<td> {t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_BIT_DEPTH') + ':'}</td>
								<td> {stream.bitDepth} </td>
							</tr>
							<tr>
								<td> {`${t('COMMON_MEDIA_TYPE_VIDEO')} ${t('COMMON_HEIGHT')}:`}</td>
								<td> {stream.height} </td>
								<td> {t('COMMON_CHANNELS') + ':'}</td>
								<td> {stream.channels} </td>
							</tr>
							<tr>
								<td> {`${t('COMMON_MEDIA_TYPE_VIDEO')} ${t('COMMON_PROFILE')}:`}</td>
								<td> {stream.videoProfile} </td>
								<td> {`${t('COMMON_MEDIA_TYPE_AUDIO')} ${t('COMMON_CODEC')}:`}</td>
								<td> {stream.audioCodec} </td>{' '}
								{/*to clarify as values is coming as HE-AAC but shown as AAC-LC*/}
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(StreamInfo);
