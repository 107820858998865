import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { DialogTitle } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Index, IndexSearchResult } from 'flexsearch';
import { getTag } from './helper';
import synonyms from './synonyms';

import './IconLibrary.scss';

const ICON_TYPE = [
	{ value: 'Filled', label: 'SCENARIO_SCENE_EDITOR_ICON_LIBRARY_MODAL_ICON_TYPE_FILED' },
	{ value: 'Outlined', label: 'SCENARIO_SCENE_EDITOR_ICON_LIBRARY_MODAL_ICON_TYPE_OUTLINED' },
	{ value: 'Rounded', label: 'SCENARIO_SCENE_EDITOR_ICON_LIBRARY_MODAL_ICON_TYPE_ROUNDED' },
	{ value: 'Two tone', label: 'SCENARIO_SCENE_EDITOR_ICON_LIBRARY_MODAL_ICON_TYPE_TWO_TONE' },
	{ value: 'Sharp', label: 'SCENARIO_SCENE_EDITOR_ICON_LIBRARY_MODAL_ICON_TYPE_SHARP' },
];

export type IconNames = keyof typeof MuiIcons;
type AllIconMap = {
	[key: string]: {};
};

const searchIndex = new Index({
	tokenize: 'full',
});

export const getIconByName = (iconName: IconNames, props: any) => {
	if (!iconName) {
		return <></>;
	}
	const Icon = MuiIcons[iconName];
	return <Icon {...props} />;
};

const allIconsMap: AllIconMap = {};
const allIcons = Object.keys(MuiIcons)
	.sort()
	.map((key) => {
		let tag = getTag(key);

		let searchable = key.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '');
		if (synonyms[searchable]) {
			searchable += ` ${synonyms[searchable]}`;
		}
		searchIndex.add(key, searchable);

		const icon = {
			key,
			tag,
			Icon: MuiIcons[key as IconNames],
		};

		allIconsMap[key] = icon;

		return icon;
	});

type IconProps = {
	key: string;
	tag: string;
	Icon: MuiIcons.SvgIconComponent;
};

type IconsProps = {
	icons: Array<IconProps>;
	addSelectedIconToIconWidget: (_iconKey: string) => {};
};

const Icons = ({ icons, addSelectedIconToIconWidget }: IconsProps) => {
	return (
		<div>
			{icons.map(({ key, Icon }) => {
				return (
					<span
						key={key}
						className="icon"
						onClick={() => {
							addSelectedIconToIconWidget(key);
						}}
					>
						<Icon />
						<p>{key}</p>
					</span>
				);
			})}
		</div>
	);
};

type IconLibrary = {
	isShow: boolean;
	onClose: () => {};
	addSelectedIconToIconWidget: () => {};
};

const IconLibrary = ({ isShow, onClose, addSelectedIconToIconWidget }: IconLibrary) => {
	const { t } = useTranslation();
	const [tag, setTag] = useState('Filled');
	const [keys, setKeys] = useState<IndexSearchResult | null>(null);

	const icons = useMemo(
		() =>
			(keys === null ? allIcons : keys.map((key: any) => allIconsMap[key])).filter(
				(icon: any) => tag === icon.tag
			),
		[tag, keys]
	);

	const handleChange = (value: string) => {
		if (value === '') {
			setKeys(null);
		} else {
			searchIndex.searchAsync(value, { limit: 3000 }).then((result) => {
				setKeys(result);
			});
		}
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={isShow}
			onClose={onClose}
			aria-labelledby="icon-dialog-title"
			className="icon-library"
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('SCENARIO_SCENE_EDITOR_ICON_LIBRARY_MODAL_TITLE')}</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</DialogTitle>
			<Box className="container">
				<Box className="search-section">
					<Box className="search-box">
						<IconButton disableRipple>
							<SearchIcon />
						</IconButton>
						<InputBase
							autoFocus
							placeholder={t('COMMON_SEARCH')}
							onChange={(e) => {
								handleChange(e.target.value);
							}}
						/>
					</Box>
				</Box>
				<Box className="icons-section">
					<Grid container className="icons-container">
						<Grid item xs={12} sm={2}>
							<form>
								<RadioGroup>
									{ICON_TYPE.map(({ value, label }) => {
										return (
											<FormControlLabel
												key={value}
												control={
													<Radio
														color="primary"
														checked={tag === value}
														onChange={() => {
															setTag(value);
														}}
														value={value}
													/>
												}
												label={t(label)}
											/>
										);
									})}
								</RadioGroup>
							</form>
						</Grid>
						<Grid item xs={12} sm={10} className="icons-grid">
							<Icons icons={icons as any} addSelectedIconToIconWidget={addSelectedIconToIconWidget} />
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Dialog>
	);
};

export default IconLibrary;
