import { FormControlLabel, Switch } from '@material-ui/core';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { withStyles } from '@material-ui/core/styles';

import { showMessage } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';
import {
	createAccountSettings,
	getAccountSettings,
	setHasAdvancedSettings,
	updateAccountSettings,
	updateEnabledEmbed,
} from '../../../actions/profileActions';

import './AdvancedSettings.scss';
import { NEW_EMBED_VERSION_2 } from '../../../constants/playerConfigurationsConstant';
import FeaturesService from '../../../services/FeaturesService';

const styles = (_) => ({
	'@global': {
		'.MuiSwitch-colorPrimary.Mui-checked:not(.Mui-disabled)': {
			color: '#126CFC',
		},
		'.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#fff',
			border: '1px solid #126CFC',
		},
	},
});

class AdvancedSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			useNewEmbedSetting: false,
		};
	}

	componentDidMount() {
		this.loadEmbedSetting();
	}

	loadEmbedSetting = () => {
		const { defaultAccountId, getAccountSettings, updateEnabledEmbed, setHasAdvancedSettings } = this.props;
		getAccountSettings(defaultAccountId).then((settings) => {
			setHasAdvancedSettings(settings && Object.keys(settings).length > 0);

			if (!(settings && Object.keys(settings).length > 0)) {
				return;
			}

			let embedVersion = null;
			if (
				settings.hasOwnProperty('qbrickPlayer') &&
				settings['qbrickPlayer'] &&
				settings['qbrickPlayer'].length > 0
			) {
				embedVersion = settings['qbrickPlayer'].find((v) => !!v.enabled);
			}

			this.setState({ useNewEmbedSetting: embedVersion?.version === '2' });
			updateEnabledEmbed(embedVersion);
		});
	};

	toggleNewEmbedSetting = (event, checked) => {
		const { getAccountSettings, updateAccountSettings, defaultAccountId, showMessage, t, createAccountSettings } =
			this.props;

		getAccountSettings(defaultAccountId).then((settings) => {
			if (!settings) {
				const newSettings = {
					...(settings ?? {}),
					...NEW_EMBED_VERSION_2,
				};

				// Create qbrickPlayer setting if there's no settings
				createAccountSettings(defaultAccountId, newSettings)
					.then((data) => {
						if (!data) {
							showMessage(t('SETTING_NEW_EMBED_ACTIVATED_FAIL'), messageTypes.error);
							return;
						}

						showMessage(
							t(
								checked === true
									? 'SETTING_NEW_EMBED_ACTIVATED_SUCCESS'
									: 'SETTING_NEW_EMBED_DEACTIVATED_SUCCESS'
							),
							messageTypes.success
						);
						this.loadEmbedSetting();
					})
					.catch(() => showMessage(t('SETTING_NEW_EMBED_ACTIVATED_FAIL'), messageTypes.error));
				return;
			}

			let qbrickPlayerSetting = settings['qbrickPlayer'] ?? [];
			qbrickPlayerSetting = qbrickPlayerSetting.map((embedVersion) => ({
				...embedVersion,
				presets:
					embedVersion.presets && Object.keys(embedVersion.presets).length > 0
						? embedVersion.presets
						: undefined,
				enabled: embedVersion?.version === '2' ? checked : !checked,
			}));
			settings.qbrickPlayer = qbrickPlayerSetting;

			updateAccountSettings(defaultAccountId, settings)
				.then((data) => {
					if (!data) {
						showMessage(t('SETTING_NEW_EMBED_ACTIVATED_FAIL'), messageTypes.error);
						return;
					}

					showMessage(
						t(
							checked === true
								? 'SETTING_NEW_EMBED_ACTIVATED_SUCCESS'
								: 'SETTING_NEW_EMBED_DEACTIVATED_SUCCESS'
						),
						messageTypes.success
					);
					this.loadEmbedSetting();
				})
				.catch(() => showMessage(t('SETTING_NEW_EMBED_ACTIVATED_FAIL'), messageTypes.error));
		});
	};

	render() {
		const { loading, useNewEmbedSetting } = this.state;
		const { t, userPermissions } = this.props;

		if (loading) {
			return (
				<LoadingOverlay
					className="loadingStreamOverlay"
					active
					spinner={<ScaleLoader color={'#ffffff'} />}
					text={`${t('COMMON_LOADING')}...`}
				/>
			);
		}

		const hasQplayerEditorPermission = FeaturesService.IsEnabled(userPermissions, 'QbrickPlayerEditor');

		return (
			<div className="advancedSettings">
				<div className="advancedSettings__header">{t('SETTINGS_ADVANCED_SETTINGS')}</div>
				<div className="advancedSettings__content">
					<div className="advancedSettings__section">
						<div className="advancedSettings__section--title">{t('SETTING_NEW_EMBED_SETTING_TITLE')}</div>
						<FormControlLabel
							control={<Switch color={'primary'} checked={useNewEmbedSetting} />}
							label={t(
								hasQplayerEditorPermission
									? 'SETTING_NEW_EMBED_SETTING_LABEL'
									: 'SETTING_NEW_EMBED_SETTING_LABEL_DISABLED'
							)}
							onChange={this.toggleNewEmbedSetting}
							disabled={!hasQplayerEditorPermission}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
	userPermissions: session.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	getAccountSettings: (accountId) => dispatch(getAccountSettings(accountId)),
	updateAccountSettings: (accountId, data) => dispatch(updateAccountSettings(accountId, data)),
	createAccountSettings: (accountId, data) => dispatch(createAccountSettings(accountId, data)),
	updateEnabledEmbed: (enabledEmbed) => dispatch(updateEnabledEmbed(enabledEmbed)),
	setHasAdvancedSettings: (value) => dispatch(setHasAdvancedSettings(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvancedSettings));
