import React, { useState, useEffect } from 'react';
import Layout from './components/Layout';
import SectionLayout from './components/SectionLayout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@material-ui/core';
import ReuseTable from './components/ReuseTable';
import { socialColumns } from './../../constants/analyticsConstant';
import { useTranslation } from 'react-i18next';

const AnalyticsSocialScreen = () => {
	const { t } = useTranslation();
	const social = ['Overview', 'Facebook', 'YouTube'];
	const [loading, _setLoading] = useState(false);
	const [activeSocial, setActiveSocial] = useState(social[0]);
	const [facebookData, _setFacebookData] = useState({
		Id: 'platform1-analytics1',
		PlatformId: 'Platform1',
		SharesAnalytics: [],
		Status: 'Retrieved',
		Synchronization: 'UnSynchronized',
		Type: 'Facebook',
	});
	const [aciveTab, setAciveTab] = useState(0);

	useEffect(() => {}, []);

	const breadC = {
		subTitle: activeSocial ? activeSocial : 'Overview',
		text: 'Social media',
		link: null,
	};

	const topMetricsfun = () => {
		return (
			<SectionLayout title="ANALYTICS_TITLE_TOP_METRICS" addClass="topMetrics" loading={loading}>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_TOTAL_PUBLISHED_VIDEOS')}</Typography>
						<Typography className="paperTxt paperNum">0</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_TOTAL_VIEWS')}</Typography>
						<Typography className="paperTxt paperNum">0</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_TOTAL_WATCH_TIME')}</Typography>
						<Typography className="paperTxt paperNum">0</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_TOTAL_SHARES')}</Typography>
						<Typography className="paperTxt paperNum">0</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_TOTAL_LIKES')}</Typography>
						<Typography className="paperTxt paperNum">0</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_TOTAL_COMMENTS')}</Typography>
						<Typography className="paperTxt paperNum">0</Typography>
					</Paper>
				</Grid>
			</SectionLayout>
		);
	};

	return (
		<Layout title={t('ANALYTICS_SOCIAL_MEDIA_ANALYTICS')} breadCrumb={breadC}>
			<SectionLayout>
				<Grid item xs={12}>
					<AppBar position="static" className="analyticsTab">
						<Tabs
							value={aciveTab}
							onChange={(event, newValue) => {
								setAciveTab(newValue);
								setActiveSocial(social[newValue]);
							}}
							aria-label="tabs"
						>
							<Tab label={t('ANALYTICS_OVERVIEW')} {...a11yProps(0)} />
							<Tab label={t('ANALYTICS_FACEBOOK')} {...a11yProps(1)} />
							<Tab label={t('ANALYTICS_YOUTUBE')} {...a11yProps(2)} />
						</Tabs>
					</AppBar>
					<TabPanel value={aciveTab} index={0}>
						{topMetricsfun()}
					</TabPanel>
					<TabPanel value={aciveTab} index={1}>
						{topMetricsfun()}
						{activeSocial && (
							<SectionLayout title="Analytics" addClass="socialActiveSec" loading={loading}>
								<ReuseTable columns={socialColumns} data={facebookData.SharesAnalytics} />
							</SectionLayout>
						)}
					</TabPanel>
					<TabPanel value={aciveTab} index={2}>
						{topMetricsfun()}
						{activeSocial && (
							<SectionLayout title="Analytics" addClass="socialActiveSec" loading={loading}>
								<ReuseTable columns={socialColumns} data={facebookData.SharesAnalytics} />
							</SectionLayout>
						)}
					</TabPanel>
				</Grid>
			</SectionLayout>
		</Layout>
	);
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		'aria-controls': `tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography component="span">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default AnalyticsSocialScreen;
