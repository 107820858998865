import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

import arrow_down from '../../../../../../../assets/images/arrow-90deg-down.svg';

import {
	TRANSITION_FADE_IN,
	TRANSITION_FADE_OUT,
	sceneActions,
	sceneTransitionInEffects,
	sceneTransitionOutEffects,
} from '../../../../../../../constants/scenarioConstant';
import {
	updateEditingSceneAndSendToPlayer,
	updateScenesAction,
	updateScenarioAction,
	updateScene,
} from '../../../../../../../actions/scenarioActions';
import TimeInput from '../../../../../../../reusable/TimeInput/TimeInput';

import CardData from '../../../../../utils/CardData';
import { getDifferenceBetweenTwodates } from '../../../../../../../services/timeStampService';
import { generateUIId } from '../../../../../../../services/elementHelperService';

import { savingDebounceTime } from '../../../../../ScenarioMainChart';
import { Divider, FormControl } from '@material-ui/core';
import WidgetList from './widgetList/WidgetList';
import { generateUUID } from '../../../../../../../utils/commonUtil';
import { t } from 'i18next';

const AllLayersSettings = (props) => {
	const dispatch = useDispatch();
	const scenes = useSelector((state) => state.scenarioReducer.scenes);
	const scenario = useSelector((state) => state.scenarioReducer.scenario);
	const editingScene = useSelector((state) => state.scenarioReducer.editingScene);
	const duration = useSelector((state) => state.scenarioReducer.editingSceneDuration);
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const { t: translator } = useTranslation();
	const debounceTimer = useRef();

	const endOfSceneAction = useMemo(() => {
		if (!editingScene) {
			return 0;
		}

		const endOfScene = editingScene.events?.find((e) => e.type === 'endOfScene')?.actions[0];
		return !endOfScene ? 0 : endOfScene.type;
	}, [editingScene]);

	const goToSceneDestination = useMemo(() => {
		if (!editingScene) {
			return 0;
		}

		const endOfScene = editingScene.events?.find((e) => e.type === 'endOfScene');
		const goToScene = (endOfScene?.actions ?? []).find((action) => action.type === 'goToScene');
		return goToScene?.metadata?.sceneId || 'none';
	}, [editingScene, endOfSceneAction]);

	const currentLayout = useMemo(() => {
		let layout = editingScene?.layouts?.find((l) => l.type === props.activeLayout);
		return layout ?? editingScene?.layouts?.[0] ?? {};
	}, [props.activeLayout, editingScene]);

	const mapTemplateWidget = useMemo(() => {
		const widgetTemplates = editingScene?.widgetTemplates || [];
		return widgetTemplates.reduce((acc, curr) => {
			return {
				...acc,
				[curr.id]: curr,
			};
		}, {});
	}, [editingScene, currentLayout]);

	const widgets = useMemo(() => {
		const boxes = currentLayout.boxes || [];
		const listMappedWidgets = boxes.reduce((acc, curBox) => {
			const widgetsInBox = curBox.widgets || [];

			widgetsInBox.forEach((widget) => {
				const widgetTemplate = mapTemplateWidget[widget.widgetTemplateId];

				if (widgetTemplate) {
					acc.push({
						...widget,
						...widgetTemplate,
						id: widget.id,
						children: [],
					});
				}
			});
			return acc;
		}, []);

		const hashedWidgetsGroupByZIndex = listMappedWidgets.reduce((hashed, currentWidget) => {
			const {
				type,
				style: { zIndex },
			} = currentWidget;

			if (type === 'video' && zIndex === undefined) {
				hashed['video'] = [currentWidget];
				return hashed;
			}

			if (hashed[String(zIndex ?? 0)] === undefined) {
				hashed[String(zIndex ?? 0)] = [currentWidget];
			} else {
				hashed[String(zIndex ?? 0)].push(currentWidget);
			}

			return hashed;
		}, {});

		const sortedWidgetsByZIndex = Object.entries(hashedWidgetsGroupByZIndex)
			.sort(([keyA], [keyB]) => {
				if (keyA === 'video') {
					return 1;
				}

				if (keyB === 'video') {
					return -1;
				}

				return Number(keyB) - Number(keyA);
			})
			.map(([key, value]) => ({ id: generateUUID(), children: value, groupZIndex: key }));

		sortedWidgetsByZIndex.forEach(({ children }) => {
			if (children.length > 0) {
				children.sort((a, b) => {
					const aStart = moment(a.start, 'hh:mm:ss:SS');
					const bStart = moment(b.start, 'hh:mm:ss:SS');
					return aStart.isAfter(bStart) ? 1 : aStart.isBefore(bStart) ? -1 : 0;
				});
			}
		});

		return sortedWidgetsByZIndex;
	}, [editingScene, currentLayout]);

	const handleEndOfSceneActionChange = useCallback(
		(event) => {
			const { value } = event.target;
			const destinationSceneId =
				value === 'goToHome'
					? scenario.default?.scene
					: value === 'loopCurrentScene'
					? editingScene.id
					: undefined;
			if (
				!editingScene.events ||
				editingScene.events.length === 0 ||
				editingScene.events.filter((e) => e.type === 'endOfScene').length === 0
			) {
				// Add event handler
				editingScene.events = [
					...(editingScene.events ?? []),
					{
						id: generateUIId(),
						type: 'endOfScene',
						actions: [
							{
								type: value,
								metadata: { sceneId: destinationSceneId },
							},
						],
					},
				];
			} else {
				editingScene.events.forEach((e) => {
					if (e.type === 'endOfScene') {
						e.actions = [
							{
								type: value,
								metadata: { sceneId: destinationSceneId },
							},
						];
					}
					return e;
				});
			}

			dispatch(updateEditingSceneAndSendToPlayer(JSON.parse(JSON.stringify(editingScene))));
			disPatchUpdatedScene(editingScene, 'events');
		},
		[editingScene]
	);

	const handleDestinationSceneChange = useCallback(
		(event) => {
			const endOfScene = editingScene.events.find((e) => e.type === 'endOfScene');
			if (endOfScene) {
				endOfScene.actions = (endOfScene.actions ?? []).map((action) =>
					action.type === 'goToScene' ? { ...action, metadata: { sceneId: event.target.value } } : action
				);
				editingScene.events = editingScene.events.map((e) => (e.type === 'endOfScene' ? { ...endOfScene } : e));

				dispatch(updateEditingSceneAndSendToPlayer(JSON.parse(JSON.stringify(editingScene))));
				disPatchUpdatedScene(editingScene, 'events');
			}
		},
		[editingScene]
	);

	const handleSceneDurationChange = useCallback((value) => {
		dispatch(
			updateEditingSceneAndSendToPlayer({
				...editingScene,
				duration: { ...(editingScene.duration ?? {}), value: value },
			})
		);
	}, []);

	const handleSceneNameChange = useCallback(
		(event) => {
			const updatedScene = { ...editingScene, name: event.target.value };
			dispatch(updateEditingSceneAndSendToPlayer(updatedScene));
			disPatchUpdatedScene(updatedScene, 'name');
		},
		[editingScene]
	);

	const onDuplicateWidget = (e, widget, allowToDuplicate) => {
		e.stopPropagation();
		allowToDuplicate && props.onDuplicateWidget?.(widget);
	};

	const onRemoveWidget = (e, widget, allowToRemove) => {
		e.stopPropagation();
		allowToRemove && props.onRemoveWidget?.(widget);
	};

	const disPatchUpdatedScene = (updatedScene, propertyToSaveToApi) => {
		const newScenes = scenes.map((scene) => (scene.id === updatedScene.id ? updatedScene : scene));

		//ui update
		dispatch(updateScenesAction(newScenes));
		dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes }));

		//save to api
		debouncedSaving(() => {
			const { scenarioContainerRef } = props;
			const { id: scenarioId } = scenario;
			const { id: sceneId } = updatedScene;
			const updateBody = {
				[propertyToSaveToApi]: updatedScene[propertyToSaveToApi],
			};

			scenarioContainerRef.current?.isAutoSaving(true);
			dispatch(updateScene(accountId, scenarioId, sceneId, updateBody)).then((resolve) => {
				if (resolve) {
					scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		}, savingDebounceTime);
	};

	const debouncedSaving = (func, timeout = 300) => {
		clearTimeout(debounceTimer.current);
		debounceTimer.current = setTimeout(func, timeout);
	};

	const handleTransitionChange = useCallback(
		(type, value) => {
			if (!editingScene) {
				return;
			}

			const {
				metadata: { transition },
			} = editingScene;

			const newTransitionValue = transition ? { ...transition, [type]: value } : { [type]: value };

			if (!transition || transition[type] !== newTransitionValue[type]) {
				const newScene = {
					...editingScene,
					metadata: { ...editingScene.metadata, transition: newTransitionValue },
				};
				dispatch(updateEditingSceneAndSendToPlayer(newScene));
				disPatchUpdatedScene(newScene, 'metadata');
			}
		},
		[editingScene]
	);

	if (!editingScene) {
		return null;
	}

	const transitionEffect = editingScene.metadata.transition ?? {};
	const { in: transitionIn, out: transitionOut } = transitionEffect;

	return (
		<>
			<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_GENERAL_SETTINGS')}>
				<FormControlLabel
					className={'scenario-chart__control'}
					control={<TextField variant="standard" value={editingScene.name} />}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_GENERAL_SETTINGS_SCENE_NAME')}
					onChange={handleSceneNameChange}
					labelPlacement="start"
				/>

				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_GENERAL_SETTINGS_END_SCENE_ACTION')}
					onChange={handleEndOfSceneActionChange}
					labelPlacement="start"
					control={
						<Select variant="standard" value={endOfSceneAction}>
							<MenuItem key={0} value={0}>
								-{translator('COMMON_CHOOSE')}-
							</MenuItem>
							{sceneActions.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{translator(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				{scenes && endOfSceneAction === 'goToScene' && scenes.length > 0 && (
					<FormControlLabel
						className={'scenario-chart__control'}
						label={<img src={arrow_down} />}
						labelPlacement="start"
						control={
							<FormControl>
								<Select
									variant="standard"
									value={goToSceneDestination}
									onChange={handleDestinationSceneChange}
								>
									<MenuItem key={0} value={'none'}>
										-Choose-
									</MenuItem>
									{scenes.map((scene) => (
										<MenuItem key={scene.id} value={scene.id}>
											{scene.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
					/>
				)}

				<FormControlLabel
					className={'scenario-chart__control'}
					control={
						<div className="scene-widgets__sort-input-control">
							<TimeInput
								readonly={!editingScene.duration?.enabled}
								max={24}
								value={
									editingScene.duration?.value ??
									(duration ? getDifferenceBetweenTwodates(duration.end, duration.start) : '')
								}
								onChange={handleSceneDurationChange}
							/>
						</div>
					}
					label={t('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_GENERAL_SETTINGS_SCENE_DURATION')}
					onChange={() => {}}
					labelPlacement="start"
				/>

				<Divider className="divider-extra-space" />

				<div className={`scenario-chart__control scenario-chart__label scenario-chart__label--bold`}>
					{translator('SCENARIO_STORYBOARD_SCENE_SETTING_TRANSITION_EFFECT')}
				</div>

				<FormControlLabel
					className={`scenario-chart__control`}
					labelPlacement="start"
					label={translator('SCENARIO_STORYBOARD_SETTING_TRANSITION_EFFECT_TRANSITION_IN')}
					onChange={(event) => {
						handleTransitionChange('in', event.target.value);
					}}
					control={
						<Select variant="standard" value={transitionIn ?? TRANSITION_FADE_IN.value}>
							{sceneTransitionInEffects.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{translator(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				<FormControlLabel
					className={`scenario-chart__control`}
					labelPlacement="start"
					label={translator('SCENARIO_STORYBOARD_SCENE_SETTING_TRANSITION_EFFECT_TRANSITION_OUT')}
					onChange={(event) => {
						handleTransitionChange('out', event.target.value);
					}}
					control={
						<Select variant="standard" value={transitionOut ?? TRANSITION_FADE_OUT.value}>
							{sceneTransitionOutEffects.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{translator(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
			</CardData>

			<CardData
				title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_GENERAL_SETTINGS_LAYER_NAVIGATOR')}
				noPadding
			>
				<WidgetList
					widgets={widgets}
					editingWidget={props.editingWidget}
					currentLayout={currentLayout}
					onDuplicateWidget={onDuplicateWidget}
					onRemoveWidget={onRemoveWidget}
					onWidgetClick={props.onWidgetClick}
				/>
				<Box style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px', paddingBottom: '15px' }}>
					<Button variant="outlined" startIcon={<Add />} color="primary" onClick={props.onAddWidgetClick}>
						{translator('SCENARIO_SCENE_EDITOR_ADD_WIDGET_MODAL_TITLE')}
					</Button>
				</Box>
			</CardData>
		</>
	);
};

export default AllLayersSettings;
