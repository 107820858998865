import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as H from 'history';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { FormControl, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import { screenRoutes } from '../../../constants/routesPath';
import clear_hover_icon from '../../../assets/images/clear-hover.svg';
import picker_icon from '../../../assets/images/cal-picker.svg';
import clear_icon from '../../../assets/images/clear.svg';
import TagsControlLibrary from '../../../reusable/TagsControl/TagsControlLibrary';
import { joinClassNames } from '../../../services/elementHelperService';
import {
	clearSearchTags,
	fetchTheSelectedMediaSearchFilterQuery,
	handleUpdateSearchFilter,
	setSearchTags,
	updateSearchTags,
} from '../../../actions/searchActions';
import { getLastIndexSmartFilterId } from '../../../services/locationPathServices';
import { NewSearchFilterDialogBox } from '../NewSearchFilterDialogBox';
import { showMessage } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';

import './AdvancedSearchArea.scss';

export const SEARCH_CONDITION = {
	relatedMatch: 'related-match',
	exactKeyword: 'exact-keyword',
	bestMatch: 'best-match',
};

interface AdvancedSearchAreaProps {
	history: H.History;
	searchQuery: string;
	advancedSearchAreaRef?: (_: HTMLElement | null) => void;
	onSaveNewSearchFilter?: (_filterId: string) => void;
	onSaveFilterAsPlaylistClick?: () => void;
	handleSearch: (_searchQuery: string, _searchWithinValue: string) => void;
	onLoadSelectedSmartFilter?: (_data: any, _searchWithinValue: string) => void;
}

const AdvancedSearchArea = forwardRef(
	(
		{
			history,
			searchQuery,
			advancedSearchAreaRef,
			onSaveNewSearchFilter,
			onSaveFilterAsPlaylistClick,
			handleSearch: handleSearchProp,
			onLoadSelectedSmartFilter,
		}: AdvancedSearchAreaProps,
		ref
	) => {
		const { t } = useTranslation();
		const dispatch = useDispatch() as any;

		const { predefinedFilters } = useSelector((state) => (state as any).publish);
		const { defaultAccountId } = useSelector((state) => (state as any).session);
		const { tags } = useSelector((state) => (state as any).search);

		const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
		const [showNewFilterDialogBoxStatus, setShowNewFilterDialogBoxStatus] = useState(false);
		const [createdFromDateState, setCreatedFromDate] = useState<any | null>(null);
		const [createdToDateState, setCreatedToDate] = useState<any | null>(null);
		const [updatedFromDateState, setUpdatedFromDate] = useState<any | null>(null);
		const [updatedToDateState, setUpdatedToDate] = useState<any | null>(null);
		const [selectedFilter, setSelectedFilter] = useState<any | null>(null);
		const [searchFiltersMenuAnchor, setSearchFiltersMenuAnchor] = useState<any | null>(null);
		const [saveAsMenuAnchor, setSaveAsMenuAnchor] = useState<any | null>(null);
		const [inputTagsState, setInputTags] = useState<string[]>([]);
		const [searchWithinValue, setSearchWithinValue] = useState(SEARCH_CONDITION.bestMatch);

		const onDetailsChange = useCallback(
			(tag: string) => {
				dispatch(updateSearchTags(tag));
			},
			[dispatch]
		);

		const isAllowSavingFilter = useCallback(() => {
			const {
				location: { pathname },
			} = history;

			return (
				!!pathname.match(screenRoutes.PUBLISH_ALL_MEDIA) || !!pathname.match(screenRoutes.PUBLISH_SMART_FILTER)
			);
		}, [history]);

		const clearSearch = useCallback(() => {
			setCreatedFromDate(null);
			setCreatedToDate(null);
			setUpdatedFromDate(null);
			setUpdatedToDate(null);
			setSelectedFilter(null);
			setIsAdvancedSearchOpen(false);
			setSearchWithinValue(SEARCH_CONDITION.bestMatch);
			setInputTags([]);
			setSearchFiltersMenuAnchor(null);
			dispatch(clearSearchTags());
		}, [dispatch]);

		const handleFilterChange = useCallback(
			(filter?: any) => {
				if (!filter) {
					clearSearch();
					history.push(screenRoutes.PUBLISH_ALL_MEDIA);
					return;
				}

				setSearchFiltersMenuAnchor(null);
				setSelectedFilter(filter);
				history.push(screenRoutes.PUBLISH_SMART_FILTER + '/' + filter.id);
			},
			[clearSearch, history]
		);

		const handleUpdateSearchFilterAction = useCallback(() => {
			dispatch(
				handleUpdateSearchFilter({ ...selectedFilter, q: searchQuery }, selectedFilter?.id, defaultAccountId)
			).then((data: any) => {
				if (!data) {
					return;
				}
				dispatch(
					showMessage(t('MEDIA_LIBRARY_ADVANCED_SEARCH_EXISTING_FILTER_UPDATED_LABEL'), messageTypes.success)
				);
			});
		}, [defaultAccountId, dispatch, searchQuery, selectedFilter, t]);

		const handleSearch = useCallback(
			(callback?: () => void) => {
				let searchQuery = '';

				if (tags.length > 0) {
					searchQuery += encodeURIComponent(' AND tags') + ':' + '(';
					if (Array.isArray(tags) && tags.length >= 1) {
						tags.map(
							(eachTag, i) =>
								(searchQuery +=
									i === tags.length - 1
										? encodeURIComponent('"' + eachTag + '"')
										: encodeURIComponent('"' + eachTag + '" '))
						);
					} else {
						searchQuery += decodeURIComponent(tags);
					}
					searchQuery += ')';
				}

				// CREATE FROM date TO date
				if (createdFromDateState || createdToDateState) {
					if (createdFromDateState && createdToDateState) {
						const createdDateFrom = new Date(createdFromDateState);
						const createdToDate = new Date(createdToDateState);
						if (createdDateFrom <= createdToDate) {
							searchQuery +=
								encodeURIComponent(' AND ') +
								'created:[' +
								createdDateFrom.getFullYear() +
								'-' +
								('0' + (createdDateFrom.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + createdDateFrom.getDate()).slice(-2) +
								' TO ' +
								createdToDate.getFullYear() +
								'-' +
								('0' + (createdToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + createdToDate.getDate()).slice(-2) +
								']';
						}
					} else if (createdFromDateState && !createdToDateState) {
						const createdDateFrom = new Date(createdFromDateState);
						const createdToDate = new Date();
						if (createdDateFrom <= createdToDate) {
							searchQuery +=
								encodeURIComponent(' AND ') +
								'created:[' +
								createdDateFrom.getFullYear() +
								'-' +
								('0' + (createdDateFrom.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + createdDateFrom.getDate()).slice(-2) +
								' TO ' +
								createdToDate.getFullYear() +
								'-' +
								('0' + (createdToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + createdToDate.getDate()).slice(-2) +
								']';
						}
					} else if (!createdFromDateState && createdToDateState) {
						const today = new Date();
						const createdToDate = new Date(createdToDateState);
						if (createdToDate <= today) {
							searchQuery +=
								encodeURIComponent(' AND ') +
								'created:[' +
								createdToDate.getFullYear() +
								'-' +
								('0' + (createdToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + createdToDate.getDate()).slice(-2) +
								' TO ' +
								createdToDate.getFullYear() +
								'-' +
								('0' + (createdToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + createdToDate.getDate()).slice(-2) +
								']';
						}
					}
				}

				// UPDATE FROM date TO date
				if (updatedFromDateState || updatedToDateState) {
					if (updatedFromDateState && updatedToDateState) {
						const updatedFromDate = new Date(updatedFromDateState);
						const updatedToDate = new Date(updatedToDateState);
						if (updatedFromDate <= updatedToDate) {
							searchQuery +=
								encodeURIComponent(' AND ') +
								'updated:[' +
								updatedFromDate.getFullYear() +
								'-' +
								('0' + (updatedFromDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + updatedFromDate.getDate()).slice(-2) +
								' TO ' +
								updatedToDate.getFullYear() +
								'-' +
								('0' + (updatedToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + updatedToDate.getDate()).slice(-2) +
								']';
						}
					} else if (updatedFromDateState && !updatedToDateState) {
						const updatedFromDate = new Date(updatedFromDateState);
						const updatedToDate = new Date();
						if (updatedFromDate <= updatedToDate) {
							searchQuery +=
								encodeURIComponent(' AND ') +
								'updated:[' +
								updatedFromDate.getFullYear() +
								'-' +
								('0' + (updatedFromDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + updatedFromDate.getDate()).slice(-2) +
								' TO ' +
								updatedToDate.getFullYear() +
								'-' +
								('0' + (updatedToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + updatedToDate.getDate()).slice(-2) +
								']';
						}
					} else if (!updatedFromDateState && updatedToDateState) {
						const today = new Date();
						const updatedToDate = new Date(updatedToDateState);
						if (updatedToDate <= today) {
							searchQuery +=
								encodeURIComponent(' AND ') +
								'updated:[' +
								updatedToDate.getFullYear() +
								'-' +
								('0' + (updatedToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + updatedToDate.getDate()).slice(-2) +
								' TO ' +
								updatedToDate.getFullYear() +
								'-' +
								('0' + (updatedToDate.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + updatedToDate.getDate()).slice(-2) +
								']';
						}
					}
				}

				handleSearchProp(searchQuery, searchWithinValue ?? SEARCH_CONDITION.bestMatch);
				callback?.();
			},
			[
				createdFromDateState,
				createdToDateState,
				handleSearchProp,
				searchWithinValue,
				tags,
				updatedFromDateState,
				updatedToDateState,
			]
		);

		const loadSearchDataFromSmartFilter = useCallback(
			(selectedFilterId?: string) => {
				const filterId = selectedFilterId !== undefined ? selectedFilterId : getLastIndexSmartFilterId();
				if (!filterId) {
					return;
				}

				dispatch(fetchTheSelectedMediaSearchFilterQuery(defaultAccountId, filterId)).then((data: any) => {
					if (data && data.q) {
						data.q = decodeURIComponent(data.q);
						let searchKeyword = data.q.split('AND')[0].trim().split('=')[1];
						let newSearchWithinValue = SEARCH_CONDITION.bestMatch;
						if (searchKeyword && searchKeyword.length > 0) {
							newSearchWithinValue =
								searchKeyword.charAt(searchKeyword.length - 1) === '*'
									? SEARCH_CONDITION.bestMatch
									: searchKeyword.length > 1 &&
									  searchKeyword.charAt(0) === '"' &&
									  searchKeyword.charAt(searchKeyword.length - 1) === '"'
									? SEARCH_CONDITION.exactKeyword
									: SEARCH_CONDITION.relatedMatch;
						}

						let createdFromDate = null;
						let createdToDate = null;
						if (data.q.includes('created')) {
							let createdDates = data.q.split('[');
							createdFromDate = createdDates[1]?.split(' ')[0];
							createdToDate = createdDates[1]?.split(' ')[2]?.split(']')[0];
						}

						let updatedFromDate = null;
						let updatedToDate = null;
						if (data.q.includes('updated')) {
							let updatedDates = data.q.split('[');
							updatedFromDate = updatedDates[2]?.split(' ')[0];
							updatedToDate = updatedDates[2]?.split(' ')[2]?.split(']')[0];
						}

						let inputTags = [];
						if (data.q.includes('tags')) {
							data.q = data.q.replace(/['"]+/g, '');
							inputTags = data.q.split('tags:(')[1]?.split(')')[0]?.split(' ');
						}

						onLoadSelectedSmartFilter?.(data, newSearchWithinValue);
						dispatch(setSearchTags(inputTags));

						setSelectedFilter({ ...data, id: filterId, name: data.name });
						setIsAdvancedSearchOpen(true);
						setSearchWithinValue(newSearchWithinValue);
						setInputTags(inputTags);
						setCreatedFromDate(createdFromDate);
						setCreatedToDate(createdToDate);
						setUpdatedFromDate(updatedFromDate);
						setUpdatedToDate(updatedToDate);

						handleSearch();
					}
				});
			},
			[defaultAccountId, dispatch, handleSearch, onLoadSelectedSmartFilter]
		);

		const getAdvancedOpenValue = () => isAdvancedSearchOpen;

		useImperativeHandle(ref, () => ({
			isAdvancedSearchOpen: getAdvancedOpenValue,
			clearSearch: clearSearch,
			executeSearch: handleSearch,
			toggleAdvancedSearch: (openAdvancedSearch = undefined) => {
				setIsAdvancedSearchOpen((prev) => (openAdvancedSearch !== undefined ? openAdvancedSearch : !prev));
			},
		}));

		useEffect(loadSearchDataFromSmartFilter, [loadSearchDataFromSmartFilter]);

		return isAdvancedSearchOpen ? (
			<>
				<div ref={advancedSearchAreaRef} className="advancedSearchOptions">
					<div className="advancedSearchSection">
						<div className="search-options-block_1">
							<div>
								<label className="main_label">{t('MEDIA_LIBRARY_SIDEBAR_SEARCH_FILTERS_LABEL')}</label>
							</div>
							<div
								id="searchFiltersDropdown"
								className="searchFiltersDropDownWrapper"
								onClick={(e) => {
									e.stopPropagation();
									setSearchFiltersMenuAnchor(e.currentTarget);
								}}
							>
								<div className="searchFiltersDropdownInternalWrapper">
									<span className="input-icon">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15.586"
											height="12.98"
											viewBox="0 0 15.586 12.98"
										>
											<g
												id="Group_7735"
												data-name="Group 7735"
												transform="translate(-952.419 -532.332)"
											>
												<g
													id="Group_7691"
													data-name="Group 7691"
													transform="translate(958.611 535.918)"
												>
													<path
														id="Path_1160"
														data-name="Path 1160"
														d="M967.7,544.369a.586.586,0,0,1,.83,0h0l2.26,2.26a.587.587,0,0,1-.83.831h0l-2.26-2.26a.587.587,0,0,1,0-.831h0Z"
														transform="translate(-961.565 -538.238)"
													/>
													<path
														id="Path_1161"
														data-name="Path 1161"
														d="M963.932,543.835a3.229,3.229,0,1,0-3.229-3.229,3.229,3.229,0,0,0,3.229,3.229Zm3.816-3.229a3.816,3.816,0,1,1-3.816-3.816,3.816,3.816,0,0,1,3.816,3.816Z"
														transform="translate(-960.116 -536.79)"
													/>
												</g>
												<g
													id="Group_7692"
													data-name="Group 7692"
													transform="translate(952.419 532.332)"
												>
													<path
														id="Path_9760"
														data-name="Path 9760"
														d="M958.452,542.017v.08l-1.609.536v-4.268a.4.4,0,0,0-.1-.269l-3.517-3.908v-1.052h8.849v1.052l-1.261,1.4a3.677,3.677,0,0,1,.38-.039,3.816,3.816,0,0,1,.676.068l.907-1.008a.4.4,0,0,0,.1-.269v-1.609a.4.4,0,0,0-.4-.4h-9.653a.4.4,0,0,0-.4.4v1.609a.4.4,0,0,0,.1.269l3.517,3.908v4.672a.4.4,0,0,0,.529.381l2.413-.8a.392.392,0,0,0,.206-.167A3.833,3.833,0,0,1,958.452,542.017Z"
														transform="translate(-952.419 -532.332)"
													/>
												</g>
											</g>
										</svg>
									</span>
									<button id="searchFiltersMenuButton" className="searchFiltersMenu">
										<label>
											{selectedFilter
												? selectedFilter.name
												: t('MEDIA_LIBRARY_ADVANCED_SEARCH_FILTER_CHOOSE_A_FILTER')}
										</label>
										<IconsStore iconName={IconsList.EXPANDED} fill={'#000'} />
									</button>
								</div>
							</div>
						</div>

						<div className="search-options-block_2">
							<div>
								<label className="main_label">{t('MEDIA_LIBRARY_ADVANCED_SEARCH_SEARCH_WITHIN')}</label>
							</div>
							<FormControl fullWidth>
								<RadioGroup
									value={searchWithinValue}
									name="search-radio-buttons-group"
									onChange={(e) => setSearchWithinValue(e.target.value)}
								>
									<FormControlLabel
										value={SEARCH_CONDITION.bestMatch}
										control={<Radio style={{ color: '#126CFC' }} />}
										label={
											<span>
												{t(
													'MEDIA_LIBRARY_ADVANCED_SEARCH_SEARCH_CONDITION_BEST_POSSIBLE_MATCH'
												)}
											</span>
										}
									/>
									<FormControlLabel
										value={SEARCH_CONDITION.exactKeyword}
										control={<Radio style={{ color: '#126CFC' }} />}
										label={
											<span>
												{t('MEDIA_LIBRARY_ADVANCED_SEARCH_SEARCH_CONDITION_EXACT_KEYWORD')}
											</span>
										}
									/>
									<FormControlLabel
										value={SEARCH_CONDITION.relatedMatch}
										control={<Radio style={{ color: '#126CFC' }} />}
										label={
											<span>
												{t(
													'MEDIA_LIBRARY_ADVANCED_SEARCH_SEARCH_CONDITION_EVERY_RELATED_MATCH'
												)}
											</span>
										}
									/>
								</RadioGroup>
							</FormControl>
						</div>

						<div className="search-options-block_3">
							<div>
								<label className="main_label">
									{t('MEDIA_LIBRARY_ADVANCED_SEARCH_CREATED_FROM_TO_LABEL')}
								</label>
							</div>
							<div className="fromToDateWrappersInternal">
								<div
									className={joinClassNames([
										'DatePickerInternalWrapper',
										`${createdFromDateState ? 'extraPaddingLeftDatePickersWrapper1' : ''}`,
									])}
								>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DatePicker
											clearable
											inputVariant="filled"
											margin="normal"
											format="yyyy-MM-dd"
											onChange={(date) => setCreatedFromDate(date)}
											value={createdFromDateState && createdFromDateState}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton>
															<img src={picker_icon} />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
									<span
										className={joinClassNames([
											'datePickerClear',
											`${createdFromDateState ? '' : 'hide'}`,
										])}
									>
										<img
											src={clear_icon}
											onMouseOver={(e) => (e.currentTarget.src = clear_hover_icon)}
											onMouseOut={(e) => (e.currentTarget.src = clear_icon)}
											onClick={() => setCreatedFromDate(null)}
										/>
									</span>
								</div>

								<div
									className={joinClassNames([
										'DatePickerInternalWrapper',
										`${createdFromDateState ? 'extraPaddingLeftDatePickersWrapper1' : ''}`,
									])}
								>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DatePicker
											clearable
											inputVariant="filled"
											margin="normal"
											format="yyyy-MM-dd"
											onChange={(date) => setCreatedToDate(date)}
											value={createdToDateState && createdToDateState}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton>
															<img src={picker_icon} />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
									<span
										className={joinClassNames([
											'datePickerClear',
											`${createdToDateState ? '' : 'hide'}`,
										])}
									>
										<img
											src={clear_icon}
											onMouseOver={(e) => (e.currentTarget.src = clear_hover_icon)}
											onMouseOut={(e) => (e.currentTarget.src = clear_icon)}
											onClick={() => setCreatedToDate(null)}
										/>
									</span>
								</div>
							</div>
							<div>
								<label className="main_label">
									{t('MEDIA_LIBRARY_ADVANCED_SEARCH_UPDATED_FROM_TO_LABEL')}
								</label>
							</div>
							<div className="fromToDateWrappersInternal">
								<div
									className={joinClassNames([
										'DatePickerInternalWrapper',
										`${updatedFromDateState ? 'extraPaddingLeftDatePickersWrapper1' : ''}`,
									])}
								>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DatePicker
											clearable
											inputVariant="filled"
											margin="normal"
											format="yyyy-MM-dd"
											onChange={(date) => setUpdatedFromDate(date)}
											value={updatedFromDateState && updatedFromDateState}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton>
															<img src={picker_icon} />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
									<span
										className={joinClassNames([
											'datePickerClear',
											`${updatedFromDateState ? '' : 'hide'}`,
										])}
									>
										<img
											src={clear_icon}
											onMouseOver={(e) => (e.currentTarget.src = clear_hover_icon)}
											onMouseOut={(e) => (e.currentTarget.src = clear_icon)}
											onClick={() => setUpdatedFromDate(null)}
										/>
									</span>
								</div>

								<div
									className={joinClassNames([
										'DatePickerInternalWrapper',
										`${updatedToDateState ? 'extraPaddingLeftDatePickersWrapper1' : ''}`,
									])}
								>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DatePicker
											clearable
											inputVariant="filled"
											margin="normal"
											format="yyyy-MM-dd"
											onChange={(date) => setUpdatedToDate(date)}
											value={updatedToDateState && updatedToDateState}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton>
															<img src={picker_icon} />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
									<span
										className={joinClassNames([
											'datePickerClear',
											`${updatedToDateState ? '' : 'hide'}`,
										])}
									>
										<img
											src={clear_icon}
											onMouseOver={(e) => (e.currentTarget.src = clear_hover_icon)}
											onMouseOut={(e) => (e.currentTarget.src = clear_icon)}
											onClick={() => setUpdatedToDate(null)}
										/>
									</span>
								</div>
							</div>
						</div>

						<div className="search-options-block_4">
							<div>
								<label className="main_label">{t('MEDIA_LIBRARY_ADVANCED_SEARCH_TAGS')}</label>
							</div>
							<TagsControlLibrary t={t} displayTags={inputTagsState} onDetailsChange={onDetailsChange} />
						</div>
					</div>

					<div className="advancedSearchActionSection">
						<div className="advancedSearchActions">
							{isAllowSavingFilter() && (
								<button
									className="MuiButtonBase-root MuiButton-root MuiButton-contained defaultActionBtn squireBtn save-btn"
									tabIndex={0}
									type="button"
									onClick={(e) => setSaveAsMenuAnchor(e.currentTarget)}
								>
									<span className="MuiButton-label">
										<label className="save-label">{t('COMMON_SAVE_AS')}</label>
										<IconsStore
											iconName={IconsList.EXPANDED_WHITE}
											fill={'#FFF'}
											width={'8px'}
											height={'8px'}
										/>
									</span>
									<span className="MuiTouchRipple-root" />
								</button>
							)}
							<Button className="defaultActionBtn search-btn" onClick={() => handleSearch()}>
								Search
							</Button>
						</div>
					</div>
				</div>

				<Menu
					id="searchFiltersMenuSystem"
					getContentAnchorEl={null}
					anchorEl={searchFiltersMenuAnchor}
					onClose={() => setSearchFiltersMenuAnchor(null)}
					open={!!searchFiltersMenuAnchor}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					PaperProps={{ style: { width: searchFiltersMenuAnchor?.getBoundingClientRect().width } }}
				>
					{predefinedFilters && (
						<>
							<MenuItem
								className="listMenuItem"
								disabled={selectedFilter === null}
								key={'empty'}
								value={''}
								onClick={(e) => {
									e.stopPropagation();
									handleFilterChange(null);
								}}
							>
								{t('MEDIA_LIBRARY_ADVANCED_SEARCH_FILTER_CHOOSE_A_FILTER')}
							</MenuItem>
							{predefinedFilters.map((filter: any, i: number) => (
								<MenuItem
									className="listMenuItem"
									selected={selectedFilter?.id === filter.id}
									key={i}
									value={filter.id}
									onClick={(e) => {
										e.stopPropagation();
										handleFilterChange(filter);
									}}
								>
									{filter.name}
								</MenuItem>
							))}
						</>
					)}
				</Menu>

				<Menu
					id="saveAsMenuSystem"
					anchorEl={saveAsMenuAnchor}
					open={!!saveAsMenuAnchor}
					onClose={() => setSaveAsMenuAnchor(null)}
				>
					{selectedFilter && selectedFilter.id && (
						<MenuItem
							key="1"
							onClick={() => {
								setSaveAsMenuAnchor(null);
								handleSearch(handleUpdateSearchFilterAction);
							}}
						>
							<label>{t('MEDIA_LIBRARY_ADVANCED_SEARCH_UPDATE_SMART_FILTER_LABEL')}</label>
						</MenuItem>
					)}
					<MenuItem
						key="12"
						onClick={() => {
							setSaveAsMenuAnchor(null);
							setShowNewFilterDialogBoxStatus(true);
							handleSearch();
						}}
					>
						<label>{t('MEDIA_LIBRARY_ADVANCED_SEARCH_SEARCH_FILTER_LABEL')}</label>
					</MenuItem>
					<MenuItem
						key="13"
						onClick={() => {
							setSaveAsMenuAnchor(null);
							handleSearch(onSaveFilterAsPlaylistClick);
						}}
					>
						<label>{t('MEDIA_LIBRARY_ADVANCED_SEARCH_DYNAMIC_PLAYLIST_LABEL')}</label>
					</MenuItem>
				</Menu>

				<NewSearchFilterDialogBox
					open={showNewFilterDialogBoxStatus}
					searchQuery={searchQuery}
					onSaveNewSearchFilter={(filterId) => {
						onSaveNewSearchFilter?.(filterId);
						setShowNewFilterDialogBoxStatus(false);
					}}
					onClose={() => setShowNewFilterDialogBoxStatus(false)}
				/>
			</>
		) : null;
	}
);

export default AdvancedSearchArea;
