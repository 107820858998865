import { Archive, Close, Unarchive } from '@material-ui/icons';
import React from 'react';
import { getFormattedTimeWithMilliseconds } from '../../../../services/timeStampService';
import { Button, IconButton } from '@material-ui/core';

import Icons from '../../../../reusable/IconsStore/Icons';
import { joinClassNames } from '../../../../services/elementHelperService';
import { useTranslation } from 'react-i18next';

export namespace QuestionAndAnswerTemplate {
	export const qnaBaseClassName = 'qna-container';
	export const TAB = {
		review: 'review',
		approved: 'approved',
		answered: 'answered',
		archive: 'archive',
	};
	export const TabItems = [
		{
			label: 'COMMON_REVIEW',
			value: TAB.review,
		},
		{
			label: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_APPROVED',
			value: TAB.approved,
		},
		{
			label: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWERED',
			value: TAB.answered,
		},
		{
			label: <Archive />,
			value: TAB.archive,
		},
	];
	interface ReviewPanelProps {
		questions?: Question[];
		selectedTab?: string;
	}

	export const ReviewPanel: React.FC<ReviewPanelProps> = ({ questions = [], selectedTab }) => {
		const { t } = useTranslation();

		return (
			<>
				{questions.length <= 0 && (
					<div className={`${qnaBaseClassName}__question--message`}>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_NO_QUESTION_MESSAGE')}
					</div>
				)}
				{questions.length > 0 &&
					questions.map((question) => (
						<Question
							key={question.id}
							question={question}
							approved={selectedTab === TAB.approved}
							answered={selectedTab === TAB.answered}
							archive={selectedTab === TAB.archive}
						/>
					))}
			</>
		);
	};

	export interface Question {
		id: string;
		message: { text: string; pinned?: boolean };
		sender: { name: string; admin?: boolean };
		recipient: { replyMessageId?: string };
		created: string;
		isAnswered?: boolean;
	}

	export interface QuestionProps {
		question: Question;
		approved?: boolean;
		answered?: boolean;
		archive?: boolean;
		isAnswering?: boolean;
	}

	export const Question: React.FC<QuestionProps> = ({ question, approved, answered, archive, isAnswering }) => {
		const { t } = useTranslation();

		return (
			<div
				className={joinClassNames(
					`${qnaBaseClassName}__question`,
					isAnswering ? `${qnaBaseClassName}__question--answering` : ''
				)}
			>
				<div className={`${qnaBaseClassName}__question-title`}>
					<div>
						<span className={`${qnaBaseClassName}__question-sender`}>
							{isAnswering && (
								<>
									{`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWERING')}`}{' '}
									<b>{question.sender?.name}</b>:
								</>
							)}
							{!isAnswering && <b>{question.sender?.name}</b>}
						</span>

						{!isAnswering && (
							<span className={`${qnaBaseClassName}__question-time`}>
								{getFormattedTimeWithMilliseconds(question.created, 'HH:mm:ss')}
							</span>
						)}
					</div>
					{isAnswering && (
						<IconButton
							className={`${qnaBaseClassName}__question-more-btn`}
							size="small"
							onClick={() => {}}
							title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STOP_ANSWER')}
						>
							<Close />
						</IconButton>
					)}
				</div>

				<div className={`${qnaBaseClassName}__question-content`}>{question.message.text}</div>

				{!isAnswering && (
					<div className={`${qnaBaseClassName}__question-actions`}>
						{!approved && !answered && !archive && (
							<>
								<IconButton
									title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ARCHIVE')}
									className={`${qnaBaseClassName}__question--archive-btn`}
								>
									<Archive />
								</IconButton>

								<Button variant="contained" color="primary" startIcon={<Icons.Recommended />}>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_APPROVE')}
								</Button>
							</>
						)}

						{approved && (
							<>
								<span className={`${qnaBaseClassName}__question-rank`}>
									<Icons.Recommended color="#126CFC" /> 10
								</span>

								<Button variant="contained" color="primary" disabled={question.isAnswered}>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ANSWER')}
								</Button>
							</>
						)}

						{answered && (
							<>
								<span className={`${qnaBaseClassName}__question-rank`}>
									<Icons.Recommended color="#126CFC" /> 10
								</span>

								<IconButton
									title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_ARCHIVE')}
									className={`${qnaBaseClassName}__question--archive-btn`}
								>
									<Archive />
								</IconButton>
							</>
						)}

						{archive && (
							<>
								<span className={`${qnaBaseClassName}__question-rank`}>
									<Icons.Recommended color="#126CFC" /> 10
								</span>

								<Button variant="contained" color="primary" startIcon={<Unarchive />}>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_UNARCHIVE')}
								</Button>
							</>
						)}
					</div>
				)}
			</div>
		);
	};
}
