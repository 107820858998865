import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import _ from 'underscore';
import { withRouter } from 'react-router';

import Timeline from '../Timeline/Timeline';
import timelineSnapshotIcon from '../../assets/images/timeline_snapshot.svg';
import { stopSnapShotMedia } from '../../actions/snapshotSettingsActions';
import {
	createTimelineItem,
	updateTimelineItem,
	removeTimelineItem,
	setSelectedTimelineItem,
	createTimelineGroup,
	removeTimelineGroup,
	setSelectedTimelineGroup,
	setDurationOfLiveMedia,
	setLiveStreamPosition,
	setLivePlayerPosition,
	setTimelineMarkerPosition,
	updateTimelineGroup,
	setPlayerStreamAsLive,
	setPlayerStreamAsNotLive,
	setLiveSettingsId,
} from '../../actions/liveManagerActions';
import { updateInteractTimelineItem } from '../../actions/interactTimelineActions';
import { snapshotTypes } from './../../constants/snapshotTypes';
import { mediaNames, messageTypes, mediaTypes, mediaPropertyNames } from './../../constants/mediaConstants';
import { createSnapshot } from './../../actions/workflowActions';
import { showNotification } from '../../actions/notificationActions';
import { getMediaQueryDetails } from '../../actions/publishActions';
import { deleteTrackFromMedia } from '../../actions/inspectScreenActions';
import { loadChannels } from '../../actions/channelActions';
import { liveEventStatusList } from '../../constants/liveEventStatus';
import {
	getTimeDifferenceInSeconds,
	getSecondsFromTimelineTime,
	mapTimeDifferenceToDefaultTime,
	getTimelineStartDate,
	getFormattedTimeWithMilliseconds,
	returnAddedTime,
	getTimelineTimeFromSeconds,
} from '../../services/timeStampService';
import { getDetails } from '../../services/mediaDisplayService';
import { generateUUID } from './../../utils/commonUtil';
import {
	LIVE_STREAM_POSITION,
	liveSettingsList,
	MIN_SNAPSHOT_TIME,
	BUFFER_TIMELINE_PERCENT,
} from './../../constants/liveManagerConstants';
import { getTrackIdFromMediaId } from '../../services/componentReusableService';

import './LiveManagerBottomPanel.scss';
import { debounce } from 'lodash';

//const defaultDuration = 43200
const defaultZoomPercentage = 0.5; //value can  be changed

class LiveManagerBottomPanel extends React.Component {
	constructor(props) {
		super(props);
		let mediaDetails = this.props.mediaDetails;
		this.state = {
			sliderValue: 0,
			inpoint: null,
			position: getTimelineStartDate(),
			options: this.getOptions(),
			customTimes: {
				pointer: getTimelineStartDate(),
			},
			liveEventStartTime:
				mediaDetails &&
				mediaDetails.custom &&
				mediaDetails.custom.livemanager &&
				mediaDetails.custom.livemanager.start
					? mediaDetails.custom.livemanager.start
					: null,
			liveEventStopTime:
				mediaDetails &&
				mediaDetails.custom &&
				mediaDetails.custom.livemanager &&
				mediaDetails.custom.livemanager.stop
					? mediaDetails.custom.livemanager.stop
					: null,
			liveButtonClicked: false,
			isPlayerPlaying: false,
			isTimelineFocused: false,
			liveEventChannelsList: [],
		};
		this.inpoint = null; //temporary fix to be removed
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.$pointerTimeDisplay = document.createElement('label');
		this.$pointerTimeDisplay.setAttribute('id', 'pointerTimeDisplay');
		this.showTimeline = false;
	}

	_initPlayer = () => {
		this.livePlayer = window.GoBrain.widgets()['live-live'];
		if (this.livePlayer) {
			this.addPlayerEventHandlers();
		} else {
			setTimeout(() => {
				this.livePlayer = window.GoBrain.widgets()['live-live'];
				this.addPlayerEventHandlers();
			}, 300);
		}
	};

	UNSAFE_componentWillMount() {
		if (this.props.bottomPanellRef) {
			this.props.bottomPanellRef(this);
		}
		this.loadChannels();
		window.addEventListener('keydown', this._handleKeyDown, true);
		window.addEventListener('click', this._handleClickEvent, true);
	}

	componentDidMount = () => {
		const { mediaDetails, liveEventStatus } = this.props;

		if (liveEventStatus !== liveEventStatusList.INITIAL) {
			this._initPlayer();
		}
		this.addSnapshot(snapshotTypes.LIVE, true, true);
		if (mediaDetails.tracks && mediaDetails.tracks.length > 0) {
			this.filterSnapshots();
		}
		if (this.props.interactItems) {
			this.loadInteractItems();
		}

		if (this.props.liveStreamPositionTime && liveEventStatus === liveEventStatusList.STARTED) {
			this.updateLiveItemEndTime(this.props.liveStreamPositionTime);
		}
	};

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (
			nextProps.lastSetPlayerPositionTime &&
			nextProps.lastSetPlayerPositionTime !== this.props.lastSetPlayerPositionTime
		) {
			this.setLivePlayerPosition(nextProps.playerPositionToSet, nextProps.startPlaying);
		}
		if (nextProps.lastSetSnapShotPlayTime != this.props.lastSetSnapShotPlayTime) {
			if (nextProps.snapshotPlayStarted && nextProps.snapshotPlayPaused) {
				this.livePlayer.pause();
			} else if (nextProps.snapshotPlayStarted && !nextProps.snapshotPlayPaused) {
				this.livePlayer.play();
			}
		}
		if (nextProps.liveEventStartTime !== this.props.liveEventStartTime) {
			this.setState({ liveEventStartTime: nextProps.liveEventStartTime });
		}
		if (nextProps.liveEventStopTime !== this.props.liveEventStopTime) {
			this.setState(
				{
					liveEventStopTime: nextProps.liveEventStopTime,
				},
				() => {
					this.setLiveEventStopAsMaxWindow(nextProps.liveEventStopTime);
				}
			);
		}
	};

	componentWillUnmount = () => {
		if (this.livePlayer) {
			this.removePlayerEventHandlers();
			this.livePlayer = null;
		}
		window.removeEventListener('keydown', this._handleKeyDown, true);
		window.removeEventListener('click', this._handleClickEvent, true);
		if (this.$pointerTimeDisplay) {
			this.$pointerTimeDisplay.remove();
		}
	};

	componentDidUpdate(prevProps) {
		const { timelineItems, mediaId, liveChannel } = this.props;
		if (liveChannel !== prevProps.liveChannel && !!liveChannel) {
			let mediaItem = _.find(timelineItems, (item) => {
				return item.id === mediaId;
			});
			if (mediaItem) {
				mediaItem.content = liveChannel?.metadata?.title ?? liveChannel?.id;
				this.props.updateTimelineItem(mediaItem);
			}
		}

		if (this.props.deletedMediaId !== prevProps.deletedMediaId) {
			this.updateDeletedItem(this.props.deletedMediaId);
		}
		if (
			this.props.updatedMedia &&
			JSON.stringify(this.props.updatedMedia) !== JSON.stringify(prevProps.updatedMedia)
		) {
			this.updatedMediaDetails(this.props.updatedMedia);
		}
		if (this.props.interactItems !== prevProps.interactItems) {
			this.loadInteractItems();
		}
		if (this.props.liveStreamPositionTime !== prevProps.liveStreamPositionTime && !this.props.liveEventStopTime) {
			this.updateLiveItemEndTime(this.props.liveStreamPositionTime);
		}

		if (this.props.showPlayer !== prevProps.showPlayer) {
			if (this.props.showPlayer && this.props.liveEventStatus !== liveEventStatusList.INITIAL) {
				this._initPlayer();
			}
			if (!this.props.showPlayer && this.livePlayer) {
				this.removePlayerEventHandlers();
				this.livePlayer = null;
			}
		} else if (this.props.liveEventStatus !== prevProps.liveEventStatus) {
			this._initPlayer();
		}
	}

	loadChannels = () => {
		const { defaultAccountId, mediaId, channelId } = this.props;
		this.props.loadChannels(defaultAccountId).then((res) => {
			let liveEventChannelsList = [];
			if (res && !res.Message && !res.mesage) {
				liveEventChannelsList = res;
				this.setState({ liveEventChannelsList: res });
			} else {
				this.setState({ liveEventChannelsList: [] });
			}

			let mediaItem = _.find(this.props.timelineItems, (item) => {
				return item.id === mediaId;
			});
			if (mediaItem) {
				let channel = _.find(liveEventChannelsList, (channel) => {
					return channel && channel.id == channelId;
				});
				let channelName =
					channel && channel.metadata && channel.metadata.title ? channel.metadata.title : channelId;
				mediaItem.content = channelName;
				this.props.updateTimelineItem(mediaItem);
			}
		});
	};

	updateDeletedItem = (deletedItemId) => {
		const { updateTimelineItem, timelineItems, defaultAccountId, mediaId, showMessage, t } = this.props;
		let item = _.find(timelineItems, (lastItem) => {
			return lastItem.id === deletedItemId;
		});
		if (item) {
			item.content = item.id;
			updateTimelineItem(item);
			this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
				if (media && !media.message && !media.Message) {
					let trackId = getTrackIdFromMediaId(media.tracks, deletedItemId);
					this.props.deleteTrackFromMedia(defaultAccountId, mediaId, trackId).then((res) => {
						if (res && !res.message && !res.Message) {
							this.props.showNotification(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SNAPSHOT_REMOVED_FROM_TIMELINE')
							);
							this.props.removeTimelineItem(item);
							let group = _.find(this.props.timelineGroups, (group) => {
								return group.id === item.group;
							});
							this.props.removeTimelineGroup(group);
						} else {
							showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_REMOVE_SNAPSHOT_TIMELINE'),
								messageTypes.error
							);
						}
					});
				} else {
					showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_REMOVE_SNAPSHOT_TIMELINE'),
						messageTypes.error
					);
				}
			});
		}
	};

	updatedMediaDetails = (updatedMedia) => {
		const { updateTimelineItem, timelineItems } = this.props;
		let item = _.find(timelineItems, (lastItem) => {
			return lastItem.id === updatedMedia.id;
		});
		if (item) {
			if (updatedMedia.mediaPropertyKey === mediaPropertyNames.TITLE) {
				item.content = updatedMedia.mediaPropertyValue;
				updateTimelineItem(item);
			}
		}
	};

	filterSnapshots = () => {
		const { mediaDetails } = this.props;
		let tracks = mediaDetails.tracks;
		if (!tracks) {
			return;
		}
		tracks.map((track, index) => {
			if (
				track &&
				track.sequences &&
				track.sequences.length == 1 &&
				track.sequences[0].type === 'custom' &&
				track.sequences[0].hasOwnProperty('custom') &&
				track.sequences[0].custom.mediaId
			) {
				this.loadSnapshotFromTracks(track, index);
			} /*else if (track && track.sequences && track.sequences.length > 0) {
        track.sequences.map(eachSequence => {
          if (eachSequence.hasOwnProperty('custom') && eachSequence.custom && eachSequence.custom.mimeType === interactMimeType) {
            this.loadInteractItems(eachSequence) //Todo
          }
        })
      }*/
		});
	};

	handleInpointClick = () => {
		const { timelineItems, liveEventStatus, updateTimelineItem, showNotification, isPlayerAtLivePosition, t } =
			this.props;
		if (liveEventStatus === liveEventStatusList.INITIAL) {
			showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_LIVE_EVENT_NOT_STARTED'));
			return;
		}

		if (this.livePlayer && !isPlayerAtLivePosition) {
			//pause the player when selecting inpoint
			this.livePlayer.pause();
		}

		const { liveStreamPositionTime } = this.props;
		//Add new snapshot or update existing, if exists
		const lastIndex = timelineItems.length - 1;
		const inpoint = this.getCurrentPositionFromLive();
		if (inpoint > liveStreamPositionTime) {
			showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_INVALID_IN_POINT'));
			return;
		}
		this.inpoint = inpoint; //temporary fix for outpoint button not getting enabled
		if (timelineItems[lastIndex].className === snapshotTypes.INIT_SNAPSHOT.CSS) {
			//Update existing snapshot
			let item = {
				...timelineItems[lastIndex],
			};

			item.start = inpoint;
			item.end = liveStreamPositionTime;
			updateTimelineItem(item);
		} //Add new snapshot
		else {
			this.addSnapshot(snapshotTypes.INIT_SNAPSHOT);
		}
		this.updateCustomTimeState(this.inpoint); //important to retain cursor else it goes to starting point
	};

	handleOutpointClick = () => {
		const { timelineItems, updateTimelineItem, showNotification, liveStreamPositionTime, t } = this.props;
		const lastIndex = timelineItems.length - 1;

		if (timelineItems[lastIndex].className === snapshotTypes.INIT_SNAPSHOT.CSS && timelineItems.length > 1) {
			let outpoint = this.getCurrentPositionFromLive();
			if (outpoint > liveStreamPositionTime) {
				showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_OUTPOINT_GREATER_THAN_LIVE_STREAM'));
				return;
			}

			let item = {
				...timelineItems[lastIndex],
			};
			if (item.start > outpoint) {
				showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_OUTPOINT_LESSER_THAN_IN_POINT'));
				return;
			}
			if (getTimeDifferenceInSeconds(outpoint, item.start) < MIN_SNAPSHOT_TIME) {
				this.props.showNotification(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_MINIMUM_SNAPSHOT_TIME')
				);
				return;
			}
			if (this.livePlayer && this.livePlayer && this.livePlayer.pause) {
				//pause the player when selecting outpoint
				this.livePlayer.pause();
			}
			item.end = outpoint;
			item.className = snapshotTypes.SNAPSHOT.CSS;

			this.updateCustomTimeState(outpoint); //important to retain cursor else it goes to starting point
			updateTimelineItem(item);
			this.inpoint = null; //added only to have update inpoint outpoint from header
			this.props.setSelectedTimelineItem(item.id);
			this.props.setSelectedTimelineGroup(item.group);
			this.props.setLiveSettingsId(liveSettingsList.SNAPSHOT);
		}
	};

	handleSetToLiveTime = () => {
		if (!this.livePlayer) {
			return;
		}
		this.livePlayer.play(); // playing the player because( if already in pause state, it doesn't position and play)
		this.livePlayer.position(-3); //setting as player
		this.stopSnapShotLoop();
		//this.setLiveButtonClick()
	};

	stopSnapShotLoop = () => {
		this.props.stopSnapShotMedia();
	};

	updateCustomTimeState = (time) => {
		const { setTimelineMarkerPosition, liveEventStartTime, liveEventStopTime } = this.props;
		if (document.getElementsByClassName('vis-item').length === 0) {
			return;
		}

		const eventStopTime = liveEventStopTime
			? mapTimeDifferenceToDefaultTime(liveEventStopTime, liveEventStartTime)
			: null;
		let timeToSet = liveEventStopTime && time > eventStopTime ? eventStopTime : time;
		if (new Date(timeToSet).getFullYear() < 1970) {
			timeToSet = getTimelineStartDate();
		}

		setTimelineMarkerPosition(timeToSet);
		this.updateCustomTimeDisplay(timeToSet);
		this.setPointerDisplay();
		let customTime = { pointer: timeToSet };
		this.setState({
			customTimes: customTime,
		});
	};

	updateCustomTimeDisplay = (time) => {
		this.$pointerTimeDisplay.textContent = getFormattedTimeWithMilliseconds(time, 'HH:mm:ss.SSS');
	};

	setPointerDisplay = () => {
		if (!this.$pointerDiv) {
			this.$pointerDiv = document.getElementsByClassName('pointer')[0];
		}
		if (
			this.$pointerDiv &&
			this.$pointerDiv.firstElementChild &&
			this.$pointerDiv.firstElementChild.children.length === 0
		) {
			this.$pointerDiv.firstElementChild.append(this.$pointerTimeDisplay);
		}
	};

	//TODO:
	//STATE: TO BE REMOVED, IF NOT USED
	timelineTimechangeEventHandler = (properties) => {
		this.setTimeLineFocus();
		if (properties && properties.time && properties.id === 'pointer') {
			//fix for timeline dragging issue
			if (properties.time < getTimelineStartDate()) {
				//LM-26 fix
				this.updateCustomTimeState(getTimelineStartDate());
				this.setLivePlayerPosition(getTimelineStartDate());
			} else {
				this.updateCustomTimeState(properties.time);
				this.setLivePlayerPosition(properties.time, false);
				setTimeout(() => {
					if (!this.props.isPlayerAtLivePosition) {
						if (this.livePlayer && this.livePlayer.pause) {
							this.livePlayer.pause();
						}
					}
				}, 100);
			}
		}
	};

	timelineMouseOverEventHandler = (event) => {
		let target = event.event.target;
		if (target && target.classList && target.classList.contains && target.classList.contains('vis-text')) {
			target.setAttribute('title', target.textContent);
		}
	};

	getCurrentPositionFromLive = () => {
		//TODO: Get current position of the live stream
		//return new Date()
		//return this.refs.timelineRef.timeline.customTimes[0].getCustomTime() // right now setting from position of player
		if (this.state.customTimes.pointer < getTimelineStartDate()) {
			//LM-26 fix
			return getTimelineStartDate();
		}
		return this.state.customTimes.pointer;
	};

	loadSnapshotFromTracks = (track, index) => {
		const { createTimelineItem, createTimelineGroup, defaultAccountId, t } = this.props;
		let id =
			track.sequences && track.sequences.length > 0 && track.sequences[0].custom
				? track.sequences[0].custom.mediaId
				: '';
		let sequence = track.sequences[0];
		let startTime = getTimelineTimeFromSeconds(sequence.start || 1);
		let endTime = getTimelineTimeFromSeconds(sequence.end || 1);
		let itemName = id;
		this.props.getMediaQueryDetails(defaultAccountId, id, mediaNames.medias).then((media) => {
			if (!media || (media && !media.id)) {
				this.props.showNotification(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_FETCH_SNAPSHOT')
				);
				//showMessage('Failed to fetch snapshot',  messageTypes.error)
			} else {
				let customMediaInfo = getDetails(media, false); // adding false as it is not a playlists
				itemName = customMediaInfo.title;
			}
			let group = {
				id: `snapshot-${id}`,
				content: `<img src=${snapshotTypes.MEDIA.ICON} ></img>`,
				style: 'background-color: #1B1D2A;',
				order: index + 1,
				groupEditable: false,
			};
			createTimelineGroup(group);
			let snapshot = {
				id: id,
				className: snapshotTypes.MEDIA.CSS,
				content: itemName,
				editable: false,
				start: startTime, //setting to cursor position
				end: endTime,
				group: group.id,
			};
			createTimelineItem(snapshot);
		});
	};

	loadInteractItems = () => {
		const {
			interactItems,
			interactGroups,
			timelineGroups,
			timelineItems,
			createTimelineGroup,
			createTimelineItem,
		} = this.props;
		interactGroups.map((eachGroup) => {
			let fountGroup = _.find(timelineGroups, (group) => {
				return group.id === eachGroup.id;
			});
			if (!fountGroup) {
				eachGroup.editable = false;
				createTimelineGroup(eachGroup);
			}
		});
		interactItems.map((eachItem) => {
			let fountItem = _.find(timelineItems, (item) => {
				return item.id === eachItem.id;
			});
			if (!fountItem) {
				if (eachItem.start) {
					eachItem.editable = false;
					createTimelineItem(eachItem);
				}
			}
		});
	};

	addSnapshot = (snapshot, isGroupReadonly, isItemReadonly) => {
		const { liveEventStopTime, liveEventChannelsList } = this.state;
		const { timelineGroups, createTimelineItem, createTimelineGroup, mediaId, liveStreamPositionTime, channelId } =
			this.props;

		let endDate = liveStreamPositionTime; //setting end date till live stream end position// to think how to show
		let channelName = '';
		const sortedGroups = timelineGroups.sort((a, b) => b.order - a.order);
		const index = sortedGroups.length > 0 ? sortedGroups[0].order + 1 : 1;
		const id = generateUUID();
		const groupId = `snapshot-${id}`;

		let group = {
			id: groupId,
			content: `<img src=${snapshot.ICON ? snapshot.ICON : timelineSnapshotIcon}></img>`,
			style: 'background-color: #1B1D2A;',
			order: index,
			groupEditable: !isGroupReadonly,
		};

		if (snapshot.TITLE && snapshot.TITLE === snapshotTypes.LIVE.TITLE) {
			let channel = _.find(liveEventChannelsList, (channel) => {
				return channel && channel.id == channelId;
			});
			channelName = channel && channel.metadata && channel.metadata.title ? channel.metadata.title : channelId;
		}

		let item = {
			id: snapshot.TITLE && snapshot.TITLE === snapshotTypes.LIVE.TITLE ? mediaId : id,
			group: group.id,
			content:
				snapshot.TITLE && snapshot.TITLE === snapshotTypes.LIVE.TITLE
					? channelName
					: snapshot.TITLE
					? `${snapshot.TITLE} ${index - 1}`
					: `Snapshot ${index - 1}`,
			className: snapshot.CSS,
			align: 'center',
			start: this.getCurrentPositionFromLive(), //setting to cursor position
			end:
				snapshot.TITLE && snapshot.TITLE === snapshotTypes.LIVE.TITLE && liveEventStopTime
					? this.getLiveEventEndTimeAsTimeline()
					: endDate,
			editable: !isItemReadonly,
		};

		createTimelineGroup(group);
		createTimelineItem(item);
	};

	getLiveEventEndTimeAsTimeline = () => {
		const { liveEventStartTime, liveEventStopTime } = this.state;
		if (liveEventStartTime && liveEventStopTime) {
			let startTime = liveEventStartTime;
			let endTime = liveEventStopTime;
			endTime = mapTimeDifferenceToDefaultTime(endTime, startTime);
			return endTime;
		}
		return null;
	};

	handleZoomInClick = (e) => {
		const { isPlayerPlaying } = this.state;
		/*const value = this.state.sliderValue <= 100 ? this.state.sliderValue + 5 : 100;
    this.setState({ sliderValue: value });

    let zoomLevel = value / 100;
    this.refs.timelineRef.timeline.zoomIn(zoomLevel)*/
		e.stopPropagation();
		if (isPlayerPlaying && this.livePlayer && this.livePlayer.pause) {
			this.livePlayer.pause();
		}
		if (this.timelineRef && this.timelineRef.timeline) {
			let timeline = this.timelineRef.timeline;
			timeline.zoomIn(defaultZoomPercentage);
		}
	};

	handleZoomOutClick = (e) => {
		const { isPlayerPlaying } = this.state;
		/*  const value = this.state.sliderValue >= 0 ? this.state.sliderValue - 5 : 0;
      this.setState({ sliderValue: value });

      let zoomLevel = value / 100;
      this.refs.timelineRef.timeline.zoomOut(zoomLevel)*/
		e.stopPropagation();
		if (isPlayerPlaying && this.livePlayer && this.livePlayer.pause) {
			this.livePlayer.pause();
		}
		if (this.timelineRef && this.timelineRef.timeline) {
			let timeline = this.timelineRef.timeline;
			timeline.zoomOut(defaultZoomPercentage);
		}
	};

	handleSliderChange = (event, value) => {
		if (this.timelineRef && this.timelineRef.timeline) {
			let timeline = this.timelineRef.timeline;
			if (value == 0) {
				timeline.fit();
				this.setState({
					sliderValue: 0,
				});
			} else {
				let newValue = 0;
				const { sliderValue } = this.state;
				if (value > sliderValue) {
					if (sliderValue == 0) {
						newValue = value;
					} else {
						//newValue = value - Number(sliderValue)
						newValue = value;
					}

					timeline.zoomIn(newValue / 100);
				} else if (value < sliderValue) {
					// newValue = sliderValue - Number(value)
					newValue = value;
					timeline.zoomOut(newValue / 100);
				}
				this.setState({
					sliderValue: value,
				});
			}
		}
	};

	setTimeLineFocus = () => {
		this.setState({
			isTimelineFocused: true,
		});
	};

	resetTimeLineFocus = () => {
		this.setState({
			isTimelineFocused: false,
		});
	};

	_handleClickEvent = () => {
		this.resetTimeLineFocus();
	};

	_handleKeyDown = (e) => {
		//takes action if keyboard is clicked
		let SPACE_BAR_KEY = 32;
		const { isPlayerPlaying, isTimelineFocused } = this.state;
		if (isTimelineFocused && isPlayerPlaying && this.livePlayer && e.keyCode === SPACE_BAR_KEY) {
			this.livePlayer.pause();
		} else if (isTimelineFocused && this.livePlayer && e.keyCode === SPACE_BAR_KEY) {
			this.livePlayer.play();
		}
	};

	addPlayerEventHandlers = () => {
		if (!this.livePlayer) {
			return;
		}
		this.livePlayer.on('position', this.handlePlayerPositionEvent);
		this.livePlayer.on('duration', this.handlePlayerDurationEvent);
		this.livePlayer.on('pause', this.handlePlayerPauseEvent);
		this.livePlayer.on('play', this.handlePlayerPlayEvent);
		setTimeout(() => {
			if (this.livePlayer && this.livePlayer.position) {
				this.livePlayer.position(-3);
			}
		});
	};

	removePlayerEventHandlers = () => {
		if (!this.livePlayer) {
			return;
		}
		this.livePlayer.off('position');
		this.livePlayer.off('duration');
		this.livePlayer.off('pause');
		this.livePlayer.off('play');
	};

	//TODO: Move player events to player component
	//Player pause event handler
	handlePlayerPauseEvent = () => {
		const { snapshotPlayStarted, snapshotPlayPaused } = this.props;
		this.setState({
			isPlayerPlaying: false,
		});

		if (snapshotPlayStarted && !snapshotPlayPaused) {
			this.livePlayer.play();
		}
	};

	handlePlayerPlayEvent = () => {
		this.setState({
			isPlayerPlaying: true,
		});
	};

	setLiveButtonClick = () => {
		this.setState({
			liveButtonClicked: true,
		});
	};

	resetLiveButtonClick = () => {
		this.setState({
			liveButtonClicked: false,
		});
	};

	setTimeShiftLive = (position) => {
		const absoluteLiveStreamPosition = Math.abs(LIVE_STREAM_POSITION);
		const currentPosition = Math.abs(position);
		// To avoid TimeShift button flicks between enable/disable
		if (
			(currentPosition > absoluteLiveStreamPosition - 1 && currentPosition < absoluteLiveStreamPosition) ||
			(currentPosition > absoluteLiveStreamPosition && currentPosition < absoluteLiveStreamPosition + 1)
		) {
			return;
		}
		if (Math.ceil(currentPosition) < absoluteLiveStreamPosition) {
			this.disableTimeShiftButton();
		} else {
			this.enableTimeShiftButton();
		}
	};

	enableTimeShiftButton = (_) => {
		this.props.setPlayerStreamAsNotLive();
	};

	disableTimeShiftButton = (_) => {
		this.props.setPlayerStreamAsLive();
	};

	//Move cursor on player position
	handlePlayerPositionEvent = (actualPosition) => {
		const { duration } = this.props;

		let delta = actualPosition;
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (actualPosition > 0 && isSafari) {
			// To avoid pointer go backward on safari
			delta = actualPosition > 0 ? 0 : actualPosition;
			this.handleSetToLiveTime();
		}

		debounce(() => this.setTimeShiftLive(delta), 100)();
		let position = duration - Math.abs(delta);
		let newTime = getTimelineTimeFromSeconds(position); //getting and setting milliseconds
		this.updateCustomTimeState(newTime);
	};

	handlePlayerDurationEvent = (duration) => {
		const { liveEventStatus, isPlayerAtLivePosition, liveEventStopTime, liveEventStartTime } = this.props;
		let newOptions = { ...this.state.options };
		newOptions.max = getTimelineTimeFromSeconds(Math.abs(duration)); //dont set props default date it doesn't work
		if (liveEventStatus === liveEventStatusList.STARTED && isPlayerAtLivePosition) {
			this.setState(
				{
					options: newOptions,
				},
				() => {
					this.addBufferTimeToTimeline();
				}
			);
			//this.updateLiveItemEndTime(newOptions.max)
		}
		if (liveEventStatus === liveEventStatusList.STOPPED && liveEventStopTime) {
			//to be checked again when live event is stopped
			let time = mapTimeDifferenceToDefaultTime(liveEventStopTime, liveEventStartTime);
			if (time < newOptions.max) {
				this.updateLiveStreamPositionTime(time);
			} else {
				this.updateLiveStreamPositionTime(newOptions.max);
			}
		} else {
			this.updateLiveStreamPositionTime(newOptions.max);
		}
		this.props.setDurationOfLiveMedia(Math.abs(duration)); // ths is right now for when duration is known
	};

	isLiveStream = () => {
		const { mediaDetails } = this.props;
		if (mediaDetails && (mediaDetails.type == mediaTypes.liveaudio || mediaDetails.type == mediaTypes.livevideo)) {
			// this is for live video to have pointer at end
			return true;
		}
		return false;
	};

	updateLiveStreamPositionTime = (time) => {
		let liveQueueItem = this.props.liveQueueItem;

		this.props.setLiveStreamPosition(time);
		if (liveQueueItem) {
			liveQueueItem.end = time;
			this.props.updateTimelineItem(liveQueueItem);
			this.props.updateInteractTimelineItem(liveQueueItem, 'setScreenUnchanged');
		}
	};

	updateLiveItemEndTime = (time) => {
		if (this.props.timelineItems.length > 0) {
			let item = this.props.timelineItems[0];
			item.end = time;
			this.props.updateTimelineItem(item);
		}
	};

	setLiveEventStopAsMaxWindow = () => {
		const { liveEventStartTime, liveEventStopTime } = this.state;
		let newOptions = { ...this.state.options };
		let startTime = liveEventStartTime;
		let endTime = liveEventStopTime;
		endTime = mapTimeDifferenceToDefaultTime(endTime, startTime);
		newOptions.max = endTime;
		newOptions.end = endTime;
		this.setState(
			{
				options: newOptions,
			},
			() => {
				this.addBufferTimeToTimeline();
			}
		);
		this.updateLiveItemEndTime(endTime); //for updating liveitem according to window
	};

	addBufferTimeToTimeline = () => {
		let newOptions = { ...this.state.options };
		let seconds = getTimeDifferenceInSeconds(this.state.options.max, this.state.options.min);
		let secondsToIncrease = seconds * BUFFER_TIMELINE_PERCENT;
		newOptions.max = returnAddedTime(this.state.options.max, secondsToIncrease, 'seconds');
		newOptions.end = newOptions.max;
		this.setState({
			options: newOptions,
		});
	};

	getOptions = () => {
		const { updateTimelineItem, removeTimelineItem, removeTimelineGroup, mediaDetails } = this.props;
		const min = getTimelineStartDate();
		let max = getTimelineStartDate();
		let end = getTimelineStartDate();

		if (
			mediaDetails &&
			mediaDetails.custom &&
			mediaDetails.custom.livemanager &&
			mediaDetails.custom.livemanager.stop
		) {
			let startTime = mediaDetails.custom.livemanager.start;
			let endTime = mediaDetails.custom.livemanager.stop;
			endTime = mapTimeDifferenceToDefaultTime(endTime, startTime);
			max = endTime;
			end = endTime;
		} else {
			max.setSeconds(this.props.duration);
			end = max;
		}

		//buffer time starts
		let seconds = getTimeDifferenceInSeconds(max, min);
		let secondsToIncrease = seconds * BUFFER_TIMELINE_PERCENT;
		max = returnAddedTime(max, secondsToIncrease, 'seconds');
		end = max;
		//buffer time ends
		const onMoveItem = (item, callback) => {
			const { timelineItems, liveStreamPositionTime, showNotification, liveEventStatus, t } = this.props;
			if (item.editable) {
				/*if (this.livePlayer) { //pause the player when selecting inpoint
          this.livePlayer.pause()
        }*/
				let _item = _.find(timelineItems, (lastItem) => {
					return lastItem.id === item.id;
				});
				if (_item && _item.group !== item.group) {
					callback(null);
					return;
				}
				if (getTimeDifferenceInSeconds(item.end, item.start) < MIN_SNAPSHOT_TIME) {
					callback(null);
					this.props.showNotification(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_MINIMUM_SNAPSHOT_TIME')
					);
					return;
				}
				if (item.start > liveStreamPositionTime || item.end > liveStreamPositionTime) {
					callback(null);
					showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SNAPSHOT_RANGE_INVALID'));
					return;
				}
				if (item.start < getTimelineStartDate() || item.end < getTimelineStartDate()) {
					callback(null);
					this.props.showNotification(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_TIME_LESS_THAN_START')
					);
					return;
				}
				if (liveEventStatus === liveEventStatusList.STOPPED) {
					let liveEventEndTime = this.getLiveEventEndTimeAsTimeline();
					if (item.start > liveEventEndTime || item.end > liveEventEndTime) {
						callback(null);
						this.props.showNotification(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_TIME_GREATER_THAN_LIVE')
						);
						return;
					}
				}
				/*if (_item.start.getTime() != item.start.getTime()) {
          this.setLivePlayerPosition(item.start, false) //Move marker to start
        } else {
          this.setLivePlayerPosition(item.end, false) //Move marker to start
        }*/ //to stop moving player when snapshot is moved
				updateTimelineItem(item);
				callback(item);
			} else {
				callback(null);
			}
		};

		const onRemoveItem = (item, callback) => {
			this.setTimeLineFocus();
			if (item.editable) {
				removeTimelineItem(item);
				let group = _.find(this.props.timelineGroups, (group) => {
					return group.id === item.group;
				});
				removeTimelineGroup(group);
			} else {
				callback(null);
			}
		};

		const sortGroups = (a, b) => {
			return b.order - a.order;
		};

		// http://visjs.org/docs/timeline/#Configuration_Options
		const options = {
			stack: false,
			editable: {
				remove: true,
				updateTime: true,
			},
			moveable: true,
			//groupOrder:'title',// function or string
			start: min,
			end: end, //good to give to reduce initial load time // check for zoom
			selectable: true,
			groupEditable: {
				add: true,
				remove: true,
				order: true,
			},
			groupOrder: sortGroups,
			min: min,
			max: max,
			zoomable: true,
			zoomMin: 1000 * 2, // one hour in milliseconds
			zoomMax: 1000 * 60 * 60 * 24, // one day in milliseconds
			orientation: 'top',
			/* verticalScroll: true,
       horizontalScroll: true,
       zoomKey: 'ctrlKey',*/
			format: {
				//with milliseonds
				/* minorLabels: {
           millisecond: 'SSSS',
           second: 'HH:mm:ss:sss',
           minute: 'HH:mm:ss:sss',
           hour: 'HH:mm:ss:sss',
           weekday: 'ddd D',
           day: 'D',
           month: 'MMM',
           year: 'YYYY'
         }*/

				minorLabels: {
					//showing timeline with only seconds and single digit hours
					second: 'HH:mm:ss',
					minute: 'HH:mm:ss',
					hour: 'HH:mm:ss',
				},
			},
			snap: function (date) {
				var milliseconds = 100;
				return Math.round(date / milliseconds) * milliseconds;
			},
			margin: {
				axis: 0,
				item: 0,
			},
			showCurrentTime: false,
			showMajorLabels: false,
			stackSubgroups: true,
			itemsAlwaysDraggable: false,
			onRemove: onRemoveItem,
			onMove: onMoveItem,
		};
		return options;
	};

	timelineClickEventHandler = (e) => {
		const { timelineItems, setSelectedTimelineItem, setSelectedTimelineGroup } = this.props;
		const { snapshotPlayStarted, selectedSnapShotStartTime, selectedSnapShotEndTime } = this.props;

		this.setTimeLineFocus();
		if (e && e.event) {
			e.event.stopPropagation();
		}

		if (e.item !== null) {
			const itemId = e.item;
			let item = _.find(timelineItems, (item) => {
				return item.id === itemId;
			});
			if (item && item.className !== snapshotTypes.LIVE.CSS) {
				this.props.setLiveSettingsId(liveSettingsList.SNAPSHOT);
				setSelectedTimelineItem(e.item);
				setSelectedTimelineGroup(e.group || item.group);
			}
		}
		if (e.what && e.what == 'axis') {
			// added to move cursor at selected time . here e.group is also null when clicked on time
			if (snapshotPlayStarted && e.time > selectedSnapShotStartTime && e.time < selectedSnapShotEndTime) {
				this.updateCustomTimeState(e.time); //adding here to handle inpoint and out point selection
				this.setLivePlayerPosition(e.time, true); //startPlaying true
				return; // to check this condition
			}
			if (e.time < getTimelineStartDate()) {
				//LM-26 fix
				this.updateCustomTimeState(getTimelineStartDate());
				this.setLivePlayerPosition(getTimelineStartDate());
			} else {
				this.updateCustomTimeState(e.time);
				this.setLivePlayerPosition(e.time, false);
				setTimeout(() => {
					if (!this.props.isPlayerAtLivePosition) {
						if (this.livePlayer && this.livePlayer.pause) {
							this.livePlayer.pause();
						}
					}
				}, 100);
			}
			//adding here to handle inpoint and out point selection
			this.stopSnapShotLoop();
		}
	};

	setLivePlayerPosition = (time, startPlaying) => {
		//this.updateCustomTimeState(time) //to check if this is really needed
		this.moveLiveStream(time, startPlaying);
	};

	moveLiveStream = (time, startPlaying) => {
		// Do your operations
		let positionToMove = getSecondsFromTimelineTime(time);
		if (this.livePlayer && this.livePlayer.position) {
			this.livePlayer.position(positionToMove);
		}
		if (startPlaying) {
			/* setTimeout(() => {//removing timeout and instead listening for pause event
         this.livePlayer.play()
       }, 500)*/
		}
	};

	/**
	 * Set the wrapper ref
	 */
	setWrapperRef(node) {
		this.timelineRef = node;
	}

	render() {
		const { options, customTimes } = this.state;
		const { timelineItems, timelineGroups, liveEventStatus, selectedItem, liveEventStartTime, t } = this.props;
		this.showTimeline =
			liveEventStatus !== liveEventStatusList.INITIAL &&
			getTimeDifferenceInSeconds(new Date(), new Date(liveEventStartTime)) > MIN_SNAPSHOT_TIME;

		const correctCustomTimes = { ...customTimes };
		if (new Date(customTimes.pointer).getFullYear() < 1970) {
			const newPointer = getTimelineStartDate();
			correctCustomTimes.pointer = newPointer;
		}

		return (
			<div className="LiveManagerBottomPanelWrapper" onClick={this.setTimeLineFocus}>
				{liveEventStatus == liveEventStatusList.INITIAL && (
					<div className="liveStreamBottomText">
						<label> {t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_START_LIVE_STREAM')} </label>
					</div>
				)}

				{!this.showTimeline && liveEventStatus === liveEventStatusList.STARTED && (
					<LoadingOverlay
						className="timelineOverlay"
						active={true}
						spinner={<ScaleLoader color={'#ffffff'} />}
						text={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_BUILDING_TIMELINE')}
					/>
				)}

				<div id="vis-timeline-container" className={this.showTimeline ? 'showDisplay' : 'hideDisplay'}>
					<Timeline
						timelineRef={this.setWrapperRef}
						options={options}
						items={timelineItems}
						groups={timelineGroups}
						customTimes={correctCustomTimes}
						clickEventHandler={this.timelineClickEventHandler}
						timechangeEventHandler={this.timelineTimechangeEventHandler}
						mouseOverEventHandler={this.timelineMouseOverEventHandler}
						selectedItems={selectedItem}
					/>
				</div>
			</div>
		);
	}
}

LiveManagerBottomPanel.propTypes = {
	duration: PropTypes.number,
	mediaId: PropTypes.string.isRequired,
};

const mapStateToProps = ({
	session,
	liveManager,
	snapshotSettings,
	inspectScreenReducer,
	interactTimelineReducer,
}) => ({
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
	timelineItems: liveManager.items,
	timelineGroups: liveManager.groups,
	defaultDate: liveManager.defaultDate,
	duration: liveManager.duration,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	lastSetPlayerPositionTime: liveManager.lastSetPlayerPositionTime,
	playerPositionToSet: liveManager.playerPositionToSet,
	startPlaying: liveManager.startPlaying,
	liveEventStatus: liveManager.liveEventStatus,
	timelineMarkerPosition: liveManager.timelineMarkerPosition,
	selectedSnapShotStartTime: snapshotSettings.selectedSnapShotStartTime,
	selectedSnapShotEndTime: snapshotSettings.selectedSnapShotEndTime,
	snapshotPlayStarted: snapshotSettings.snapshotPlayStarted,
	snapshotPlayPaused: snapshotSettings.snapshotPlayPaused,
	lastSetSnapShotPlayTime: snapshotSettings.lastSetSnapShotPlayTime,
	selectedItem: liveManager.selectedItem,
	liveEventStartTime: liveManager.liveEventStartTime,
	liveEventStopTime: liveManager.liveEventStopTime,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
	deletedMediaId: inspectScreenReducer.deletedMediaId,
	updatedMedia: inspectScreenReducer.updatedMedia,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	liveQueueItem: interactTimelineReducer.liveQueueItem,
	liveChannel: liveManager.liveChannel,
});

const mapDispatchToProps = (dispatch) => ({
	setLiveSettingsId: (id) => dispatch(setLiveSettingsId(id)),
	createTimelineItem: (item) => dispatch(createTimelineItem(item)),
	updateTimelineItem: (item) => dispatch(updateTimelineItem(item)),
	updateTimelineGroup: (group) => dispatch(updateTimelineGroup(group)),
	removeTimelineItem: (item) => dispatch(removeTimelineItem(item)),
	setSelectedTimelineItem: (item) => dispatch(setSelectedTimelineItem(item)),
	createTimelineGroup: (group) => dispatch(createTimelineGroup(group)),
	removeTimelineGroup: (group) => dispatch(removeTimelineGroup(group)),
	setSelectedTimelineGroup: (group) => dispatch(setSelectedTimelineGroup(group)),
	createSnapshot: (accountId, extractVod, body) => dispatch(createSnapshot(accountId, extractVod, body)),
	showNotification: (message) => dispatch(showNotification(message)),
	setDurationOfLiveMedia: (duration) => dispatch(setDurationOfLiveMedia(duration)),
	setLiveStreamPosition: (datetime) => dispatch(setLiveStreamPosition(datetime)),
	setLivePlayerPosition: (datetime, autoplay) => dispatch(setLivePlayerPosition(datetime, autoplay)),
	setTimelineMarkerPosition: (datetime) => dispatch(setTimelineMarkerPosition(datetime)),
	stopSnapShotMedia: () => dispatch(stopSnapShotMedia()),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	setPlayerStreamAsLive: () => dispatch(setPlayerStreamAsLive()),
	setPlayerStreamAsNotLive: () => dispatch(setPlayerStreamAsNotLive()),
	deleteTrackFromMedia: (accId, mediaId, trackId) => dispatch(deleteTrackFromMedia(accId, mediaId, trackId)),
	updateInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(updateInteractTimelineItem(item, setScreenUnModified)),
	loadChannels: (accountId) => dispatch(loadChannels(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveManagerBottomPanel));
