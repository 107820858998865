import React, { useState } from 'react';
import { FormControlLabel, IconButton, ListItem, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import {
	ACTION_GO_TO_SCENE,
	ACTION_GO_TO_URL,
	ACTION_JUMP_TO_TIME,
} from '../../../../../../../../../../constants/scenarioConstant';

import GoToSceneAction from './actionOfEvent/GoToSceneAction';
import GoToUrlAction from './actionOfEvent/GoToUrlAction';
import JumpToTimeAction from './actionOfEvent/JumpToTimeAction';

interface EventProps {
	type: string;
	defined: {
		label: string;
		icon: any;
		actions: { label: string; value: string }[];
	};
	data: any[];
	scenes: any[];
	editingScene: any;
	gotoUrlRef: React.Ref<HTMLInputElement> | undefined;
	openNewTabRef: React.Ref<HTMLInputElement> | undefined;
	handleDestinationSceneChange: () => {};
	handleGotoUrlWidgetChange: () => {};
	handleGotoUrlOpenNewTabChange: () => {};
	handleDeleteWidgetEvent: (_eventType: string) => {};
	handleAddActionToEvent: (_actionType: string, _eventType: string) => {};
	handleJumpToTimeMetaDataChange: (_value: string) => {};
}

const Event = ({
	type,
	defined,
	data,
	scenes,
	editingScene,
	gotoUrlRef,
	openNewTabRef,
	handleDestinationSceneChange,
	handleGotoUrlWidgetChange,
	handleGotoUrlOpenNewTabChange,
	handleDeleteWidgetEvent,
	handleAddActionToEvent,
	handleJumpToTimeMetaDataChange,
}: EventProps) => {
	const { t: translator } = useTranslation();
	const [open, setOpen] = useState(true);

	const { icon, label, actions: availableActions } = defined;
	const selectedAction = data?.[0]?.type;
	const selectedValueOfAction = data?.[0]?.metadata;

	const isGoToSceneAction = selectedAction === ACTION_GO_TO_SCENE.value;
	const isGoToUrlAction = selectedAction === ACTION_GO_TO_URL.value;
	const isJumpToTimeAction = selectedAction === ACTION_JUMP_TO_TIME.value;

	return (
		<div className="widget-event-wrapper">
			<ListItem
				className="widget-event-list-item-title"
				onClick={() => {
					setOpen(!open);
				}}
			>
				{icon}
				<ListItemText
					className="widget-event-title-text"
					primary={<Typography variant="body1">{translator(label)}</Typography>}
				/>
				<IconButton
					size="small"
					onClick={(e) => {
						e.stopPropagation();
						handleDeleteWidgetEvent(type);
					}}
				>
					<DeleteIcon htmlColor="#4d4d4d" />
				</IconButton>
			</ListItem>
			{open && (
				<div className="widget-event-list-item-content">
					<FormControlLabel
						className={'scenario-chart__control'}
						label={'Event'}
						onChange={(e) => {
							handleAddActionToEvent((e.target as any).value, type);
						}}
						labelPlacement="start"
						control={
							<Select variant="standard" value={selectedAction ?? 'none'}>
								<MenuItem value={'none'} key={0}>
									-{translator('COMMON_CHOOSE')}-
								</MenuItem>
								{availableActions.map((action) => (
									<MenuItem value={action.value} key={action.value}>
										{translator(action.label)}
									</MenuItem>
								))}
							</Select>
						}
					/>
					{isGoToSceneAction && (
						<GoToSceneAction
							scenes={scenes}
							editingScene={editingScene}
							metadata={selectedValueOfAction}
							handleDestinationSceneChange={handleDestinationSceneChange}
						/>
					)}

					{isGoToUrlAction && (
						<GoToUrlAction
							gotoUrlRef={gotoUrlRef}
							openNewTabRef={openNewTabRef}
							metadata={selectedValueOfAction}
							handleGotoUrlWidgetChange={handleGotoUrlWidgetChange}
							handleGotoUrlOpenNewTabChange={handleGotoUrlOpenNewTabChange}
						/>
					)}

					{isJumpToTimeAction && (
						<JumpToTimeAction
							metadata={selectedValueOfAction}
							selectedEvent={type}
							handleJumpToTimeMetaDataChange={handleJumpToTimeMetaDataChange}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default Event;
