import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import IconsStore from '../../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../../reusable/IconsStore/IconsList';

import './SpeechToTextDeleteSubtitleDialog.scss';

export const SpeechToTextDeleteSubtitleDialog = ({
	t,
	state,
	defaultAccountId,
	deleteSubtitle,
	handleHideDeleteSelectedSubtitleDialog,
}) => (
	<Dialog
		fullWidth
		maxWidth={'sm'}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		open={state && state.showDeleteSelectedSubtitleDialogStatus}
		className="confirmationPopup"
	>
		<DialogTitle className="dialogTitle" disableTypography>
			<Typography variant="h6">
				{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_DELETE_SINGLE_SUBTITLE') + '?'}
			</Typography>
			<IconButton onClick={handleHideDeleteSelectedSubtitleDialog}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent>
			<DialogContentText>
				<div className="dialogContentWrapper">
					<label>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_DELETE_CONFIRMATION_SINGLE_SUBTITLE')}</label>
					<br></br>
					<label>{t('COMMON_DELETE_CONFIRMATION_SINGLE')}</label>
				</div>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<div className="dialogActionsWrapper">
				<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
					<Button
						variant="contained"
						className="defaultActionBtn neutralBtn"
						onClick={handleHideDeleteSelectedSubtitleDialog}
					>
						{t('BACK')}
					</Button>
					<Button
						variant="contained"
						className="defaultActionBtn deleteButtonWithIcon"
						onClick={() =>
							deleteSubtitle(defaultAccountId, state.currentAssetIdSub, state.currentResourceIdSub)
						}
					>
						<IconsStore iconName={IconsList.TRASHCAN} />
						{t('COMMON_DELETE')}
					</Button>
				</div>
			</div>
		</DialogActions>
	</Dialog>
);
