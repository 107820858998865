import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Close, InfoOutlined, Lock, CheckBox } from '@material-ui/icons';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InteractiveMap } from '../../assets/svg/InteractiveMap.svg';
import { ReactComponent as EditorDashboard } from '../../assets/svg/EditorDashboard.svg';
import { ReactComponent as RecordingDashboard } from '../../assets/svg/RecordingDashboard.svg';
import { ReactComponent as LiveEvent } from '../../assets/svg/LiveEvent.svg';
import { ReactComponent as Interact } from '../../assets/svg/InteractiveProject.svg';
import { ReactComponent as UploadVideo } from '../../assets/svg/video-playlist.svg';
import { ReactComponent as EditVideo } from '../../assets/svg/showing-video-frames.svg';
import { ReactComponent as Recording } from '../../assets/svg/radio_button_checked.svg';
import { joinClassNames } from '../../services/elementHelperService';
import { showMessage } from '../../actions/globalActions';
import { messageTypes } from '../../constants/mediaConstants';
import ColorHub from '../../reusable/ColorHub/ColorHub';
import { LocalStorageService } from '../../services/localStorageService';

export namespace DashboardUtils {
	const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,}$/;
	export const LINKS = {
		contact: 'https://www.qbrick.com/contact-us',
		help: 'https://support.qbrick.com/hc/en-us',
		news: 'https://www.qbrick.com/whats-new/',
	};
	export const DASHBOARD_BASE_CLASSNAME = 'dashboard';
	export const RecentMediaTypes = {
		video: 'video',
		live: 'live',
		interact: 'interact',
		edits: 'edits',
		record: 'record',
		speechToText: 'speechToText',
	};

	export const DASHBOARD_LABELS = {
		video: {
			recentListTitle: 'DASHBOARD_RECENT_MEDIAS_VIDEO_TITLE',
			shortcutLabel: 'DASHBOARD_RECENT_MEDIAS_VIDEO_SHORTCUT_LABEL',
			emptyMessage: 'DASHBOARD_RECENT_MEDIAS_VIDEO_EMPTY_MESSAGE',
			emptySubMessage: 'DASHBOARD_RECENT_MEDIAS_VIDEO_EMPTY_SUB_MESSAGE',
			noMoreMedia: 'DASHBOARD_RECENT_MEDIAS_VIDEO_NO_MORE_MEDIA',
			shortcutBtnLabel: (translate: any) => (
				<>
					<b>{translate('COMMON_UPLOAD')}</b> {translate('COMMON_MEDIA')}
				</>
			),
			shortcutBtnTitle: 'DASHBOARD_SHORTCUT_BUTTON_TITLE_UPLOAD_MEDIA',
			shortcutBtnIcon: <ColorHub component={<UploadVideo />} htmlColor="#000" />,
			backgroundColor: '#126CFC1A',
		},
		edits: {
			featureLabel: 'DASHBOARD_FEATURE_PERMISSION_DIALOG_CHECKBOX_EXPRESS_FEATURE_LABEL',
			recentListTitle: 'DASHBOARD_RECENT_MEDIAS_EXPRESS_TITLE',
			shortcutLabel: 'DASHBOARD_RECENT_MEDIAS_EXPRESS_SHORTCUT_LABEL',
			emptyMessage: 'DASHBOARD_RECENT_MEDIAS_EXPRESS_EMPTY_MESSAGE',
			emptySubMessage: 'DASHBOARD_RECENT_MEDIAS_EXPRESS_EMPTY_SUB_MESSAGE',
			noMoreMedia: 'DASHBOARD_RECENT_MEDIAS_EXPRESS_NO_MORE_MEDIA',
			shortcutBtnLabel: (translate: any) => (
				<>
					<b>{translate('COMMON_EDIT')}</b> {translate('COMMON_VIDEO')}
				</>
			),
			shortcutBtnTitle: 'DASHBOARD_SHORTCUT_BUTTON_TITLE_EDIT_VIDEO',
			shortcutBtnIcon: <ColorHub component={<EditVideo />} htmlColor="#000" />,
			backgroundColor: '#3DAA8B4D',
		},
		interact: {
			featureLabel: 'DASHBOARD_FEATURE_PERMISSION_DIALOG_CHECKBOX_INTERACTIVE_FEATURE_LABEL',
			recentListTitle: 'DASHBOARD_RECENT_MEDIAS_INTERACTIVE_TITLE',
			shortcutLabel: 'DASHBOARD_RECENT_MEDIAS_INTERACTIVE_SHORTCUT_LABEL',
			emptyMessage: 'DASHBOARD_RECENT_MEDIAS_INTERACTIVE_EMPTY_MESSAGE',
			emptySubMessage: 'DASHBOARD_RECENT_MEDIAS_INTERACTIVE_EMPTY_SUB_MESSAGE',
			noMoreMedia: 'DASHBOARD_RECENT_MEDIAS_INTERACTIVE_NO_MORE_MEDIA',
			shortcutBtnLabel: (translate: any) => (
				<>
					{translate('COMMON_CREATE')} <br />
					<b>
						{translate('COMMON_INTERACTIVE')} {translate('COMMON_VIDEO')}
					</b>
				</>
			),
			shortcutBtnTitle: 'DASHBOARD_SHORTCUT_BUTTON_TITLE_CREATE_INTERACTIVE_VIDEO',
			shortcutBtnIcon: <ColorHub component={<Interact />} htmlColor="#000" />,
			backgroundColor: '#EBE2D899',
		},
		record: {
			featureLabel: 'DASHBOARD_FEATURE_PERMISSION_DIALOG_CHECKBOX_RECORDER_FEATURE_LABEL',
			shortcutBtnLabel: (translate: any) => (
				<>
					{translate('COMMON_START')} <b>{translate('COMMON_RECORDING')}</b>
				</>
			),
			shortcutBtnTitle: 'DASHBOARD_SHORTCUT_BUTTON_TITLE_START_RECORDING',
			shortcutBtnIcon: <ColorHub component={<Recording />} htmlColor="#000" />,
			backgroundColor: '#E6B85C4D',
		},
		live: {
			featureLabel: 'DASHBOARD_FEATURE_PERMISSION_DIALOG_CHECKBOX_LIVE_FEATURE_LABEL',
			recentListTitle: 'DASHBOARD_RECENT_MEDIAS_LIVE_TITLE',
			shortcutLabel: 'DASHBOARD_RECENT_MEDIAS_LIVE_SHORTCUT_LABEL',
			emptyMessage: 'DASHBOARD_RECENT_MEDIAS_LIVE_EMPTY_MESSAGE',
			emptySubMessage: 'DASHBOARD_RECENT_MEDIAS_LIVE_EMPTY_SUB_MESSAGE',
			noMoreMedia: 'DASHBOARD_RECENT_MEDIAS_LIVE_NO_MORE_MEDIA',
			shortcutBtnLabel: (translate: any) => (
				<>
					{translate('COMMON_CREATE')} <b>{translate('COMMON_LIVE_EVENT')}</b>
				</>
			),
			shortcutBtnTitle: 'DASHBOARD_SHORTCUT_BUTTON_TITLE_CREATE_LIVE_EVENT',
			shortcutBtnIcon: <ColorHub component={<LiveEvent />} htmlColor="#000" />,
			backgroundColor: '#126CFC1A',
		},
		speechToText: {
			featureLabel: 'DASHBOARD_FEATURE_PERMISSION_DIALOG_CHECKBOX_S2T_FEATURE_LABEL',
		},
	};

	export const INTRODUCING_SLIDES = [
		{
			id: RecentMediaTypes.interact,
			header: 'DASHBOARD_INTRODUCING_SLIDES_INTERACTIVE_HEADER',
			content: `DASHBOARD_INTRODUCING_SLIDES_INTERACTIVE_CONTENT`,
			buttonLabel: 'DASHBOARD_INTRODUCING_SLIDES_INTERACTIVE_BUTTON_LABEL',
			graphic: <InteractiveMap />,
			graphicColor: '#3daa8b',
		},
		{
			id: RecentMediaTypes.edits,
			header: 'DASHBOARD_INTRODUCING_SLIDES_EXPRESS_HEADER',
			content: `DASHBOARD_INTRODUCING_SLIDES_EXPRESS_CONTENT`,
			buttonLabel: 'DASHBOARD_INTRODUCING_SLIDES_EXPRESS_BUTTON_LABEL',
			graphic: <EditorDashboard />,
			graphicColor: '#126CFC1A',
		},
		{
			id: RecentMediaTypes.record,
			header: 'DASHBOARD_INTRODUCING_SLIDES_RECORDER_HEADER',
			content: `DASHBOARD_INTRODUCING_SLIDES_RECORDER_CONTENT`,
			buttonLabel: 'DASHBOARD_INTRODUCING_SLIDES_RECORDER_BUTTON_LABEL',
			graphic: <RecordingDashboard />,
			graphicColor: '#EBE2D8',
		},
	];

	const getButtonCustomStyle = makeStyles((_theme) => ({
		customButton: {
			width: '30px',
			height: '30px',
			minWidth: '30px',
			backgroundColor: 'transparent',
			borderRadius: '4px',
			color: '#909CA5',
			border: 'transparent',
			'&:hover': {
				border: '#F7F8FA',
				backgroundColor: '#F7F8FA',
			},
		},
		customButtonGroup: {
			backgroundColor: '#FFFFFF33',
			padding: '4px',
		},
	}));
	export const useButtonCustomStyle = () => getButtonCustomStyle();

	interface EmptyMediasProps {
		message?: string;
		subMessage?: string;
		baseClassName?: string;
	}
	export const EmptyMedias = ({ message, subMessage, baseClassName }: EmptyMediasProps) => {
		const baseClass = baseClassName ?? DASHBOARD_BASE_CLASSNAME;
		return (
			<div className={`${baseClass}__message-container`}>
				{message && <div className={`${baseClass}__main-message`}>{message}</div>}
				{subMessage && <div className={`${baseClass}__sub-message`}>{subMessage}</div>}
			</div>
		);
	};

	interface FooterProps {
		isMobile?: boolean;
	}
	export const Footer = ({ isMobile }: FooterProps) => {
		const ContactLink = () => {
			const { t } = useTranslation();
			return (
				<>
					<a href={LINKS.contact} target="_blank">
						{t('DASHBOARD_FOOTER_CONTACT_SALES')}
					</a>
					<a href={LINKS.help} target="_blank">
						{t('DASHBOARD_FOOTER_SUPPORT_PORTAL')}
					</a>
					<a href="tel:+46 8 459 90 00" className={`${DASHBOARD_BASE_CLASSNAME}__blue-link`}>
						+46 8 459 90 00
					</a>
					<a href="mailto:support@qbrick.com" className={`${DASHBOARD_BASE_CLASSNAME}__blue-link`}>
						support@qbrick.com
					</a>
				</>
			);
		};

		return (
			<div
				className={joinClassNames(
					`${DASHBOARD_BASE_CLASSNAME}__container`,
					`${DASHBOARD_BASE_CLASSNAME}__container--footer`
				)}
			>
				<div className={`${DASHBOARD_BASE_CLASSNAME}__left`}>
					{isMobile ? <ContactLink /> : `©Qbrick ${new Date().getFullYear()}`}
				</div>

				<div className={`${DASHBOARD_BASE_CLASSNAME}__right`}>
					{isMobile ? `©Qbrick ${new Date().getFullYear()}` : <ContactLink />}
				</div>
			</div>
		);
	};

	interface ShortcutButtonProps {
		type?: string;
		noPermission?: boolean;
		onClick?: (_e: React.MouseEvent) => void;
	}
	export const ShortcutButton = ({ type = RecentMediaTypes.video, noPermission, onClick }: ShortcutButtonProps) => {
		const { t: translator } = useTranslation();
		const labels = (DASHBOARD_LABELS as any)[type];
		return (
			<Button
				className={joinClassNames(
					`${DASHBOARD_BASE_CLASSNAME}__action-item`,
					`${DASHBOARD_BASE_CLASSNAME}--shadow`,
					noPermission ? `${DASHBOARD_BASE_CLASSNAME}__action-item--locked` : ''
				)}
				onClick={onClick}
				title={translator(labels.shortcutBtnTitle)}
			>
				{noPermission && (
					<div className={`${DASHBOARD_BASE_CLASSNAME}__action-locked-layer`}>
						<Lock />
					</div>
				)}
				<div
					className={`${DASHBOARD_BASE_CLASSNAME}__action-icon`}
					style={{ backgroundColor: labels.backgroundColor }}
				>
					{labels.shortcutBtnIcon}
				</div>
				<span>{labels.shortcutBtnLabel(translator)}</span>
			</Button>
		);
	};

	interface PermissionInformationDialogProps {
		permission?: string | null;
		currentPermissions?: any;
		onClose?: () => void;
	}
	export const PermissionInformationDialog = ({
		onClose,
		permission,
		currentPermissions,
	}: PermissionInformationDialogProps) => {
		const dispatch = useDispatch();
		const { t } = useTranslation();
		const username = LocalStorageService.getUserName();
		const defaultAccountName = LocalStorageService.getDefaultAccountName();

		const [email, setEmail] = useState(username);
		const [company, setCompany] = useState(defaultAccountName);
		const [features, setFeatures] = useState<string[]>(permission ? [permission] : []);

		const onFeatureCheckboxChange = useCallback((feature: string, checked: boolean) => {
			if (checked) {
				setFeatures((prev) => [...prev, feature]);
			} else {
				setFeatures((prev) => prev.filter((f) => f !== feature));
			}
		}, []);

		const renderFeatureCheckbox = useCallback(
			(feature: string, t: any) => {
				const labels = (DASHBOARD_LABELS as any)[feature];
				return (
					<FormControlLabel
						name="Extra.1712828381450"
						label={t(labels.featureLabel)}
						labelPlacement="end"
						disabled={currentPermissions[feature]}
						control={
							currentPermissions[feature] ? (
								<div className={`${DASHBOARD_BASE_CLASSNAME}__features--excluded`}>
									<CheckBox />
								</div>
							) : (
								<Checkbox
									style={{ padding: '8px' }}
									color="primary"
									checked={features.includes(feature)}
									onChange={(_e, checked) => onFeatureCheckboxChange(feature, checked)}
									value={t(labels.featureLabel)}
								/>
							)
						}
					/>
				);
			},
			[currentPermissions, features, onFeatureCheckboxChange]
		);

		const onSubmitUpsalesForm = useCallback(
			(e: React.FormEvent) => {
				onClose?.();
				(window as any).validateUpsalesForm?.(e, (status: any) => {
					if (200 === status) {
						dispatch(showMessage('Your request has been sent!', messageTypes.success));
					} else {
						dispatch(showMessage('Unable to send request. Please try again!', messageTypes.warning));
					}
				});
			},
			[dispatch, onClose]
		);

		useEffect(() => {
			setFeatures(permission ? [permission] : []);
			setEmail(LocalStorageService.getUserName());
			setCompany(LocalStorageService.getDefaultAccountName());
		}, [permission]);

		return (
			<Dialog fullWidth maxWidth={'sm'} open={!!permission} className="confirmationPopup">
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">
						<InfoOutlined style={{ marginRight: '8px' }} />
						{t('DASHBOARD_FEATURE_PERMISSION_DIALOG_TITLE')}
					</Typography>
					<IconButton
						onClick={() => {
							onClose?.();
							setFeatures([]);
						}}
					>
						<Close />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<DialogContentText className="dialogContentWrapper">
						<span className="dialogContentText">{t('DASHBOARD_FEATURE_PERMISSION_DIALOG_INFO_TEXT')}</span>
					</DialogContentText>
					<form
						className={`${DASHBOARD_BASE_CLASSNAME}__upsales-form`}
						name={'form_11033uf5ed4e05debb44539c37fd0c98e9b7d4'}
						action="https://power.upsales.com/api/external/formSubmit"
						method="POST"
						onSubmit={onSubmitUpsalesForm}
					>
						<input type="hidden" name="formCid" value="11033" />
						<input type="hidden" name="formId" value="11033uf5ed4e05debb44539c37fd0c98e9b7d4" />
						<input type="hidden" name="isFrame" value="false" />
						<FormControl fullWidth>
							<FormLabel>
								{t('COMMON_EMAIL')} <span className="required-mark">*</span>
							</FormLabel>
							<TextField
								name="Contact.email"
								required
								fullWidth
								variant="outlined"
								color={'primary'}
								placeholder={'Qbrick user email'}
								value={email}
								onChange={(event) => setEmail(event.target.value)}
								error={!!email && !EMAIL_REGEXP.test(email)}
							/>
						</FormControl>

						<FormControl fullWidth>
							<FormLabel>
								{t('COMMON_COMPANY')} <span className="required-mark">*</span>
							</FormLabel>
							<TextField
								name="Client.name"
								required
								fullWidth
								variant="outlined"
								color="primary"
								placeholder={'Qbrick user company'}
								value={company}
								onChange={(event) => setCompany(event.target.value)}
							/>
						</FormControl>

						<FormControl>
							<FormLabel style={{ marginTop: '24px' }}>
								{t('DASHBOARD_FEATURE_PERMISSION_DIALOG_APP_SELECTION_INFO_TEXT')}
							</FormLabel>
							<div className={`${DASHBOARD_BASE_CLASSNAME}__features`}>
								{renderFeatureCheckbox(RecentMediaTypes.interact, t)}
								{renderFeatureCheckbox(RecentMediaTypes.edits, t)}
								{renderFeatureCheckbox(RecentMediaTypes.live, t)}
								{renderFeatureCheckbox(RecentMediaTypes.record, t)}
								{renderFeatureCheckbox(RecentMediaTypes.speechToText, t)}
							</div>
						</FormControl>
						<Button
							variant="contained"
							color="primary"
							style={{ whiteSpace: 'nowrap', width: '100%', margin: '12px 0' }}
							type="submit"
							disabled={!email || !company || !EMAIL_REGEXP.test(email)}
						>
							{t('COMMON_SEND')}
						</Button>
					</form>
				</DialogContent>
			</Dialog>
		);
	};
}
