import React, { useRef, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Typography,
} from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import CatalogLimitedAccess, { ACCESS_LEVEL } from '../../../../reusable/CatalogTree/CatalogLimitedAccess';
import { useDispatch, useSelector } from 'react-redux';
import { refreshCatalogs, refreshMedias, updateCatalogAccessLimit } from '../../../../actions/publishActions';
import { showMessage } from '../../../../actions/globalActions';
import { messageTypes } from '../../../../constants/mediaConstants';
import {
	compareCatalogAccess,
	findParentsCatalog,
	getCatalogAccessLimitHash,
	getCatalogHash,
	getUsersCatalogAccessLimitHasBeenChanged,
	standarlizeRestrictionUser,
} from '../../../../reusable/MediaLibrary/utils';

interface EditCatalogDialogProps {
	open: boolean;
	editingCatalog: any;
	onClose: () => void;
}

const EditCatalogDialog = ({ open, editingCatalog, onClose }: EditCatalogDialogProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const { catalogs } = useSelector((state) => (state as any).publish);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [infoMessage, setInfoMessage] = useState<string>('');
	const limitedAccessRef = useRef<any>(null);

	const handleUpdateAccessLimit = () => {
		setIsSubmit(true);

		const catalogMapNeedToUpdate: { [key: string]: any } = {};
		let isNeedToClearChildCatalogAccess = false;

		let restrictionUsersAfterUpdate = limitedAccessRef.current?.getRestrictionUsers();
		const isCatalogInheritedAccess = limitedAccessRef.current?.isCatalogInheritedAccess();
		const catalogHashMap = getCatalogHash(catalogs);

		const { id: editingCatalogId, access: editingCatalogAccess } = editingCatalog;
		let id = editingCatalogId;
		let access = editingCatalogAccess;

		if (isCatalogInheritedAccess) {
			const { id: parentCatalogId, access: parentCatalogAccess } =
				limitedAccessRef.current?.getParentRestrictionUsersInfo();

			const isAccessEqual = compareCatalogAccess(restrictionUsersAfterUpdate, parentCatalogAccess);

			if (!isAccessEqual) {
				id = parentCatalogId;
				access = catalogHashMap[parentCatalogId].access ?? {};
				isNeedToClearChildCatalogAccess = true;
			} else {
				restrictionUsersAfterUpdate = {};
			}
		}

		const restrictionUsersBefore = standarlizeRestrictionUser(access);
		const usersNeedToBeUpdated = getUsersCatalogAccessLimitHasBeenChanged(
			restrictionUsersBefore,
			restrictionUsersAfterUpdate
		);

		const { newUsers, removedUsers, updatedUsers } = usersNeedToBeUpdated;

		if (
			Object.keys(newUsers).length === 0 &&
			Object.keys(removedUsers).length === 0 &&
			Object.keys(updatedUsers).length === 0
		) {
			setIsSubmit(false);
			limitedAccessRef.current?.resetAndCloseModal();
			setInfoMessage('');
			return;
		}

		const oldCatalogAccessLimitHash = getCatalogAccessLimitHash(catalogs);

		if (Object.keys(newUsers).length > 0) {
			const catalogsToAddPermission = findParentsCatalog(catalogs, id);

			Object.keys(newUsers).forEach((userId: any) => {
				catalogsToAddPermission.forEach((catalogId: any) => {
					const oldCatalogAccess = oldCatalogAccessLimitHash[catalogId];

					if (Object.keys(oldCatalogAccess).length === 0 && catalogId !== id) {
						//in case parents catalog is 'everyone' which mean no users in oldCatalogAccess object, then no need to update
						return;
					}

					const oldAccessLevelOfUser = oldCatalogAccess[userId];
					const newCatalogAccess = oldCatalogAccess
						? {
								...oldCatalogAccess,
								[userId]:
									catalogId === id
										? newUsers[userId]
										: oldAccessLevelOfUser
										? oldAccessLevelOfUser
										: { id: userId, accessLevel: ACCESS_LEVEL.LIMITED.type },
						  }
						: {
								[userId]:
									catalogId === id
										? newUsers[userId]
										: { id: userId, accessLevel: ACCESS_LEVEL.LIMITED.type },
						  };
					const catalogAlreadyInUpdateMap = catalogMapNeedToUpdate[
						catalogId as keyof typeof catalogMapNeedToUpdate
					] as any;

					const newCatalogToUpdate = catalogAlreadyInUpdateMap
						? { ...catalogAlreadyInUpdateMap, ...newCatalogAccess }
						: newCatalogAccess;
					catalogMapNeedToUpdate[catalogId as keyof typeof catalogMapNeedToUpdate] = newCatalogToUpdate;
				});
			});
		}

		if (Object.keys(removedUsers).length > 0) {
			const catalogToRemovePermission = [id];

			Object.keys(removedUsers).forEach((userId: any) => {
				catalogToRemovePermission.forEach((catalogId: any) => {
					const oldCatalogAccess = { ...oldCatalogAccessLimitHash[catalogId] };
					const catalogAlreadyInUpdateMap = catalogMapNeedToUpdate[
						catalogId as keyof typeof catalogMapNeedToUpdate
					] as any;

					if (catalogAlreadyInUpdateMap) {
						delete catalogAlreadyInUpdateMap[userId];
						catalogMapNeedToUpdate[catalogId as keyof typeof catalogMapNeedToUpdate] =
							catalogAlreadyInUpdateMap;
					} else {
						delete oldCatalogAccess[userId];
						catalogMapNeedToUpdate[catalogId as keyof typeof catalogMapNeedToUpdate] = oldCatalogAccess;
					}
				});
			});
		}

		if (Object.keys(updatedUsers).length > 0) {
			Object.keys(updatedUsers).forEach((userId: any) => {
				const oldCatalogAccess = oldCatalogAccessLimitHash[id];
				const catalogAlreadyInUpdateMap = catalogMapNeedToUpdate[
					id as keyof typeof catalogMapNeedToUpdate
				] as any;

				if (catalogAlreadyInUpdateMap) {
					catalogMapNeedToUpdate[id as keyof typeof catalogMapNeedToUpdate] = {
						...catalogAlreadyInUpdateMap,
						[userId]: updatedUsers[userId],
					};
				} else {
					catalogMapNeedToUpdate[id as keyof typeof catalogMapNeedToUpdate] = {
						...oldCatalogAccess,
						[userId]: updatedUsers[userId],
					};
				}
			});
		}

		if (isNeedToClearChildCatalogAccess) {
			catalogMapNeedToUpdate[editingCatalogId] = {};
		}

		const promiseList = Object.entries(catalogMapNeedToUpdate).map(([catalogId, accessLevel]: any) => {
			return dispatch(updateCatalogAccessLimit(defaultAccountId, catalogId, accessLevel));
		});

		Promise.all(promiseList).then((data: any) => {
			if (data && data.length) {
				setTimeout(() => {
					// delay 1s for backend have time to update
					limitedAccessRef.current?.resetAndCloseModal();
					setInfoMessage('');
					setIsSubmit(false);
					dispatch(showMessage(t('MODAL_EDIT_CATALOG_SUCCESS_NOTIFICATION'), messageTypes.success));
					dispatch(refreshMedias());
					dispatch(refreshCatalogs());
				}, 1000);
			} else {
				limitedAccessRef.current?.resetAndCloseModal();
				setInfoMessage('');
				dispatch(showMessage(t('MODAL_EDIT_CATALOG_FAILED_NOTIFICATION'), messageTypes.error));
				setIsSubmit(false);
			}
		});
	};

	return (
		<Dialog
			onClose={() => {
				limitedAccessRef.current?.resetAndCloseModal();
				setInfoMessage('');
			}}
			aria-labelledby="simple-dialog-title"
			open={!!open}
			fullWidth
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('MODAL_EDIT_CATALOG_TITLE')}</Typography>
				<IconButton
					onClick={() => {
						limitedAccessRef.current?.resetAndCloseModal();
						setInfoMessage('');
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>

			<CatalogLimitedAccess
				open={open}
				ref={limitedAccessRef}
				onClose={onClose}
				preDefineCatalog={editingCatalog}
				setInfoMessage={setInfoMessage}
			/>

			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
						<Button
							variant="contained"
							className="defaultActionBtn"
							onClick={handleUpdateAccessLimit}
							disabled={isSubmit}
						>
							{isSubmit ? (
								<>
									<span>{t('MODAL_EDIT_CATALOG_BUTTON_LABEL')}</span>{' '}
									<CircularProgress size={10} color="inherit" />{' '}
								</>
							) : (
								<span>{t('MODAL_EDIT_CATALOG_BUTTON_LABEL')}</span>
							)}
						</Button>
					</div>
					{infoMessage && (
						<Box display="flex" alignItems="center" paddingX="12px" paddingBottom="12px">
							<InfoOutlined />
							<Typography style={{ paddingLeft: 10 }} variant="caption">
								{infoMessage}
							</Typography>
						</Box>
					)}
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default EditCatalogDialog;
