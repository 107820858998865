import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import IconsStore from '../../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../../reusable/IconsStore/IconsList';
import { dialogSizes } from '../../../../constants/dialogSizesConstant';
import { generateId } from '../../../../services/stringHelperService';
import { useDispatch, useSelector } from 'react-redux';
import { assignMediaItemToTheSelectedPlaylist, createNewPlaylist } from '../../../../actions/publishActions';
import { showMessage } from '../../../../actions/globalActions';
import { messageTypes } from '../../../../constants/mediaConstants';
import { handlePostQueriesForSearchResult } from '../../../../actions/searchActions';

interface CreateNewPlaylistDialogProps {
	open: boolean;
	previousSource?: string;
	backToThePreviousDialogBoxIfSpecified?: () => void;
	onClose?: () => void;
	onCreateNewPlaylist?: (_newPlaylistId: string) => void;
	mediasToAssign?: any[];
	searchQuery?: string;
	sortType?: string;
	saveFilterAsPlaylist?: boolean;
}

const CreateNewPlaylistDialog: React.FC<CreateNewPlaylistDialogProps> = ({
	open,
	previousSource,
	onClose,
	backToThePreviousDialogBoxIfSpecified,
	onCreateNewPlaylist,
	mediasToAssign,
	searchQuery,
	sortType,
	saveFilterAsPlaylist,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const { defaultAccountId } = useSelector((state) => (state as any).session);

	const [newPlaylistTitle, setNewPlaylistTitle] = useState('');

	const handleCreateNewPlaylist = () => {
		if (saveFilterAsPlaylist) {
			createNewDynamicPlaylist();
			return;
		}

		const playlistId = generateId();
		dispatch(createNewPlaylist(defaultAccountId, generateId(), playlistId, newPlaylistTitle)).then((data: any) => {
			if (data) {
				assignMediasToPlaylist(playlistId);
				onCreateNewPlaylist?.(playlistId);
			}
		});
	};

	const createNewDynamicPlaylist = () => {
		const playlistId = generateId();
		dispatch(
			handlePostQueriesForSearchResult(
				defaultAccountId,
				generateId(),
				generateId(),
				playlistId,
				newPlaylistTitle,
				searchQuery ?? 'q=*',
				sortType,
				50
			)
		).then((data: any) => {
			if (data) {
				dispatch(showMessage(t('LABEL_NEW_PLAYLIST_CREATED'), messageTypes.success));
				onCreateNewPlaylist?.(playlistId);
			}
		});
	};

	const assignMediasToPlaylist = (playlistId: string) => {
		if (!mediasToAssign || mediasToAssign.length === 0) {
			return;
		}

		mediasToAssign.map((media: any) => {
			dispatch(assignMediaItemToTheSelectedPlaylist(defaultAccountId, playlistId, media.id)).then((data: any) => {
				if (!data) {
					dispatch(showMessage(t('MODAL_CREATE_NEW_PLAYLIST_FAILED_ASSIGN_TO_PLAYLIST'), messageTypes.error));
					return;
				}
				dispatch(
					showMessage(t('MODAL_CREATE_NEW_PLAYLIST_LABEL_ASSIGN_TO_PLAYLIST_SUCCESS'), messageTypes.success)
				);
			});
		});
	};

	return (
		<>
			<Dialog
				id="createNewPlaylistsDialogBox"
				fullWidth={true}
				maxWidth={dialogSizes.SMALL as any}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				open={open}
			>
				<DialogTitle className="dialogTitle" disableTypography>
					{previousSource === 'playlists' && (
						<Typography variant="h6">{t('MODAL_CREATE_NEW_PLAYLIST_LABEL')}</Typography>
					)}
					{previousSource !== 'playlists' && (
						<Typography variant="h6">
							{
								<a
									className="createNewPlaylistsDialog__backBtn"
									onClick={backToThePreviousDialogBoxIfSpecified}
								>
									<IconsStore iconName={IconsList.LEFT_ARROW} color="#444A58" />
									{t('MODAL_CREATE_NEW_PLAYLIST_GO_BACK_TO_LIST_LABEL')}
								</a>
							}
						</Typography>
					)}
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</DialogTitle>
				<div className="dialogContentWrapper">
					<label>{t('MODAL_CREATE_NEW_PLAYLIST_TITLE_OF_PLAYLIST')} *</label>
					<div className="fullWidthControl">
						<input
							type="text"
							value={newPlaylistTitle}
							className={`genericTextInput`}
							onChange={(e) => setNewPlaylistTitle(e.target.value)}
							placeholder={t('MODAL_CREATE_NEW_PLAYLIST_NAME_PLAY_HOLDER')}
						/>
					</div>
				</div>
				<DialogActions>
					<div className="dialogActionsWrapper">
						<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
							<Button
								variant="contained"
								className={`defaultActionBtn ${
									previousSource !== 'playlists' ? 'goBackBtnExtended' : ''
								}`}
								onClick={handleCreateNewPlaylist}
								disabled={newPlaylistTitle ? false : true}
							>
								{previousSource === 'playlists'
									? t('MODAL_CREATE_NEW_PLAYLIST_CREATE_PLAYLIST_LABEL')
									: t('COMMON_ADD_TO_PLAYLIST')}
							</Button>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CreateNewPlaylistDialog;
