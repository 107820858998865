import React, { useCallback, forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingOverlay from 'react-loading-overlay';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';

import display_blue_fill from '../../../assets/images/display-blue-fill.svg';
import phone_white_fill from '../../../assets/images/phone-white-fill.svg';
import display_grey_fill_large from '../../../assets/images/display-grey-fill-large.svg';
import phone_blue_fill_large from '../../../assets/images/phone-blue-fill-large.svg';
import { joinClassNames } from '../../../services/elementHelperService';
import {
	getScenario,
	setRefreshSceneMedia,
	setOriginScenarioAction,
	setSceneLoading,
	updateScenarioActiveLayoutAction,
	updateUploadingScenesAction,
} from '../../../actions/scenarioActions';
import { showMessage } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';
import { screenRoutes } from '../../../constants/routesPath';
import { getDifferenceBetweenTime } from '../../../services/timeStampService';
import { getEncoderJobs, refreshMedias } from '../../../actions/publishActions';

const baseClassName = 'scenario-chart';
const headerBaseClassName = `${baseClassName}__header`;
const bodyBaseClassName = `${baseClassName}__body`;

const SAVING_STATE_IDLE = 'SCENARIO_STORYBOARD_SAVING_STATE_IDLE';
const SAVING_STATE_SAVING = 'SCENARIO_STORYBOARD_SAVING_STATE_SAVING';

const ScenarioScreenContainer = forwardRef(function ScenarioContainer(props, ref) {
	const dispatch = useDispatch();
	const param = useParams();
	const history = useHistory();

	const { t } = props;

	const sceneLoading = useSelector((state) => state.scenarioReducer.sceneLoading);
	const scenario = useSelector((state) => state.scenarioReducer.scenario);
	const scenes = useSelector((state) => state.scenarioReducer.scenes);
	const uploadingScenes = useSelector((state) => state.scenarioReducer.uploadingScenes);
	const sceneMediaIdsToRefresh = useSelector((state) => state.scenarioReducer.sceneMediaIdsToRefresh);
	const activeLayout = useSelector((state) => state.scenarioReducer.activeLayout ?? 'desktop');
	const defaultAccountId = useSelector((state) => state.session.defaultAccountId);

	const onGoingEncodingIntervalId = useRef(undefined);
	const [savingState, setSavingState] = useState(SAVING_STATE_IDLE);
	const [durationFromLastUpdated, setDurationFromLastUpdated] = useState('');
	const [lastUpdated, setLastUpdated] = useState(null);

	useEffect(() => {
		setLastUpdated(scenario.updated ? new Date(scenario.updated) : null);
	}, [scenario.updated]);

	useEffect(() => {
		setDurationFromLastUpdated(
			new Date() - lastUpdated > 1000 ? getDifferenceBetweenTime(new Date(), lastUpdated) : ''
		);
		const calculatedDurationFromLastUpdatedTimer = setInterval(() => {
			if (!lastUpdated) {
				setDurationFromLastUpdated('');
				return;
			}
			setDurationFromLastUpdated(getDifferenceBetweenTime(new Date(), lastUpdated));
		}, 10000);

		return () => {
			clearInterval(calculatedDurationFromLastUpdatedTimer);
		};
	}, [lastUpdated]);

	useImperativeHandle(ref, () => ({
		onSaveScenario: (isRedirectUser) => {
			return onSaveChanges(isRedirectUser);
		},
		isAutoSaving: (value) => {
			if (value) {
				setSavingState(SAVING_STATE_SAVING);
				return;
			}
			setLastUpdated(new Date());
			setSavingState(SAVING_STATE_IDLE);
		},
	}));

	const setActiveLayout = useCallback(
		(layout) => {
			dispatch(updateScenarioActiveLayoutAction(layout));
			props.onActiveLayoutChange?.(layout);
		},
		[props.onActiveLayoutChange, dispatch]
	);

	const _loadScenario = (forceLoad = false) => {
		if (!forceLoad && (!scenario || !scenario.id)) {
			dispatch(setSceneLoading(false));
			return;
		}
		dispatch(getScenario(defaultAccountId, scenario.id)).then((updatedScenario) => {
			if (!updatedScenario) {
				dispatch(setSceneLoading(false));
				dispatch(showMessage("Can't get scenario", messageTypes.error));
				return;
			}

			dispatch(setOriginScenarioAction(updatedScenario));
			dispatch(setSceneLoading(false));
		});
	};

	//remove soon
	const onSaveChanges = (isRedirectUser) => {
		if (!scenario || !scenario.id) {
			setSavingState(SAVING_STATE_IDLE);
			return;
		}

		if (!scenario.defaults?.sceneId) {
			setSavingState(SAVING_STATE_IDLE);
			dispatch(showMessage(t('SCENARIO_STORYBOARD_MISSING_HOME_SCENE'), messageTypes.info));
			return;
		}

		if (isRedirectUser) {
			dispatch(setSceneLoading(true));
		}

		props.onSaveChangesClick?.();

		return Promise.resolve();
	};

	const changeScene = (sceneId) => {
		history.push(`${screenRoutes.PUBLISH_SCENARIOS}/${param.scenarioId}/detail/scenes/${sceneId}/edit`, '_self');
	};

	const sortedScene = _.orderBy(scenes, [(scene) => scene.name?.toLowerCase(), 'id'], ['asc']);

	const checkForOngoingEncoding = useCallback(() => {
		dispatch(getEncoderJobs(defaultAccountId)).then((res) => {
			const allJobs = res?.jobs ?? [];
			let newFinishedJobs = [];
			const newEncodingFiles = [];
			for (let i = 0; i < allJobs.length; i++) {
				const job = allJobs[i];
				if (job?.status === 'SUCCESS') {
					newFinishedJobs.push(job.media_id);
				} else if ((job.steps ?? []).find((s) => s.type === 'encode' && s.status === 'SUCCESS')) {
					newFinishedJobs.push(job.media_id);
				} else {
					newEncodingFiles.push(job.media_id);
				}
			}

			newFinishedJobs = newFinishedJobs.filter((f) => uploadingScenes.find((u) => u === f));
			if (newFinishedJobs.join(',') !== sceneMediaIdsToRefresh.join(',')) {
				dispatch(refreshMedias());
			}

			dispatch(setRefreshSceneMedia(newFinishedJobs));
			dispatch(updateUploadingScenesAction(newEncodingFiles));
		});
	}, [defaultAccountId, dispatch, sceneMediaIdsToRefresh, uploadingScenes]);

	useEffect(() => {
		if (onGoingEncodingIntervalId?.current) {
			onGoingEncodingIntervalId?.current && clearInterval(onGoingEncodingIntervalId.current);
		}
		onGoingEncodingIntervalId.current = setInterval(checkForOngoingEncoding, 10000);

		return () => onGoingEncodingIntervalId?.current && clearInterval(onGoingEncodingIntervalId.current);
	}, [checkForOngoingEncoding]);

	return (
		<div className={joinClassNames(baseClassName, props.classNames)}>
			<div className={headerBaseClassName}>
				<div className={`${headerBaseClassName}-slot ${headerBaseClassName}-slot--left`}>
					<Button color="primary" startIcon={<ChevronLeft />} onClick={props.onExitButtonClick}>
						{props.t('COMMON_BACK')}
					</Button>
					{param.sceneId && (
						<Select variant="standard" value={param.sceneId} className={`${baseClassName}__select`}>
							{sortedScene.map((scene) => (
								<MenuItem
									value={scene?.id}
									key={scene?.id}
									onClick={() => {
										changeScene(scene?.id);
									}}
								>
									{scene?.name?.length > 10 ? `${scene.name.substring(0, 10)}...` : scene?.name}
								</MenuItem>
							))}
						</Select>
					)}
				</div>
				{!props.hideDevicesSwitch && (
					<div className={`${headerBaseClassName}-slot ${headerBaseClassName}-slot--center`}>
						<ButtonGroup>
							<Button
								variant="outlined"
								color={activeLayout === 'desktop' ? 'primary' : undefined}
								onClick={() => setActiveLayout('desktop')}
								title="Desktop"
							>
								<img
									style={{ width: '15px', height: '12px' }}
									src={activeLayout === 'desktop' ? display_blue_fill : display_grey_fill_large}
								/>
							</Button>
							<Button
								variant="outlined"
								color={activeLayout === 'mobile' ? 'primary' : undefined}
								onClick={() => setActiveLayout('mobile')}
								title="Mobile"
							>
								<img
									style={{ width: '9px', height: '16px' }}
									src={activeLayout === 'mobile' ? phone_blue_fill_large : phone_white_fill}
								/>
							</Button>
						</ButtonGroup>
					</div>
				)}
				<div className={`${headerBaseClassName}-slot ${headerBaseClassName}-slot--right`}>
					<div className={`${baseClassName}__saving-status`}>
						<span>{`${t(savingState)}`}</span>
						<div>
							{lastUpdated &&
								durationFromLastUpdated !== '' &&
								`${t('SCENARIO_STORYBOARD_LAST_SAVED')} ${durationFromLastUpdated}`}
						</div>
					</div>
					<Button
						variant="outlined"
						color="primary"
						onClick={props.onPreviewButtonClick}
						style={{ marginRight: '12px' }}
					>
						{props.t('COMMON_PREVIEW')}
					</Button>
				</div>
			</div>

			<div className={bodyBaseClassName ? bodyBaseClassName : 'scenario-chart__body'}>
				{props.children}
				{sceneLoading && (
					<LoadingOverlay
						className="loadingStreamOverlay"
						active
						spinner={<ScaleLoader color={'#ffffff'} />}
						text={''}
					/>
				)}
			</div>
		</div>
	);
});

export default ScenarioScreenContainer;
