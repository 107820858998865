import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Layout from './components/Layout';
import SectionLayout from './components/SectionLayout';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormControl, Select, Box } from '@material-ui/core';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { _optionsWithByteFormat } from './../../utils/commonUtil';
import { getRealTime } from './../../services/analyticsServices';
import { useTranslation } from 'react-i18next';

const AnalyticsRealTimeScreen = () => {
	const { t } = useTranslation();
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const timeRangeArr = [
		{ value: '1 Hour', label: 'ANALYTICS_TIME_RANGE_1_HOUR' },
		{ value: '1 Day', label: 'ANALYTICS_TIME_RANGE_1_DAY' },
		{ value: '1 Week', label: 'ANALYTICS_TIME_RANGE_1_WEEK' },
		{ value: '1 Month', label: 'ANALYTICS_TIME_RANGE_1_MONTH' },
	];
	const [filterByTime, setFilterByTime] = useState(timeRangeArr[1].value);
	const [realTimeData, setRealTimeData] = useState([]);
	const [resolutionVal, setResolutionVal] = useState(300000);
	const [loading, setLoading] = useState(false);

	function reStuctureData(items) {
		const itemsKeys = items && Object.keys(items);

		let dataCollection = [];
		let colors = [
			'rgb(124, 181, 236)',
			'rgb(252, 80, 80)',
			'rgb(12, 156, 238)',
			'rgb(61, 189, 194)',
			'rgb(161, 244, 128)',
		];
		itemsKeys?.map((item, i) => {
			const obj = {
				name: item,
				color: colors[i] || '#333',
				data: items[item]?.map((ite) => {
					const views = {
						x: ite.tValue,
						y: ite.value,
					};
					return views;
				}),
			};

			dataCollection.push(obj);
		});

		return dataCollection;
	}

	useEffect(() => {
		const today = moment().valueOf();
		const yesterDay = moment().subtract(1, 'days').valueOf();
		const lastWeek = moment().subtract(7, 'days').valueOf();
		const lastMonth = moment().subtract(28, 'days').valueOf();
		let startDate;
		let endDate;
		let resolution;
		setLoading(true);
		switch (filterByTime) {
			case timeRangeArr[1].value:
				startDate = yesterDay;
				endDate = today;
				resolution = 300000;
				break;
			case timeRangeArr[2].value:
				startDate = lastWeek;
				endDate = today;
				resolution = 2100000;
				break;
			case timeRangeArr[3].value:
				startDate = lastMonth;
				endDate = today;
				resolution = 8400000;
				break;
			default:
				startDate = moment().subtract(60, 'minutes').valueOf();
				endDate = today;
				resolution = 300000;
				break;
		}
		setResolutionVal(resolution);
		const data = getRealTime(accountId, startDate, endDate, resolution);
		data.then((items) => {
			setRealTimeData(reStuctureData(items));
			setLoading(false);
		});
	}, [filterByTime, accountId]);

	return (
		<Layout title="ANALYTICS_TITLE_CDN_ANALYTICS">
			<div className="sec filterSec">
				<Box mr={2}>
					<FormControl>
						<InputLabel id="time">Time range</InputLabel>
						<Select
							native
							inputProps={{
								name: 'time',
								id: 'time',
							}}
							value={filterByTime}
							label={t('ANALYTICS_TIME_RANGE')}
							onChange={(e) => setFilterByTime(e.target.value)}
						>
							{timeRangeArr.map(({ label, value }) => (
								<option key={value} value={value}>
									{t(label)}
								</option>
							))}
						</Select>
					</FormControl>
				</Box>
			</div>
			<SectionLayout
				title="ANALYTICS_TITLE_REAL_TIME"
				subTitle="Average distribution aggregated over 5 minute time periods"
				addClass="realtime"
				loading={loading}
			>
				<Grid item xs={12}>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_optionsWithByteFormat(realTimeData, 'area', undefined, '', '', resolutionVal)}
					/>
				</Grid>
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsRealTimeScreen;
