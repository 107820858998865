import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { resizeScreenAfterCalculation } from '../../actions/inspectScreenActions';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { createBrowserHistory } from 'history';
import { menuKeys } from './../../constants/inspectScreenMenu';
import { getUrlParameters } from '../../services/componentReusableService';
import { screenRoutes } from '../../constants/routesPath';

import './ScreenParentContainer.scss';

const history = createBrowserHistory();

class ScreenParentContainer extends Component {
	constructor(props) {
		super(props);
		this.historyUnlisten = null;
	}

	UNSAFE_componentWillMount() {
		window.addEventListener('resize', this.handleScrollForBody, true);
		this.historyUnlisten = history.listen(() => {
			this.handleScrollForBody();
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.appFullScreenMode !== this.props.appFullScreenMode) {
			this.handleScrollForBody();
		}
	}

	isLiveOrInteractScreen = () => {
		if (window.location.hash.match('liveManager') || window.location.hash.match('interactiveManager')) {
			return true;
		}
		return false;
	};

	getTextOfLoadingAnimation = () => {
		const { t } = this.props;
		if (window.location.hash.match('liveManager')) {
			return t('COMMON_UPLOADING_IMAGE');
		} else {
			return t('COMMON_ELEMENT_IS_UPDATING');
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleScrollForBody, true);
		this.historyUnlisten();
	}

	componentDidMount() {
		this.handleScrollForBody();
	}

	isHeaderHidden = () => {
		const { appFullScreenMode } = this.props;
		let urlParams = getUrlParameters(window.location.hash);
		let selectedMenu = urlParams.selectedMenu ? urlParams.selectedMenu : '';
		if (selectedMenu === menuKeys.details || appFullScreenMode) {
			return true;
		}
		return false;
	};

	handleScrollForBody = () => {
		if (this.props.noSizeCalculation) {
			return;
		}

		let $bodyForScroll = document.getElementsByClassName('screenParentContainer');
		let $loginHeader = document.getElementById('loginHeader');
		let $mainHeader = document.getElementById('mainHeader');
		let headerMargin = 8; //8px for box shadow height
		let $header = $loginHeader || $mainHeader;
		if (this.isLiveOrInteractScreen() || this.isHeaderHidden()) {
			headerMargin = 0;
		}
		if (!$bodyForScroll) {
			return;
		} else {
			$bodyForScroll = $bodyForScroll[0];
		}
		let headerHeight = $header ? $header.offsetHeight + headerMargin : this.isHeaderHidden() ? 0 : 65;
		if ($bodyForScroll) {
			let heightToBeAdded = window.innerHeight - headerHeight;
			if (Number(heightToBeAdded) && window.location.hash.match(screenRoutes.LOGIN)) {
				$bodyForScroll.style.height = heightToBeAdded + 6 + 'px'; //adding 6 since there is no border in login screen
				$bodyForScroll.style.padding = '0px';
			} else {
				if (Number(heightToBeAdded)) {
					$bodyForScroll.style.height = heightToBeAdded - 1 + 'px';
				}
			}
		}
		this.props.resizeScreenAfterCalculation();
	};

	render() {
		const { isElementUpdating, appFullScreenMode, classNames } = this.props;

		return (
			<LoadingOverlay
				active={isElementUpdating}
				spinner={<ScaleLoader color={'#ffffff'} />}
				text={this.getTextOfLoadingAnimation()}
			>
				<div
					id="screenParentContainer"
					attr={this.isHeaderHidden() || appFullScreenMode ? 'headerHidden' : 'headerShown'}
					className={['screenParentContainer', classNames ?? ''].join(' ').trim()}
				>
					{this.props.children}
				</div>
			</LoadingOverlay>
		);
	}
}

const mapStateToProps = ({ interactElementsReducer, apps }) => ({
	isElementUpdating: interactElementsReducer.isElementUpdating,
	appFullScreenMode: apps.appFullScreenMode,
});

const mapDispatchToProps = (dispatch) => ({
	resizeScreenAfterCalculation: () => dispatch(resizeScreenAfterCalculation()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ScreenParentContainer);
