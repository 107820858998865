import { Button, ButtonGroup, FormControlLabel, IconButton, Input } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ColorPicker from '../../../../../../../../../reusable/ColorPicker/ColorPicker';
import { Clear } from '@material-ui/icons';
import { IconNames, getIconByName } from '../../../../../../../../../reusable/IconLibrary/IconLibrary';
import CardData from '../../../../../../../utils/CardData';

type IconProps = {
	widgetTemplate: {
		style: {
			textLabel?: {
				color?: string;
				hoverColor?: string;
			};
		};
		settings: { iconKey?: string };
	};

	isAtHoverSetting: boolean;
	setHoverSetting: (_value: boolean) => {};
	handleTextLabelColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelHoverColorChange: (_event: {} | undefined, _color: {}) => {};
	onShowIconLibraryPickerModal: () => {};
};

const Icon = ({
	widgetTemplate,
	isAtHoverSetting,
	setHoverSetting,
	handleTextLabelColorChange,
	handleTextLabelHoverColorChange,
	onShowIconLibraryPickerModal,
}: IconProps) => {
	const { t: translator } = useTranslation();

	const {
		style: {
			textLabel: { color = '#ffffff', hoverColor = '#126cfc' } = {
				color: '#ffffff',
				hoverColor: '#126cfc',
			},
		},
		settings: { iconKey },
	} = widgetTemplate;

	return (
		<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT')}>
			<div className="scene-widget__control">
				<ButtonGroup className="scene-widget__buttonGroup">
					<Button
						variant="outlined"
						color={isAtHoverSetting ? undefined : 'primary'}
						onClick={() => setHoverSetting(false)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_NORMAL')}
					</Button>
					<Button
						variant="outlined"
						color={isAtHoverSetting ? 'primary' : undefined}
						onClick={() => setHoverSetting(true)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_HOVER')}
					</Button>
				</ButtonGroup>
			</div>

			<div className={'scene-widget__control'}>
				<div className="scene-widget__iconSelector--placeholder" onClick={onShowIconLibraryPickerModal}>
					{getIconByName(iconKey as IconNames, {})}
				</div>
			</div>

			{!isAtHoverSetting && (
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_PRIMARY_COLOR')}
					onChange={handleTextLabelColorChange}
					labelPlacement="start"
					control={
						<div className="scene-widgets__sort-input-control">
							<ColorPicker
								width="40px"
								height="22px"
								padding="2px"
								color={color}
								onChangeComplete={(color: any) => handleTextLabelColorChange(undefined, color)}
							/>
							<Input value={color} />
							<IconButton size="small" onClick={() => handleTextLabelColorChange(undefined, { hex: '' })}>
								<Clear />
							</IconButton>
						</div>
					}
				/>
			)}

			{isAtHoverSetting && (
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_HOVER_COLOR')}
					onChange={handleTextLabelHoverColorChange}
					labelPlacement="start"
					control={
						<div className="scene-widgets__sort-input-control">
							<ColorPicker
								width="40px"
								height="22px"
								padding="2px"
								color={hoverColor}
								onChangeComplete={(color: any) => handleTextLabelHoverColorChange(undefined, color)}
							/>
							<Input value={hoverColor} />
							<IconButton
								size="small"
								onClick={() => handleTextLabelHoverColorChange(undefined, { hex: '' })}
							>
								<Clear />
							</IconButton>
						</div>
					}
				/>
			)}
		</CardData>
	);
};

export default Icon;
