import { useDispatch, useSelector } from 'react-redux';
import { uploadMediaBegins } from '../../../actions/publishActions';
import { AdobeExpressUtils } from '../../../screens/PublishScreen/AdobeExpress/utils';
import { showAlert, showMessage } from '../../../actions/globalActions';
import { useTranslation } from 'react-i18next';
import { messageTypes } from '../../../constants/mediaConstants';
import { generateUUID } from '../../../utils/commonUtil';

interface UploadImageByUrlProps {
	mediaTitle: string;
	mediaUrl: string;
	projectId?: string;
	mediaType: string;
}

const useUploadingImageByUrl = () => {
	const dispatch = useDispatch() as any;
	const { t: translator } = useTranslation();
	const accountId = useSelector((state) => (state as any).session.defaultAccountId);

	const uploadImageByUrl = async ({ mediaTitle, mediaUrl, projectId, mediaType }: UploadImageByUrlProps) => {
		const standarlizeMediaType = mediaType.replaceAll('jpeg', 'jpg');
		const blodFile = await fetch(mediaUrl)
			.then((r) => r.blob())
			.then(
				(blobFile) =>
					new File(
						[blobFile],
						`${projectId ? AdobeExpressUtils.getMediaIdFromProjectId(projectId) : generateUUID()}.${
							standarlizeMediaType.split('/')[1]
						}`,
						{
							type: standarlizeMediaType,
						}
					)
			);

		dispatch(
			uploadMediaBegins(
				accountId,
				'',
				'',
				undefined,
				blodFile,
				'',
				`${mediaTitle}.${standarlizeMediaType.split('/')[1]}`
			)
		).then((data: any) => {
			if (data) {
				dispatch(
					showMessage(
						`${translator('MEDIA_LIBRARY_NOTIFICATION_REFRESH_TO_REVIEW_CONTENT')}`,
						messageTypes.info
					)
				);
			} else {
				dispatch(showAlert(`${translator('COMMON_ERROR')}`, messageTypes.error));
			}
		});
	};

	return { uploadImageByUrl };
};

export default useUploadingImageByUrl;
