import React from 'react';
import dashboard from '../../assets/images/dashboard.svg';
import library from '../../assets/images/library.svg';
import analytics from '../../assets/images/analytics.svg';
import dashboard_grey from '../../assets/images/dashboard_grey.svg';
import library_grey from '../../assets/images/library_grey.svg';
import analytics_grey from '../../assets/images/analytics_grey.svg';
import { IconsList } from '../../reusable/IconsStore/IconsList';
import IconsStore from '../../reusable/IconsStore/IconsStore';
import { screenRoutes } from './../../constants/routesPath';
import { capitalizeFirstLetter } from '../../services/stringHelperService';

const getDashboardIcon = (_) => <IconsStore iconName={IconsList.DASHBOARD} width="20" height="20" color={'#000'} />;

const getAnalyticsIcon = (_) => (
	<IconsStore iconName={IconsList.MAIN_MENU_ANALYTICS} width="20" height="20" color={'#000'} />
);

const getLibraryIcon = (_) => {
	return (
		<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.13 32.36">
			<defs>
				<style>{'.cls-1{fill:#000;}'}</style>
			</defs>
			<title>Library</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path className="cls-1" d="M31.15,9.54H7.2a1.25,1.25,0,1,0,0,2.5h24a1.25,1.25,0,0,0,0-2.5Z" />
					<path
						className="cls-1"
						d="M34.09,3.21H13.73V3.14A3.14,3.14,0,0,0,10.59,0H3.14A3.15,3.15,0,0,0,0,3.14V28.69a4.26,4.26,0,0,0,4.25,4.25H34.09a4.25,4.25,0,0,0,4.25-4.25V7.46A4.25,4.25,0,0,0,34.09,3.21Zm1.75,25.48a1.75,1.75,0,0,1-1.75,1.75H4.25A1.76,1.76,0,0,1,2.5,28.69V3.14a.64.64,0,0,1,.64-.64h7.45a.64.64,0,0,1,.64.64V5.71H34.09a1.75,1.75,0,0,1,1.75,1.75Z"
					/>
				</g>
			</g>
		</svg>
	);
};

export const NavigationItems = {
	getDashboardItems(t) {
		let items = [
			{
				id: 1,
				order: 0,
				key: KEYS.dashboard,
				text: t ? t('DASHBOARD') : 'DASHBOARD',
				icon: dashboard,
				icon_grey: dashboard_grey,
				icon_black: getDashboardIcon(),
				selected: false,
				children: [],
			},
			{
				id: 4,
				order: 200,
				key: KEYS.publish,
				text: t ? t('HEADER_MENU_LABEL_MEDIA_LIBRARY') : 'MEDIA LIBRARY',
				icon: library,
				icon_grey: library_grey,
				icon_black: getLibraryIcon(),
				selected: false,
				children: [],
			},
			{
				id: 5,
				order: 300,
				key: KEYS.analytics,
				text: t ? t('HEADER_MENU_LABEL_ANALYTICS') : 'ANALYTICS',
				icon: analytics,
				icon_grey: analytics_grey,
				icon_black: getAnalyticsIcon(),
				selected: false,
				children: [],
			},
		];

		return items;
	},

	getAnalyticPlayItems(t) {
		let items = [
			{
				id: 'play1',
				order: 0,
				key: screenRoutes.ANALYTICS_VIDEO_PERFORMANCE,
				text: t ? t('ANALYTICS_MENU_VIDEO_PERFORMANCE') : 'Video Performance',
				selected: true,
				children: [],
			},
		];

		return items;
	},

	getAnalyticsCdnItems(t) {
		let items = [
			{
				id: 'cdn1',
				order: 0,
				key: screenRoutes.ANALYTICS_REAL_TIME,
				text: t ? t('ANALYTICS_MENU_REAL_TIME') : 'Real Time Distribution',
				selected: true,
				children: [],
			},
			{
				id: 'cdn2',
				order: 1,
				key: screenRoutes.ANALYTICS_GENERAL_PERFORMANCE,
				text: t ? t('ANALYTICS_MENU_GENERAL_PERFORMANCE') : 'General Performance',
				selected: false,
				children: [],
			},
			{
				id: 'cdn3',
				order: 2,
				key: screenRoutes.ANALYTICS_URL_PERFORMANCE,
				text: t ? t('ANALYTICS_MENU_URL_PERFORMANCE') : 'URL Performance',
				selected: false,
				children: [],
			},
			{
				id: 'cdn4',
				order: 3,
				key: screenRoutes.ANALYTICS_STORAGE,
				text: t ? t('ANALYTICS_MENU_STORAGE') : 'Storage',
				selected: false,
				children: [],
			},
			{
				id: 'cdn5',
				order: 4,
				key: ANALYTICS_SUBKEY.technology,
				text: t ? t('ANALYTICS_MENU_TECHNOLOGY') : 'Technology',
				selected: false,
				children: [
					{
						id: 'cdn5-1',
						order: 0,
						key: screenRoutes.ANALYTICS_OPERATING_SYSTEMS,
						text: t ? t('ANALYTICS_MENU_OPERATING_SYSTEMS') : 'Operating Systems',
						selected: false,
						children: [],
					},
					{
						id: 'cdn5-2',
						order: 1,
						key: screenRoutes.ANALYTICS_DEVICE_TYPE,
						text: t ? t('ANALYTICS_MENU_DEVICE_TYPE') : 'Device Types',
						selected: false,
						children: [],
					},
					{
						id: 'cdn5-3',
						order: 2,
						key: screenRoutes.ANALYTICS_BROWSERS,
						text: t ? t('ANALYTICS_MENU_BROWSERS') : 'Browsers',
						selected: false,
						children: [],
					},
					{
						id: 'cdn5-4',
						order: 3,
						key: screenRoutes.ANALYTICS_BROWSERS_TYPE,
						text: t ? t('ANALYTICS_MENU_BROWSERS_TYPE') : 'Browsers Type',
						selected: false,
						children: [],
					},
					{
						id: 'cdn5-5',
						order: 4,
						key: screenRoutes.ANALYTICS_PROTOCOL,
						text: t ? t('ANALYTICS_MENU_PROTOCOL') : 'Protocol',
						selected: false,
						children: [],
					},
				],
			},
			{
				id: 'cdn6',
				order: 5,
				key: ANALYTICS_SUBKEY.location,
				text: t ? t('ANALYTICS_MENU_LOCATION') : 'Location',
				selected: false,
				children: [
					{
						id: 'cdn6-1',
						order: 0,
						key: screenRoutes.ANALYTICS_COUNTRIES,
						text: t ? t('ANALYTICS_MENU_COUNTRIES') : 'Countries',
						selected: false,
						children: [],
					},
					{
						id: 'cdn6-2',
						order: 1,
						key: screenRoutes.ANALYTICS_CITIES,
						text: t ? t('ANALYTICS_MENU_CITIES') : 'Cities',
						selected: false,
						children: [],
					},
					{
						id: 'cdn6-3',
						order: 2,
						key: screenRoutes.ANALYTICS_REGIONS,
						text: t ? t('ANALYTICS_MENU_REGIONS') : 'Regions',
						selected: false,
						children: [],
					},
				],
			},
		];

		return items;
	},

	getAnalyticItems(t) {
		let items = [
			{
				id: 'playItem',
				order: 0,
				key: 'playItem',
				text: 'Play',
				selected: true,
				children: this.getAnalyticPlayItems(t),
			},
			{
				id: 'cdnItem',
				order: 1,
				key: 'cdnItem',
				text: 'CDN',
				selected: true,
				children: this.getAnalyticsCdnItems(t),
			},
		];

		return items;
	},
};

export const KEYS = {
	dashboard: 'dashboard',
	create: 'create',
	live: 'live',
	publish: 'publish',
	interact: 'interact',
	analytics: 'analytics',
	profile: 'profile',
	search: 'search',
	apps: 'apps',
	editorApp: 'apps_editorDev',
	edit: 'edit',
};

export const PUBLISH_SUBKEY = {
	allMedia: 'allMedia',
	playlists: 'playlists',
	interact: 'interact',
	catalogs: 'catalogs',
	smartFilters: 'smartFilters',
	edit: 'edit',
};

export const ANALYTICS_SUBKEY = {
	technology: 'technology',
	location: 'location',
};

export const getdefaultSelectedSubItems = () => {
	let subItemsSelecedList = {};
	let navItems = NavigationItems.getDashboardItems();
	for (let item in navItems) {
		if (navItems[item].children && navItems[item].children.length > 1) {
			if (navItems[item].key == KEYS.publish) {
				subItemsSelecedList[navItems[item].key] = onLocationMatchesSubKey();
			} else {
				subItemsSelecedList[navItems[item].key] = navItems[item].children[1].key;
			}
		}
	}
	return subItemsSelecedList;
};

export const onLocationMatchesSubKey = () => {
	const currentLocation = window.location.href;
	if (currentLocation.match('playlists')) {
		return PUBLISH_SUBKEY.playlists;
	} else {
		return PUBLISH_SUBKEY.allMedia;
	}
};

export const getTextsFromKey = (key, t) => {
	let navItems = NavigationItems.getDashboardItems(t);
	let text = '';
	return getTexts(key, navItems);

	function getTexts(key, lists) {
		for (let item in lists) {
			if (key == lists[item].key) {
				text = lists[item].text ? lists[item].text.toLocaleLowerCase() : lists[item].text;
				return text;
			} else if (lists[item].children && lists[item].children.length > 1) {
				let children = lists[item].children;
				for (let child in children) {
					if (key == children[child].key) {
						text = children[child].text ? children[child].text.toLocaleLowerCase() : children[child].text;
						return text;
					}
				}
			}
		}
	}
};

export const getBreadCrumbTopLevelsText = (selectedItemKey, selectedSubItemList, t) => {
	let textsList = [];
	let firstLevelText = getTextsFromKey(selectedItemKey, t);
	if (!firstLevelText) {
		firstLevelText = selectedItemKey;
	}
	firstLevelText = capitalizeFirstLetter(firstLevelText);
	textsList.push({ text: firstLevelText, href: '#' });
	let secondLevelText = getTextsFromKey(selectedSubItemList[selectedItemKey], t);
	secondLevelText = secondLevelText ? capitalizeFirstLetter(secondLevelText) : '';
	if (secondLevelText) {
		textsList.push({ text: secondLevelText, href: '#' });
	} else if (selectedItemKey === KEYS.publish) {
		textsList.push({ text: t('ALL_MEDIA'), href: '#' });
	}
	return textsList;
};
