import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { ThumbnailImageDeleteDialog } from './ThumbnailImageDeleteDialog';
import { reverseArray } from './../../../../../services/inspectScreenHelperService';
import { showNotification } from '../../../../../actions/notificationActions';
import { getDetails } from './../../../../../services/mediaDisplayService';
import {
	removeTagFromRelOfResource,
	addTagToRelOfResource,
	getResourceIdOfMediaWithTag,
} from './../../../../../services/mediaResourceService';
import Dropzone from 'react-dropzone';
import { getSortedNumberBasedOnWidth } from '../../../../../services/mediaDisplayService';
import { sortArrayOfObjectInDescOrder } from '../../../../../services/globalService';
import { generateId } from '../../../../../services/stringHelperService';
import {
	getMediaQueryDetails,
	removeThumbnailImage,
	stopMediaUploadProcess,
	startMediaUploadProcess,
	startThumbImageUploadProcess,
	setTheThumbnailImageAsDefaultAction,
	keepLoadResourcesAfterTheFlowStarted,
	updateResourceOfMedia,
	removeResourceAsThumbnail,
	updateMediaCover,
	setUploadingProcessActive,
} from '../../../../../actions/publishActions';
import { showAlert, showMessage } from '../../../../../actions/globalActions';
import {
	mediaNames,
	mediaTypes,
	imageRelTagList,
	messageTypes,
	UPLOAD_FAILED,
	UPLOADING_FILE,
} from '../../../../../constants/mediaConstants';
import { toggleCaptureFromVideoButton, setCurrentThumbnail } from '../../../../../actions/inspectScreenActions';

import CreateThumbnailWithAE from './CreateThumbnailWithAE';
import CaptureThumbnailDialog from './CaptureThumbnailDialog/CaptureThumbnailDialog';
import './ThumbnailManager.scss';

class ThumbnailManager extends Component {
	constructor(props) {
		super(props);
		this.interval = null;
		this.state = {
			selectedMediaItems: [],
			thumbnailImageSet: [],
			thumbnailFilenameArray: [],
			currentlySelectedThumbnailImage: '',
			mediaDetails: this.props.mediaDetails,
			thumbnailUploadDialogBoxStatus: false,
			inspectScrenExitConfirmationStatus: false,
			showThumnailImageDeleteDialogStatus: false,
			openCaptureThumbnailDialog: false,
			videoSouce: '',
			tabsList: [
				{
					id: imageRelTagList.THUMBNAIL,
					text: this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CHOOSE_THUMBNAIL'),
				},
			],
			selectedTabId: '',
			imageContextMenuData: null,
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.onThumbnailManagerLoaded) {
			this.props.onThumbnailManagerLoaded(this);
		}
		this.handleTabChange('', imageRelTagList.THUMBNAIL);
		this.setTabsForLiveMedia();
		this.saveVideoSource();
	}

	saveVideoSource = () => {
		try {
			let tempArray = this.props.mediaDetails.asset.resources ?? [];
			tempArray = tempArray.filter((obj) => {
				return obj.type === 'video';
			});
			let renditionsArray = tempArray[0]?.renditions ?? [];
			renditionsArray = sortArrayOfObjectInDescOrder(renditionsArray, 'size');
			this.setState({ videoSouce: renditionsArray[0]?.links[0]?.href ?? '' });
			this.props.toggleCaptureFromVideoButton(false);
		} catch (error) {
			console.error(error);
			this.props.toggleCaptureFromVideoButton(true);
			// expected output: ReferenceError: nonExistentFunction is not defined
			// Note - error messages will vary depending on browser
		}
	};

	setTabsForLiveMedia = () => {
		const { mediaDetails, accessToLive } = this.props;
		if (
			(mediaDetails.type === mediaTypes.liveaudio || mediaDetails.type === mediaTypes.livevideo) &&
			accessToLive
		) {
			this.setState({
				tabsList: [
					{
						id: imageRelTagList.THUMBNAIL,
						text: this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CHOOSE_THUMBNAIL'),
					},
					{
						id: imageRelTagList.PRECOVER,
						text: this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CHOOSE_PRE_COVER'),
					},
					{
						id: imageRelTagList.POSTCOVER,
						text: this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CHOOSE_POST_COVER'),
					},
				],
			});
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.interactButtonImageUploaded != this.props.interactButtonImageUploaded) {
			if (this.props.interactButtonImageUploaded) {
				this.handleImageSelection(this.props.currentThumbnail);
				this.props.setInteractButtonImageUploaded(false);
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getDefaultImgForSelectedTab = (media) => {
		const { selectedTabId } = this.state;
		switch (selectedTabId) {
			case imageRelTagList.THUMBNAIL:
				return media.thumbnails && media.thumbnails[0].id;
			case imageRelTagList.PRECOVER:
				return getResourceIdOfMediaWithTag(media, imageRelTagList.PRECOVER);
			case imageRelTagList.POSTCOVER:
				return getResourceIdOfMediaWithTag(media, imageRelTagList.POSTCOVER);
			default:
				break;
		}
	};

	fetchAndSetMediaDetails = (_) => {
		const { defaultAccountId, t, mediaName, mediaId, mediaType } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaName, mediaType).then((media) => {
			if (!media) {
				this.props.showNotification(t('COMMON_MEDIA_NOT_FOUND'));
				return;
			}
			if (typeof media == 'string') {
				this.props.showNotification(media);
				return;
			}
			this.updateMediaDetails(media);
			this.handleSettingsOfMedia(media);
		});
	};

	handleSettingsOfMedia = (media) => {
		let defaultImageSelected = null;
		let defaultThumbnailImgId = null;
		let thumbImageResourcesTemp = [];
		let defaultImageSelectedArr = [];
		const { mediaDetails, selectedTabId } = this.state;
		if (media && media.asset && media.asset.resources) {
			defaultThumbnailImgId = this.getDefaultImgForSelectedTab(media);
			thumbImageResourcesTemp = media.asset.resources.filter(
				(eachImageResource) =>
					eachImageResource.type === 'image' &&
					eachImageResource.renditions &&
					!eachImageResource.rel.includes('interactiveImage')
			);
			this.handleGobrainPosterWithSetOfImages(thumbImageResourcesTemp);
			defaultImageSelectedArr = thumbImageResourcesTemp.filter(
				(eachImageResource) => eachImageResource.id === defaultThumbnailImgId
			);
			defaultImageSelected =
				defaultImageSelectedArr && defaultImageSelectedArr.length > 0 ? defaultImageSelectedArr[0] : null;
			if (!defaultImageSelected) {
				if (selectedTabId === imageRelTagList.THUMBNAIL) {
					const thumbImageListForFiltering = media.asset.resources;
					defaultImageSelectedArr = thumbImageListForFiltering.filter(
						(eachResource) =>
							eachResource &&
							eachResource.type === 'image' &&
							eachResource.renditions &&
							eachResource.rel &&
							eachResource.rel.indexOf('thumbnail') != -1
					);
					if (defaultImageSelectedArr && defaultImageSelectedArr[0] && defaultImageSelectedArr[0].id) {
						defaultImageSelected = defaultImageSelectedArr[0];
						defaultThumbnailImgId = defaultImageSelected.id;
					}
				}
			}
		} else {
			thumbImageResourcesTemp = mediaDetails.thumbnail;
		}

		this.props.setCurrentThumbnail(defaultImageSelected);
		this.setState({
			defaultImageSelected: defaultImageSelected,
			thumbnailImageSet: thumbImageResourcesTemp,
			thumbnailFilenameArray: this.getFIlenames(thumbImageResourcesTemp),
			defaultThumbnailImgId: defaultThumbnailImgId,
		});
	};

	getFIlenames = (data) => {
		let tempResult = [];

		if (!Array.isArray(data)) {
			return tempResult;
		}

		for (var i = 0; i < data.length; i++) {
			try {
				tempResult.push(data[i].renditions[0].path.substr(data[i].renditions[0].path.lastIndexOf('/') + 1));
			} catch (err) {
				console.error(err);
			}
		}

		return tempResult;
	};

	handleGobrainPosterWithSetOfImages = (thumbImageResources) => {
		const goBrainPoster = document.querySelector('.gobrain-poster');
		let thumbnailImageArr = [];
		if (goBrainPoster) {
			if (thumbImageResources.length === 0) {
				goBrainPoster.setAttribute('style', 'background-image: none');
			}
			if (
				thumbImageResources.length === 1 &&
				thumbImageResources[0].rel &&
				thumbImageResources[0].rel.indexOf('thumbnail') != -1
			) {
				goBrainPoster.setAttribute(
					'style',
					'background-image: url("' + thumbImageResources[0].renditions[0].links[0].href + '")'
				);
			}
			if (thumbImageResources.length > 1) {
				thumbnailImageArr = thumbImageResources.filter(
					(eachResource) => eachResource && eachResource.rel && eachResource.rel.indexOf('thumbnail') != -1
				);
				if (thumbnailImageArr.length > 0) {
					let renditionsArray = thumbnailImageArr[0].renditions;
					if (renditionsArray.length > 0) {
						renditionsArray = getSortedNumberBasedOnWidth(renditionsArray).reverse();
					}
					goBrainPoster.setAttribute(
						'style',
						'background-image: url("' + renditionsArray[0].links[0].href + '")'
					);
				}
			}
		}
	}; //right now not removing  gobrain poster if there are images and none of them are having rel tag with thumbnail. As if some image is there then better to show that

	handleImageSelection = (eachThumb) => {
		const { mediaType, showNotification, t } = this.props;
		if (mediaType === mediaTypes.trashedMedias) {
			showNotification(t('COMMON_RESTORE_TO_CHANGE'));
			return;
		}

		const { defaultImageSelected, defaultThumbnailImgId, selectedTabId } = this.state;

		switch (selectedTabId) {
			case imageRelTagList.THUMBNAIL:
				if (defaultThumbnailImgId !== eachThumb.id) {
					this.selectTheThumbnailImageasDefault(eachThumb.renditions[0].id, eachThumb, defaultImageSelected);
				} else {
					this.removeResourceAsThumbnail(eachThumb);
				}
				break;
			case imageRelTagList.PRECOVER:
				if (defaultThumbnailImgId !== eachThumb.id) {
					this.updateRelArrayWithTag(eachThumb, defaultImageSelected, imageRelTagList.PRECOVER);
				} else {
					this.removeTagFromResourceAndUpdate(eachThumb, imageRelTagList.PRECOVER);
				}
				break;
			case imageRelTagList.POSTCOVER:
				if (defaultThumbnailImgId !== eachThumb.id) {
					this.updateRelArrayWithTag(eachThumb, defaultImageSelected, imageRelTagList.POSTCOVER);
				} else {
					this.removeTagFromResourceAndUpdate(eachThumb, imageRelTagList.POSTCOVER);
				}
				break;
			default:
				break;
		}
	};

	removeTagFromResourceAndUpdate = (resource, tag) => {
		const { t, defaultAccountId } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		if (!resource || (resource && !resource.id)) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_RESOURCE_ID_NOT_FOUND')
			);
			return;
		}
		const assetId = mediaDetails.asset.id;
		resource = removeTagFromRelOfResource(resource, tag);
		this.props.updateResourceOfMedia(defaultAccountId, assetId, resource).then((res) => {
			if (res) {
				this.props.showNotification(tag + ' removed');
				if (tag === imageRelTagList.PRECOVER) {
					this.updatePrecoverInTracks(resource, 'delete');
				}
			}
			setTimeout(() => {
				this.fetchAndSetMediaDetails();
			}, 2000);
		});
	};

	removeResourceAsThumbnail = (resource) => {
		const { t, defaultAccountId, mediaId, mediaName } = this.props;
		if (mediaName === mediaNames.medias) {
			this.props.removeResourceAsThumbnail(defaultAccountId, mediaId, resource.id).then((res) => {
				if (res) {
					this.removeTagFromCurrentAndOtherResources(resource);
				} else {
					this.props.showNotification(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_TO_PROCESS')
					);
				}
			});
		} else {
			this.removeTagFromCurrentAndOtherResources(resource);
		}
	};

	removeTagFromCurrentAndOtherResources = (resource) => {
		const { t, defaultAccountId } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		let assetId = mediaDetails.asset.id;
		let tag = imageRelTagList.THUMBNAIL;
		resource = removeTagFromRelOfResource(resource, tag);
		this.props.updateResourceOfMedia(defaultAccountId, assetId, resource).then((res) => {
			if (res) {
				this.removeTagFromOtherResources(resource.id, tag, () => {
					setTimeout(() => {
						this.props.showNotification(t('REMOVED_IMAGE_AS_THUMBNAIL'));
						this.fetchAndSetMediaDetails();
					}, 2000);
				});
			} else {
				this.props.showNotification(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_TO_PROCESS')
				);
			}
		});
	};

	removeTagFromOtherResources = (currentResourceId, tag, callback) => {
		const { mediaDetails } = this.state;
		const { defaultAccountId, t } = this.props;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		let assetId = mediaDetails.asset.id;
		let allImageResources =
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.filter(
				(eachImageResource) => eachImageResource.type === 'image' && eachImageResource.renditions
			);

		let resources = allImageResources.filter(
			(eachImageResource) =>
				eachImageResource.id &&
				eachImageResource.id !== currentResourceId &&
				eachImageResource.rel &&
				eachImageResource.rel.length > 0 &&
				eachImageResource.rel.indexOf(tag) !== -1
		);
		if (resources.length > 0) {
			let count = 0;
			let resourcesLength = resources.length;
			resources.map((eachImageResource) => {
				eachImageResource = removeTagFromRelOfResource(eachImageResource, tag);
				this.props.updateResourceOfMedia(defaultAccountId, assetId, eachImageResource).then((_) => {
					count = count + 1;
					if (resourcesLength === count) {
						if (callback) {
							callback();
						}
					}
				});
			});
		} else {
			if (callback) {
				callback();
			}
		}
	};

	handleHideCaptureThumbnailDialog = (_) => {
		this.setState({ openCaptureThumbnailDialog: false });
	};

	handleShowCaptureThumbnailDialog = (_) => {
		const { mediaType, t, showNotification } = this.props;
		if (mediaType === mediaTypes.trashedMedias) {
			showNotification(t('COMMON_RESTORE_TO_CHANGE'));
			return;
		}
		this.setState({ openCaptureThumbnailDialog: true });
	};

	renderCaptureThumbnailDialog = (_) => {
		const { t } = this.props;
		const { openCaptureThumbnailDialog, videoSouce } = this.state;
		let thumbnailImageSet = reverseArray(this.state.thumbnailImageSet);
		if (openCaptureThumbnailDialog) {
			return (
				<CaptureThumbnailDialog
					t={t}
					state={this.state}
					loadTheSelectedMediaItems={this.loadTheSelectedMediaItems}
					videoSource={videoSouce}
					open={openCaptureThumbnailDialog}
					closeInformationDialog={this.handleHideCaptureThumbnailDialog}
					thumbnailImageSet={thumbnailImageSet}
				/>
			);
		} else {
			return null;
		}
	};

	updateRelArrayWithTag = (resource, oldResource, tag) => {
		const { mediaDetails } = this.state;
		const { defaultAccountId, t } = this.props;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		resource = addTagToRelOfResource(resource, tag);
		let assetId = mediaDetails.asset.id;
		this.removeTagFromOtherResources(resource.id, tag, () => {
			this.props.updateResourceOfMedia(defaultAccountId, assetId, resource).then((res) => {
				if (res && (!res.message || !res.Message)) {
					if (tag === imageRelTagList.PRECOVER) {
						this.props.showNotification(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_PRE_COVER_UPDATED')
						);
						this.updatePrecoverInTracks(resource, 'add');
					} else if (tag === imageRelTagList.POSTCOVER) {
						this.props.showNotification(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_POST_COVER_UPDATED')
						);
					}
					setTimeout(() => {
						this.fetchAndSetMediaDetails();
					}, 2000);
				} else {
					this.props.showNotification(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_TO_UPDATE')
					);
				}
			});
		});
	};

	selectTheThumbnailImageasDefault = (id, resource, oldResource = null) => {
		//oldResource.rel = []
		//resource.rel = ['thumbnail']
		oldResource = removeTagFromRelOfResource(oldResource, imageRelTagList.THUMBNAIL);
		resource = addTagToRelOfResource(resource, imageRelTagList.THUMBNAIL);

		const { defaultAccountId, mediaId, t, mediaName } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		const assetId = this.state.mediaDetails.asset.id; //this.setState({ currentlySelectedThumbnailImageAsDefault: id })
		if (mediaName !== mediaNames.playlists) {
			this.props.setTheThumbnailImageAsDefaultAction(defaultAccountId, mediaId, resource.id).then((res) => {
				if (res) {
					if (oldResource && oldResource.id) {
						this.props.updateResourceOfMedia(defaultAccountId, assetId, oldResource).then((res) => {
							if (res) {
								this.updateResourceAndChangePoster(resource);
							} else {
								this.props.showNotification(
									t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_TO_UPDATE')
								);
							}
						});
					} else {
						this.updateResourceAndChangePoster(resource);
					}
				} else {
					this.props.showNotification(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_TO_UPDATE')
					);
				}
			});
		} else {
			if (oldResource && oldResource.id) {
				this.props.updateResourceOfMedia(defaultAccountId, assetId, oldResource).then((res) => {
					if (res) {
						this.updateResourceAndChangePoster(resource);
					} else {
						this.props.showNotification(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_TO_UPDATE')
						);
					}
				});
			} else {
				this.updateResourceAndChangePoster(resource);
			}
			/*let oldResourceAsTemp = oldResource[0]
			oldResourceAsTemp.rel = []
			this.props.setTheThumbnailImageAsDefaultImagePlaylist(defaultAccountId, assetId, resource, oldResourceAsTemp).then(_ => this.fetchAndSetMediaDetails())*/
		}
	};

	updateResourceAndChangePoster = (resource) => {
		const { defaultAccountId, t } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		const assetId = this.state.mediaDetails.asset.id;
		this.props.updateResourceOfMedia(defaultAccountId, assetId, resource).then(() => {
			this.props.showNotification(t('THUMBNAIL_UPDATED'));
			setTimeout(() => {
				this.fetchAndSetMediaDetails();
			}, 2000);
			if (
				resource &&
				resource.renditions &&
				resource.renditions[0] &&
				resource.renditions[0].links &&
				resource.renditions[0].links[0] &&
				resource.renditions[0].links[0].href
			) {
				const goBrainPoster = document.querySelector('.gobrain-poster');
				goBrainPoster &&
					goBrainPoster.setAttribute(
						'style',
						'background-image: url("' + resource.renditions[0].links[0].href + '")'
					);
			}
		});
	};

	showDeleteSelectedThumbImage = (thumbImg, resourceId) => {
		const { mediaType, showNotification, t } = this.props;
		if (mediaType === mediaTypes.trashedMedias) {
			showNotification(t('COMMON_RESTORE_TO_CHANGE'));
			return;
		}

		this.setState({
			showThumnailImageDeleteDialogStatus: true,
			selectedThumbImg: thumbImg,
			selectedResourceId: resourceId,
		});
	};

	handleHideThumbImageDialog = (_) =>
		this.setState({ showThumnailImageDeleteDialogStatus: false, selectedThumbImg: '', selectedResourceId: '' });

	handleRemoveTheSelectedMediaItem = (itemName) =>
		this.setState({
			selectedMediaItems: this.state.selectedMediaItems.filter((eachFile) => eachFile.name != itemName),
		});

	handleHideThumbImageUploadDialog = (_) => {
		this.setState({ thumbnailUploadDialogBoxStatus: false, selectedMediaItems: [] });
		this.fetchAndSetMediaDetails();
	};

	showThumbnailUploadDialogBox = (_) => {
		this.setState({ thumbnailUploadDialogBoxStatus: true });
	};

	openDropZoneSelection = () => {
		const { mediaType, t, showNotification } = this.props;
		if (mediaType === mediaTypes.trashedMedias) {
			showNotification(t('COMMON_RESTORE_TO_CHANGE'));
			return;
		}
		this.refs.dropzone.open();
	};

	loadTheSelectedMediaItems = (files) => {
		let tempArray = [...files];
		for (var i = 0; i < tempArray.length; i++) {
			tempArray[i] = this.getFileWithRandomName(tempArray[i]);
		}

		this.setState({ selectedMediaItems: tempArray }, () => {
			this.handleThumbImageUpload();
		});
	};

	getFileWithRandomName = (file) => {
		const newName = generateId() + '.' + file.name.split('.').pop();
		const options = { type: file.type, lastModified: file.lastModified };
		try {
			// If this call fails, we go for Blob
			return new File([file], newName, options);
		} catch (e) {
			// If we reach this point a new File could not be constructed
			var myBlob = new Blob([file], options || {});
			myBlob.name = newName;
			return myBlob;
		}
	};

	randomNumber = (min, max) => {
		return Math.floor(Math.random() * (max - min) + min);
	};

	updateMediaDetails = (media) => {
		const { mediaName } = this.props;
		const customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
		this.setState({ mediaDetails: customMediaInfo });
	};

	handleUploadThumbnailFromAE = (file) => {
		this.setState({ selectedMediaItems: [file] }, this.handleThumbImageUpload);
	};

	handleThumbImageUpload = () => {
		const startTime = new Date().getTime();
		const { t, defaultAccountId, mediaId, mediaName, mediaType } = this.props;
		const { mediaDetails, selectedMediaItems } = this.state;

		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}

		const assetId = mediaDetails.asset.id;
		this.setState({ thumbnailUploadDialogBoxStatus: false });

		if (selectedMediaItems && selectedMediaItems.length > 0) {
			let counter = 0;
			let failedCounter = 0;
			setUploadingProcessActive(UPLOADING_FILE);

			selectedMediaItems.map((eachFile) => {
				this.props.startThumbImageUploadProcess(defaultAccountId, eachFile, assetId).then((res) => {
					counter++;
					if (!res || res === UPLOAD_FAILED) {
						failedCounter++;
					} else {
						this.props.startMediaUploadProcess(
							this.state.selectedMediaItems,
							this.state.selectedMediaItems
						);
						this.props
							.getMediaQueryDetails(defaultAccountId, mediaId, mediaName, mediaType)
							.then((media) => {
								if (!media) {
									this.props.showNotification(
										t(
											'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_LABEL_MEDIA_NOT_FOUND'
										)
									);
									return;
								}

								this.updateMediaDetails(media);
								this.setState({ selectedMediaItems: [] });
								this.props.stopMediaUploadProcess();
								this.interval = setInterval((_) => {
									if (new Date().getTime() - startTime > 60000) {
										clearInterval(this.interval);
										return;
									} else {
										this.props
											.keepLoadResourcesAfterTheFlowStarted(defaultAccountId, assetId, true)
											.then(() => {
												this.fetchAndSetMediaDetails();
											});
									}
								}, 2000);
							});
					}

					if (counter === selectedMediaItems.length) {
						setTimeout(() => setUploadingProcessActive(''), 500);
						if (failedCounter > 0) {
							this.props.showAlert('');
							this.props.showAlert(`${UPLOAD_FAILED} (${failedCounter})`, messageTypes.error);
						}
					}
				});
			});
		}
	};

	deleteSelectedThumbImage = (id, resourceId) => {
		const { t, defaultAccountId } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_FAILED_PROCESS_NO_ASSET_ID')
			);
			return;
		}
		const assetId = this.props.mediaDetails.asset.id;
		this.setState({ thumbnailUploadDialogBoxStatus: false });
		this.handleHideThumbImageDialog();
		this.props.removeThumbnailImage(defaultAccountId, assetId, resourceId).then((data) => {
			if (data) {
				setTimeout(() => {
					this.fetchAndSetMediaDetails();
				}, 2000);
			}
		});
	};

	clearTheFilesSelection = (_) => this.setState({ selectedMediaItems: [] });

	handleTabChange = (e, id) => {
		this.setState(
			{
				selectedTabId: id,
			},
			() => this.fetchAndSetMediaDetails()
		);
	};

	getImageSelectionTabs = () => {
		const { selectedTabId, tabsList } = this.state;

		return (
			<div>
				<div className="imageSelectionTabsList">
					{tabsList.map((tab, i) => (
						<div
							key={i}
							id={tab.id}
							className={'selectionTab ' + (selectedTabId === tab.id ? 'selected' : '')}
							onClick={(e) => this.handleTabChange(e, tab.id)}
						>
							<label className="infoHeading">{tab.text}</label>
						</div>
					))}
				</div>
			</div>
		);
	};

	updatePrecoverInTracks = (resource, operation) => {
		const { mediaDetails } = this.state;
		let tracks = mediaDetails.tracks || [];
		if (!tracks) {
			return;
		}
		let gotTypeCover = false;
		tracks.map((track) => {
			let trackId = track.id;
			if (track.sequences[0].type === 'cover' && !gotTypeCover) {
				gotTypeCover = true;
				let bodyObj = track.sequences[0];
				let coverId = bodyObj.id;
				let cover = bodyObj;
				if (cover && !cover.resource) {
					cover['resource'] = {};
				}
				if (operation === 'add' && resource.id) {
					cover.resource.id = resource.id;
					this.updateMediaCover(trackId, coverId, cover);
				} else if (operation === 'delete') {
					cover.resource.id = '';
					this.updateMediaCover(trackId, coverId, cover);
				}
			}
		});
	};

	updateMediaCover = (trackId, coverId, cover) => {
		const { t, defaultAccountId, mediaId } = this.props;
		this.props.updateMediaCover(defaultAccountId, mediaId, trackId, coverId, cover).then((res) => {
			if (!res) {
				this.props.showNotification(t('PRE_COVER_UPDATION_IN_TRACKS_FAILED'));
			}
		});
	};

	openImageContextMenu = (event, imageData) => {
		event.stopPropagation();
		event.preventDefault();

		this.setState({
			imageContextMenuData: {
				menuAnchor: { left: event.clientX + 2, top: event.clientY - 6 },
				imageData: imageData,
			},
		});
	};

	closeImageContextMenu = () => {
		this.setState({ imageContextMenuData: null });
	};

	render() {
		const { t, defaultAccountId, showMessage } = this.props;

		const { defaultThumbnailImgId } = this.state;

		let thumbnailImageSet = reverseArray(this.state.thumbnailImageSet);

		return (
			<>
				<div className="thumbnailsContentWrapper">
					{thumbnailImageSet && thumbnailImageSet.length > 0 && Array.isArray(thumbnailImageSet) ? (
						<>{this.getImageSelectionTabs()}</>
					) : (
						<h4>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_NO_IMAGE')}</h4>
					)}
					<div className={thumbnailImageSet && thumbnailImageSet.length > 0 ? 'actualThubGallery' : ''}>
						{thumbnailImageSet && thumbnailImageSet.length > 0 && Array.isArray(thumbnailImageSet) ? (
							thumbnailImageSet.map(
								(eachThumb) =>
									eachThumb &&
									eachThumb.renditions &&
									eachThumb.renditions[0].type === 'image' && (
										<React.Fragment key={eachThumb.id}>
											<div className="singleThumbnail">
												<div className="thumbDeleteSvgBtnWrapper">
													{defaultThumbnailImgId !== eachThumb.id && (
														<svg
															viewBox="0 0 19.5 19.5"
															onClick={() =>
																this.showDeleteSelectedThumbImage(
																	eachThumb.renditions[0].id,
																	eachThumb.id
																)
															}
														>
															<defs />
															<g id="Layer_2" data-name="Layer 2">
																<g id="Layer_1-2" data-name="Layer 1">
																	<path
																		className="cls-1"
																		d="M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z"
																	/>
																	<path
																		className="cls-1"
																		d="M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z"
																	/>
																</g>
															</g>
														</svg>
													)}
												</div>
												<div
													title={eachThumb.id}
													className={`playlistThumbnailImgWrapper ${
														defaultThumbnailImgId === eachThumb.id
															? 'selectedThumbnailByDefault'
															: ''
													}`}
												>
													{
														<img
															src={eachThumb.renditions[0].links[0].href}
															onClick={() => this.handleImageSelection(eachThumb)}
															onContextMenu={(e) =>
																this.openImageContextMenu(e, eachThumb.renditions[0])
															}
														/>
													}
												</div>
											</div>
										</React.Fragment>
									)
							)
						) : (
							<div className="playlistThumbnailImgWrapper">
								<img className="singleThumbnail" src={thumbnailImageSet} />
							</div>
						)}
					</div>
				</div>
				<div className="thumbnailManagerButtonWrapper">
					<Button
						disabled={this.props.mediaType === mediaTypes.trashedMedias}
						className="defaultActionBtn"
						onClick={this.openDropZoneSelection}
					>
						{t('COMMON_UPLOAD_FILE')}
					</Button>
					<Button
						disabled={this.props.mediaType === mediaTypes.trashedMedias}
						className={this.props.disableCaptureFromVideo ? 'hideCaptureButton' : 'defaultActionBtn'}
						style={{ marginLeft: '7px' }}
						onClick={this.handleShowCaptureThumbnailDialog}
					>
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CAPTURE')}
					</Button>
					<CreateThumbnailWithAE
						mediaType={this.props.mediaType}
						mediaDetails={this.state.mediaDetails}
						contextMenuData={this.state.imageContextMenuData}
						onContextMenuClose={this.closeImageContextMenu}
						uploadThumbnail={this.handleUploadThumbnailFromAE}
					/>
				</div>
				<Dropzone
					ref="dropzone"
					accept=".jpg, .jpeg, .gif, .png, .bmp"
					parallelChunkUploads={true}
					onDrop={(acceptedFiles, fileRejections) => {
						if (this.props.mediaType === mediaTypes.trashedMedias) {
							this.props.showNotification(t('COMMON_RESTORE_TO_CHANGE'));
							return;
						}

						if (fileRejections.length > 0) {
							showMessage(t('COMMON_LABEL_NOT_SUPPORTED_FILES'), messageTypes.error);
							return;
						}

						this.loadTheSelectedMediaItems(acceptedFiles);
					}}
					onFileDialogCancel={this.clearTheFilesSelection}
					inputContent={(files, extra) =>
						extra.reject ? t('MEDIA_UPLOAD_MODAL_IMAGE_FILES_ONLY') : t('MEDIA_UPLOAD_MODAL_DRAG_FILES')
					}
				>
					{({ getRootProps, getInputProps }) => (
						<>
							<div className="container">
								<div {...getRootProps({ className: 'dropzone' })}>
									<input {...getInputProps()} />
								</div>
							</div>
						</>
					)}
				</Dropzone>
				<ThumbnailImageDeleteDialog
					t={t}
					state={this.state}
					defaultAccountId={defaultAccountId}
					deleteSelectedThumbImage={this.deleteSelectedThumbImage}
					handleHideThumbImageDialog={this.handleHideThumbImageDialog}
				/>
				{this.renderCaptureThumbnailDialog()}
			</>
		);
	}
}

const mapStateToProps = ({ session, publish, inspectScreenReducer }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
	disableCaptureFromVideo: inspectScreenReducer.disableCaptureFromVideo,
	currentThumbnail: inspectScreenReducer.currentThumbnail,
	interactButtonImageUploaded: inspectScreenReducer.interactButtonImageUploaded,
});

const mapDispatchToProps = (dispatch) => ({
	stopMediaUploadProcess: (_) => dispatch(stopMediaUploadProcess()),
	toggleCaptureFromVideoButton: (value) => dispatch(toggleCaptureFromVideoButton(value)),
	showNotification: (message) => dispatch(showNotification(message)),
	removeThumbnailImage: (accId, id, resourceId) => dispatch(removeThumbnailImage(accId, id, resourceId)),
	getMediaQueryDetails: (accId, mediaId, mediaName, mediaType) =>
		dispatch(getMediaQueryDetails(accId, mediaId, mediaName, mediaType)),
	startThumbImageUploadProcess: (accId, file, assetId) =>
		dispatch(startThumbImageUploadProcess(accId, file, assetId)),
	startMediaUploadProcess: (onGoingfiles, selectedFiles) =>
		dispatch(startMediaUploadProcess(onGoingfiles, selectedFiles)),
	keepLoadResourcesAfterTheFlowStarted: (accId, assetId, status) =>
		dispatch(keepLoadResourcesAfterTheFlowStarted(accId, assetId, status)),
	updateResourceOfMedia: (accountId, assetId, resource) =>
		dispatch(updateResourceOfMedia(accountId, assetId, resource)),
	setCurrentThumbnail: (currentThumbnail) => dispatch(setCurrentThumbnail(currentThumbnail)),
	setTheThumbnailImageAsDefaultAction: (accId, mediaId, resourceId) =>
		dispatch(setTheThumbnailImageAsDefaultAction(accId, mediaId, resourceId)),
	removeResourceAsThumbnail: (accId, mediaId, resourceId) =>
		dispatch(removeResourceAsThumbnail(accId, mediaId, resourceId)),
	updateMediaCover: (accId, mediaId, trackId, coverId, cover) =>
		dispatch(updateMediaCover(accId, mediaId, trackId, coverId, cover)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	showAlert: (stackMessage, type) => dispatch(showAlert(stackMessage, type)),
	setUploadingProcessActive: (value) => dispatch(setUploadingProcessActive(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ThumbnailManager);
