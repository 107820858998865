import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import './Layout.scss';
import Sidebar from './Sidebar';
import { KEYS } from '../../../layout/nav/navigationItems';
import { updateSelectedKey } from '../../../actions/navActions';

const Layout = ({ children, title, breadCrumb, mediaView }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		!mediaView && dispatch(updateSelectedKey(KEYS.analytics));
	}, []);

	return (
		<Grid container spacing={0} className="analyticsBody">
			<Grid item xs={12} sm={3} md={2}>
				<Sidebar />
			</Grid>
			<Grid item xs={12} sm={9} md={10}>
				<div className="analyticsRightBody addScroll">
					<div className="sec mainSec">
						<h1 className="pageTitle">{t(title)}</h1>
						{breadCrumb && (
							<p>
								{' '}
								{breadCrumb.link ? (
									<Link to={breadCrumb.link}>{breadCrumb.text}</Link>
								) : (
									breadCrumb.text
								)}{' '}
								/ <b>{breadCrumb.subTitle}</b>
							</p>
						)}
					</div>
					{children}
				</div>
			</Grid>
		</Grid>
	);
};

export default Layout;
