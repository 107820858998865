import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from '@material-ui/core';

import Close from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VideocamIcon from '@material-ui/icons/Videocam';
import MicIcon from '@material-ui/icons/Mic';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useScreenRecoder, { RECORDING_STATUS, USER_DEVICES_INPUT } from '../../hooks/screens/library/useScreenRecorder';
import { setScreenRecorderModalStatus } from '../../actions/sessionActions';
import ActionsButton from './actionButton/ActionsButton';
import { useDrag } from './hooks/useDrag';
import { ReactComponent as SwitchScreenIcon } from '../../assets/images/switch_right.svg';
import ConfirmationDialog from '../../reusable/ConfirmationDialog';
import { ReactComponent as BothIcon } from '../../assets/images/branding_watermark.svg';

import './ScreenRecorderConfigModal.scss';

export const CAMERA_MODE = 'CAMERA_MODE';
export const SCREEN_MODE = 'SCREEN_MODE';
export const BOTH_MODE = 'BOTH_MODE';

const CLOSE_RECORDING = 'CLOSE_RECORDING';
const RESTART_RECORDING = 'RESTART_RECORDING';

interface ScreenRecorderConfigModalProps {}

const ScreenRecorderConfigModal = forwardRef((props: ScreenRecorderConfigModalProps, ref: any) => {
	const dispatch = useDispatch();
	const { t: translator } = useTranslation();

	const isModalShow = useSelector((state) => (state as any).session.screenRecorderModalStatus);
	const [mode, setMode] = useState<string>(CAMERA_MODE);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState<string>('');

	const previewVideoRef = useRef<HTMLVideoElement>();
	const webcamPreviewVideoRef = useRef<HTMLVideoElement>();
	const previousModeRef = useRef<string>('');

	const {
		previewStream,
		recordingStatus,
		duration,
		selectedInput: { videoId, audioId },
		availableInputs: { videoIds, audioIds },
		screenShareStreams: { webcamStream },
		afterJobRef,
		switchScreen,
		startRecording,
		pauseRecording,
		resumeRecording,
		stopRecording,
		restartRecording,
		updateUserInput,
		setScreenShareStream,
		resetRecoder,
	} = useScreenRecoder({
		mode,
		isPreviewing: isModalShow,
		previewVideoRef,
		webcamPreviewVideoRef,
		previousModeRef,
		onUserHitNativeBrowserStopSharingButton: () => {
			handleClose();
		},
		handleModeChange: (value) => {
			setMode(value);
		},
	});
	const draggableHandlerRef = useRef(null);
	const { position, handleMouseDown } = useDrag({ ref: draggableHandlerRef });

	const isRecording = recordingStatus === RECORDING_STATUS.RECORDING || recordingStatus === RECORDING_STATUS.PAUSED;

	useImperativeHandle(ref, () => {
		return {
			showScreenRecordModal: (afterJobFunc?: any) => {
				dispatch(setScreenRecorderModalStatus(true));
				afterJobRef.current = afterJobFunc;
			},
			isRecordingFromInteract: () => {
				return isRecording && afterJobRef.current;
			},
		};
	});

	useEffect(() => {
		if (previewVideoRef && previewVideoRef.current) {
			previewVideoRef.current.srcObject = previewStream ? new MediaStream(previewStream.getTracks()) : null;
		}

		if (mode !== BOTH_MODE) {
			return;
		}

		if (webcamPreviewVideoRef && webcamPreviewVideoRef.current) {
			webcamPreviewVideoRef.current.srcObject = webcamStream ? new MediaStream(webcamStream.getTracks()) : null;
		}
	}, [previewStream, mode, webcamStream]);

	const handleClose = () => {
		dispatch(setScreenRecorderModalStatus(false));
		!isRecording && stopRecording({});
		!isRecording && setScreenShareStream({});
		!isRecording && setMode(CAMERA_MODE);
	};

	const handleOpen = () => {
		dispatch(setScreenRecorderModalStatus(true));
	};

	const handleModeChange = (value: string) => {
		previousModeRef.current = mode;
		setMode(value);
	};

	const handleStopRecording = (_event?: any, ignoreResult?: boolean) => {
		stopRecording({ initUploadProcess: !ignoreResult });
		dispatch(setScreenRecorderModalStatus(false));
		setMode(CAMERA_MODE);
	};

	const handleCloseButtonClicked = () => {
		if (isRecording) {
			setShowConfirmationDialog(CLOSE_RECORDING);
		} else {
			handleClose();
		}
	};

	const handleConfirmationSubmited = ({ ok }: any) => {
		if (ok) {
			if (showConfirmationDialog === CLOSE_RECORDING) {
				handleStopRecording(undefined, true);
				resetRecoder();
			}

			if (showConfirmationDialog === RESTART_RECORDING) {
				restartRecording();
			}
		}
		setShowConfirmationDialog('');
	};

	const handleRestartRecordingButtonClicked = () => {
		setShowConfirmationDialog(RESTART_RECORDING);
	};

	return (
		<>
			{!isModalShow && isRecording && (
				<div
					className="screen-recorder-floating-container"
					ref={draggableHandlerRef}
					style={{
						bottom: (position as any).y,
						right: (position as any).x,
					}}
				>
					<Button className="drag-handler" disableFocusRipple disableRipple onMouseDown={handleMouseDown}>
						<MoreVertIcon />
					</Button>
					<ActionsButton
						recordingStatus={recordingStatus}
						isModalShow={isModalShow}
						recordDuration={duration}
						handleStopRecording={handleStopRecording}
						handlePauseRecording={pauseRecording}
						handleResumeRecording={resumeRecording}
						handleRestartRecording={handleRestartRecordingButtonClicked}
						handleCloseModal={handleClose}
						handleOpenModal={handleOpen}
					/>
				</div>
			)}
			<Dialog
				id="screen-recorder-dialog"
				open={isModalShow}
				maxWidth="sm"
				onClose={isRecording ? handleClose : () => {}}
				keepMounted
			>
				<div className="dialog-title">
					<Typography variant="h6">{translator('MODAL_CREATE_SCREEN_RECORDER_TITLE')}</Typography>
					<IconButton onClick={handleCloseButtonClicked} size="medium">
						<Close />
					</IconButton>
				</div>
				<DialogContent className="preview-display-container">
					<div className="video-elements">
						<video
							id="preview"
							className={`${mode === CAMERA_MODE ? 'flipped' : ''}`}
							width={450} // from design
							height={253}
							autoPlay={true}
							playsInline={true}
							muted={true}
							ref={previewVideoRef as any}
						/>

						{mode === BOTH_MODE && (
							<div className="user-webcam-container" style={{ width: 84, height: 84 }}>
								<video
									id="user-webcam"
									width={150}
									height={84}
									autoPlay={true}
									playsInline={true}
									ref={webcamPreviewVideoRef as any}
									muted={true}
								/>
							</div>
						)}
					</div>
				</DialogContent>
				{!isRecording && (
					<DialogContent className="config-options-container">
						<div className="wrapper record-modes-toggle">
							<ButtonGroup
								className="record-mode-btn-group"
								disableElevation
								variant="outlined"
								color="primary"
								disabled={isRecording}
								size="large"
								fullWidth
							>
								<Button
									onClick={() => {
										handleModeChange(CAMERA_MODE);
									}}
									startIcon={<PersonIcon htmlColor={mode === CAMERA_MODE ? 'inherit' : '#e5e9ec'} />}
									color={mode === CAMERA_MODE ? 'primary' : 'default'}
								>
									{translator('MODAL_CREATE_SCREEN_RECORDER_MODE_CAMERA')}
								</Button>
								<Button
									onClick={() => {
										handleModeChange(SCREEN_MODE);
									}}
									startIcon={
										<DesktopMacIcon htmlColor={mode === SCREEN_MODE ? 'inherit' : '#e5e9ec'} />
									}
									color={mode === SCREEN_MODE ? 'primary' : 'default'}
								>
									{translator('MODAL_CREATE_SCREEN_RECORDER_MODE_SCREEN')}
								</Button>
								<Button
									onClick={() => {
										handleModeChange(BOTH_MODE);
									}}
									startIcon={<BothIcon fill={mode === BOTH_MODE ? '#126CFC' : '#e5e9ec'} />}
									color={mode === BOTH_MODE ? 'primary' : 'default'}
								>
									{translator('MODAL_CREATE_SCREEN_RECORDER_MODE_BOTH')}
								</Button>
							</ButtonGroup>
						</div>
						<div className="wrapper sources-selector">
							{(mode === CAMERA_MODE || mode === BOTH_MODE) && (
								<div className="source-selector camera-source">
									<Select
										className="select-options"
										value={videoId || 'Default'}
										onChange={(e) => {
											updateUserInput(USER_DEVICES_INPUT.VIDEO, e.target.value);
										}}
										variant="outlined"
										fullWidth
										IconComponent={ExpandMoreIcon}
										disabled={Object.values(videoIds).length === 0}
										renderValue={(value: any) => {
											return (
												<Box className="selected-option">
													<VideocamIcon />
													{(
														Object.values(videoIds).find(
															({ deviceId }: any) => deviceId === videoId
														) as any
													)?.label || value}
												</Box>
											);
										}}
									>
										{Object.values(videoIds).map(({ label, deviceId }: any) => (
											<MenuItem key={deviceId} value={deviceId}>
												{label}
											</MenuItem>
										))}
									</Select>
								</div>
							)}

							{(mode === SCREEN_MODE || mode === BOTH_MODE) && (
								<>
									<div className="source-selector screen-source">
										<Button
											className="button-switch-screen"
											value={'Switch screen'}
											onClick={() => {
												switchScreen();
											}}
											variant="outlined"
											fullWidth
											startIcon={<SwitchScreenIcon className="switch-icon" />}
										>
											{translator('MODAL_CREATE_SCREEN_RECORDER_SWITCH_SCREEN')}
										</Button>
									</div>
								</>
							)}

							<div className="source-selector audio-source">
								<Select
									className="select-options"
									value={audioId || 'Default'}
									onChange={(e) => {
										updateUserInput(USER_DEVICES_INPUT.AUDIO, e.target.value);
									}}
									variant="outlined"
									fullWidth
									IconComponent={ExpandMoreIcon}
									disabled={Object.values(audioIds).length === 0}
									renderValue={(value: any) => {
										return (
											<Box className="selected-option">
												<MicIcon />
												{(
													Object.values(audioIds).find(
														({ deviceId }: any) => deviceId === audioId
													) as any
												)?.label || value}
											</Box>
										);
									}}
								>
									{Object.values(audioIds).map(({ label, deviceId }: any) => (
										<MenuItem key={deviceId} value={deviceId}>
											{label}
										</MenuItem>
									))}
								</Select>
							</div>
						</div>
					</DialogContent>
				)}

				<DialogActions className="actions-cta">
					{!isRecording ? (
						<Button onClick={startRecording} disabled={!previewStream} variant="contained" color="primary">
							{translator('MODAL_CREATE_SCREEN_RECORDER_START_RECORDING')}
						</Button>
					) : (
						<div className="recording-cta-container">
							<ActionsButton
								recordingStatus={recordingStatus}
								isModalShow={isModalShow}
								recordDuration={duration}
								handleStopRecording={handleStopRecording}
								handlePauseRecording={pauseRecording}
								handleResumeRecording={resumeRecording}
								handleRestartRecording={handleRestartRecordingButtonClicked}
								handleCloseModal={handleClose}
								handleOpenModal={handleOpen}
							/>
						</div>
					)}
				</DialogActions>
			</Dialog>
			{!!showConfirmationDialog && (
				<ConfirmationDialog
					title={translator(
						showConfirmationDialog === CLOSE_RECORDING
							? 'MODAL_CREATE_SCREEN_RECORDER_CLOSE_RECORDING_TITLE'
							: 'MODAL_SCREEN_RECORDER_RESTART_RECORDING_TITLE'
					)}
					callback={handleConfirmationSubmited}
					message={translator(
						showConfirmationDialog === CLOSE_RECORDING
							? 'MODAL_SCREEN_RECORDER_CLOSE_RECORDING_MESSAGE'
							: 'MODAL_SCREEN_RECORDER_RESTART_RECORDING_MESSAGE'
					)}
					okButtonText={translator('COMMON_YES')}
					cancelButtonText={translator('COMMON_CANCEL')}
				/>
			)}
		</>
	);
});

export default ScreenRecorderConfigModal;
