import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const TopMetrics = (props) => {
	const { reportsSummaryData } = props;
	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">{t('ANALYTICS_VIEWS')}</Typography>
					<Typography className="paperTxt paperNum">{reportsSummaryData?.views?.sum?.value}</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">{t('ANALYTICS_UNIQUE_VIEWS')}</Typography>
					<Typography className="paperTxt paperNum">{reportsSummaryData?.uniqueViews?.sum?.value}</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">{t('ANALYTICS_COMPLETES')}</Typography>
					<Typography className="paperTxt paperNum">{reportsSummaryData?.completes?.sum?.value}</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">{t('ANALYTICS_WATCHED')}</Typography>
					<Typography className="paperTxt paperNum">
						{reportsSummaryData?.percentageWatched?.average?.value}%
					</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">Popularity</Typography>
					<Typography className="paperTxt paperNum">
						{reportsSummaryData?.popularity?.score?.value}
					</Typography>
				</Paper>
			</Grid>
		</>
	);
};

export default memo(TopMetrics);
