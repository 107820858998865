import React from 'react';
import Button from '@material-ui/core/Button';
import Avatar from 'react-avatar';
import { SUPPORTED_LANGUAGE } from '../../../layout/header/LanguageSelector';
import Cookies from 'js-cookie';

export const ProfileInformation = ({
	t,
	i18n,
	errors,
	touched,
	password,
	username,
	handleBlur,
	passwordNew,
	passwordConfirm,
	updateFormHandler,
	changePasswordHandler,
	stringToColor,
	getInitials,
	errorsInTheForm,
}) => {
	const handleLanguageChange = (lang) => {
		if (lang === i18n.language) {
			return;
		}
		Cookies.set('selected_language', lang);
		window.location.reload();
	};

	return (
		<>
			<div className="profileLeftColumn">
				<Avatar
					className="profileAvatarIcon"
					color={stringToColor(username)}
					name={getInitials(username)}
					size="200"
					round
				/>
			</div>
			<div className="profileRightColumn">
				<div className="profileSectionInputBlock">
					<h4 className="profileBlockHeaderTitle">{t('SETTINGS_PERSONAL_INFORMATION')}</h4>
				</div>
				<div className="profileSectionInputBlock">
					<label>{t('COMMON_EMAIL')}</label>
					<input type="text" className="genericTextInput" readOnly value={username} />
				</div>
				<div className="profileSectionInputBlock">
					<h4 className="profileBlockHeaderTitle">{t('COMMON_LANGUAGE')}</h4>
				</div>
				<div className="profileSectionInputBlock">
					<label>{t('SETTINGS_SELECTED_LANGUAGE')}</label>
					<select
						className="genericTextInput"
						onChange={(e) => {
							handleLanguageChange(e.target.value);
						}}
					>
						{Object.values(SUPPORTED_LANGUAGE).map(({ code, name }) => {
							return (
								<option key={code} value={code} selected={code === i18n.resolvedLanguage}>
									{t(name)}
								</option>
							);
						})}
					</select>
				</div>
				<div className="profileSectionInputBlock">
					<h4 className="profileBlockHeaderTitle">{t('SETTINGS_CHANGE_PASSWORD')}</h4>
				</div>
				<div className="profileSectionInputBlock">
					<label>{t('SETTINGS_OLD_PASSWORD')} *</label>
					<input
						type="password"
						value={password}
						onBlur={handleBlur('password')}
						onChange={updateFormHandler('password')}
						className={`genericTextInput ${touched.password && errors.password ? 'errorIndication' : ''}`}
					/>
				</div>
				<div className="profileSectionInputBlock">
					<label>{t('SETTINGS_NEW_PASSWORD')} *</label>
					<input
						type="password"
						value={passwordNew}
						onBlur={handleBlur('newPassword')}
						onChange={updateFormHandler('newPassword')}
						className={`genericTextInput ${
							touched.newPassword && errors.newPassword ? 'errorIndication' : ''
						}`}
					/>
				</div>
				<div className="profileSectionInputBlock">
					<label>{t('SETTINGS_REPEAT_PASSWORD')} *</label>
					<input
						type="password"
						value={passwordConfirm}
						onBlur={handleBlur('confirmPassword')}
						onChange={updateFormHandler('confirmPassword')}
						className={`genericTextInput ${
							touched.confirmPassword && errors.confirmPassword ? 'errorIndication' : ''
						}`}
					/>
				</div>
				<div className="profileSectionInputBlock profileSectionPasswordReq">
					<label className="boldedText">{t('SETTINGS_PASSWORD_REQUIREMENTS')}</label>
					<label style={{ whiteSpace: 'pre-wrap' }}>{t('SETTINGS_PASSWORD_RULE_LABEL')}</label>
				</div>
				<div className="profileSectionInputBlock">
					<Button
						variant="contained"
						className="defaultActionBtn"
						onClick={changePasswordHandler}
						disabled={
							errorsInTheForm ||
							!password ||
							!passwordNew ||
							!passwordConfirm ||
							passwordNew != passwordConfirm
						}
					>
						{t('SETTINGS_CHANGE_PASSWORD')}
					</Button>
				</div>
			</div>
		</>
	);
};
