import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { InputLabel, FormControl, Select, Box } from '@material-ui/core';
import Layout from './components/Layout';
import SectionLayout from './components/SectionLayout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FilterByDates from './components/FilterByDates';
import { getLocations, getStat } from './../../services/analyticsServices';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { _options, _optionsWithByteFormat } from './../../utils/commonUtil';
import { useTranslation } from 'react-i18next';

const AnalyticsGeneralPerformanceScreen = () => {
	const { t } = useTranslation();
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [locations, setLocations] = useState({});
	const [filterByCountry, setFilterByCountry] = useState('');
	const [filterByCity, setFilterByCity] = useState('');
	const [filterByRegion, setFilterByRegion] = useState('');
	const [volumeData, setVolumeData] = useState([]);
	const [volumeDataTotal, setVolumeDataTotal] = useState(0);
	const [hitsData, setHitsData] = useState([]);
	const [hitsDataTotal, setHitsDataTotal] = useState(0);
	const [viewsData, setViewsData] = useState([]);
	const [viewsDataTotal, setViewsDataTotal] = useState(0);
	const [today, setToday] = useState(moment().valueOf());
	const [lastWeek, setLastWeek] = useState(moment().subtract(7, 'days').valueOf());
	const [loading, setLoading] = useState(false);

	const getLocation = (sData = lastWeek, eDate = today) => {
		getLocations(accountId, sData, eDate).then((res) => {
			setLocations(res);
		});
	};
	function getStatData(sData = lastWeek, eDate = today) {
		setLoading(true);
		const value = getStat(accountId, 'size', sData, eDate, filterByCity, filterByCountry, filterByRegion);
		const hits = getStat(accountId, 'hits', sData, eDate, filterByCity, filterByCountry, filterByRegion);
		const views = getStat(accountId, 'uv', sData, eDate, filterByCity, filterByCountry, filterByRegion);

		function reStuctureData(items, name, color) {
			let arrData = [];
			let newData = [
				{
					name: name,
					color: color,
					data: arrData,
				},
			];
			items &&
				Object.keys(items).length > 0 &&
				items.map((item) => {
					const views = {
						x: item.tValue,
						y: item.value,
					};
					arrData.push(views);
				});

			return newData;
		}

		value.then((items) => {
			setVolumeDataTotal(items?.total);
			setVolumeData(reStuctureData(items, 'Distributed Volume', 'rgb(252, 80, 80)'));
		});
		hits.then((items) => {
			setHitsDataTotal(items?.total);
			setHitsData(reStuctureData(items, 'Number of Hits', 'rgb(255, 208, 12)'));
		});
		views.then((items) => {
			setViewsDataTotal(items?.total);
			setViewsData(reStuctureData(items, 'Asset views', 'rgb(12, 156, 238)'));
			setLoading(false);
		});
	}

	const getFilterData = (startDate, endDate) => {
		getLocation(startDate, endDate);
		getStatData(startDate, endDate);

		setLastWeek(startDate);
		setToday(endDate);
	};

	useEffect(() => {
		getLocation();
	}, []);

	useEffect(() => {
		getStatData();
	}, [filterByCity, filterByCountry, filterByRegion, accountId]);

	return (
		<Layout title="ANALYTICS_TITLE_CDN_ANALYTICS">
			<FilterByDates getFilterData={getFilterData} />
			<div className="sec filterSec">
				<Box mr={2}>
					<FormControl>
						<InputLabel id="country">{t('ANALYTICS_COUNTRIES')}</InputLabel>
						<Select
							native
							inputProps={{
								name: 'country',
								id: 'country',
							}}
							value={filterByCountry}
							label={t('ANALYTICS_COUNTRIES')}
							onChange={(e) => {
								setFilterByCountry(e.target.value);
								setFilterByCity('');
								setFilterByRegion('');
							}}
						>
							<option key="countries" value="">
								{t('ANALYTICS_COUNTRIES')}
							</option>
							{locations?.countrylabel?.map((name) => (
								<option key={name} value={name}>
									{name}
								</option>
							))}
						</Select>
					</FormControl>
				</Box>
				<Box mr={2}>
					<FormControl>
						<InputLabel id="city">{t('ANALYTICS_PERFORMANCE_BY_CITIES')}</InputLabel>
						<Select
							native
							inputProps={{
								name: 'city',
								id: 'city',
							}}
							value={filterByCity}
							label={t('ANALYTICS_PERFORMANCE_BY_CITIES')}
							onChange={(e) => {
								setFilterByCity(e.target.value);
								setFilterByCountry('');
								setFilterByRegion('');
							}}
						>
							<option key="cities" value="">
								{t('ANALYTICS_PERFORMANCE_BY_CITIES')}
							</option>
							{locations?.citylabel?.map((name) => (
								<option key={name} value={name}>
									{name}
								</option>
							))}
						</Select>
					</FormControl>
				</Box>
				<Box mr={2}>
					<FormControl>
						<InputLabel id="regions">{t('ANALYTICS_PERFORMANCE_BY_REGIONS')}</InputLabel>
						<Select
							native
							inputProps={{
								name: 'regions',
								id: 'regions',
							}}
							value={filterByRegion}
							label={t('ANALYTICS_PERFORMANCE_BY_REGIONS')}
							onChange={(e) => {
								setFilterByRegion(e.target.value);
								setFilterByCity('');
								setFilterByCountry('');
							}}
						>
							<option key="regions" value="">
								{t('ANALYTICS_PERFORMANCE_BY_REGIONS')}
							</option>
							{locations?.regionlabel?.map((name) => (
								<option key={name} value={name}>
									{name}
								</option>
							))}
						</Select>
					</FormControl>
				</Box>
			</div>
			<SectionLayout title="ANALYTICS_TITLE_TOP_METRICS" addClass="topMetrics" loading={loading}>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_ASSET_VIEWS')}</Typography>
						<Typography className="paperTxt paperNum">{viewsDataTotal}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_DISTRIBUTED_VOLUME')}</Typography>
						<Typography className="paperTxt paperNum">{volumeDataTotal}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_NUMBER_OF_HITS')}</Typography>
						<Typography className="paperTxt paperNum">{hitsDataTotal}</Typography>
					</Paper>
				</Grid>
			</SectionLayout>
			<SectionLayout title="ANALYTICS_TITLE_PERFORMANCE" addClass="performance" loading={loading}>
				<Grid item xs={12}>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_options(viewsData, 'area', undefined, '', '')}
					/>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_optionsWithByteFormat(volumeData, 'area', undefined, '', '')}
					/>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_options(hitsData, 'area', undefined, '', '')}
					/>
				</Grid>
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsGeneralPerformanceScreen;
