import React, { useState, useEffect } from 'react';
import Layout from './components/Layout';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FilterByDates from './components/FilterByDates';
import SectionLayout from './components/SectionLayout';
import Grid from '@material-ui/core/Grid';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { _options } from './../../utils/commonUtil';
import { getStat, getTopUrls } from './../../services/analyticsServices';
import ReuseTable from './components/ReuseTable';
import { topUrlsColumns } from './../../constants/analyticsConstant';
import { useTranslation } from 'react-i18next';

const AnalyticsUrlPerformanceScreen = () => {
	const { t } = useTranslation();
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [volumeData, setVolumeData] = useState([]);
	const [volumeDataTotal, setVolumeDataTotal] = useState(0);
	const [hitsData, setHitsData] = useState([]);
	const [hitsDataTotal, setHitsDataTotal] = useState(0);
	const [viewsData, setViewsData] = useState([]);
	const [viewsDataTotal, setViewsDataTotal] = useState(0);
	const [topsUrlsData, setTopsUrlsData] = useState(0);
	const [today, setToday] = useState(moment().valueOf());
	const [lastWeek, setLastWeek] = useState(moment().subtract(7, 'days').valueOf());
	const [loading, setLoading] = useState(false);
	const [path, setPath] = useState('');

	function getStatData(sData = lastWeek, eDate = today) {
		setLoading(true);
		const value = getStat(accountId, 'size', sData, eDate, null, null, null, path);
		const hits = getStat(accountId, 'hits', sData, eDate, null, null, null, path);
		const views = getStat(accountId, 'uv', sData, eDate, null, null, null, path);
		const topUrls = getTopUrls(accountId, sData, eDate);

		function reStuctureData(items, name, color) {
			let arrData = [];
			let newData = [
				{
					name: name,
					color: color,
					data: arrData,
				},
			];
			items &&
				Object.keys(items)?.length > 0 &&
				items.map((item) => {
					const views = {
						x: item.tValue,
						y: item.value,
					};
					arrData.push(views);
				});

			return newData;
		}

		value.then((items) => {
			setVolumeDataTotal(items?.total);
			setVolumeData(reStuctureData(items, t('ANALYTICS_DISTRIBUTED_VOLUME'), 'rgb(252, 80, 80)'));
		});
		hits.then((items) => {
			setHitsDataTotal(items?.total);
			setHitsData(reStuctureData(items, t('ANALYTICS_NUMBER_OF_HITS'), 'rgb(255, 208, 12)'));
		});
		views.then((items) => {
			setViewsDataTotal(items?.total);

			setViewsData(reStuctureData(items, t('ANALYTICS_ASSET_VIEWS'), 'rgb(12, 156, 238)'));
		});
		topUrls.then((items) => {
			setTopsUrlsData(items);
			setLoading(false);
		});
	}

	const getFilterData = (startDate, endDate) => {
		getStatData(startDate, endDate);
		setLastWeek(startDate);
		setToday(endDate);
	};

	const openLink = (id) => setPath(id);

	useEffect(() => {
		getStatData();
	}, [path, accountId]);

	const breadC = {
		subTitle: path ? path : 'All URL:s',
		text: 'Url Performance',
		link: null,
	};
	return (
		<Layout title="ANALYTICS_TITLE_CDN_ANALYTICS" breadCrumb={breadC}>
			<FilterByDates getFilterData={getFilterData} />
			<SectionLayout title="ANALYTICS_TITLE_TOP_METRICS" addClass="topMetrics" loading={loading}>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_ASSET_VIEWS')}</Typography>
						<Typography className="paperTxt paperNum">{viewsDataTotal}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_DISTRIBUTED_VOLUME')}</Typography>
						<Typography className="paperTxt paperNum">{volumeDataTotal}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={3} className="paper">
						<Typography className="paperTxt">{t('ANALYTICS_NUMBER_OF_HITS')}</Typography>
						<Typography className="paperTxt paperNum">{hitsDataTotal}</Typography>
					</Paper>
				</Grid>
			</SectionLayout>
			<SectionLayout title="ANALYTICS_TITLE_PERFORMANCE" addClass="performance" loading={loading}>
				<Grid item xs={12}>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_options(viewsData, 'area', undefined, '', '')}
					/>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_options(volumeData, 'area', undefined, '', '')}
					/>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_options(hitsData, 'area', undefined, '', '')}
					/>
				</Grid>
			</SectionLayout>
			<SectionLayout title="ANALYTICS_TITLE_TOP_URLS" addClass="topUrls" loading={loading}>
				<ReuseTable columns={topUrlsColumns} data={topsUrlsData} openLink={openLink} />
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsUrlPerformanceScreen;
