import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import arrow_down from '../../../../../../../../../../../assets/images/arrow-90deg-down.svg';

interface GoToSceneActionProps {
	scenes: any[];
	editingScene: any;
	metadata?: { sceneId?: string };
	handleDestinationSceneChange: () => {};
}

const GoToSceneAction = ({ scenes, editingScene, metadata, handleDestinationSceneChange }: GoToSceneActionProps) => {
	const { t: translator } = useTranslation();

	return (
		<FormControlLabel
			className={'scenario-chart__control'}
			label={<img src={arrow_down} />}
			labelPlacement="start"
			control={
				<FormControl>
					<Select
						onChange={handleDestinationSceneChange}
						variant="standard"
						value={metadata?.sceneId ?? 'none'}
					>
						<MenuItem value={'none'} key={0}>
							-{translator('CHOOSE')}-
						</MenuItem>
						{scenes.map((scene, idx) => (
							<MenuItem value={scene.id} key={scene.id}>
								{scene.id === editingScene.id
									? translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CURRENT_SCENE')
									: scene.name ||
									  `${translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_UN_TITLE_SCENE')} ${idx}`}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CHOOSE_SCENE_IN_PROJECT')}
					</FormHelperText>
				</FormControl>
			}
		/>
	);
};

export default GoToSceneAction;
