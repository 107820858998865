import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { dialogSizes } from './../../../constants/dialogSizesConstant';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';

export const InspectScreenExitConfirmationDialog = ({
	t,
	inspectScrenExitConfirmationStatus,
	handleSaveAndRoute,
	handleCancelAndRoute,
	handleStayOnScreen,
}) => (
	<Dialog
		id="inspectScreenExitConfirmationDialog"
		fullWidth
		maxWidth={dialogSizes.SMALL}
		open={inspectScrenExitConfirmationStatus}
		disableBackdropClick={Boolean(true)}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogTitle className="inspectScreenExitConfirmationDialogTitle">
			<div className="dialogTitleContentWrapper">
				<button className="headercloseBtn" onClick={handleStayOnScreen}>
					<IconsStore iconName={IconsList.CLOSE} />
				</button>
			</div>
		</DialogTitle>
		<DialogContent>
			<DialogContentText className="inspectScreenExitConfirmationTexts">
				<div className="dialogTitleContentWrapper">
					<label> {t('COMMON_UNSAVED_CHANGES')}</label> <br /> <br />
					<label> {t('COMMON_NO_SAVE_INFO')}</label>
				</div>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<div className="dialogActionsWrapper">
				<div className="dialogBoxInternalBlock exitConfirmationBtnContentWrapper">
					<Button variant="contained" className="defaultActionBtn neutralBtn" onClick={handleStayOnScreen}>
						{t('COMMON_CANCEL')}
					</Button>
					<Button
						variant="contained"
						className="defaultActionBtn deleteButtonWithIcon"
						onClick={handleCancelAndRoute}
					>
						{t('COMMON_NO_SAVE_CHANGES')}
					</Button>
					<Button
						variant="contained"
						className="defaultActionBtn saveButton"
						onClick={() => handleSaveAndRoute()}
					>
						{t('COMMON_SAVE_CHANGES')}
					</Button>
				</div>
			</div>
		</DialogActions>
	</Dialog>
);
