import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import { AddBox, Close } from '@material-ui/icons';
import { IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import _ from 'underscore';

import { dialogSizes } from '../../constants/dialogSizesConstant';
import { mediaNames } from '../../constants/mediaConstants';
import { performSearchQueryAction } from '../../actions/searchActions';
import { loadMediasForPagination, loadAllMediaItemsCount } from '../../actions/publishActions';
import { mediaNamesQuery, sortTypes, miniLibraryViewsId } from '../../constants/mediaConstants';

import Pagination from '../Pagination/Pagination';
import { mediaTypes } from '../../constants/mediaConstants';
import { MediasDisplay } from '../MediaDisplay/MediasDisplay';

import './MiniMediaLibrary.scss';

const views = [
	{
		id: miniLibraryViewsId.libraryView,
		text: 'LIBRARY',
	},
];

export interface MiniMediaLibraryProps {
	modalOnly?: boolean;
	useNewDesign?: boolean;
	singleChoice?: boolean;
	open?: boolean;
	actionButton?: React.ReactElement<any>;
	tabConfig?: any;
	mediaType?: string;
	selectionText?: string;
	actionButtonText?: string;
	onClose?: () => void;
	handleSelectedMedia?: (_selectedMedias: any[]) => void;
}

const MiniMediaLibrary: React.FC<MiniMediaLibraryProps> = ({
	modalOnly,
	useNewDesign,
	actionButton,
	singleChoice,
	tabConfig,
	mediaType,
	selectionText,
	actionButtonText,
	open,
	onClose,
	handleSelectedMedia,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const defaultAccountId = useSelector((state) => (state as any).session.defaultAccountId);
	const refreshMedias = useSelector((state) => (state as any).publish.refreshMedias);

	const [loading, setLoading] = useState<boolean>(false);
	const [displayLibrary, setDisplayLibrary] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(50);
	const [totalSearchCount, setTotalSearchCount] = useState<number>(0);
	const [tabView, setTabView] = useState<string>(miniLibraryViewsId.libraryView);
	const [searchValue, setSearchValue] = useState<string>('');
	const [medias, setMedias] = useState<any[]>([]);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);

	const updateMedias = useCallback((medias: any | any[]) => {
		const updatedMedias = medias && medias.map && medias.length > 0 ? medias : [];
		setLoading(false);
		setMedias(updatedMedias);
	}, []);

	const loadLatestUploadedMedias = useCallback(
		(newPage?: number) => {
			const currentOffset = (newPage !== undefined ? newPage : page) * rowsPerPage;
			const searchMediaQuery =
				mediaType === mediaTypes.image
					? `q=${mediaNamesQuery.image}`
					: `q=${mediaNamesQuery.allMediasWithoutImage}`;

			setLoading(true);
			dispatch(loadAllMediaItemsCount(defaultAccountId, mediaNames.medias, `?${searchMediaQuery}`)).then(
				(data: any) => {
					setTotalSearchCount(Number(data) || 0);
				}
			);
			dispatch(
				loadMediasForPagination(
					defaultAccountId,
					rowsPerPage,
					currentOffset,
					mediaNamesQuery.medias,
					sortTypes.newest,
					`&${searchMediaQuery}`
				)
			).then((medias: any) => {
				updateMedias(medias);
			});
		},
		[defaultAccountId, dispatch, mediaType, page, rowsPerPage, updateMedias]
	);

	const searchMedia = useCallback(
		(newPage?: number) => {
			setLoading(true);

			const offset = (newPage !== undefined ? newPage : page) * rowsPerPage;
			const limitQuery = '&offset=' + offset + '&limit=' + rowsPerPage;
			const sortQuery = '&sort=' + sortTypes.newest.replace(' ', '+');
			const mediaTypeQuery = mediaType === mediaTypes.image ? mediaNamesQuery.image : '';
			const searchQuery =
				mediaTypeQuery !== '' ? `?q=${searchValue}* AND ${mediaTypeQuery}` : `?q=${searchValue}*`;
			const query = searchQuery + limitQuery + sortQuery;

			dispatch(loadAllMediaItemsCount(defaultAccountId, mediaNames.medias, query)).then((data: any) => {
				setTotalSearchCount(Number(data) || 0);
			});

			dispatch(performSearchQueryAction(defaultAccountId, mediaNames.medias, query)).then((data: any) => {
				updateMedias(data);
			});
		},
		[defaultAccountId, dispatch, mediaType, page, rowsPerPage, searchValue, updateMedias]
	);

	const hideMediaRelativeDialogBox = useCallback(() => {
		setDisplayLibrary(false);
		setSelectedItems([]);
		setSearchValue('');
	}, []);

	const handleClose = useCallback(() => {
		hideMediaRelativeDialogBox();
		onClose?.();
	}, [hideMediaRelativeDialogBox, onClose]);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			if (e.key === 'Enter') {
				if (!searchValue) {
					loadLatestUploadedMedias();
					return;
				}
				searchMedia();
			}
		},
		[loadLatestUploadedMedias, searchMedia, searchValue]
	);

	const isMediaSelected = useCallback(
		(mediaId: string) => {
			return selectedItems.length > 0 && !!selectedItems.find((s) => s.id === mediaId);
		},
		[selectedItems]
	);

	const onMediaSelectionChange = useCallback(
		(eachMedia: any) => {
			if (!eachMedia) {
				return;
			}

			if (singleChoice) {
				setSelectedItems([eachMedia]);
				return;
			}

			if (isMediaSelected(eachMedia.id)) {
				setSelectedItems((prevItems) => prevItems.filter((s: any) => s.id !== eachMedia.id));
				return;
			}

			setSelectedItems((prevItems) => [...prevItems, eachMedia]);
		},
		[isMediaSelected, singleChoice]
	);

	const handleAddSelectedMedias = useCallback(() => {
		if (selectedItems.length <= 0) {
			return;
		}
		handleSelectedMedia?.(selectedItems);
		handleClose();
	}, [handleClose, handleSelectedMedia, selectedItems]);

	const handleChangePage = useCallback(
		(_event: any, newPage: number) => {
			setPage(newPage);
			if (searchValue) {
				searchMedia(newPage);
			} else {
				loadLatestUploadedMedias(newPage);
			}
		},
		[loadLatestUploadedMedias, searchMedia, searchValue]
	);

	const handleChangeRowsPerPage = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			setRowsPerPage(parseInt(event.target.value, 10));
			if (searchValue) {
				searchMedia();
			} else {
				loadLatestUploadedMedias();
			}
		},
		[loadLatestUploadedMedias, searchMedia, searchValue]
	);

	const getAddToMediaButtonsSection = () => {
		if (actionButton) {
			return React.cloneElement(actionButton, {
				...actionButton.props,
				onClick: () => setDisplayLibrary(true),
			});
		}

		if (useNewDesign) {
			return (
				<Button
					className="mediaAddBtn"
					variant="contained"
					color="primary"
					onClick={() => setDisplayLibrary(true)}
					startIcon={<AddBox style={{ fontSize: '18px' }} />}
				>
					{actionButtonText ?? t('ADD_MEDIA')}
				</Button>
			);
		}

		return (
			<div className="mediaAddBtnSection">
				<a onClick={() => setDisplayLibrary(true)}>
					<AddBox />
					{actionButtonText ?? t('ADD_MEDIA')}
				</a>
			</div>
		);
	};

	const renderTabs = () => {
		return (
			<>
				{tabConfig?.views !== false &&
					views.map((view) => (
						<div key={view.id} className="viewTab" onClick={() => setTabView(view.id)}>
							<label> {t(view.text)}</label>
						</div>
					))}
				{tabConfig?.actions}
				<TextField
					className="miniLibSearchField"
					variant="standard"
					placeholder={t('SEARCH')}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								<IconButton onClick={() => searchMedia()} edge="end">
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					onKeyDown={handleKeyDown}
				/>
			</>
		);
	};

	useEffect(() => {
		loadLatestUploadedMedias();
	}, []);

	useEffect(() => {
		setDisplayLibrary(!!open);
		setSearchValue('');
		setSelectedItems([]);
	}, [open]);

	useEffect(() => {
		if (page > 0) {
			return;
		}
		searchMedia();
	}, [refreshMedias, searchMedia, page]);

	return (
		<div id="mediaLibrarySection">
			{!modalOnly && getAddToMediaButtonsSection()}
			<Dialog
				id="MiniMediaLibrary"
				aria-labelledby="simple-dialog-title"
				fullWidth={true}
				maxWidth={dialogSizes.MEDIUM as any}
				open={displayLibrary}
				onClose={handleClose}
			>
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">
						{singleChoice ? t('COMMON_CHOOSE_MEDIA') : t('COMMON_CHOOSE_MEDIAS')}
					</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</DialogTitle>

				<DialogContent className="tabsSection">{renderTabs()}</DialogContent>

				<DialogContent className="dialogContent">
					{tabView === miniLibraryViewsId.libraryView && (
						<>
							<span>{searchValue ? t('COMMON_LABEL_SEARCH_RESULTS') : t('COMMON_LATEST_MEDIAS')}</span>
							<MediasDisplay
								isMiniLibrary
								loading={loading}
								view={'grid'}
								medias={medias}
								mediaType={'medias'}
								selectedMedias={selectedItems}
								onSelectMedia={onMediaSelectionChange}
							/>
						</>
					)}

					{tabView === miniLibraryViewsId.uploadView && (
						<div className="uploadContainer">
							<Button variant="contained" className="defaultActionBtn">
								{t('COMMON_LABEL_BROWSE')}
							</Button>
							<span>{t('COMMON_LABEL_BROWSE_OR_DRAG')}</span>
						</div>
					)}
				</DialogContent>

				{tabView === miniLibraryViewsId.libraryView && (
					<DialogActions>
						<Pagination
							count={totalSearchCount}
							page={page}
							rowsPerPage={rowsPerPage}
							labelRowsPerPage={`${t('COMMON_MEDIAS_PER_PAGE')}:`}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
						<div className="dialogActionsWrapper">
							<Button
								variant="contained"
								className="defaultActionBtn"
								onClick={handleAddSelectedMedias}
								disabled={!selectedItems.length}
							>
								{selectionText ?? t('COMMON_ADD_SELECTION')}
								{!singleChoice && selectedItems.length > 0 && <>&nbsp;({selectedItems.length})</>}
							</Button>
						</div>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default MiniMediaLibrary;
