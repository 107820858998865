import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
	FormControlLabel,
	IconButton,
	Input,
	InputAdornment,
	Link,
	Menu,
	MenuItem,
	Switch,
	Typography,
} from '@material-ui/core';
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';

import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import { createLegacyLiveMedia, createLiveMediaFromChannel } from '../../actions/workflowActions';
import { getLastIndexCatalogId } from '../../services/locationPathServices';
import { generateUUID } from '../../utils/commonUtil';
import { getMediaQueryDetails, saveInspectScreenDetails } from '../../actions/publishActions';
import { showMessage } from '../../actions/globalActions';
import { getDetails } from '../../services/mediaDisplayService';
import { mediaNames, messageTypes } from '../../constants/mediaConstants';
import { workflowTypes } from '../../constants/workflowTypes';

import './CreateLiveEventDialog.scss';

const sizes = {
	XLARGE: 'xl',
	LARGE: 'lg',
	MEDIUM: 'md',
	SMALL: 'sm',
};

const RESOLUTIONS = [2160, 1440, 1080, 720, 480, 240];

const NO_SPECIAL_CHARACTER_REGEXP = /^[A-Za-z0-9-_]+$/;

class CreateLiveEventDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			liveEventName: '',
			channelId: '',
			openLegacySettings: false,
			haveLegacySettings: false,
			selectedLegacyChannel: null,
			resolutionAndBitrateList: [],
			streamName: '',
			resolutionRef: null,
			editingResolution: {},
		};
	}

	componentDidMount() {
		this.updateStateAccordingToPermissionAndChannelList();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.liveChannels !== this.props.liveChannels) {
			this.updateStateAccordingToPermissionAndChannelList();
		}
	}

	updateStateAccordingToPermissionAndChannelList = () => {
		const { liveChannels, userPermissions } = this.props;

		const hasLiveManagerProduct = checkPermissionGrantedFor(userPermissions, 'channels');
		const hasLegacyLiveProduct = checkPermissionGrantedFor(userPermissions, 'legacyLive');

		const legacySettings = (liveChannels ?? []).filter((c) => c.id?.includes('legacy'));
		const openLegacySettings =
			!hasLiveManagerProduct ||
			(hasLegacyLiveProduct &&
				legacySettings.length === (liveChannels ?? []).length &&
				(liveChannels ?? []).length > 0);
		let selectedLegacyChannel = legacySettings.find((c) => c.id === 'legacy');
		selectedLegacyChannel = selectedLegacyChannel
			? selectedLegacyChannel
			: legacySettings.find((c) => c.id?.includes('legacy'));
		const resolutionAndBitrateList = selectedLegacyChannel?.liveOrigin?.manifest?.videos;
		const channelId = this.getNotLegacyChannelId();

		this.setState({
			channelId: channelId,
			haveLegacySettings: legacySettings.length > 0,
			openLegacySettings: openLegacySettings,
			selectedLegacyChannel: selectedLegacyChannel,
			resolutionAndBitrateList: resolutionAndBitrateList,
		});
	};

	getNotLegacyChannelId = () => {
		const notLegacyChannels = (this.props.liveChannels ?? []).filter((c) => !c.id?.includes('legacy'));
		if (notLegacyChannels.length > 0) {
			return notLegacyChannels[0].id;
		}
		return '';
	};

	handleClose = () => {
		this.props.hideCreateLiveEventDialogBox();
	};

	handleNameChange = (e) => {
		this.setState({
			liveEventName: e.target.value,
		});
	};

	handleStreamNameChange = (event) => {
		if (event.target.value && !NO_SPECIAL_CHARACTER_REGEXP.test(event.target.value)) {
			return;
		}

		this.setState({
			streamName: event.target.value,
		});
	};

	handleChannelChange = (e) => {
		if (e && e.target && e.target.value) {
			this.setState({
				channelId: e.target.value,
			});
		}
	};

	handleCreateNewLiveEvent = () => {
		const { liveEventName, channelId } = this.state;
		const { createLiveMediaFromChannel, createLiveMedia, showMessage, t, defaultAccountId } = this.props;
		if (!liveEventName || !channelId) {
			return;
		}
		this.handleClose();

		let mediaId = generateUUID();
		let selectedCatalog = getLastIndexCatalogId();

		let body = {
			workflow: {
				id: workflowTypes.CREATE_LIVE_EVENT_FROM_CHANNEL.ID,
				scope: workflowTypes.CREATE_LIVE_EVENT_FROM_CHANNEL.SCOPE,
			},
			input: {
				channelId: channelId,
				mediaId: mediaId,
				mediaTitle: liveEventName,
				catalogId: selectedCatalog,
			},
		};

		createLiveMediaFromChannel(defaultAccountId, body).then((res) => {
			if (res) {
				showMessage(t('MODAL_CREATE_NEW_LIVE_EVENT_LIVE_MEDIA_CREATED'), messageTypes.success);
				createLiveMedia?.(mediaId);
			}

			setTimeout(() => this.updateSaveAsOnDemand(mediaId), 3000);
		});
	};

	updateSaveAsOnDemand = (mediaId) => {
		const { defaultAccountId, getMediaQueryDetails, saveInspectScreenDetails, showMessage, t } = this.props;

		getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			let mediaDetail = getDetails(media, false);
			if (!mediaDetail) {
				showMessage(t('MODAL_CREATE_NEW_LIVE_EVENT_FAILED_TO_SAVE_MEDIA_DETAILS'), messageTypes.error);
				return;
			}

			if (mediaDetail.hasOwnProperty('custom') && mediaDetail.custom) {
				if (mediaDetail.custom.hasOwnProperty('livemanager') && mediaDetail.custom.livemanager) {
					mediaDetail.custom.livemanager = {
						...(mediaDetail?.custom?.livemanager ?? {}),
						SaveAsOnDemand: mediaDetail.custom?.livemanager?.SaveAsOnDemand ?? true,
					};
				} else {
					mediaDetail.custom = {
						...(mediaDetail.custom ?? {}),
						livemanager: { SaveAsOnDemand: true },
					};
				}
			} else {
				mediaDetail = {
					...mediaDetail,
					custom: { livemanager: { SaveAsOnDemand: true } },
				};
			}

			const body = {
				id: mediaDetail.id,
				asset: {
					id: mediaDetail.asset.id,
				},
				catalog: mediaDetail.catalog
					? {
							id: mediaDetail.catalog.id,
					  }
					: undefined,
				metadata: mediaDetail.metadata,
				tags: mediaDetail.tags,
				rel: mediaDetail.rel,
				custom: mediaDetail.custom,
			};

			saveInspectScreenDetails(defaultAccountId, mediaId, mediaNames.medias, body)
				.then((data) => {
					if (!data) {
						showMessage(t('MODAL_CREATE_NEW_LIVE_EVENT_FAILED_TO_SAVE_MEDIA_DETAILS'), messageTypes.error);
						return;
					}
				})
				.catch(() =>
					showMessage(t('MODAL_CREATE_NEW_LIVE_EVENT_FAILED_TO_SAVE_MEDIA_DETAILS'), messageTypes.error)
				);
		});
	};

	handleCreateNewLegacyLiveEvent = () => {
		const { liveEventName, selectedLegacyChannel, streamName, resolutionAndBitrateList } = this.state;
		const { createLegacyLiveMedia, createLegacyLiveMediaAction, showMessage, defaultAccountId, t } = this.props;

		const safeStreamName = streamName.replaceAll(' ', '');
		const isResolutionListValid =
			resolutionAndBitrateList?.length > 0 &&
			resolutionAndBitrateList.filter((r) => !r.height || !r.systemBitrate).length === 0;
		const canCreateLegacyLive = selectedLegacyChannel?.id && liveEventName && streamName && isResolutionListValid;

		if (!canCreateLegacyLive) {
			return;
		}

		this.handleClose();

		let mediaId = generateUUID();

		let videos = [];

		if (resolutionAndBitrateList.length === 1) {
			const singleResAndBitrate = resolutionAndBitrateList[0];
			videos = [
				{
					...singleResAndBitrate,
					width: singleResAndBitrate.width
						? singleResAndBitrate.width
						: Math.round(singleResAndBitrate.height * (16 / 9)),
					src: `${safeStreamName}`,
				},
			];
		} else {
			videos = resolutionAndBitrateList.map((res) => ({
				...res,
				width: res.width ? res.width : Math.round(res.height * (16 / 9)),
				src: `${safeStreamName}_${res.height}p`,
			}));
		}

		let body = {
			channelId: selectedLegacyChannel?.id,
			mediaId: mediaId,
			mediaTitle: liveEventName,
			catalogId: getLastIndexCatalogId(),
			videos: videos,
			dvr: !!selectedLegacyChannel?.id?.includes('dvr'),
		};

		createLegacyLiveMediaAction(defaultAccountId, body).then((res) => {
			if (res) {
				showMessage(t('MODAL_CREATE_NEW_LIVE_EVENT_LIVE_MEDIA_CREATED'), messageTypes.success);
			}
			createLegacyLiveMedia?.(mediaId);
		});
	};

	openLegacySettings = () => {
		this.setState({ openLegacySettings: true });
	};

	closeLegacySettings = () => {
		this.setState({ openLegacySettings: false });
	};

	onToggleDVR = (event, checked) => {
		const { liveChannels } = this.props;
		this.setState(() => {
			const newSelectedChannel = (liveChannels ?? []).find((c) =>
				checked ? c.id?.includes('dvr') : c.id === 'legacy'
			);
			return {
				selectedLegacyChannel: newSelectedChannel,
				resolutionAndBitrateList: newSelectedChannel?.liveOrigin?.manifest?.videos,
			};
		});
	};

	addNewResolution = () => {
		this.setState((prevState) => {
			if (prevState.resolutionAndBitrateList?.find((r) => !r.height && !r.systemBitrate)) {
				return null;
			}

			return {
				resolutionAndBitrateList: [...(prevState.resolutionAndBitrateList ?? []), {}],
			};
		});
	};

	removeResolution = (itemToRemove) => {
		this.setState((prevState) => ({
			resolutionAndBitrateList: (prevState.resolutionAndBitrateList ?? []).filter(
				(i) => !(i.height === itemToRemove.height && i.systemBitrate === itemToRemove.systemBitrate)
			),
		}));
	};

	onResolutionChange = (event, item) => {
		this.setState((prevState) => {
			const newResList = (prevState.resolutionAndBitrateList ?? []).map((res) => {
				if (res.height === item.height && res.systemBitrate === item.systemBitrate) {
					return {
						...res,
						height: event.target.value,
					};
				}
				return res;
			});

			return { resolutionAndBitrateList: newResList };
		});
	};

	onResolutionMenuItemClick = (event) => {
		this.setState((prevState) => {
			const editingItem = prevState.editingResolution;
			const newResList = (prevState.resolutionAndBitrateList ?? []).map((res) => {
				if (res.height === editingItem.height && res.systemBitrate === editingItem.systemBitrate) {
					return {
						...res,
						height: event.target.value,
					};
				}
				return res;
			});

			return { resolutionAndBitrateList: newResList, editingItem: {}, resolutionRef: null };
		});
	};

	onBitrateChange = (event, item) => {
		this.setState((prevState) => {
			const newResList = (prevState.resolutionAndBitrateList ?? []).map((res) => {
				if (res.height === item.height && res.systemBitrate === item.systemBitrate) {
					return {
						...res,
						systemBitrate: event.target.value * 1000,
					};
				}
				return res;
			});

			return { resolutionAndBitrateList: newResList };
		});
	};

	render() {
		const { t, liveChannels, userPermissions, open } = this.props;
		const {
			liveEventName,
			channelId,
			openLegacySettings,
			haveLegacySettings,
			selectedLegacyChannel,
			resolutionAndBitrateList,
			streamName,
			resolutionRef,
		} = this.state;

		const notLegacyChannels = (liveChannels ?? []).filter((c) => !c?.id?.includes('legacy'));
		const hasDVRChannel = (liveChannels ?? []).find((c) => c?.id?.includes('dvr'));

		const isResolutionListValid =
			(resolutionAndBitrateList ?? []).length > 0 &&
			resolutionAndBitrateList.filter((r) => !r?.height || !r?.systemBitrate).length === 0;
		const enableLegacyLiveButton =
			selectedLegacyChannel?.id && liveEventName && streamName && isResolutionListValid;

		const validNewResolutions = RESOLUTIONS.filter(
			(r) => !(resolutionAndBitrateList ?? []).find((res) => res.height === r)
		);

		const hasLiveManagerProduct = checkPermissionGrantedFor(userPermissions, 'channels');
		const hasLegacyLiveProduct = checkPermissionGrantedFor(userPermissions, 'legacyLive') && haveLegacySettings;

		return (
			<>
				<Dialog
					id="createLiveEventDialog"
					fullWidth={true}
					maxWidth={sizes.SMALL}
					aria-labelledby="alert-dialog-title"
					open={open}
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">{t('MODAL_CREATE_NEW_LIVE_EVENT_TITLE')}</Typography>
						<IconButton onClick={this.handleClose}>
							<Close />
						</IconButton>
					</DialogTitle>

					{!openLegacySettings && hasLiveManagerProduct && (
						<>
							<div className="dialogContentWrapper">
								<label> {t('MODAL_CREATE_NEW_LIVE_EVENT_EVENT_NAME_TITLE')}</label>
								<div className="fullWidthControl">
									<input
										type="text"
										placeholder={t('MODAL_CREATE_NEW_LIVE_EVENT_EVENT_NAME_TITLE')}
										onChange={this.handleNameChange}
										value={liveEventName}
									/>
								</div>
							</div>
							<div className="dialogContentWrapper">
								<label>{t('COMMON_CHANNEL')}</label>
								<div className="sortingDropDownWrapper fullWidthControl">
									<select className="sortingDropDown" onChange={this.handleChannelChange}>
										{notLegacyChannels.length > 0 ? (
											notLegacyChannels.map((channel) => (
												<option key={channel.id} value={channel.id}>
													{channel.metadata && channel.metadata.title
														? channel.metadata.title
														: channel.id}
												</option>
											))
										) : (
											<option>{`-${t('COMMON_SELECT')}-`}</option>
										)}
									</select>
								</div>
							</div>

							{hasLegacyLiveProduct && (
								<div className="dialogContentWrapper">
									<Link onClick={this.openLegacySettings} style={{ display: 'inline-flex' }}>
										{t('MODAL_CREATE_NEW_LIVE_EVENT_LEGACY_LIVE_SETUP_LABEL')}{' '}
										<KeyboardArrowRight />
									</Link>
								</div>
							)}
						</>
					)}

					{openLegacySettings && hasLegacyLiveProduct && (
						<>
							{notLegacyChannels.length > 0 && hasLiveManagerProduct && (
								<div className="dialogContentWrapper">
									<Link onClick={this.closeLegacySettings} style={{ display: 'inline-flex' }}>
										<KeyboardArrowLeft /> {t('MODAL_CREATE_NEW_LIVE_EVENT_BASIC_SETUP_LABEL')}
									</Link>
								</div>
							)}

							<div className="dialogContentWrapper">
								<label> {t('MODAL_CREATE_NEW_LIVE_EVENT_EVENT_NAME_TITLE')}</label>
								<div className="fullWidthControl">
									<input
										type="text"
										placeholder={t('MODAL_CREATE_NEW_LIVE_EVENT_EVENT_NAME_TITLE')}
										onChange={this.handleNameChange}
										value={liveEventName}
									/>
								</div>
							</div>
							<div className="dialogContentWrapper">
								<label>{t('MODAL_CREATE_NEW_LIVE_EVENT_STREAM_NAME_LABEL')}</label>
								<div className="fullWidthControl">
									<input
										type="text"
										placeholder={t('MODAL_CREATE_NEW_LIVE_EVENT_STREAM_NAME_LABEL')}
										onChange={this.handleStreamNameChange}
										value={streamName}
									/>
								</div>
							</div>

							{hasDVRChannel && (
								<div className="dialogContentWrapper">
									<FormControlLabel
										control={
											<Switch
												color="primary"
												checked={!!selectedLegacyChannel?.id?.includes('dvr')}
											/>
										}
										label={t('MODAL_CREATE_NEW_LIVE_EVENT_DVR_ON_LABEL')}
										onChange={this.onToggleDVR}
									/>
								</div>
							)}

							<div className="dialogContentWrapper">
								<label>{t('MODAL_CREATE_NEW_LIVE_EVENT_RES_AND_BIT')}</label>
								{resolutionAndBitrateList &&
									resolutionAndBitrateList.length > 0 &&
									resolutionAndBitrateList.map((item, index) => (
										<div key={index} className="fullWidthControl dialogContent--inputs">
											<Input
												type="number"
												variant="standard"
												min={0}
												endAdornment={<InputAdornment position="end">p</InputAdornment>}
												value={item.height}
												placeholder={t('COMMON_RESOLUTION')}
												onChange={(event) => this.onResolutionChange(event, item)}
												onClick={(e) =>
													this.setState({
														resolutionRef:
															validNewResolutions?.length > 0 ? e.target : null,
														editingResolution: item,
													})
												}
											/>
											<Input
												type="number"
												variant="standard"
												min={0}
												endAdornment={<InputAdornment position="end">kbps</InputAdornment>}
												value={item.systemBitrate / 1000}
												placeholder={t('COMMON_BITRATE')}
												onChange={(event) => this.onBitrateChange(event, item)}
											/>
											{index !== 0 ? (
												<IconButton onClick={() => this.removeResolution(item)}>
													<Close />
												</IconButton>
											) : (
												<div />
											)}
										</div>
									))}

								<div className="fullWidthControl dialogContent--inputs">
									<Button
										disabled={resolutionAndBitrateList?.find((r) => !r.height && !r.systemBitrate)}
										onClick={this.addNewResolution}
										style={
											resolutionAndBitrateList?.find((r) => !r.height && !r.systemBitrate)
												? undefined
												: { color: '#126CFC', borderColor: '#126CFC' }
										}
										startIcon={<Add />}
									>
										{t('COMMON_ADD')}
									</Button>
								</div>
							</div>
						</>
					)}

					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
								{haveLegacySettings && openLegacySettings ? (
									<Button
										variant="contained"
										className="defaultActionBtn"
										onClick={this.handleCreateNewLegacyLiveEvent}
										disabled={!enableLegacyLiveButton}
									>
										{t('MODAL_CREATE_NEW_LIVE_EVENT_BUTTON_CREATE_LEGACY_LIVE_EVENT')}
									</Button>
								) : (
									<Button
										variant="contained"
										className="defaultActionBtn"
										onClick={this.handleCreateNewLiveEvent}
										disabled={!(channelId && liveEventName)}
									>
										{t('MODAL_CREATE_NEW_LIVE_EVENT_TITLE')}
									</Button>
								)}
							</div>
						</div>
					</DialogActions>
				</Dialog>

				{validNewResolutions.length > 0 && resolutionRef && (
					<Menu
						anchorEl={resolutionRef}
						open={resolutionRef}
						style={{ transform: 'translateY(48px)' }}
						onClose={() => this.setState({ resolutionRef: null })}
						autoFocus={false}
					>
						{validNewResolutions.map((res, index) => (
							<MenuItem key={index} value={res} onClick={this.onResolutionMenuItemClick}>
								{res}
							</MenuItem>
						))}
					</Menu>
				)}
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
	userPermissions: session.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
	createLiveMediaFromChannel: (accountId, body) => dispatch(createLiveMediaFromChannel(accountId, body)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	showMessage: (message, type) => dispatch(showMessage(message, type)),
	createLegacyLiveMediaAction: (accountId, body) => dispatch(createLegacyLiveMedia(accountId, body)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CreateLiveEventDialog);
