import React, { useState, memo, useCallback } from 'react';
import { InputLabel, FormControl, Select, Box, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { filterTypeValues } from './../../../constants/analyticsConstant';
import { useTranslation } from 'react-i18next';

const FilterByDates = ({ getFilterData, fromToday, playSec }) => {
	const { t } = useTranslation();
	const today = moment().valueOf();
	const yensterday = playSec ? today : moment().subtract(1, 'day').valueOf();
	const lastWeek = moment().subtract(7, 'days').valueOf();
	const lastMonth = moment().subtract(30, 'days').valueOf();
	const [filterType, setFilterType] = useState(fromToday ? filterTypeValues[3].value : filterTypeValues[2].value);
	const [startDate, setStartDate] = useState(fromToday ? yensterday : lastWeek);
	const [endDate, setEndDate] = useState(today);

	const handleTypeChange = useCallback((type) => {
		let firstDate = '';
		let lastDate = '';
		switch (type) {
			case filterTypeValues[1].value:
				firstDate = lastMonth;
				lastDate = today;
				break;
			case filterTypeValues[2].value:
				firstDate = lastWeek;
				lastDate = today;
				break;
			case filterTypeValues[3].value:
				firstDate = yensterday;
				lastDate = today;
				break;
			default:
				firstDate = startDate;
				lastDate = endDate;
				break;
		}
		setFilterType(type);
		setStartDate(firstDate);
		setEndDate(lastDate);
	}, []);

	const handleStartDateChange = (date) => {
		setStartDate(date);
		setFilterType(filterTypeValues[0].value);
	};
	const handleEndDateChange = (date) => {
		setEndDate(date);
		setFilterType(filterTypeValues[0].value);
	};

	return (
		<div className="sec filterSec">
			<Box mr={2}>
				<FormControl>
					<InputLabel id="date-type">{t('ANALYTICS_DATE_TYPE')}</InputLabel>
					<Select
						native
						inputProps={{
							name: 'date',
							id: 'date-type',
						}}
						value={filterType}
						label={t('COMMON_DATE')}
						onChange={(e) => handleTypeChange(e.target.value)}
					>
						{filterTypeValues.map(({ label, value }) => (
							<option key={value} value={value}>
								{t(label)}
							</option>
						))}
					</Select>
				</FormControl>
			</Box>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Box mr={2}>
					<KeyboardDatePicker
						id="start-date"
						label={t('ANALYTICS_FROM_DATE')}
						format="yyyy-MM-dd"
						value={startDate}
						onChange={(e) => handleStartDateChange(moment(e).valueOf())}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</Box>
				<Box mr={2}>
					<KeyboardDatePicker
						id="end-date"
						label={t('ANALYTICS_TO_DATE')}
						format="yyyy-MM-dd"
						value={endDate}
						onChange={(e) => handleEndDateChange(moment(e).valueOf())}
						maxDate={moment().format('L')}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</Box>
			</MuiPickersUtilsProvider>
			<Box display="flex" justifyContent="flex-end">
				<Button
					variant="contained"
					size="large"
					className="defaultActionBtn btnLarge"
					onClick={() => getFilterData(startDate, endDate, filterType)}
				>
					{t('COMMON_APPLY')}
				</Button>
			</Box>
		</div>
	);
};

export default memo(FilterByDates);
