import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Switch, Tab, Tabs, TextField } from '@material-ui/core';

import { joinClassNames } from '../../../../services/elementHelperService';
import { ViewerEngagementTemplate } from '../ViewerEngagementTemplate';
import { QuestionAndAnswerTemplate } from './QuestionAnsAnswerTemplate';

import './QuestionAndAnswerContainer.scss';
import { useFetchQnAData } from './useFetchQnAData';
import { useDispatch, useSelector } from 'react-redux';
import { createQnA, publishQnAPrompt, unpublishQnAPrompt } from '../../../../actions/liveChatActions';
import { generateId } from '../../../../services/stringHelperService';

interface QuestionAndAnswerContainerProps {
	mediaId: string;
	settings: any;
}

const qnaBaseClassName = QuestionAndAnswerTemplate.qnaBaseClassName;

const QuestionAndAnswerContainer: React.FC<QuestionAndAnswerContainerProps> = ({ mediaId, settings }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId, username } = useSelector((state: any) => state.session);

	const [selectedTab, setSelectedTab] = useState<string>(QuestionAndAnswerTemplate.TAB.review);
	const [isQnAActive, setIsQnAActive] = useState<boolean>(false);
	const [promptValue, setPromptValue] = useState<string>(t('QnA_PROMPT_PLACEHOLDER'));
	const [answeringQuestion, setAnsweringQuestion] = useState<QuestionAndAnswerTemplate.Question | undefined>(
		undefined
	);
	const [answerText, setInputText] = useState<string>('');
	const [currentQnA, setCurrentQnA] = useState<string | undefined>(undefined);

	const { qnas, questions } = useFetchQnAData({ defaultAccountId, mediaId });

	useEffect(() => {
		if (!qnas || qnas.length === 0) {
			return;
		}

		setCurrentQnA(qnas[0]);
	}, [qnas]);

	const onTabChange = useCallback((_: React.ChangeEvent<{}>, value: string) => {
		setSelectedTab(value);
	}, []);

	const onToggleQnA = useCallback(
		(_: React.ChangeEvent<{}>, checked: boolean) => {
			setIsQnAActive(checked);
			if (checked) {
				const qnaId = currentQnA ?? generateId();
				if (!currentQnA) {
					const body = {
						id: qnaId,
						sender: {
							userId: username,
							name:
								settings && settings.adminProfile && settings.adminProfile.chatName
									? settings.adminProfile.chatName
									: 'admin',
							admin: true,
							email: username,
						},
						text: promptValue,
					};
					dispatch(createQnA(defaultAccountId, mediaId, body)).then((data: any) => {
						if (!data) {
							console.info('No data received');
						}
						dispatch(publishQnAPrompt(defaultAccountId, mediaId, qnaId));
					});
				} else {
					dispatch(publishQnAPrompt(defaultAccountId, mediaId, qnaId));
				}
			} else {
				dispatch(unpublishQnAPrompt(defaultAccountId, mediaId, currentQnA));
			}
		},
		[currentQnA, defaultAccountId, dispatch, mediaId, promptValue, settings, username]
	);

	const onPromptValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPromptValue(event.target.value);
	}, []);

	const onAnswerQuestion = useCallback(() => {
		setAnsweringQuestion(undefined);
	}, []);

	const onAnswerInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setInputText(event.target.value);
	}, []);

	return (
		<>
			<ViewerEngagementTemplate.TabContentWrapper useShortContainer={!!answeringQuestion}>
				<FormControl fullWidth className={`${qnaBaseClassName}__header`}>
					<FormControlLabel
						label={
							<>
								<ViewerEngagementTemplate.Indicator isActive={isQnAActive && !!promptValue} />
								{t(
									isQnAActive && !!promptValue
										? 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_ACTIVE_LABEL'
										: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_INACTIVE_LABEL'
								)}
							</>
						}
						labelPlacement="start"
						onChange={onToggleQnA}
						control={<Switch color="primary" checked={isQnAActive && !!promptValue} />}
						disabled={promptValue === ''}
					/>
				</FormControl>

				<FormControl fullWidth className={`${qnaBaseClassName}__prompt`}>
					<FormControlLabel
						label={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_LABEL')}
						labelPlacement="top"
						placeholder={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_PLACEHOLDER')}
						control={
							<TextField
								fullWidth
								variant="standard"
								value={promptValue}
								color="primary"
								onChange={onPromptValueChange}
							/>
						}
					/>
				</FormControl>

				<Tabs
					value={selectedTab}
					onChange={onTabChange}
					className={`${qnaBaseClassName}__tab-bar ${ViewerEngagementTemplate.baseClassName}__tab-bar--horizontal`}
					TabIndicatorProps={{
						style: {
							color: '#ffffff',
							backgroundColor: 'transparent',
						},
					}}
				>
					{QuestionAndAnswerTemplate.TabItems.map((item) => (
						<Tab
							title={item.value === 'archive' ? 'Archive' : undefined}
							value={item.value}
							label={typeof item.label === 'string' ? t(item.label) : item.label}
							key={item.value}
							className={joinClassNames([
								`${ViewerEngagementTemplate.baseClassName}__tab`,
								item.value === selectedTab
									? `${ViewerEngagementTemplate.baseClassName}__tab--selected`
									: '',
							])}
						/>
					))}
				</Tabs>

				<div className={`${qnaBaseClassName}__panel`}>
					<QuestionAndAnswerTemplate.ReviewPanel questions={questions} selectedTab={selectedTab} />
				</div>
			</ViewerEngagementTemplate.TabContentWrapper>

			{answeringQuestion && (
				<ViewerEngagementTemplate.Input
					value={answerText}
					onChange={onAnswerInputChange}
					onSendClick={onAnswerQuestion}
				>
					<QuestionAndAnswerTemplate.Question question={answeringQuestion} isAnswering />
				</ViewerEngagementTemplate.Input>
			)}
		</>
	);
};

export default QuestionAndAnswerContainer;
