import React from 'react';
import { Theaters, Timeline, Tv, VideoLibrary, Help, Web } from '@material-ui/icons';
import ColorHub from '../../reusable/ColorHub/ColorHub';
import { ReactComponent as ContentCut } from '../../assets/svg/ContentCut.svg';
import { ReactComponent as LiveEvent } from '../../assets/svg/LiveEvent.svg';

const IconInteract: React.FC<{}> = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.05" viewBox="0 0 15 13.05">
		<path
			id="clip-snapping"
			d="M8.492,3.978a.489.489,0,0,0-.481.5v1.3a.489.489,0,1,0,.978,0v-1.3a.489.489,0,0,0-.5-.5ZM5.51,5.05a.489.489,0,0,0-.427.725l.458.862A.489.489,0,1,0,6.4,6.177l-.458-.862A.489.489,0,0,0,5.51,5.05Zm5.966,0a.489.489,0,0,0-.422.266l-.458.862a.489.489,0,1,0,.864.459l.458-.862a.489.489,0,0,0-.441-.725ZM2.141,7.9A1.143,1.143,0,0,0,1,9.04v2.935a1.143,1.143,0,0,0,1.141,1.141H7.033a1.143,1.143,0,0,0,1.141-1.141V9.04A1.143,1.143,0,0,0,7.033,7.9Zm7.826,0A1.143,1.143,0,0,0,8.826,9.04v2.935a1.143,1.143,0,0,0,1.141,1.141h4.891A1.143,1.143,0,0,0,16,11.974V9.04A1.143,1.143,0,0,0,14.858,7.9Zm-4,6.214a.489.489,0,0,0-.422.266l-.458.862a.489.489,0,1,0,.864.459l.458-.862a.489.489,0,0,0-.441-.725Zm5.059,0a.489.489,0,0,0-.427.725l.458.862a.489.489,0,1,0,.864-.459l-.458-.862a.489.489,0,0,0-.437-.266Zm-2.53.629a.489.489,0,0,0-.481.5v1.3a.489.489,0,1,0,.978,0v-1.3a.489.489,0,0,0-.5-.5Z"
			transform="translate(-1 -3.978)"
		/>
	</svg>
);

export const KEYS = {
	dashboard: 'dashboard',
	create: 'create',
	live: 'live',
	publish: 'publish',
	analytics: 'analytics',
	profile: 'profile',
	search: 'search',
	apps: 'apps',
	editorApp: 'apps_editorDev',
	portal: 'portal',
	interact: 'interact',
	edit: 'edit',
};

export const DEFAULT_MENU_ITEMS = [
	{
		id: 1,
		order: 0,
		key: KEYS.publish,
		text: 'HEADER_MENU_LABEL_MEDIA_LIBRARY',
		icon: <VideoLibrary />,
		selected: false,
		children: [],
	},
	{
		id: 2,
		order: 1,
		key: KEYS.edit,
		text: 'HEADER_MENU_LABEL_EXPRESS',
		icon: <ColorHub component={<ContentCut width={'18px'} height={'18px'} />} htmlColor="#000" />,
		selected: false,
		children: [],
	},
	{
		id: 3,
		order: 2,
		key: KEYS.interact,
		text: 'HEADER_MENU_LABEL_INTERACT',
		icon: <IconInteract />,
		selected: false,
		children: [],
	},
	{
		id: 4,
		order: 3,
		key: KEYS.live,
		text: 'HEADER_MENU_LABEL_LIVE',
		icon: <ColorHub component={<LiveEvent width={'18px'} height={'18px'} />} htmlColor="#000" />,
		selected: false,
		children: [],
	},
	{
		id: 10,
		order: 300,
		key: KEYS.analytics,
		text: 'HEADER_MENU_LABEL_ANALYTICS',
		icon: <Timeline />,
		selected: false,
		children: [],
	},
];

export const MENU_ICONS = {
	portal: <Tv />,
	publish: <VideoLibrary />,
	analytics: <Timeline />,
	interact: <IconInteract />,
	editor: <Theaters />,
	support: <Help />,
	univid: <Web />,
};

export const MENU_ORDER = {
	portal: 500,
	publish: 0,
	analytics: 505,
	interact: 1,
	editor: 500,
	apps: 506,
};
