import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormControlLabel, TextField } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import DecorationImage from '../../../assets/images/scenario_creation_modal.png';
import './ScenarioCreationDialog.scss';
import { generateUUID } from '../../../utils/commonUtil';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { messageTypes } from '../../../constants/mediaConstants';
import { showMessage } from '../../../actions/globalActions';
import { createScenario as createScenarioAction } from '../../../actions/scenarioActions';

interface ScenarioCreationDialogProps {
	open: boolean;
	onClose?: () => void;
	onCreateScenario?: (_scenarioId: string) => void;
}

const ScenarioCreationDialog: React.FC<ScenarioCreationDialogProps> = ({ open, onClose, onCreateScenario }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const defaultAccountId = useSelector((state) => (state as any).session.defaultAccountId);
	const [scenarioTitle, setScenarioTitle] = useState('');
	const [error, setError] = useState(false);

	const onScenarioTitleChange = (event: React.ChangeEvent<{}>) => {
		setScenarioTitle((event.target as any).value);
	};

	const closeDialog = () => {
		setError(false);
		onClose?.();
	};

	const createScenario = () => {
		const scenarioId = generateUUID();
		const assetId = generateUUID();
		dispatch(createScenarioAction(defaultAccountId, scenarioTitle, scenarioId, assetId))
			.then((data: any) => {
				if (data && data.length) {
					dispatch(showMessage(t('MODAL_CREATE_NEW_INTERACT_VIDEO_CREATED'), messageTypes.success));
					onCreateScenario?.(scenarioId);
				} else {
					dispatch(
						showMessage(t('MODAL_CREATE_NEW_INTERACT_VIDEO_FAILED_NOTIFICATION'), messageTypes.success)
					);
				}
			})
			.catch((error: any) =>
				dispatch(
					showMessage(
						t('MODAL_CREATE_NEW_INTERACT_VIDEO_FAILED_NOTIFICATION') + ' ' + error,
						messageTypes.error
					)
				)
			);

		setScenarioTitle('');
	};

	return (
		<Dialog className="create-scenario-dialog" open={open} maxWidth="sm" fullWidth onClose={closeDialog}>
			<Box display="flex" flexDirection="row">
				<Box className="decoration-image">
					<img src={DecorationImage} />
				</Box>
				<Box className="dialog-content">
					<Box className="container close-button">
						<Button onClick={closeDialog} size="small" color="primary">
							<Close color="primary" />
						</Button>
					</Box>

					<Box className="container">
						<Typography variant="h2">
							{t('MEDIA_LIBRARY_MEDIA_DISPLAY_NEW_INTERACT_VIDEO_TITLE')}
						</Typography>
						<Typography variant="body1">{t('MODAL_CREATE_NEW_INTERACT_SUB_TITLE')}</Typography>
					</Box>

					<Box className="container">
						<FormControlLabel
							className={`create-model-field`}
							labelPlacement="top"
							label={<b>{t('MODAL_CREATE_NEW_INTERACT_VIDEO_NAME_LABEL')}</b>}
							onChange={onScenarioTitleChange}
							control={
								<TextField
									variant="outlined"
									size="small"
									value={scenarioTitle}
									placeholder={t('MODAL_CREATE_NEW_INTERACT_VIDEO_NAME_PLACEHOLDER')}
									error={error}
								/>
							}
						/>
					</Box>

					<Box className="container create-button">
						<Button variant="contained" color="primary">
							<Typography
								className="create-button-text"
								variant="body1"
								onClick={() => {
									if (!scenarioTitle) {
										setError(true);
									} else {
										setError(false);
										createScenario();
									}
								}}
							>
								{t('MODAL_CREATE_NEW_INTERACT_VIDEO_CREATE_BUTTON_LABEL')}
							</Typography>
						</Button>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default ScenarioCreationDialog;
