import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ViewerEngagementTemplate } from '../ViewerEngagementTemplate';
import { closePoll, deletePoll, publishPoll, unpublishPoll, getPollResult } from '../../../../actions/pollActions';
import { showMessage } from '../../../../actions/globalActions';
import { messageTypes } from '../../../../constants/mediaConstants';
import { setViewEngagementLoading } from '../../../../actions/viewEngagementActions';

import { useFetchPolls } from './useFetchPolls';
import { PollsContainerTemplate } from './PollsContainerTemplate';

import './Poll.scss';

const PollsContainer: React.FC<PollsContainerTemplate.PollsContainerProps> = ({ mediaId }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();
	const { defaultAccountId } = useSelector((state: any) => state.session);

	const [shouldUpdatePolls, setShouldUpdatePolls] = useState<boolean>(true);
	const [pollResult, setPollResult] = useState(undefined);

	const pollResultIntervalId = useRef<any | undefined>(undefined);

	const { activePolls, upcomingPolls, previousPolls } = useFetchPolls({
		defaultAccountId,
		mediaId,
		updatePolls: shouldUpdatePolls,
		callback: () => setShouldUpdatePolls(false),
	});

	const fetchPollResult = useCallback(() => {
		if (activePolls.length === 0) {
			return;
		}
		const activePoll = activePolls[0];

		dispatch(getPollResult(defaultAccountId, mediaId, activePoll.id))
			.then((data: any) => setPollResult({ ...data }))
			.catch((error: any) =>
				showMessage(
					`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_CANNOT_GET_RESULT')} ${error}`,
					messageTypes.error
				)
			);
	}, [defaultAccountId, mediaId, activePolls]);

	const onPublishPoll = useCallback((poll: PollsContainerTemplate.PollData) => {
		dispatch(setViewEngagementLoading(true));

		const activePoll = activePolls[0];
		if (activePoll) {
			dispatch(unpublishPoll(defaultAccountId, mediaId, activePoll.id)).then(() => {
				dispatch(
					showMessage(
						`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UN_PUBLISH_OLD_MESSAGE')}`,
						messageTypes.info
					)
				);
			});
		}

		dispatch(publishPoll(defaultAccountId, mediaId, poll.id))
			.then((publishRes: any) => {
				if (publishRes && publishRes.message) {
					dispatch(
						showMessage(
							`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH_FAILED_MESSAGE')}. ${
								publishRes.me
							}`,
							messageTypes.error
						)
					);
					dispatch(setViewEngagementLoading(false));
					return;
				}

				dispatch(
					showMessage(
						`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH_SUCCESS_MESSAGE')}`,
						messageTypes.success
					)
				);
				setTimeout(() => setShouldUpdatePolls(true), 1000);
				dispatch(setViewEngagementLoading(false));
			})
			.catch((error: any) => {
				dispatch(
					showMessage(
						`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH_FAILED_MESSAGE')}. ${error}`,
						messageTypes.error
					)
				);
				dispatch(setViewEngagementLoading(false));
			});
	}, []);

	const onPollActionClick = useCallback(
		(_: React.MouseEvent<HTMLButtonElement>, poll: PollsContainerTemplate.PollData) => {
			onPublishPoll(poll);
		},
		[onPublishPoll]
	);

	const onEndPollClick = useCallback(
		(_: React.MouseEvent<HTMLButtonElement>, pollToEnd: PollsContainerTemplate.PollData, endPollOption: string) => {
			dispatch(setViewEngagementLoading(true));
			if (!pollToEnd) {
				dispatch(setViewEngagementLoading(false));
				return;
			}

			if (endPollOption === 'unpublish') {
				dispatch(unpublishPoll(defaultAccountId, mediaId, pollToEnd.id))
					.then(() => {
						dispatch(
							showMessage(
								`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UN_PUBLISH_SUCCESS_MESSAGE')}`,
								messageTypes.info
							)
						);
					})
					.catch((error: any) =>
						dispatch(
							showMessage(
								`${t(
									'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UN_PUBLISH_FAILED_MESSAGE'
								)}. ${error}`,
								messageTypes.error
							)
						)
					);
			}

			if (pollToEnd.closed) {
				setTimeout(() => setShouldUpdatePolls(true), 1000);
				return;
			}

			dispatch(closePoll(defaultAccountId, mediaId, pollToEnd.id))
				.then(() => {
					dispatch(
						showMessage(
							`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_CLOSE_SUCCESS_MESSAGE')}`,
							messageTypes.info
						)
					);
					setTimeout(() => setShouldUpdatePolls(true), 1000);
				})
				.catch((error: any) => {
					dispatch(
						showMessage(
							`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_CLOSE_FAILED_MESSAGE')}. ${error}`,
							messageTypes.error
						)
					);
					dispatch(setViewEngagementLoading(false));
				});
		},
		[defaultAccountId, mediaId]
	);

	const onDeletePoll = useCallback(
		(pollToRemoveId: string) => {
			dispatch(deletePoll(defaultAccountId, mediaId, pollToRemoveId)).then(() => {
				setTimeout(() => setShouldUpdatePolls(true), 1000);
				showMessage(`${t('POLL_DELETED')} `, messageTypes.info);
			});
		},
		[defaultAccountId, mediaId]
	);

	useEffect(() => {
		if (activePolls.length > 0) {
			if (pollResultIntervalId.current) {
				clearInterval(pollResultIntervalId.current);
			}
			pollResultIntervalId.current = setInterval(() => fetchPollResult(), 1000);
		} else {
			if (pollResultIntervalId.current) {
				clearInterval(pollResultIntervalId.current);
			}
		}
	}, [activePolls, pollResultIntervalId.current]);

	return (
		<>
			<PollsContainerTemplate.PollSection
				polls={activePolls}
				title={
					<>
						<ViewerEngagementTemplate.Indicator isActive={activePolls && activePolls.length > 0} />
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_ACTIVE_POLL')}
					</>
				}
				noPollMessage={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_NO_ACTIVE')}
				onEndPollClick={onEndPollClick}
				pollResult={pollResult}
			/>
			<PollsContainerTemplate.PollSection
				polls={upcomingPolls}
				title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UP_COMING')}
				collapsible
				noPollMessage={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_NO_UP_COMING')}
				onActionClick={onPollActionClick}
				onDeletePoll={onDeletePoll}
			/>
			<PollsContainerTemplate.PollSection
				polls={previousPolls}
				title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PREVIOUS')}
				collapsible
				noPollMessage={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_NOTHING')}
				onActionClick={onPollActionClick}
				onDeletePoll={onDeletePoll}
			/>
		</>
	);
};

export default PollsContainer;
