import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckBox, CheckBoxOutlineBlank, MoreHoriz } from '@material-ui/icons';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';

import { getDetails, getHighQThumbnailFromRenditions } from '../../services/mediaDisplayService';
import { customScreenType, mediaActionKeys, mediaNames, mediaTypes } from '../../constants/mediaConstants';
import no_thumbnail from '../../assets/svg/NoThumbnail.svg';
import { getFormattedDate } from '../../services/timeStampService';
import { getInspectLink, getMediaTypeText, isMediaProcessing } from '../../utils/commonUtil';
import { setSelectedCatalogId } from '../../actions/publishActions';
import { joinClassNames } from '../../services/elementHelperService';

import { MediasDisplayProps, MediaItemProps, ShortcutMenuItems } from './MediasDisplay';
import { EDITED_MEDIA_ID_PREFIX } from '../../screens/PublishScreen/AdobeExpress/utils';

import './ListMediasDisplay.scss';
import { notAllowToShowEditCatalogAccess } from '../MediaLibrary/utils';

export const ListMediasDisplay: React.FC<MediasDisplayProps> = ({
	medias,
	mediaType,
	selectedMedias,
	screenType,
	isMiniLibrary,
	isSelectAll,
	thumbnails,
	classNames,
	isAllowAEOption,
	restrictions,
	onLibraryViewChange,
	onSelectMedia,
	onMediaActionMenuClick,
	onSelectAllMediaItemsPerPage,
}) => {
	const { t } = useTranslation();
	const isMobileView = useMemo(
		() => screenType === customScreenType.small || screenType === customScreenType.mobile,
		[screenType]
	);

	return (
		<div className={joinClassNames('list-media-display', classNames)}>
			<table>
				<thead>
					<tr className="list-media-display__header">
						<th style={{ textAlign: 'center' }}>
							<IconButton
								style={{ width: '32px', height: '32px' }}
								title={t('MEDIA_LIBRARY_MEDIA_DISPLAY_SELECT_ALL_MEDIAS_TITLE')}
								onClick={onSelectAllMediaItemsPerPage}
							>
								{isSelectAll ? <CheckBox htmlColor="#126cfc" /> : <CheckBoxOutlineBlank />}
							</IconButton>
						</th>
						<th>
							<div className={`${mediaContainerBaseClassName}__main`}>
								<div
									className={`${mediaContainerBaseClassName}__thumbnail`}
									style={mediaType === 'catalogs' ? { width: 'auto', minWidth: '60px' } : undefined}
								></div>
								<span>{t('COMMON_TITLE')}</span>
							</div>
						</th>
						{!isMobileView && <th className={`list-media-display__right-align`}>{t('COMMON_TYPE')}</th>}
						{!isMobileView && <th className={`list-media-display__right-align`}>{t('COMMON_CREATED')}</th>}
						{!isMiniLibrary && <th></th>}
					</tr>
				</thead>
				<tbody>
					{medias &&
						medias.filter(Boolean).length > 0 &&
						medias.map((media: any) => (
							<MediaListItem
								restrictions={restrictions}
								isMobileView={isMobileView}
								key={media.id}
								media={media}
								mediaType={mediaType}
								onSelectMedia={onSelectMedia}
								onMediaActionMenuClick={onMediaActionMenuClick}
								isSelected={(selectedMedias ?? []).find((i: any) => i.id === media.id)}
								isMiniLibrary={isMiniLibrary}
								onLibraryViewChange={onLibraryViewChange}
								thumbnail={(thumbnails ?? [])
									.filter(Boolean)
									.find((t: any) => t.scenarioId === media.id)}
								isAllowAEOption={isAllowAEOption}
							/>
						))}
				</tbody>
			</table>
		</div>
	);
};

const mediaContainerBaseClassName = 'media-item-container';

export const MediaListItem: React.FC<MediaItemProps> = ({
	media,
	mediaType,
	isSelected,
	isMobileView,
	isMiniLibrary,
	thumbnail: thumbnailMedia,
	isAllowAEOption,
	restrictions,
	onSelectMedia,
	onMediaActionMenuClick,
	onLibraryViewChange,
}) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();
	const accountRestrictions = useSelector((state) => (state as any).publish.accountRestrictions);
	const { userId, userPermissions } = useSelector((state) => (state as any).session);
	const { catalogs } = useSelector((state) => (state as any).publish);
	const [contextMenu, setContextMenu] = useState<any | undefined>(undefined);

	const mediaDetail = getDetails(media, mediaType === mediaTypes.playlists);

	const thumbnail = useMemo(() => {
		let thumbnailSource = mediaDetail;
		if (thumbnailMedia) {
			thumbnailSource = getDetails(thumbnailMedia, false);
		}

		if (!thumbnailSource) {
			return '';
		}

		let mediaThumbnail = thumbnailSource.thumbnail as any;
		if (mediaThumbnail instanceof Array) {
			mediaThumbnail = getHighQThumbnailFromRenditions(mediaThumbnail);
		}
		return mediaThumbnail;
	}, [mediaDetail, thumbnailMedia]);

	const typeText = useMemo(() => {
		return getMediaTypeText(mediaDetail, mediaType, t);
	}, [mediaType, mediaDetail]);

	const realMediaType = useMemo(() => {
		return mediaType === mediaNames.trashedMedias ? mediaType : mediaDetail?.type ?? mediaType;
	}, [mediaDetail?.type, mediaType]);

	const onOpenShortcutMenu = useCallback(
		(e: React.MouseEvent<HTMLElement>, isMoreBtnClicked = false) => {
			if (isMiniLibrary) {
				return;
			}
			e.stopPropagation();
			e.preventDefault();

			setContextMenu(
				isMoreBtnClicked
					? e.currentTarget
					: {
							left: e.clientX + 2,
							top: e.clientY - 6,
					  }
			);
		},
		[isMiniLibrary]
	);

	const onContextMenuItemClick = useCallback(
		(item: any) => {
			setContextMenu(null);
			switch (item.value) {
				case mediaActionKeys.openMediaInNewWindow:
					window.open(getInspectLink(mediaDetail, false, mediaType), '_blank');
					break;
				case mediaActionKeys.inspectCatalog:
					window.open(getInspectLink(mediaDetail, true), '_self');
					break;
				default:
					onMediaActionMenuClick?.(mediaDetail, item.value);
			}
		},
		[onMediaActionMenuClick, mediaDetail, mediaType]
	);

	const inspectMedia = useCallback(() => {
		if (mediaDetail?.type === mediaTypes.catalog) {
			const catalogSubId = mediaDetail?.id ?? '';
			onLibraryViewChange?.('catalogs', catalogSubId);
			dispatch(setSelectedCatalogId(catalogSubId === '' ? 'allCatalog' : catalogSubId));
			setTimeout(() => {
				window.open(getInspectLink(mediaDetail), '_self');
			});
		} else {
			window.open(getInspectLink(mediaDetail, false, mediaType), '_self');
		}
	}, [mediaDetail, onLibraryViewChange, dispatch, mediaType]);

	if (!mediaDetail) {
		return null;
	}

	return (
		<>
			<tr
				className={mediaContainerBaseClassName}
				onContextMenu={onOpenShortcutMenu}
				onClick={isMiniLibrary ? () => onSelectMedia?.(mediaDetail) : inspectMedia}
			>
				<td style={{ textAlign: 'center' }}>
					<IconButton
						style={{ width: '32px', height: '32px' }}
						title={t('MEDIA_LIBRARY_MEDIA_DISPLAY_SELECT_MEDIA')}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							onSelectMedia?.(mediaDetail);
						}}
					>
						{isSelected ? <CheckBox htmlColor="#126cfc" /> : <CheckBoxOutlineBlank />}
					</IconButton>
				</td>
				<td>
					<div className={`${mediaContainerBaseClassName}__main`}>
						<div
							className={`${mediaContainerBaseClassName}__thumbnail`}
							style={mediaDetail.type === mediaTypes.catalog ? { width: 'auto' } : undefined}
						>
							{isMediaProcessing(restrictions ?? accountRestrictions, mediaDetail.appliedRestrictions) ? (
								<div className={`${mediaContainerBaseClassName}__main--processing`}>
									{t('COMMON_PROCESSING_VIDEO')}
								</div>
							) : (
								<img
									loading="lazy"
									className={
										!thumbnail ||
										thumbnail === no_thumbnail ||
										thumbnail.toString().toLowerCase().includes('nothumbnail')
											? `${mediaContainerBaseClassName}__main-no-thumbnail`
											: ''
									}
									src={thumbnail === no_thumbnail ? no_thumbnail : thumbnail}
								/>
							)}
						</div>
						<span
							className={`${mediaContainerBaseClassName}__title`}
							title={mediaDetail.title || t('COMMON_NO_TITLE')}
						>
							{mediaDetail.title || t('COMMON_NO_TITLE')}
						</span>
					</div>
				</td>
				{!isMobileView && (
					<>
						<td className={`list-media-display__right-align`}>
							{typeText && <span className={`${mediaContainerBaseClassName}__type`}>{typeText}</span>}
						</td>
						<td className={`list-media-display__right-align`}>
							<span className={`${mediaContainerBaseClassName}__date`}>
								{getFormattedDate(mediaDetail.created)}
							</span>
						</td>
					</>
				)}
				{!isMiniLibrary && (
					<td style={{ textAlign: 'center' }}>
						<IconButton
							style={{ width: '32px', height: '32px' }}
							title="Click to see actions"
							onClick={(e) => onOpenShortcutMenu(e, true)}
						>
							<MoreHoriz />
						</IconButton>
					</td>
				)}
			</tr>

			{Boolean(contextMenu) && (
				<Menu
					open={Boolean(contextMenu)}
					getContentAnchorEl={null}
					onClose={() => setContextMenu(null)}
					anchorReference={contextMenu?.top !== undefined ? 'anchorPosition' : undefined}
					anchorPosition={contextMenu}
					anchorEl={contextMenu?.top !== undefined ? undefined : contextMenu}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				>
					{(
						(ShortcutMenuItems as any)[realMediaType ?? 'no-type'] ??
						(ShortcutMenuItems as any)[typeText] ??
						ShortcutMenuItems.unknown
					)?.map((item: any, i: number) => {
						if (item.value === mediaActionKeys.editWithAE || item.value === mediaActionKeys.openAEProject) {
							if (!isAllowAEOption || isMobileView) {
								return null;
							}

							const isMediaCreatedByAEEditor = media.id.startsWith(EDITED_MEDIA_ID_PREFIX);

							if (isMediaCreatedByAEEditor && item.value === mediaActionKeys.editWithAE) {
								return null;
							}

							if (!isMediaCreatedByAEEditor && item.value === mediaActionKeys.openAEProject) {
								return null;
							}
						}

						if (item.value === mediaActionKeys.editAccessCatalog) {
							if (notAllowToShowEditCatalogAccess(media, catalogs, userId, userPermissions)) {
								return null;
							}
						}

						return (
							<MenuItem key={i} onClick={() => onContextMenuItemClick(item)}>
								<ListItemIcon style={{ minWidth: '30px', ...(item.style ?? {}) }}>
									{item.icon}
								</ListItemIcon>
								<ListItemText primary={t(item.text)} />
							</MenuItem>
						);
					})}
				</Menu>
			)}
		</>
	);
};
